<!-- start page title -->
<app-pagetitle title="FAQs" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row justify-content-center mt-3">
                    <div class="col-xl-5 col-lg-8">
                        <div class="text-center">
                            <h5>Can't find what you are looking for?</h5>
                            <p class="text-muted">If several languages coalesce, the grammar of the resulting language
                                is more simple and regular than that of the individual</p>
                            <div>
                                <button type="button" class="btn btn-primary mt-2 me-2 waves-effect waves-light">Email
                                    Us</button>
                                <button type="button" class="btn btn-success mt-2 waves-effect waves-light">Send us a
                                    tweet</button>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-xl-10">
                                    <form class="app-search d-none d-lg-block mt-4">
                                        <div class="position-relative">
                                            <input type="text" class="form-control" placeholder="Search...">
                                            <button class="btn btn-primary" type="button"><i
                                                    class="bx bx-search-alt align-middle"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->

                <div class="row mt-5">
                    <div class="col-xl-4 col-sm-6" *ngFor="let data of FaqsData">
                        <div class="card">
                            <div class="card-body overflow-hidden position-relative">
                                <div>
                                    <i class="bx {{data.icon}} widget-box-1-icon text-primary"></i>
                                </div>
                                <div class="faq-count">
                                    <h5 class="text-primary">{{data.no}}</h5>
                                </div>
                                <h5 class="mt-3">{{data.title}}</h5>
                                <p class="text-muted mt-3 mb-0">{{data.content}}</p>
                            </div>
                            <!-- end card body -->
                        </div>
                        <!-- end card -->
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end  card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->