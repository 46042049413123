import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/classes/ErrorResponse';
import { environment } from 'src/environments/environment';
import { Portfolio } from '../models/portfolioModels/portfolio.model';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {
 
  portfolioUrl!: string;
  downloadPortfolioDocumentsUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  constructor(private http: HttpClient) {
    this.portfolioUrl = environment.apiBase + '/portfolio/'
    this.downloadPortfolioDocumentsUrl =   environment.apiBase + '/portfolio-documents?id=';
  }

  public postPortfolio(body: string) {
    return this.http
      .post(environment.apiBase + '/portfolio', body, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  removePortfolio(id: number): Observable<any> {
    return this.http.delete(this.portfolioUrl + id);
  }

  getPortfolioById(id: number): Observable<Portfolio> {
    return this.http.get<Portfolio>(this.portfolioUrl + id);
  }

  getPortfolioImages(id: any): Observable<any> {
    return this.http.get(
      this.portfolioUrl + id + '/images',
      {
        observe: 'response'
      }
    );
  }

  getPortfolioImagesByPath(path: string) {
    return this.http.get(environment.apiBase + "/" + path, {
      observe: 'response',
    });
  }

  getPortfolioVideo(id: number): Observable<any> {
    return this.http.get(
      this.portfolioUrl + id + '/video',
      {
        observe: 'response'
      }
    );
  }

  getPortfolioDocument(id: number): Observable<any> {
    return this.http.get(this.downloadPortfolioDocumentsUrl + id, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  uprdatePortfolio(id: number, portfolio: Portfolio): Observable<any> {
    return this.http.put(this.portfolioUrl + id, portfolio);
  }

  public postImages(
    portfolioId: number,
    files: any,
    main: any,
    cityPhotoFile: File
  ) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('main', main.value);
    formData.append('files', cityPhotoFile);
    formData.append('city', cityPhotoFile.name);
    return this.http
      .post(environment.apiBase + '/portfolio/' + portfolioId + '/images', formData)
      .pipe(catchError(this.handleError));
  }
  
  public deleteVideo(portfolioId: number) {
    return this.http
      .delete(environment.apiBase + '/portfolio/' + portfolioId + '/video')
      .pipe(catchError(this.handleError));
  }

  public postVideo(portfolioId: number, file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post(environment.apiBase + '/portfolio/' + portfolioId + '/video', formData)
      .pipe(catchError(this.handleError));
  }


  public postDocuments(portfolioId: number, files: any) {
    const formData: FormData = new FormData();
    let listofFiles: any = [];
    for (const list of Object.entries(files)) {
      if (list[1]) {
        for (const iterator of list[1] as []) {
          listofFiles.push(iterator['file']);
          formData.append('files', iterator['file']);
          formData.append('params', JSON.stringify({"name": iterator['name'],
          "subTypeId": iterator['subtype']
          }));
        }
      }
    }
    if (!formData.has('params')) {
      formData.append('params', '');
    }
    return this.http
      .post(environment.apiBase + '/portfolio/' + portfolioId + '/documents', formData)
      .pipe(catchError(this.handleError));
  }

  removeToken(symbol: string): Observable<any> {
    const formData: FormData = new FormData();
    var operations = {
      query:
        `
                mutation{
                  removePortfolioToken(symbol:"` +
        symbol +
        `") {
                        transactionHash
                        blockNumber
                        effectiveGasPrice
                        contractAddress
                    }
                }
            `,
      variables: null,
    };
    formData.append('operations', JSON.stringify(operations));
    return this.http.post(environment.blockchainBase, formData);
  }

  saveToken(
    id: number,
    symbol: string,
    transactionHash: string
  ): Observable<any> {
    return this.http.post(this.portfolioUrl + 'saveToken/' + id, {
      symbol,
      transactionHash,
    });
  }

  private handleError(error: ErrorResponse) {
    let errorMessage = '';
    if (error.errorMessage != null) {
      errorMessage = `Error: ${error.errorMessage}`;
    } else  if (error.httpStatus != null) {
      errorMessage = `Error Code: ${error.httpStatus}`;
    } else {
      errorMessage = `Error Code: ${error}`;
    }
    return throwError(errorMessage);
  }
  
}
