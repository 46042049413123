import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class AcceptedFileTypeService {

    docExcetnsions: Array<string> = ["doc", "dot", "docx", "dotx", "docm", "dotm"];
    xslExcetnsions: Array<string> = ["xls", "xlt", "xla", "xlsx", "xltx", "xlsm", "xltm", "xlam", "xlsb"];
    pttExcetnsions: Array<string> = ["ppt", "pot", "pps", "ppa", "pptx", "potx", "ppsx", "ppam", "pptm", "potm", "ppsm"];
    imgExcetnsions: Array<string> = ["png", "jpg", "jpeg"];
    pdfExcetnsions: Array<string> = ["pdf"];


    constructor() {
    }

    validateFileTypes(files: File[]): any {
        let res = {
            valid: true,
            files: [] as any
        }
        for (let i = 0; i < files.length; i++) {
            let extension = files[i].name.split(".")[files[i].name.split(".").length - 1];
            if (!this.docExcetnsions.includes(extension) && !this.xslExcetnsions.includes(extension)
                && !this.pttExcetnsions.includes(extension) && !this.imgExcetnsions.includes(extension) && !this.pdfExcetnsions.includes(extension)) {
                    res.valid = false;
                    res.files.push(files[i].name)
            }
        }
        return res;
    }
}