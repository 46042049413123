import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { LocationSocialEconomicsPinService } from 'src/app/core/services/location-social-economics-pin.service';

@Component({
  selector: 'social-economics',
  templateUrl: './social-economics.component.html',
  styleUrls: ['./social-economics.component.scss']
})
export class SocialEconomicsComponent implements OnInit {

  @Input("form")
  socialEconomicsForm!: FormGroup;

  @Input("formsubmit")
  formsubmit!: boolean;
  public separator: string = ',';

  constructor(private locationSocialEconomicsPinService: LocationSocialEconomicsPinService ) { }

  ngOnInit(): void {
    
    this.socialEconomicsCities.valueChanges.subscribe(
      (res) =>{
        this.onStartUp(this.socialEconomicsCities);
        this.onStartUp(this.socialEconomicsMunicipalities);
        this.onStartUp(this.socialEconomicsStates);
      }
    )
  }

  get socialEconomicsCities(){
    return this.socialEconomicsForm.get('socialEconomicsCities') as FormArray
  }

  get socialEconomicsMunicipalities(){
    return this.socialEconomicsForm.get('socialEconomicsMunicipalities') as FormArray
  }
  get socialEconomicsStates(){
    return this.socialEconomicsForm.get('socialEconomicsStates') as FormArray
  }

  private onStartUp(array: FormArray){
    for (let control of array.controls){
      if (control.valid) {
        let locationPin = this.locationSocialEconomicsPinService.getLocationByRegionAndLevel(control.get('region')?.value, control.get('administrative_level')?.value);
        locationPin.locationSocialEconomics!.expectedPopulationGrowth = +control.get('expected_population_growth')?.value;
        locationPin.locationSocialEconomics!.netNumberOfCommuters = +control.get('commuters')?.value;
        locationPin.locationSocialEconomics!.numberOfInhabitants = +control.get('number_of_inhabitants')?.value;
        locationPin.locationSocialEconomics!.retailCentrality = +control.get('centrality')?.value;
        locationPin.locationSocialEconomics!.retailPurchasingPower = +control.get('purchasing_power')?.value;
      }
    }
  }
}
