import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/classes/user';
import { Status } from 'src/app/core/enums/status_enum';
import { UserProfileService } from 'src/app/core/services/user.service';
import {
  costuemerDataTableSortableDirective,
  CustomerSortEvent,
} from './customers.datatable-sortable.directive';
import { CustomerTable } from './customers.datatable.model';
import { CustomerDataTableService } from './customers.datatable.service';

@Component({
  selector: 'app-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss'],
})
export class CustomersTableComponent implements AfterViewInit {
  usersList!: User[];
  Status: typeof Status = Status;
  @ViewChildren(costuemerDataTableSortableDirective)
  headers!: QueryList<costuemerDataTableSortableDirective>;
  hideme: boolean[] = [];
  tables$: Observable<CustomerTable[]>;
  total$: Observable<number>;

  constructor(public tableService: CustomerDataTableService, private userService: UserProfileService, public translate: TranslateService) { 
    this.tables$ = tableService.tables$;
    this.total$ = tableService.total$;
  }

  @Input()
  purpose!: string;

  ngAfterViewInit() {
    this._fetchData();
  }

  _fetchData() {
    if (this.purpose === 'customers') {
      this.userService.getAllAcceptedUsers().subscribe((response) => {
        let arr = [];
        for (let i = 0; i < response.length; i++) {
          arr[i] = {
            id: response[i].id + '',
            name: response[i].name,
            lastName: response[i].lastName,
            lei: response[i].lei,
            status: this.ajustStatus(response[i].registrationStatus.status),
            email: response[i].email,
            documents: response[i].documents.length | 0,
          };
        }
        this.tableService.tableData = arr;
        this.tableService.setPaging();
      });
    } else {
      this.userService.getAllRegistrationRequests().subscribe((response) => {
        let arr = [];
        for (let i = 0; i < response.length; i++) {
          arr[i] = {
            id: response[i].id + '',
            name: response[i].name,
            lastName: response[i].lastName,
            lei: response[i].lei,
            status: this.ajustStatus(response[i].registrationStatus.status),
            email: response[i].email,
            documents: response[i].documents.length | 0,
          };
        }
        this.tableService.tableData = arr;
        this.tableService.setPaging();
      });
    }
  }

  onSort(event: any) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.costSorTable !== event.column) {
        header.direction = '';
      }
    });
    this.tableService.sortColumn = event.column;
    this.tableService.sortDirection = event.direction;
  }

  onSearch(){
    this.tableService.onSearch();
  }

  ajustStatus(status: string): string {
    switch (status) {
      case 'STATUS_ACCEPTED':
        return 'Accepted';
      case 'STATUS_REGISTRATION_PENDING':
        return 'Pending';
      case 'STATUS_REOPENED':
        return 'Reopened';
      case 'STATUS_WAITING_FOR_DOCUMENTS':
        return 'Waiting for documents';
      case 'STATUS_WAITING_FOR_ADDITIONAL_DOCUMENTS':
        return 'Waiting for additional documents';
      case 'STATUS_REJECTED':
        return 'Rejected';
      default:
        return 'Unknown';
    }
  }

  translateStatus(status: string){
    switch (status) {
      case 'Accepted':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.ACCEPTED';
      case 'Pending':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.PENDING';
      case 'Reopened':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.REOPENED';
      case 'Waiting for documents':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.WAITINGFORDOCUMENTS';
      case 'Waiting for additional documents':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.WAITINGFORADDITIONALDOCUMENTS';
      case 'Rejected':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.REJECTED';
      default:
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.UNKNOWN';
    }
  }
}
