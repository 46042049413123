import { HttpClient } from '@angular/common/http';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import '@geoman-io/leaflet-geoman-free';
import * as L from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';

@Component({
  selector: 'app-here-map',
  templateUrl: './here-map.component.html',
  styleUrls: ['./here-map.component.scss'],
})
export class HereMapComponent implements OnInit, AfterViewChecked {
  @ViewChild('map')
  public mapElement!: ElementRef;
  @Input() showControls!: Boolean;
  @Output() mapValid = new EventEmitter<any>();

  public listOfPolygons: any[] = [];
  public listOfMarkers: any[] = [];
  public accessToken!: string;

  public platform: any;
  public geocoder: any;

  poligon!: any;

  public searchValue!: string;

  private map: any;
  public srcTiles!: string;

  private provider: OpenStreetMapProvider = new OpenStreetMapProvider();
  private searchControl: any = GeoSearchControl({
    notFoundMessage: 'Sorry, that address could not be found.',
    provider: this.provider,
    showMarker: false,
  });

  public constructor(private http: HttpClient) {
    this.accessToken =
      'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw';
    this.poligon = new Array<[]>();
  }
  ngAfterViewChecked(): void {
    this.map.invalidateSize();
  }

  public ngOnInit() {
    this.srcTiles =
      'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' +
      this.accessToken;
  }

  public ngAfterViewInit() {
    this.map = L.map(this.mapElement.nativeElement, {
      center: [50.112209, 8.682268],
      zoom: 13,
      layers: [
        L.tileLayer(this.srcTiles, {
          maxZoom: 18,
          tileSize: 512,
          zoomOffset: -1,
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        }),
      ],
      zoomControl: true,
    });
    if (this.showControls) {
      this.map.pm.addControls({
        position: 'topleft',
        drawCircle: false,
        drawCircleMarker: false,
        drawPolyline: false,
        drawRectangle: false,
        drawPolygon: true,
        editMode: false,
        dragMode: false,
        cutPolygon: false,
        removalMode: true,
        drawMarker: true,
      });
      this.map.addControl(this.searchControl);
    }

    L.Icon.Default.imagePath = 'assets/leaflet/';

    this.map.on('pm:create', (e: any) => {
      if (e.shape === 'Marker') {
        this.listOfMarkers.push(e.layer._latlng);
      } else {
        this.listOfPolygons.push(e.layer._latlngs);
      }
      if (this.listOfMarkers.length !== 0 || this.listOfPolygons.length !== 0) {
        this.validateMap(true);
      } else {
        this.validateMap(false);
      }
    });
    this.map.on('pm:remove', (e: any) => {
      if (e.shape === 'Marker') {
        for (const iterator of this.listOfMarkers) {
          if (e.layer._latlng.lat === iterator.lat && e.layer._latlng.lng === iterator.lng) {
            this.listOfMarkers.splice(this.listOfMarkers.indexOf(iterator), 1);
          }
        }
      } else {
        for (const iterator of this.listOfPolygons) {
          if (e.layer._latlngs === iterator) {
            this.listOfPolygons.splice(
              this.listOfPolygons.indexOf(iterator),
              1
            );
          }
        }
      }
      if (this.listOfMarkers.length !== 0 || this.listOfPolygons.length !== 0) {
        this.validateMap(true);
      } else {
        this.validateMap(false);
      }
    });
  }
  validateMap(value: any) {
    this.mapValid.emit(value);
  }

  public drowMarker(lat: number, lng: number, socEconomics: any) {
    let socioEconomics =
      '<b>Social economics parametars</b><br>' +
      'Region: ' +
      (socEconomics.region ?? 'NaN') +
      '<br>' +
      'Number of Inhabitants: ' +
      (socEconomics.number_of_inhabitants ?? 'NaN') +
      '<br>' +
      'Expected population growth: ' +
      (socEconomics.expected_population_growth ?? 'NaN') +
      '<br>' +
      'Net number of commuters: ' +
      (socEconomics.commuters ?? 'NaN') +
      '<br>' +
      'Retail centrality (Ø = 100): ' +
      (socEconomics.centrality ?? 'NaN') +
      '<br>' +
      'Retail purchasing power (Ø = 100): ' +
      (socEconomics.purchasing_power ?? 'NaN') +
      '<br>';
    this.listOfMarkers.push(new L.LatLng(lat,lng));
    L.marker([lat, lng], { pmIgnore: false })
      .addTo(this.map)
      .bindPopup(socioEconomics, { closeOnClick: false, closeButton: false });
  }

  public drowPolygon(latLangs: []) {
    L.polygon(latLangs, { pmIgnore: false }).addTo(this.map);
  }

  public flyTo(lat: number, lng: number) {
    this.map.setView([lat, lng], 10);
    this.map.invalidateSize();
  }
}
