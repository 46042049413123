<!-- breadcrumb item -->
<div class="row">
  <div class="col-12">
    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
      <div class="d-flex flex-row align-items-center">
      <button type="button" *ngIf="displayBackButton" class="btn btn-outline-info backButton logo-dark" routerLink={{backRout}}>
        <i class="mdi mdi-arrow-left" style="font-size: 1em;"></i>
        Back
      </button>
      <button type="button" *ngIf="displayBackButton" class="btn btn-outline-info backButton-light logo-light" routerLink={{backRout}}>
        <i class="mdi mdi-arrow-left" style="font-size: 1em;"></i>
        Back
      </button>
      <h4 class="mb-sm-0 font-size-18">{{ title }}</h4>
    </div>
      <div class="page-title-right">
        <ol class="breadcrumb m-0">
          <ng-container *ngFor="let item of breadcrumbItems">
            <li class="breadcrumb-item" [ngClass]="{'active': item.active == true}">{{ item.label }}
            </li>
          </ng-container>
        </ol>
      </div>
    </div>
  </div>
</div>
