import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { Portfolio } from 'src/app/core/models/portfolioModels/portfolio.model';
import { PortfolioService } from 'src/app/core/services/portfolio.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WizarService } from 'src/app/core/services/wizard.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Usage } from 'src/app/classes/Usage';
import { Highlight } from 'src/app/classes/Highlight';
import { Ownership } from 'src/app/classes/Ownership';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BreakDownDialogComponent } from '../onboarding-wizard/break-down-dialog/break-down-dialog.component';
import { HereMapComponent } from 'src/app/shared/here-map/here-map.component';
import { formatNumber } from '@angular/common';
import { EChartsOption } from 'echarts';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { RemoveNdaService } from 'src/app/core/services/removeNda.service';
import { AcceptedFileTypeService } from 'src/app/core/services/acceptedFileType.service';
import { MyUploadAdapter } from 'src/app/classes/myUploadAdapter';
import { GoogleMapComponent } from 'src/app/shared/google-map/google-map.component';
import { ShowPinOnMapService } from 'src/app/core/services/showPinOnMap.service ';
import { GeocoderService } from 'src/app/core/services/geocoder.service';

@Component({
  selector: 'app-update-portfolio',
  templateUrl: './update-portfolio.component.html',
  styleUrls: ['./update-portfolio.component.scss']
})
export class UpdatePortfolioComponent implements OnInit {

  breadCrumbItems!: Array<{}>;
  public Editor = ClassicEditor;
  editorConfig  = {
    placeholder: 'Type the content here!',
    
  };
  public separator: string = ',';

  @ViewChild('myEditor') myEditor: any;

  @ViewChild('ngSelectComponent') ngSelectComponent!: NgSelectComponent;

  /*@ViewChild('map')
  public mapElement!: HereMapComponent;*/

  @ViewChild('googleMap')
  public mapElement!: GoogleMapComponent;

  curretnYear!: number;
  yearsArr: string[] = [];
  yearsArrFloating: string[] = [];

  usages!: Array<Usage>;
  ownerships!: Array<Ownership>;
  highlights!: Array<Highlight>;


  portfolio!: Portfolio;
  isInitialized: boolean = false;
  galleryOptions!: NgxGalleryOptions[];
  galleryImages!: [];
  propertyDetailsForm!: FormGroup;
  listOfImagesName: Array<any> = [];
  cityImageUrl!: string;
  openModalVar: boolean = false;

  secondStepForm!: FormGroup;
  rentForm!: FormGroup;
  topFiveValue: number = 0;
  othersValue: number = 0;
  rentSum!: number;
  lettableAreaSum!: number;
  dynamicData: any = {};
  showTenantInfo: boolean = true;
  showLeasData: boolean = false;
  breakOptionsSum: number = 0;
  leasEndSum: number = 0;
  extensionOptionsSum: number = 0;

  thirdStepForm!: FormGroup;
  debtSituationForm!: FormGroup;
  debtBreakDownForm!: FormGroup;
  ltv: number = 0

  filesTypesSubtypesList: any = {
    ndaFiles: [],
    cadastralMapsFiles: [],
    landRegistrationExcerptFiles: [],
    contaminationRegisterExFiles: [],
    buildingPermitFiles: [],
    floorSpaceTableFiles: [],
    curvatureAnalysisFiles: [],
    floorPlanFiles: [],
    objectDescriptionFiles: [],
    rentRollFiles: [],
    sampleRentalAgreementFiles: [],
    analysisOfAncillaryCostsFiles: [],
    valuationReportFiles: [],
    annualAccountsFiles: [],
    techDueDiligenceFiles: [],
    insuranceAgreementFiles: [],
    organizationStructureFiles: [],
    legalAndRegulatoryFiles: [],
    portfolioMoreInfo: [],
    otherFiles: [],
  };

  formsubmit: boolean = false;

  updatePortfolioForm!: FormGroup;
  leasDataForHttp: any;
  highlightsForPreview: any = [];


  fourthStepForm!: FormGroup;

  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private portfolioService: PortfolioService,
    private wizardService: WizarService,
    private modalService: NgbModal,
    private router: Router,
    private removeNdaService: RemoveNdaService,
    private acceptedFileTypeService: AcceptedFileTypeService,
    private showPinOnMapService: ShowPinOnMapService,
    private geocoderService: GeocoderService
    ) {
    this.breadCrumbItems = [
      { label: 'Onboarded assets' },
      { label: 'Show/Edit portfolio', active: true },
    ];
    this.curretnYear = new Date().getFullYear();
    for (let index = 0; index < 11; index++) {
      let temp = this.curretnYear + index;
      this.yearsArr.push(temp.toString());
      this.yearsArrFloating.push(temp.toString());
    }

    this.yearsArr[this.yearsArr.length - 1] =
      '>' + this.yearsArr[this.yearsArr.length - 1];

    this.yearsArrFloating[this.yearsArrFloating.length - 1] =
      '>' + this.yearsArrFloating[this.yearsArrFloating.length - 1];
    this.yearsArr.push('unlimited');
    this.yearsArrFloating.push('floating');
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.portfolio != null) {
        let locationMapDetails = this.portfolio.locationMapDetails;
        for (let i = 0; i < locationMapDetails.mapInfo.length; i++) {
          if (locationMapDetails.mapInfo[i].type == 'marker') {
            this.mapElement.drowMarker({
              lat:locationMapDetails.mapInfo[i].coordinates[0].lat,
              lng:locationMapDetails.mapInfo[i].coordinates[0].lng
            });
            this.mapElement.flyTo(
              locationMapDetails.mapInfo[i].coordinates[0].lat,
              locationMapDetails.mapInfo[i].coordinates[0].lng
            );
          }
          if (locationMapDetails.mapInfo[i].type == 'polygon') {
            locationMapDetails.mapInfo[i].coordinates.sort((x,y)=> {
              return x.orderNumber -y.orderNumber;
            })
            this.mapElement.drowPolygon(locationMapDetails.mapInfo[i].coordinates);
          }
        }
      }
    }, 2500);
  }

  ngOnInit(): void {
    this._fetchData();
  }


  _fetchData() {
    let id = 0;
    this.route.paramMap.subscribe((params: ParamMap) => {
      id = +params.get('id')!;
    });
    this.portfolioService.getPortfolioById(id).subscribe((res) => {
      this.portfolio = res;
      let highlightsForm = this.fb.group({
        highlights: this.fb.array([]),
      });
      this.portfolio.portfolioHighlights.sort(function (a, b) {
        return a.orderNumber - b.orderNumber;
      });
      let cities = this.fb.array([])
      for (let i = 0; i < this.portfolio.cities.length; i++) {
        const cityForm = this.fb.group({
          id: [this.portfolio.cities[i].id, Validators.required],
          city: [this.portfolio.cities[i].city, Validators.required],
        });
        cities.push(cityForm);
      }
      let states = this.fb.array([])
      if (this.portfolio.federalStates.length === 0) {
        const stateForm = this.fb.group({
          federalState: ['', Validators.required]
        });
        states.push(stateForm)
      } else {
        for (let i = 0; i < this.portfolio.federalStates.length; i++){
          const stateForm = this.fb.group({
            id: [this.portfolio.federalStates[i].id, Validators.required],
            federalState: [this.portfolio.federalStates[i].federalState, Validators.required]
          });
          states.push(stateForm)
        }
      }
      let highlightArrayForm = highlightsForm.get('highlights') as FormArray;
      for (let i = 0; i < this.portfolio.portfolioHighlights.length; i++) {
        const highlight = this.fb.group({
          id: [this.portfolio.portfolioHighlights[i].highlight.id, Validators.required],
          orderNumber: [
            this.portfolio.portfolioHighlights[i].orderNumber || i + 1,
            Validators.required,
          ],
          pictureName: [
            this.portfolio.portfolioHighlights[i].highlight.pictureName,
            Validators.required,
          ],
          highlightDescription: [
            this.portfolio.portfolioHighlights[i].description || '',
            Validators.required,
          ],
        });
        highlightArrayForm.push(highlight);
      }
      this.galleryImages = [];
      let portfolioUsages = [];
      for (let i = 0; i < this.portfolio.portfolioUsages.length; i++) {
        portfolioUsages.push(this.portfolio.portfolioUsages[i].usage);
      }
      this.lettableAreaSum = res.lettableArea;
      let areaBreakDown = [];
      let rentBreakDown = [];
      for (let i = 0; i < this.portfolio.portfolioUsages.length; i++) {
        const areaBreakDownRow = this.fb.group({
          description: [
            this.portfolio.portfolioUsages[i].usage.description,
            Validators.required,
          ],
          id: [this.portfolio.portfolioUsages[i].usage.id, Validators.required],
          value: [
            this.portfolio.portfolioUsages[i].areaBreakdownValue,
            [Validators.required, Validators.min(0)],
          ],
        });
        const rentBreakDownRow = this.fb.group({
          description: [
            this.portfolio.portfolioUsages[i].usage.description,
            Validators.required,
          ],
          id: [this.portfolio.portfolioUsages[i].usage.id, Validators.required],
          value: [
            this.portfolio.portfolioUsages[i].rentBreakdownValue,
            [Validators.required, Validators.min(0)],
          ],
        });
        areaBreakDown.push(areaBreakDownRow);
        rentBreakDown.push(rentBreakDownRow);
      }

      let ownerships = [];
      for (let i = 0; i < this.portfolio.ownerships.length; i++) {
        const ownershipsForm = this.fb.group({
          id: [this.portfolio.ownerships[i].ownership.id, Validators.required],
          description: [this.portfolio.ownerships[i].ownership.description, Validators.required],
        });
        ownerships.push(this.portfolio.ownerships[i].ownership);
      }

      let construction_years = []
      for (let i = 0; i < this.portfolio.constructionYears.length; i++) {
        construction_years.push(this.portfolio.constructionYears[i].year);
      }
      let refurbishment_years = []
      for (let i = 0; i < this.portfolio.lastRefurbishments.length; i++) {
        refurbishment_years.push(this.portfolio.lastRefurbishments[i].year);
      }

      this.propertyDetailsForm = this.fb.group({
        id: [this.portfolio.id, [Validators.required]],
        title: [this.portfolio.title, [Validators.required]],
        cities: cities,
        states: states,
        description: [this.portfolio.description, [Validators.required]],
        construction_years: [construction_years.toString(), [Validators.required]],
        refurbishment_years: [refurbishment_years.toString()],
        multiUsageOption: [portfolioUsages, [Validators.required]],
        multiUsageOptionText: [this.portfolio.portfolioUsageText, [Validators.required]],
        ownershipOption: [ownerships, [Validators.required]],
        highlightOption: highlightArrayForm,
        plot_size: [this.portfolio.plotSize, Validators.required],
        parking_lots: [this.portfolio.numberOfParkingLots, Validators.required],
        occupancy_rate: [
          this.portfolio.occupancyRate,
          [Validators.required, Validators.min(0), Validators.max(100)],
        ],
        walt: [this.portfolio.walt, Validators.required],
        enabled: [this.portfolio.enabled, Validators.required],
        images: [null, Validators.required],
        video: [null],
        areaBreakdown: this.fb.array(areaBreakDown, Validators.required),
        rentBreakdown: this.fb.array(rentBreakDown, Validators.required),
        location_description: [
          this.portfolio.locationDescription,
          Validators.required,
        ],
        cityImage: [null, Validators.required],
        listOfImagesName: [
          { value: this.listOfImagesName, disabled: false },
          [Validators.required],
        ],
        socialEconomics: this.fb.group({
          socialEconomicsCities: this.fb.array([]),
          socialEconomicsMunicipalities: this.fb.array([]),
          socialEconomicsStates: this.fb.array([]),
        }),
        map: ['ok', [Validators.required]],
        highlightSection: [this.portfolio.highlightSection, ]
      });
      let fromRentBreakdownArray = this.propertyDetailsForm.get(
        'rentBreakdown'
      ) as FormArray;
      this.rentSum = 0;
      for (let i = 0; i < fromRentBreakdownArray.controls.length; i++) {
        let breakdownValue =
          fromRentBreakdownArray.controls[i].get('value')?.value;
        this.rentSum += breakdownValue * 1;
      }
      let tenantValue = (this.portfolio as any).tenantValue;
      this.topFiveValue = tenantValue?.top5Value ?? 0;
      this.othersValue = tenantValue?.othersValue ?? this.rentSum - this.topFiveValue;
      const rentFormVar = (this.rentForm = this.fb.group({
        topFive: [this.topFiveValue, [Validators.required]],
        others: [
          this.othersValue,
          [Validators.required, Validators.min(0)],
        ],
        rentTotal: [this.rentSum],
      }));
      

      
      if (this.portfolio.leaseExpiryProfile != undefined) {
        this.showLeasData = true;
        this.breakOptionsSum =
        this.leasEndSum =
        this.extensionOptionsSum =
        this.rentSum;
      } else {
        this.breakOptionsSum =
        this.leasEndSum =
        this.extensionOptionsSum = 0;
      }

      
      let leasArr = [];
      if (this.showLeasData == true) {
        for (
          let i = 0;
          i < this.portfolio.leaseExpiryProfile?.leaseData.source.length;
          i++
        ) {
          this.yearsArr[i] = this.portfolio.leaseExpiryProfile?.leaseData.source[i][0];
          const rowForm = this.fb.group({
            breakOptionsControl: [
              this.portfolio.leaseExpiryProfile?.leaseData.source[i][1],
              [Validators.required],
            ],
            leasEndControl: [
              this.portfolio.leaseExpiryProfile?.leaseData.source[i][2],
              [Validators.required],
            ],
            extensionOptionsControl: [
              this.portfolio.leaseExpiryProfile?.leaseData.source[i][3],
              [Validators.required],
            ],
          });
          leasArr.push(rowForm);
        }
      } else {
        for (let index = 0; index < this.yearsArr.length; index++) {
          const leasDataRow = this.fb.group({
            breakOptionsControl: [0,],
            leasEndControl: [0,],
            extensionOptionsControl: [0,],
          });
          leasArr.push(leasDataRow);

        }
      }
      
      if (this.portfolio.portfolioTenants.length == 0) {
        this.showTenantInfo = false;
      }
      this.portfolio.portfolioTenants.sort(function(a, b){return a.orderValue - b.orderValue});
      this.secondStepForm = this.fb.group({
        rentData: rentFormVar,
        firstTenant: [
          this.portfolio.portfolioTenants[0]?.tenantName || '',
          [Validators.required],
        ],
        secondTenant: [
          this.portfolio.portfolioTenants[1]?.tenantName || '',
          [Validators.required],
        ],
        thirdTenant: [
          this.portfolio.portfolioTenants[2]?.tenantName || '',
          [Validators.required],
        ],
        fourthTenant: [
          this.portfolio.portfolioTenants[3]?.tenantName || '',
          [Validators.required],
        ],
        fifthTenant: [
          this.portfolio.portfolioTenants[4]?.tenantName || '',
          [Validators.required],
        ],
        leasData: this.fb.array(leasArr),
      });

      if (this.showLeasData == false) {
        this.leasDataForm.disable();
        this.leasDataForm.controls.map((ctrl) => {
          ctrl.removeValidators([Validators.required]);
        });
      }

      const debtSituationFormVar = (this.debtSituationForm = this.fb.group({
        grossAssetValue: [
          this.portfolio.grossValue,
          [Validators.required, Validators.min(0)],
        ],
        totalDebt: [this.portfolio.debt, [Validators.required, Validators.min(0)]],
        debtOutstanding: [
          this.portfolio.debtOutstanding,
          [Validators.required, Validators.min(0)],
        ],
        debtService: [
          this.portfolio.debtService,
          [Validators.required, Validators.min(0)],
        ],
      }));

      this.ltv = (this.portfolio.debtOutstanding / this.portfolio.grossValue) * 100;
      let debtBreakDownValues = JSON.parse(this.portfolio.debtBreakdown.debtBreakdown);
      let debtBreakDownFormValues = this.fb.array([]);
      let debtBreakDownSum = 0;
      for (let i = 0; i < this.yearsArrFloating.length; i++) {
        debtBreakDownSum += debtBreakDownValues[i].debtBreakDownValue;
        const debtFrom = this.fb.group({
          debtBreakDownValue: [debtBreakDownValues[i].debtBreakDownValue, [Validators.required, Validators.min(0)]],
        });
        debtBreakDownFormValues.push(debtFrom);
      }

      this.thirdStepForm = this.fb.group({
        debtSituation: debtSituationFormVar,
        debtBreakDown: debtBreakDownFormValues,
        debtBreakDownSum: [
          { value: debtBreakDownSum, disabled: true },
          [Validators.required, Validators.min(0)],
        ],
      });
      this.debtSituationForm.valueChanges.subscribe((value) => {
        let totalGrossAssetValue = 0;
        let totalDebtOutStanding = 0;

        for (var key in value) {
          if (value.hasOwnProperty(key)) {
            if (key === 'grossAssetValue') {
              totalGrossAssetValue = value[key];
            } else if (key === 'debtOutstanding') {
              totalDebtOutStanding = value[key];
            }
          }
        }
        if (totalGrossAssetValue !== 0) {
          this.ltv = (totalDebtOutStanding / totalGrossAssetValue) * 100;
        }
      });

      for (let i = 0; i < this.portfolio.portfolioDocuments.length; i++) {
        let doc = this.portfolio.portfolioDocuments[i];
        let name = this.trimPath(doc.path);
        this.portfolioService.getPortfolioDocument(doc.id).subscribe((file) => {
          let fileData = new File([file.body], name, { type: file.body.type });
          this.addFile(fileData, doc.assetDocumentSubtype.id);
        });
      }

      this.getAllUsages();
      this.getAllOwnerships();
      this.getAllHighlights();
      this.getPortfolioImagesWithUrl();
      this.getPortfolioVideo(+this.portfolio.id);

      this.propertyDetailsForm.get('multiUsageOption')?.valueChanges.subscribe((values) => {
        let oldArea = this.propertyDetailsForm.get(
          'areaBreakdown'
        ) as FormArray;
        let oldRent = this.propertyDetailsForm.get(
          'rentBreakdown'
        ) as FormArray;
        let newArea = this.fb.array([]);
        let newRent = this.fb.array([]);
        for (const value of values) {
          let numAreaValue = undefined;
          for (let odlAreaValue of oldArea.value) {
            if (odlAreaValue.description == value.description) {
              numAreaValue = odlAreaValue.value;
              break;
            }
          }
          let numRentValue = undefined;
          for (let odlRentValue of oldRent.value) {
            if (odlRentValue.description == value.description) {
              numRentValue = odlRentValue.value;
              break;
            }
          }
          const areaBreakDownRow = this.fb.group({
            description: [
              value.description,
              Validators.required,
            ],
            id: [value.id, Validators.required],
            value: [
              numAreaValue,
              [Validators.required, Validators.min(0)],
            ],
          });
          const rentBreakDownRow = this.fb.group({
            description: [
              value.description,
              Validators.required,
            ],
            id: [value.id, Validators.required],
            value: [
              numRentValue,
              [Validators.required, Validators.min(0)],
            ],
          });
          newArea.push(areaBreakDownRow);
          newRent.push(rentBreakDownRow);
        }
        let formArray = this.propertyDetailsForm.get(
          'areaBreakdown'
        ) as FormArray;
        this.clearFormArray(formArray);
        for (let area of newArea.controls) {
          formArray.push(area);
        }
        //for rent
        let formArrayRent = this.propertyDetailsForm.get(
          'rentBreakdown'
        ) as FormArray;
        this.clearFormArray(formArrayRent);
        for (let rent of newRent.controls) {
          formArrayRent.push(rent);
        }
      });

      this.topFive?.valueChanges.subscribe((value) => {
        if (value) {
          this.topFiveValue = value;
        } else {
          this.topFiveValue = 0;
        }
        this.othersValue = this.rentSum - this.topFiveValue;
        this.others?.setValue(this.othersValue);
        this.updateChart();
      });

      this.rentTotal?.valueChanges.subscribe((value) => {
        this.othersValue = value - this.topFiveValue;
        this.others?.setValue(this.othersValue);
        this.updateChart();
      });

      this.rentBreakDownRow?.valueChanges.subscribe((value) => {
        this.rentSum = 0;
        for (let i = 0; i < value.length; i++) {
          this.rentSum += value[i].value * 1;
        }
        this.rentTotal?.setValue(this.rentSum);
      });

      this.leasDataForm.valueChanges.subscribe((value) => {
        let sumBreakOptions = 0;
        let sumLeasEnd = 0;
        let sumExtensionOptions = 0;
        for (let i = 1; i < value.length; i++) {
          if (
            value[i].breakOptionsControl &&
            value[i].breakOptionsControl != 0
          ) {
            sumBreakOptions += value[i].breakOptionsControl;
          }
          if (value[i].leasEndControl && value[i].leasEndControl != 0) {
            sumLeasEnd += value[i].leasEndControl;
          }
          if (
            value[i].extensionOptionsControl &&
            value[i].extensionOptionsControl != 0
          ) {
            sumExtensionOptions += value[i].extensionOptionsControl;
          }
        }

        this.breakOptionsSum = sumBreakOptions * 1;
        this.leasEndSum = sumLeasEnd * 1;
        this.extensionOptionsSum = sumExtensionOptions * 1;
      });

      this.debtBreakDown.valueChanges.subscribe((value) => {
        let sumOfDebtBreakDown = 0;
        for (const iterator of value) {
          if (iterator.debtBreakDownValue > 0) {
            sumOfDebtBreakDown =
              +sumOfDebtBreakDown + +iterator.debtBreakDownValue;
            this.thirdStepForm.controls['debtBreakDownSum'].setValue(
              sumOfDebtBreakDown
            );
          }
        }
      });
      this.debtSituationForm.valueChanges.subscribe((value) => {
        let totalGrossAssetValue = 0;
        let totalDebtOutStanding = 0;

        for (var key in value) {
          if (value.hasOwnProperty(key)) {
            if (key === 'grossAssetValue') {
              totalGrossAssetValue = value[key];
            } else if (key === 'debtOutstanding') {
              totalDebtOutStanding = value[key];
            }
          }
        }
        if (totalGrossAssetValue !== 0) {
          this.ltv = (totalDebtOutStanding / totalGrossAssetValue) * 100;
        }
      });
      this.updateChart();
      this.showTopTenantSwitch();
      this.updatePortfolioForm = this.fb.group({
        propertyDetailsForm: this.propertyDetailsForm,
        secondStepForm: this.secondStepForm,
        thirdStepForm: this.thirdStepForm,
      });

      this.fourthStepForm = this.fb.group({
        nda: [false, Validators.requiredTrue]
      })
      this.removeNdaService.removeNdaEvent
      .subscribe(() => {
        this.fourthStepForm.get('nda')?.setValue(false);
      });
      
      this.isInitialized = true;

    });
  }

  getPortfolioImagesWithUrl(){
    for (let portfolioImage of this.portfolio.portfolioImages) {
      this.portfolioService.getPortfolioImagesByPath(portfolioImage.path!).subscribe(
        (res) => {
          let img = res.body as any;
          let fileData = this.readFileData(img);
          if (img.main) {
            this.propertyDetailsForm.get('listOfImagesName')?.setValue({ id: fileData.lastModified, value: fileData.name });
          }
          if (img.city) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
              this.cityImageUrl = event.target.result;
            }
            reader.readAsDataURL(fileData);
            this.propertyDetailsForm.get('cityImage')?.setValue(fileData);
          } else {
            this.images.push(fileData);
            this.listOfImagesName = [
              ...this.listOfImagesName,
              { id: fileData.lastModified, value: fileData.name },
            ];
          }
          this.propertyDetailsForm.get('images')?.setValue(this.images);
        }
      );

    }
  }

  getPortfolioImages(id: number) {
    this.portfolioService.getPortfolioImages(id).subscribe((response) => {
      this.galleryImages = response.body;
      for (var assetImage of this.galleryImages) {
        let img = assetImage as any;
        let fileData = this.readFileData(img);
        if (img.main) {
          this.propertyDetailsForm.get('listOfImagesName')?.setValue({ id: fileData.lastModified, value: fileData.name });
        }
        if (img.city) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.cityImageUrl = event.target.result;
          }
          reader.readAsDataURL(fileData);
          this.propertyDetailsForm.get('cityImage')?.setValue(fileData);
        } else {
          this.images.push(fileData);
          this.listOfImagesName = [
            ...this.listOfImagesName,
            { id: fileData.lastModified, value: fileData.name },
          ];
        }
      }
      this.propertyDetailsForm.get('images')?.setValue(this.images);
    });
  }

  getPortfolioVideo(id: number) {
    this.portfolioService.getPortfolioVideo(id).subscribe((response) => {
      if (response.body != null) {
        this.video = this.readFileData(response.body);
        this.propertyDetailsForm.get('video')?.setValue(this.video);
      }

    });
  }

  readFileData(media: any): File {
    var sliceSize = 1024;
    var byteCharacters = atob(media.encodedBytes);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);
    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new File(byteArrays, media.fileName, { type: media.contentType });
  }

  public getAllUsages() {
    this.wizardService.getAllUsages().subscribe((response) => {
      this.usages = response;
    });
  }

  public getAllOwnerships() {
    this.wizardService.getAllOwnerships().subscribe((response) => {
      this.ownerships = response;
    });
  }
  getAllHighlights() {
    this.wizardService.getAllHighlights().subscribe((response) => {
      this.highlights = response;
    });
  }

  get propertyDetailsFormData() {
    return this.propertyDetailsForm.controls;
  }
  get rentBreakDownRow() {
    return this.propertyDetailsForm.get('rentBreakdown') as FormGroup;
  }

  get cities() {
    return this.propertyDetailsForm.get('cities') as FormArray;
  }
  get states(){
    return this.propertyDetailsForm.get('states') as FormArray;
  }
  get socialEconomics() {
    return this.propertyDetailsForm.get('socialEconomics') as FormGroup;
  }
  get socialEconomicsCities(){
    return this.socialEconomics.get('socialEconomicsCities') as FormArray
  }
  get socialEconomicsMunicipalities(){
    return this.socialEconomics.get('socialEconomicsMunicipalities') as FormArray
  }
  get socialEconomicsStates(){
    return this.socialEconomics.get('socialEconomicsStates') as FormArray
  }

  get topFive() {
    return this.rentForm.get('topFive');
  }
  get others() {
    return this.rentForm.get('others');
  }
  get rentTotal() {
    return this.rentForm.get('rentTotal');
  }
  get firstTenant() {
    return this.secondStepForm.get('firstTenant');
  }
  get secondTenant() {
    return this.secondStepForm.get('secondTenant');
  }
  get thirdTenant() {
    return this.secondStepForm.get('thirdTenant');
  }
  get fourthTenant() {
    return this.secondStepForm.get('fourthTenant');
  }
  get fifthTenant() {
    return this.secondStepForm.get('fifthTenant');
  }
  get leasDataForm() {
    return this.secondStepForm.get('leasData') as FormArray;
  }
  get debtBreakDown() {
    return this.thirdStepForm.get('debtBreakDown') as FormArray;
  }
  get grossAssetValue() {
    return this.debtSituationForm.get('grossAssetValue');
  }
  get totalDebt() {
    return this.debtSituationForm.get('totalDebt');
  }
  get debtOutstanding() {
    return this.debtSituationForm.get('debtOutstanding');
  }
  get debtService() {
    return this.debtSituationForm.get('debtService');
  }
  get floating() {
    return this.debtBreakDownForm.get('floating');
  }
  get debtBreakDownSum() {
    return this.debtBreakDownForm.get('debtBreakDownSum');
  }
  get ltOneYear() {
    return this.debtBreakDownForm.get('ltOneYear');
  }
  get mtOneYear() {
    return this.debtBreakDownForm.get('mtOneYear');
  }

  get fourthStepFormData() {
    return this.fourthStepForm.controls;
  }


  addCity() {
    const cityForm = this.fb.group({
      city: ['', Validators.required],
    });
    this.cities.push(cityForm);
  }

  deleteCity(i: any) {
    this.cities.removeAt(i);
  }

  addFederalState(){
    const stateForm = this.fb.group({
      federalState: ['', Validators.required]
    });
    this.states.push(stateForm);
  }

  deleteFederalState(i: any){
    this.states.removeAt(i)
  }

  images: File[] = [];
  video!: File | null;

  onSelect(event: any) {
    for (const addedMedia of event.addedFiles) {
      let containsFile = false;
      for (const iterator of this.images) {
        if (iterator.name === addedMedia.name) {
          containsFile = true;
          break;
        }
      }
      if (this.video != null && this.video.name === addedMedia.name) {
        containsFile = true;
        break;
      }
      if (!containsFile) {
        if (addedMedia.type === 'video/mp4') {
          this.video = addedMedia;
          this.propertyDetailsForm.get('video')?.setValue(this.video);
        } else {
          this.images.push(addedMedia);
          this.propertyDetailsForm.get('listOfImagesName')?.enable();
          this.listOfImagesName = [
            ...this.listOfImagesName,
            { id: addedMedia.lastModified, value: addedMedia.name },
          ];
          this.propertyDetailsForm.get('images')?.setValue(this.images);
        }
      }
    }
  }

  onRemove(event: any) {
    if (event.type === "video/mp4") {
      this.video = null;
      this.propertyDetailsForm.get('video')?.setValue(this.video);
    } else {
      this.images.splice(this.images.indexOf(event), 1);
      for (const iterator of this.listOfImagesName) {
        if (iterator.value === event.name) {
          let selected = this.ngSelectComponent.selectedValues[0] as any;
          this.listOfImagesName.splice(this.listOfImagesName.indexOf(iterator), 1);
          if (selected.value == iterator.value) {
            this.propertyDetailsForm.get('listOfImagesName')?.setValue(this.portfolio.portfolioImages[0])
          }
        }
      }
      this.listOfImagesName = [...this.listOfImagesName];
      if (this.listOfImagesName.length === 0) {
        this.propertyDetailsForm.get('listOfImagesName')?.disable();
        }
      this.propertyDetailsForm.get('images')?.setValue(this.images);
    }
  }


  trimName(name: string) {
    if (name.length > 25) {
      return name.substring(0, 25) + '...';
    }
    return name;
  }

  acceptedType() {
    if (this.video != null) {
      return 'image/jpg,image/jpeg,image/png'
    } else {
      return 'image/jpg,image/jpeg,image/png, video/mp4';
    }
  }

  removeCityImg() {
    this.propertyDetailsForm.get('cityImage')?.setValue(null);
    this.cityImageUrl = '';
  }

  cityFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.cityImageUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
    this.propertyDetailsForm.get('cityImage')?.setValue(event.target.files[0]);
  }

  openModal() {
    this.openModalVar = true;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      BreakDownDialogComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.areaValues =
      this.propertyDetailsForm.get('areaBreakdown')?.value;
    modalRef.componentInstance.rentValues =
      this.propertyDetailsForm.get('rentBreakdown')?.value;
    modalRef.result
      .then((result) => {
        if (!result.area && !result.rent) {
        } else {
          //for area
          let formArray = this.propertyDetailsForm.get(
            'areaBreakdown'
          ) as FormArray;
          this.clearFormArray(formArray);
          for (let area of result.area.controls) {
            formArray.push(area);
          }
          //for rent
          let formArrayRent = this.propertyDetailsForm.get(
            'rentBreakdown'
          ) as FormArray;
          this.clearFormArray(formArrayRent);
          for (let rent of result.rent.controls) {
            formArrayRent.push(rent);
          }
        }
      })
      .catch((res) => { });
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  validateMap(value: any) {
    if (value) {
      this.propertyDetailsFormData.map.setValue('ok');
    } else {
      this.propertyDetailsFormData.map.setValue(null);
    }
  }

  showTopTenantSwitch() {
    if (!this.showTenantInfo) {
      this.secondStepForm.controls['rentData'].disable({ onlySelf: true });
      this.removeControl( this.secondStepForm.controls['firstTenant']);
      this.removeControl( this.secondStepForm.controls['secondTenant']);
      this.removeControl( this.secondStepForm.controls['thirdTenant']);
      this.removeControl( this.secondStepForm.controls['fourthTenant']);
      this.removeControl( this.secondStepForm.controls['fifthTenant']);
    } else {
      this.secondStepForm.controls['rentData'].enable();
      this.addControl( this.secondStepForm.controls['firstTenant']);
      this.addControl( this.secondStepForm.controls['secondTenant']);
      this.addControl( this.secondStepForm.controls['thirdTenant']);
      this.addControl( this.secondStepForm.controls['fourthTenant']);
      this.addControl( this.secondStepForm.controls['fifthTenant']);
    }
  }

  private removeControl(control: any){
    control.disable({ onlySelf: true });
    control.removeValidators(Validators.required);
    control.setValue('');
  }
  private addControl(control: any){
    control.enable();
    control.addValidators(Validators.required);
  }


  showLeasDataSwitch(){
    if (!this.showLeasData) {
      this.leasDataForm.disable();
      this.leasDataForm.controls.map((ctrl) => {
        ctrl.setValue(
          {
            breakOptionsControl: 0,
            extensionOptionsControl: 0,
            leasEndControl: 0
          }
        )
        ctrl.removeValidators([Validators.required]);
      });
      this.breakOptionsSum = 0;
      this.leasEndSum = 0;
      this.extensionOptionsSum = 0;
    }
    else {
      this.leasDataForm.enable();
      this.leasDataForm.controls.map((ctrl) => {
        ctrl.addValidators([Validators.required]);
      });

    }
  }

  donughnutChart: EChartsOption = {
    tooltip: { trigger: 'item', formatter: '{a} <br/>{b}: {c} ({d}%)' },
    legend: {
      orient: 'vertical',
      bottom: '0%',
      left: 'right',
    },
    color: ['#33aeb1', '#2a8d8f'],
    series: {
      name: 'Rent percentage',
      type: 'pie',
      radius: ['40%', '80%'],
    },
  };


  updateChart() {
    this.dynamicData = this.donughnutChart;
    this.dynamicData.series = [];
    this.dynamicData.title = {
      text: '€' + formatNumber(this.rentSum, 'en-US', '1.0-0') + ' p.a.',
      left: 'center',
      top: 'center',
    };
    this.dynamicData.series = {
      name: 'Rent percentage',
      type: 'pie',
      radius: ['40%', '80%'],
      avoidLabelOverlap: false,
      label: {
        show: true,
        formatter: '{d}%',
        position: 'inside',
        color: 'white',
      },
      data: [
        { value: this.topFiveValue, name: 'Top 5 tenants' },
        { value: this.othersValue, name: 'Others' },
      ],
    };
    this.donughnutChart = { ...this.donughnutChart, ...this.dynamicData };
  }


  addDebtBreakDownRow() {
    for (const iterator of this.yearsArrFloating) {
      const debtFrom = this.fb.group({
        debtBreakDownValue: [0, [Validators.required, Validators.min(0)]],
      });
      this.debtBreakDown.push(debtFrom);
    }
  }

  onFileSelected(event: any, subtype: String) {
    this.clearOldList(subtype);
    let validation = this.acceptedFileTypeService.validateFileTypes(event.target.files);
    if (validation.valid == false) {
      let message = 'Unsuported file(s): ' + validation.files.toString();
      Swal.fire({
        title: 'Incorrect...',
        text: message,
        icon: 'error',
        confirmButtonColor: '#c33434',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'OK',
      }).then((result) => {
        
      });
      return;
    }
    for (let file of event.target.files) {
      switch (subtype) {
        case 'cadastralMaps':
          this.filesTypesSubtypesList.cadastralMapsFiles.push({
            file: file,
            name: file.name,
            subtype: 1,
          });
          break;
        case 'landRegistrationExcerpt':
          this.filesTypesSubtypesList.landRegistrationExcerptFiles.push({
            file: file,
            name: file.name,
            subtype: 2,
          });
          break;
        case 'contaminationRegisterEx':
          this.filesTypesSubtypesList.contaminationRegisterExFiles.push({
            file: file,
            name: file.name,
            subtype: 3,
          });
          break;
        case 'buildingPermit':
          this.filesTypesSubtypesList.buildingPermitFiles.push({
            file: file,
            name: file.name,
            subtype: 4,
          });
          break;
        case 'floorSpaceTable':
          this.filesTypesSubtypesList.floorSpaceTableFiles.push({
            file: file,
            name: file.name,
            subtype: 5,
          });
          break;
        case 'curvatureAnalysis':
          this.filesTypesSubtypesList.curvatureAnalysisFiles.push({
            file: file,
            name: file.name,
            subtype: 6,
          });
          break;
        case 'floorPlan':
          this.filesTypesSubtypesList.floorPlanFiles.push({
            file: file,
            name: file.name,
            subtype: 7,
          });
          break;
        case 'objectDescription':
          this.filesTypesSubtypesList.objectDescriptionFiles.push({
            file: file,
            name: file.name,
            subtype: 8,
          });
          break;
        case 'rentRoll':
          this.filesTypesSubtypesList.rentRollFiles.push({
            file: file,
            name: file.name,
            subtype: 9,
          });
          break;
        case 'sampleRentalAgreement':
          this.filesTypesSubtypesList.sampleRentalAgreementFiles.push({
            file: file,
            name: file.name,
            subtype: 10,
          });
          break;
        case 'analysisOfAncillaryCosts':
          this.filesTypesSubtypesList.analysisOfAncillaryCostsFiles.push({
            file: file,
            name: file.name,
            subtype: 11,
          });
          break;
        case 'valuationReport':
          this.filesTypesSubtypesList.valuationReportFiles.push({
            file: file,
            name: file.name,
            subtype: 12,
          });
          break;
        case 'annualAccounts':
          this.filesTypesSubtypesList.annualAccountsFiles.push({
            file: file,
            name: file.name,
            subtype: 13,
          });
          break;
        case 'techDueDiligence':
          this.filesTypesSubtypesList.techDueDiligenceFiles.push({
            file: file,
            name: file.name,
            subtype: 14,
          });
          break;
        case 'insuranceAgreement':
          this.filesTypesSubtypesList.insuranceAgreementFiles.push({
            file: file,
            name: file.name,
            subtype: 15,
          });
          break;
        case 'organizationStructure':
          this.filesTypesSubtypesList.organizationStructureFiles.push({
            file: file,
            name: file.name,
            subtype: 16,
          });
          break;
        case 'legalAndRegulatory':
          this.filesTypesSubtypesList.legalAndRegulatoryFiles.push({
            file: file,
            name: file.name,
            subtype: 18,
          });
          break;
        case 'other':
          this.filesTypesSubtypesList.otherFiles.push({
            file: file,
            name: file.name,
            subtype: 17,
          });
          break;
        case 'ndaFiles':
          this.filesTypesSubtypesList.ndaFiles.push({
            file: file,
            name: file.name,
            subtype: 19,
          });
          this.fourthStepForm.get('nda')?.setValue(true);
          break;
        case 'moreInfo':
          this.filesTypesSubtypesList.portfolioMoreInfo.push({
            file: file,
            name: file.name,
            subtype: 20,
          });
          break;
      }
    }
  }

  clearOldList(subtype: String) {
    switch (subtype) {
      case 'ndaFiles':
        this.filesTypesSubtypesList.ndaFiles = [];
        this.fourthStepForm.get('nda')?.setValue(false);
        break;
      case 'cadastralMaps':
        this.filesTypesSubtypesList.cadastralMapsFiles = [];
        break;
      case 'landRegistrationExcerpt':
        this.filesTypesSubtypesList.landRegistrationExcerptFiles = [];
        break;
      case 'contaminationRegisterEx':
        this.filesTypesSubtypesList.contaminationRegisterExFiles = [];
        break;
      case 'buildingPermit':
        this.filesTypesSubtypesList.buildingPermitFiles = [];
        break;
      case 'floorSpaceTable':
        this.filesTypesSubtypesList.floorSpaceTableFiles = [];
        break;
      case 'curvatureAnalysis':
        this.filesTypesSubtypesList.curvatureAnalysisFiles = [];
        break;
      case 'floorPlan':
        this.filesTypesSubtypesList.floorPlanFiles = [];
        break;
      case 'objectDescription':
        this.filesTypesSubtypesList.objectDescriptionFiles = [];
        break;
      case 'rentRoll':
        this.filesTypesSubtypesList.rentRollFiles = [];
        break;
      case 'sampleRentalAgreement':
        this.filesTypesSubtypesList.sampleRentalAgreementFiles = [];
        break;
      case 'analysisOfAncillaryCosts':
        this.filesTypesSubtypesList.analysisOfAncillaryCostsFiles = [];
        break;
      case 'valuationReport':
        this.filesTypesSubtypesList.valuationReportFiles = [];
        break;
      case 'annualAccounts':
        this.filesTypesSubtypesList.annualAccountsFiles = [];
        break;
      case 'techDueDiligence':
        this.filesTypesSubtypesList.techDueDiligenceFiles = [];
        break;
      case 'insuranceAgreement':
        this.filesTypesSubtypesList.insuranceAgreementFiles = [];
        break;
      case 'organizationStructure':
        this.filesTypesSubtypesList.organizationStructureFiles = [];
        break;
      case 'legalAndRegulatory':
        this.filesTypesSubtypesList.legalAndRegulatoryFiles = [];
        break;
      case 'moreInfo':
        this.filesTypesSubtypesList.portfolioMoreInfo = [];
        break;
      case 'other':
        this.filesTypesSubtypesList.otherFiles = [];
        break;
    }
  }

  trimPath(path: string): string {
    var splitted = path.split(environment.pathDelimiter);
    return splitted[splitted.length - 1];
  }

  removeDocument(i: number) {
    this.portfolio.portfolioDocuments.splice(i, 1);
  }

  private addFile(file: File, subtype: number) {
    let arrFile = {
      file: file,
      name: file.name,
      subtype: subtype,
    };
    switch (subtype) {
      case 1:
        this.filesTypesSubtypesList.cadastralMapsFiles.push(arrFile);
        break;
      case 2:
        this.filesTypesSubtypesList.landRegistrationExcerptFiles.push(arrFile);
        break;
      case 3:
        this.filesTypesSubtypesList.contaminationRegisterExFiles.push(arrFile);
        break;
      case 4:
        this.filesTypesSubtypesList.buildingPermitFiles.push(arrFile);
        break;
      case 5:
        this.filesTypesSubtypesList.floorSpaceTableFiles.push(arrFile);
        break;
      case 6:
        this.filesTypesSubtypesList.curvatureAnalysisFiles.push(arrFile);
        break;
      case 7:
        this.filesTypesSubtypesList.floorPlanFiles.push(arrFile);
        break;
      case 8:
        this.filesTypesSubtypesList.objectDescriptionFiles.push(arrFile);
        break;
      case 9:
        this.filesTypesSubtypesList.rentRollFiles.push(arrFile);
        break;
      case 10:
        this.filesTypesSubtypesList.sampleRentalAgreementFiles.push(arrFile);
        break;
      case 11:
        this.filesTypesSubtypesList.analysisOfAncillaryCostsFiles.push(arrFile);
        break;
      case 12:
        this.filesTypesSubtypesList.valuationReportFiles.push(arrFile);
        break;
      case 13:
        this.filesTypesSubtypesList.annualAccountsFiles.push(arrFile);
        break;
      case 14:
        this.filesTypesSubtypesList.techDueDiligenceFiles.push(arrFile);
        break;
      case 15:
        this.filesTypesSubtypesList.insuranceAgreementFiles.push(arrFile);
        break;
      case 16:
        this.filesTypesSubtypesList.organizationStructureFiles.push(arrFile);
        break;
      case 18:
        this.filesTypesSubtypesList.legalAndRegulatoryFiles.push(arrFile);
        break;
      case 17:
        this.filesTypesSubtypesList.otherFiles.push(arrFile);
        break;
      case 19:
        this.filesTypesSubtypesList.ndaFiles.push(arrFile);
        this.fourthStepForm.get('nda')?.setValue(true);
        break;
      case 20:
          this.filesTypesSubtypesList.portfolioMoreInfo.push(arrFile);
          break;
    }
  }

  async onSubmit() {
    this.formsubmit = true;
    if (
      this.updatePortfolioForm.valid
    ) {
      if (this.showLeasData && (this.leasEndSum !== this.rentSum || this.breakOptionsSum !== this.rentSum || this.extensionOptionsSum !== this.rentSum)) {
        return;
      }
      this.portfolio.enabled = this.propertyDetailsForm.value.enabled ?? false;
      this.portfolio.title = this.propertyDetailsForm.value.title ?? '';
      this.portfolio.grossValue =
        this.thirdStepForm?.value?.debtSituation?.grossAssetValue ?? 0;
      this.portfolio.description =
        this.propertyDetailsForm?.value?.description ?? '';
      this.portfolio.plotSize = this.propertyDetailsForm?.value?.plot_size ?? 0;
      this.portfolio.debt =
        this.thirdStepForm?.value?.debtSituation?.totalDebt ?? 0;
      this.portfolio.debtBreakdown = {
        id : this.portfolio.debtBreakdown.id,
        debtBreakdown :JSON.stringify(
        Object.values(this.thirdStepForm.value.debtBreakDown)
      )};
      this.portfolio.constructionYears[0] = {
        id : this.portfolio.constructionYears[0].id,
        year :this.propertyDetailsForm?.value?.construction_years ?? ''
      };
      this.portfolio.lastRefurbishments[0] = {
        id : this.portfolio.constructionYears[0].id,
        year :this.propertyDetailsForm?.value?.refurbishment_years ?? ''
      };
      this.portfolio.portfolioUsageText = this.propertyDetailsForm?.value?.multiUsageOptionText ?? '';
      this.portfolio.debtService =
        this.thirdStepForm?.value?.debtSituation?.debtService ?? 0;
      this.portfolio.debtOutstanding =
        this.thirdStepForm?.value?.debtSituation?.debtOutstanding ?? 0;
      this.portfolio.netRentalIncome = this.rentSum ?? 0;
      this.portfolio.occupancyRate =
        this.propertyDetailsForm?.value?.occupancy_rate ?? 0;
      this.portfolio.numberOfParkingLots =
        this.propertyDetailsForm?.value?.parking_lots ?? 0;
      this.portfolio.walt = this.propertyDetailsForm?.value?.walt ?? 0;
      this.portfolio.tenantValue = {
        id:  this.portfolio.tenantValue != undefined ? this.portfolio.tenantValue.id : undefined,
        othersValue: this.secondStepForm?.value?.rentData?.others ?? 0,
        top5Value: this.secondStepForm?.value?.rentData?.topFive ?? 0,
      };
      let portfolioCitiesForHttp: any = [];
      let cities = this.propertyDetailsForm.get(
        'cities'
      ) as FormArray;
      for (const iterator of cities.value) {
        let portfolioCityObject =  {
          city: iterator.city,
          id: iterator.id,
        };
        portfolioCitiesForHttp.push(portfolioCityObject);

      }
      this.portfolio.cities = portfolioCitiesForHttp;

      let portfolioStatesForHttp: any = [];
      let states = this.propertyDetailsForm.get(
        'states'
      ) as FormArray;
      for (const interatior of states.value) {
        let portfolioStateObject = {
          federalState: interatior.federalState,
          id: interatior.id
        };
        portfolioStatesForHttp.push(portfolioStateObject)
      }
      this.portfolio.federalStates = portfolioStatesForHttp;

      let portfolioOwnershipsForHttp: any = [];
      let ownerships = this.propertyDetailsForm.get(
        'ownershipOption'
      ) as FormArray;
      for (const iterator of ownerships.value) {
        let portfolioOwnershipObject =  {
          ownership: {
            description: iterator.description,
            id: iterator.id,
          },
        };
        portfolioOwnershipsForHttp.push(portfolioOwnershipObject);

      }
      this.portfolio.ownerships = portfolioOwnershipsForHttp;
      if (this.showTenantInfo) {
        this.portfolio.portfolioTenants = [
          {
            id:this.portfolio.portfolioTenants[0] != undefined ? this.portfolio.portfolioTenants[0].id : undefined,
            orderValue: 1,
            tenantName: this.secondStepForm?.value?.firstTenant ?? '',
          },
          {
            id: this.portfolio.portfolioTenants[1] != undefined ? this.portfolio.portfolioTenants[1].id : undefined,
            orderValue: 2,
            tenantName: this.secondStepForm?.value?.secondTenant ?? '',
          },
          {
            id: this.portfolio.portfolioTenants[2] != undefined ? this.portfolio.portfolioTenants[2].id : undefined,
            orderValue: 3,
            tenantName: this.secondStepForm?.value?.thirdTenant ?? '',
          },
          {
            id: this.portfolio.portfolioTenants[3] != undefined ? this.portfolio.portfolioTenants[3].id : undefined,
            orderValue: 4,
            tenantName: this.secondStepForm?.value?.fourthTenant ?? '',
          },
          {
            id: this.portfolio.portfolioTenants[4] != undefined ? this.portfolio.portfolioTenants[4].id : undefined,
            orderValue: 5,
            tenantName: this.secondStepForm?.value?.fifthTenant ?? '',
          },
        ];
      } else {
        this.portfolio.portfolioTenants = [];
        this.portfolio.tenantValue = {
          id: this.portfolio.tenantValue.id,
          othersValue: this.rentSum,
          top5Value: 0,
        };
      }
      let localArray = [];
      if (this.showLeasData == true) {
        for (const value of this.secondStepForm.value.leasData) {
          let array = Object.values(value);
          let index = this.secondStepForm.value.leasData.indexOf(value);
          array.unshift(this.yearsArr[index * 1]);
          localArray.push(array);
        }
        this.leasDataForHttp = {
          source: [],
        };
        this.leasDataForHttp.source = localArray;
        this.portfolio.leaseExpiryProfile = {
          id: this.portfolio.leaseExpiryProfile?.id,
          leaseData: this.leasDataForHttp,
        };
      } else {
        this.portfolio.leaseExpiryProfile = undefined;
      }


      let highLightsForHttp = [];
      let formHighlightsArray = this.propertyDetailsForm.get(
        'highlightOption'
      ) as FormArray;
      for (let highlight of formHighlightsArray.controls) {
        this.highlightsForPreview.push({
          pictureName: highlight.get('pictureName')?.value,
          highlightDescription: highlight.get('highlightDescription')?.value,
          orderNumber: highlight.get('orderNumber')?.value
        });
      }
      for (const highPrev of this.highlightsForPreview) {
        for (const iterator of this.highlights) {
          if (highPrev.pictureName === iterator.pictureName) {
            highLightsForHttp.push({
              description: highPrev.highlightDescription,
              highlight: {
                id: iterator.id,
                pictureName: iterator.pictureName,
              },
              orderNumber: highPrev.orderNumber
            });
            break;
          }
        }
      }
      this.portfolio.portfolioHighlights = highLightsForHttp;
      let portfolioUsagesForHttp: any = [];
      let areaBreakDown = this.propertyDetailsForm.get(
        'areaBreakdown'
      ) as FormArray;
      let rentBreakDown = this.propertyDetailsForm.get(
        'rentBreakdown'
      ) as FormArray;
      this.lettableAreaSum = 0;
      for (const iterator of areaBreakDown.value) {
        let assetUsageObject = {};
        this.lettableAreaSum += iterator.value * 1;
        for (const rentIterator of rentBreakDown.value) {
          if (iterator.id === rentIterator.id) {
            assetUsageObject = {
              areaBreakdownValue: iterator.value,
              rentBreakdownValue: rentIterator.value,
              usage: {
                description: iterator.description,
                id: iterator.id,
              },
            };
            portfolioUsagesForHttp.push(assetUsageObject);
          }
        }
      }
      this.portfolio.portfolioUsages = portfolioUsagesForHttp;
      this.portfolio.lettableArea = this.lettableAreaSum ?? 0;
      let mapCoordinates = {
        mapInfo: [],
      } as any;
      if (this.mapElement.listOfMarkers) {
        for (const iterator of this.mapElement.listOfMarkers) {
          let place_id =(await this.geocoderService.getPlaceIdByLatLng(iterator.lat, iterator.lng)).results[0].place_id;
          let markerTemp = {
            type: google.maps.drawing.OverlayType.MARKER,
            coordinates: [
              {
                lat: iterator.lat,
                lng: iterator.lng
              }
            ],
            placeId: place_id
          }
          mapCoordinates.mapInfo.push(markerTemp);
        }
      }
      if (this.mapElement.listOfPolygons) {
        for (const polygon of this.mapElement.listOfPolygons) {
          let i =0;
          let coordinatesPlaceholder: any = [];
          for (const iterator of polygon) {
            coordinatesPlaceholder.push({
              lat: iterator.lat,
              lng: iterator.lng,
              orderNumber: i++
            });
          }
          let place_id = (await this.geocoderService.getPlaceIdByBound(polygon)).results[0].place_id;
          let tempPolygon = {
            type: google.maps.drawing.OverlayType.POLYGON,
            coordinates: coordinatesPlaceholder,
            placeId: place_id
          };
          mapCoordinates.mapInfo.push(tempPolygon);
        }
      }
      let socialEconomicsForHttp: any[] = [];

      this.addSocialEconomicsForHttp(this.socialEconomicsCities,socialEconomicsForHttp);
      this.addSocialEconomicsForHttp(this.socialEconomicsMunicipalities,socialEconomicsForHttp);
      this.addSocialEconomicsForHttp(this.socialEconomicsStates,socialEconomicsForHttp);
     

      this.portfolio.portfolioLocation = {
        id: this.portfolio.portfolioLocation.id,
        portfolioLocationSocialEconomics: socialEconomicsForHttp
      }
      this.portfolio.locationMapDetails = mapCoordinates;
      this.portfolio.locationDescription = this.propertyDetailsForm?.value?.location_description ?? ''
      this.portfolio.highlightSection = this.propertyDetailsForm?.value?.highlightSection ?? ''
      Swal.fire({
        title: 'Updating portfolio data...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        text: '',
        didOpen: () => {
          Swal.showLoading();
        },
      });
      this.portfolio.portfolioImages = [];
      this.portfolio.portfolioDocuments = [];

      this.portfolioService
        .uprdatePortfolio(+this.portfolio.id, this.portfolio)
        .pipe(catchError(err => of({ message: err, error: true })))
        .subscribe((res) => {
          if (res.error != undefined) {
            Swal.fire({
              title: 'Incorrect...',
              text: res.message,
              icon: 'error',
              confirmButtonColor: '#c33434',
              cancelButtonColor: '#f46a6a',
              confirmButtonText: 'OK',
            });
          } else {
            Swal.getTitle()!.textContent = 'Updating portfolio images...';
            let portfolioId = res.id;
            this.portfolioService
              .postImages(
                portfolioId,
                this.propertyDetailsForm.get('images')?.value,
                this.propertyDetailsForm.get('listOfImagesName')?.value,
                this.propertyDetailsForm.get('cityImage')?.value
              ).pipe(catchError(err => of({ message: err, error: true })))
              .subscribe((res: any) => {
                if (res.error != undefined && res.error == true) {
                  Swal.fire({
                    title: 'Incorrect...',
                    text: res.message,
                    icon: 'error',
                    confirmButtonColor: '#c33434',
                    cancelButtonColor: '#f46a6a',
                    confirmButtonText: 'OK',
                  }).then((result) => {
                    this.router.navigate(['/apps/onboarded-assets']);
                  });
                }
              });
            Swal.getTitle()!.textContent = 'Updating portfolio video...';
            if (this.propertyDetailsForm.get('video')?.value == null) {
              this.portfolioService
                .deleteVideo(
                  portfolioId
                )
                .pipe(catchError(err => of({ message: err, error: true })))
                .subscribe((res: any) => {
                  if (res.error != undefined && res.error == true) {
                    Swal.fire({
                      title: 'Incorrect...',
                      text: res.message,
                      icon: 'error',
                      confirmButtonColor: '#c33434',
                      cancelButtonColor: '#f46a6a',
                      confirmButtonText: 'OK',
                    });
                  }
                });
            } else {
              this.portfolioService
                .postVideo(
                  portfolioId,
                  this.propertyDetailsForm.get('video')?.value
                )
                .pipe(catchError(err => of({ message: err, error: true })))
                .subscribe((res: any) => { 
                  if (res.error != undefined && res.error == true) {
                    Swal.fire({
                      title: 'Incorrect...',
                      text: res.message,
                      icon: 'error',
                      confirmButtonColor: '#c33434',
                      cancelButtonColor: '#f46a6a',
                      confirmButtonText: 'OK',
                    });
                  }
                });
            }

            Swal.getTitle()!.textContent = 'Updating portfolio documents...';
            this.portfolioService
              .postDocuments(portfolioId, this.filesTypesSubtypesList)
              .pipe(catchError(err => of({ message: err, error: true })))
              .subscribe((res: any) => {
                if (res.error != undefined && res.error == true) {
                  Swal.fire({
                    title: 'Incorrect...',
                    text: res.message,
                    icon: 'error',
                    confirmButtonColor: '#c33434',
                    cancelButtonColor: '#f46a6a',
                    confirmButtonText: 'OK',
                  });
                } else {
                  Swal.fire({
                    title: 'Success',
                    text: 'Portfolio was successfully updated!',
                    icon: 'success',
                    confirmButtonColor: '#34c38f',
                    cancelButtonColor: '#f46a6a',
                    confirmButtonText: 'OK',
                  }).then((result) => {
                    this.router.navigate(['/apps/onboarded-assets']);
                  });
                }
              });
          }
        });
    }
  }

  private addSocialEconomicsForHttp(arr: any, socialEconomicsForHttp: any) {
    for (const iterator of arr.controls) {
      let portfolioSocEconomicsObject =  {
        administrativeLevel: {id: iterator.get('administrative_level')?.value},
        numberOfInhabitants: iterator.get('number_of_inhabitants')?.value,
        expectedPopulationGrowth: iterator.get('expected_population_growth')?.value,
        netNumberOfCommuters: iterator.get('commuters')?.value,
        retailCentrality: iterator.get('centrality')?.value,
        retailPurchasingPower:  iterator.get('purchasing_power')?.value,
        region: iterator.get('region')?.value,
        id: iterator.get('id')?.value
      };
      socialEconomicsForHttp.push(portfolioSocEconomicsObject);
    }
  }

  public onReady(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
      return new MyUploadAdapter(loader);
    };
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  public addNewSocialEconomics(event: any) {
    let socialEconomicsForm: any;
    let portfolioLocationSocialEconomics = this.portfolio.portfolioLocation.portfolioLocationSocialEconomics.filter((pse) => {
      return pse.region == event.region && pse.administrativeLevel?.id == event.level
    });
    if (portfolioLocationSocialEconomics.length > 0) {
      let pse = portfolioLocationSocialEconomics[0];
      socialEconomicsForm = this.fb.group({
        id: [pse.id, Validators.required],
        region: [{ value: event.region, disabled: true }, Validators.required],
        number_of_inhabitants: [pse.numberOfInhabitants, Validators.required],
        expected_population_growth: [pse.expectedPopulationGrowth, Validators.required],
        commuters: [pse.netNumberOfCommuters, Validators.required],
        centrality: [pse.retailCentrality, Validators.required],
        purchasing_power: [pse.retailPurchasingPower, Validators.required],
        administrative_level: [event.level, Validators.required],
        include: [true,  Validators.required]
      });
    } else {
      socialEconomicsForm = this.fb.group({
        region: [{ value: event.region, disabled: true }, Validators.required],
        number_of_inhabitants: ['', Validators.required],
        expected_population_growth: ['', Validators.required],
        commuters: ['', Validators.required],
        centrality: ['', Validators.required],
        purchasing_power: ['', Validators.required],
        administrative_level: [event.level, Validators.required],
        include: [true,  Validators.required]
      });
    }
    switch (event.level) {
      case 1:
        this.socialEconomicsCities.push(socialEconomicsForm);
        break;
      case 2:
        this.socialEconomicsMunicipalities.push(socialEconomicsForm);
        break;
      case 4:
        this.socialEconomicsStates.push(socialEconomicsForm);
        break;
    }
    this.showPinOnMapService.refreshPinsOnMap();
  }

  public removeDistrictSocialEconomics(event: any) {
    let arr: any;
    switch (event.level) {
      case 1:
        arr = this.socialEconomicsCities;
        break;
      case 2:
        arr =  this.socialEconomicsMunicipalities;
        break;
      case 4:
        arr =  this.socialEconomicsStates;
        break;
    }
    if (arr != undefined) {
      for (let i = 0; i < arr.length; i++) {
        let socialEconomics = arr.controls[i];
        if (socialEconomics.get('region')?.value == event.region) {
          arr.removeAt(i);
        }
      }
    }
    this.showPinOnMapService.refreshPinsOnMap();
  }
}
