<!-- start page title -->
<app-pagetitle title="Form Editors" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Ckeditor Classic editor</h4>
                <p class="card-title-desc">Example of Ckeditor Classic editor</p>
            </div>
            <div class="card-body">
                <ckeditor [editor]="Editor" data="<p>Content of the editor.</p>"></ckeditor>
            </div>
        </div>
    </div>
    <!-- end col -->
</div>
<!-- end row -->