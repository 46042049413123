<div class="container">
    <div class="file-list" *ngFor="let file of files; let i = index">
        <b>• {{ (file.name | slice:0:20) + ' ...' }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="removeFile(i)"><i class="bx bx-trash-alt"></i></button>&nbsp;      
            <button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click) = "previewFile(content,file)"><i class="far fa-file-pdf"></i></button>        
    </div>   
</div>
    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{fileName}}</h4>
            <div type="btn" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                style="cursor: pointer;">
                <span aria-hidden="true" style="font-size: 2.5em;">&times;</span>
            </div>
        </div>
        <div class="modal-body">
            <pdf-viewer *ngIf="pdfExcetnsions.includes(fileType)" [src]="src" [rotation]="0" [fit-to-page]="true"
                        [original-size]="true" [show-all]="true" style="display: block; height: 70vh;"></pdf-viewer>
            <div class="d-flex justify-content-center mb-3">
                <div class="p-2">
                    <img *ngIf="imgExcetnsions.includes(fileType)" [src]="transform(src)"
                        style="display: inline; height: 70vh; justify-content: center;" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Ok</button>
        </div>
    </ng-template>

