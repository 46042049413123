import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Highlight } from 'src/app/classes/Highlight';

@Component({
  selector: 'app-highlight-description',
  templateUrl: './highlight-description.component.html',
  styleUrls: ['./highlight-description.component.scss']
})
export class HighlightDescriptionComponent implements OnInit {

  formsubmit:boolean = false;

  @Input() description!:string;


  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {

  }

  ngOnInit(): void {
  }

  passBack(){
    this.formsubmit = true;
    if (this.description != null){
      this.activeModal.close(this.description);
    }
  }
}
