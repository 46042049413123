import { Injectable, Output, EventEmitter } from "@angular/core";
import { EventClass, LocationPin } from "src/app/shared/google-map/google-map.component";

@Injectable({
    providedIn: 'root'
})
export class ShowPinOnMapService {


    @Output() showPin = new EventEmitter<EventClass>();
    @Output() removePin = new EventEmitter<google.maps.Marker>();
    @Output() refreshPins = new EventEmitter<string>();

    showPinOnMap(region:string, level: number) {
        let eventClass = new EventClass();
        eventClass.region = region;
        eventClass.level = level
        this.showPin.emit(eventClass);
    }


    removePinFromMap(marker: google.maps.Marker) {
        this.removePin.emit(marker);
    }

    refreshPinsOnMap(){
        this.refreshPins.emit("refresh");
    }
}
