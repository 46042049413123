<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'REQUESTINGUSER.LIST.CUSTOMERSLIST' | translate }}</h4>
        <p class="card-title-desc" *ngIf="purpose == 'customers'">{{ 'REQUESTINGUSER.LIST.ACCEPTEDCUSTOMERSLIST' | translate }}
        <p class="card-title-desc" *ngIf="purpose != 'customers'">{{ 'REQUESTINGUSER.LIST.PANDINGCUSTOMERSLIST' | translate }}
        </p>
      </div>
      <div class="card-body">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_length" id="tickets-table_length"><label
                class="d-inline-flex align-items-center">Show
                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                  [(ngModel)]="tableService.pageSize" class="form-control form-control-sm mx-2">
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="25">25</option>
                  <option [ngValue]="50">50</option>
                  <option [ngValue]="100">100</option>
                </select> entries</label></div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                    class="d-inline-flex align-items-center">Search:
                    <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                        aria-controls="tickets-table" [(ngModel)]="tableService.searchTerm" (change)="onSearch()"></label></div>
          </div>
        </div>

        <table class="table align-middle datatable dt-responsive table-check nowrap datatables"
          style="border-collapse: collapse; border-spacing: 0 8px; width: 100%;">
          <thead>
            <tr class="bg-transparent">
              <th costSorTable="id" (sort)="onSort($event)">{{ 'REQUESTINGUSER.LIST.ID' | translate }}</th>
              <th costSorTable="name" (sort)="onSort($event)">{{ 'REQUESTINGUSER.LIST.NAME' | translate }}</th>
              <th costSorTable="lastName" (sort)="onSort($event)">{{ 'REQUESTINGUSER.LIST.LASTNAME' | translate }}</th>
              <th costSorTable="lei" (sort)="onSort($event)">LEI</th>
              <th costSorTable="email" (sort)="onSort($event)">{{ 'REQUESTINGUSER.LIST.EMAIL' | translate }}</th>
              <th costSorTable="status" (sort)="onSort($event)">{{ 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.TEXT' | translate }}</th>
              <th>{{ 'REQUESTINGUSER.LIST.DOCUMENTS' | translate }}</th>
              <th>{{ 'REQUESTINGUSER.LIST.ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody *ngFor="let table of tables$ | async;let i=index">
            <tr>
              <td>
                <ngb-highlight [result]="table.id" [term]="tableService.searchTerm"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="table.name" [term]="tableService.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="table.lastName" [term]="tableService.searchTerm"></ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="table.lei" [term]="tableService.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="table.email" [term]="tableService.searchTerm"></ngb-highlight>
              </td>
              <td>
                <span class='badge' [ngClass]="{
                                    'badge-soft-warning ms-1': table.status === 'Pending',
                                    'badge-soft-success ms-1': table.status === 'Accepted',
                                    'badge-soft-danger ms-1': table.status === 'Rejected',
                                    'badge-soft-primary': table.status === 'Waiting for documents',
                                    'badge-soft-info ms-1': table.status === 'Waiting for additional documents',
                                    'badge-soft-dark ms-1': table.status === 'Reopened'}">
                  <ngb-highlight [result]="translateStatus(table.status) | translate " [term]="tableService.searchTerm"></ngb-highlight>
                </span>

              </td>
              <td>

                <i *ngIf="table.documents == 0" class="bx bx-block font-size-16 align-middle"
                  style="color: rgb(161, 43, 43);"></i>
                <i *ngIf="table.documents > 0" class="bx bx-check-double font-size-16 align-middle"
                  style="color: rgb(43, 161, 43);"></i>


              </td>
              <td>
                <button *ngIf='purpose === "customers"' [routerLink]="['../customers', table.id]" routerLink="/" class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle logo-dark">
                  <i class="mdi mdi-file-document-edit-outline" style="font-size: 1.2em; color: #28394A;"></i>
                </button>
                <button *ngIf='purpose === "customers"' [routerLink]="['../customers', table.id]"  routerLink="/" class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle logo-light">
                  <i class="mdi mdi-file-document-edit-outline" style="font-size: 1.2em; color: #858d98;"></i>
                </button>
                <button *ngIf='purpose === "requests"'  [routerLink]="['../requests', table.id]" routerLink="/" class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle logo-dark">
                  <i class="mdi mdi-file-document-edit-outline" style="font-size: 1.2em; color: #28394A;"></i>
                </button>
                <button *ngIf='purpose === "requests"'  [routerLink]="['../requests', table.id]"  routerLink="/" class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle logo-light">
                  <i class="mdi mdi-file-document-edit-outline" style="font-size: 1.2em; color: #858d98;"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row justify-content-md-between align-items-md-center mt-2">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
              Showing
              {{tableService.startIndex}} to
              {{tableService.endIndex}} of {{tableService.totalRecords}}
              entries
            </div>
          </div>
          <!-- Pagination -->
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end pagination-rounded">
              <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="tableService.page" [pageSize]="tableService.pageSize"
              (pageChange)="tableService.onPageChange(tableService.page)"
              [maxSize]="3"
              [rotate]="true">
              </ngb-pagination>
            </div>
          </div>
          <!-- End Pagination -->
        </div>
      </div>

    </div>
  </div> <!-- end col -->
</div> <!-- end row -->
