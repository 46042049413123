<!-- start page title -->
<app-pagetitle title="Advanced Plugins" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Css Switch</h4>
                <p class="card-title-desc">Here are a few types of switches. </p>
            </div>
            <!-- end card header -->
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6">
                        <h5 class="font-size-14 mb-3">Example switch</h5>
                        <div>
                            <ui-switch defaultBoColor="#dfdfdf" color="#5156be" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off"></ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#a2a2a2" [checked]="true" checkedLabel="On"
                                uncheckedLabel="Off" class="ms-1">
                            </ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#2ab57d" [checked]="true" checkedLabel="Yes"
                                uncheckedLabel="No" class="ms-1">
                            </ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#5156be" [checked]="true" checkedLabel="Yes"
                                uncheckedLabel="No" class="ms-1">
                            </ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#4ba6ef" [checked]="true" checkedLabel="Yes"
                                uncheckedLabel="No" class="ms-1">
                            </ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#ffbf53" [checked]="true" checkedLabel="Yes"
                                uncheckedLabel="No" class="ms-1">
                            </ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#fd625e" [checked]="true" checkedLabel="Yes"
                                uncheckedLabel="No" class="ms-1">
                            </ui-switch>
                            <ui-switch defaultBoColor="#dfdfdf" color="#343a40" [checked]="true" checkedLabel="Yes"
                                uncheckedLabel="No" class="ms-1">
                            </ui-switch>
                        </div>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Choices</h4>
                <p class="card-title-desc">Choices.js is a lightweight, configurable select box/text input plugin.</p>
            </div>
            <!-- end card header -->

            <div class="card-body">
                <div>
                    <h5 class="font-size-14 mb-3">Single select input Example</h5>

                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-single-default"
                                    class="form-label font-size-13 text-muted">Default</label>
                                <ng-select [items]="Default" bindLabel="name" [(ngModel)]="selectedAccount">
                                    <ng-template ng-optgroup-tmp let-item="item">
                                        {{item.country || 'Unnamed group'}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-single-groups" class="form-label font-size-13 text-muted">Option
                                    groups</label>
                                <ng-select [items]="Groups" bindLabel="name" bindValue="name" groupBy="country"
                                    [(ngModel)]="selectedGroup">
                                    <ng-template ng-optgroup-tmp let-item="item">
                                        {{item.country || 'Unnamed group'}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-single-no-search" class="form-label font-size-13 text-muted">Options
                                    added
                                    via config with no search</label>
                                <ng-select [items]="Options" bindLabel="name" [(ngModel)]="selectedOption">
                                </ng-select>
                            </div>
                        </div>

                    </div>
                    <!-- end row -->
                </div>
                <!-- Single select input Example -->


                <div class="mt-4">
                    <h5 class="font-size-14 mb-3">Multiple select input</h5>

                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-multiple-default"
                                    class="form-label font-size-13 text-muted">Default</label>
                                <div class="multi_default">
                                    <ng-select [items]="Default" [multiple]="true" bindLabel="name"
                                        [(ngModel)]="multiDefaultOption">
                                        <ng-template ng-optgroup-tmp let-item="item">
                                            {{item.country || 'Unnamed group'}}
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-multiple-remove-button"
                                    class="form-label font-size-13 text-muted">With
                                    remove button</label>
                                <ng-select [items]="selectValue" [multiple]="true"></ng-select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="mb-3">
                                <label for="choices-multiple-groups" class="form-label font-size-13 text-muted">Option
                                    groups</label>
                                <ng-select [items]="Groups" [multiple]="true" bindLabel="name" bindValue="name"
                                    groupBy="country">
                                    <ng-template ng-optgroup-tmp let-item="item">
                                        {{item.country || 'Unnamed group'}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>

                    </div>
                    <!-- end row -->
                </div>
                <!-- multi select input Example -->

                <div class="mt-4">
                    <h5 class="font-size-14 mb-3">Text inputs</h5>
                    <div>
                        <label for="choices-text-disabled" class="form-label font-size-13 text-muted">Disabled</label>
                        <ng-select [items]="Default" bindLabel="name" [multiple]="true" [disabled]="disable"
                            [(ngModel)]="selectedPeople1">
                        </ng-select>
                    </div>
                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Colorpicker</h4>
                <p class="card-title-desc">Flat, Simple, Hackable Color-Picker.</p>
            </div>
            <div class="card-body">

                <div class="text-center">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="mt-4">
                                <h5 class="font-size-14">Classic Demo</h5>
                                <div class="color_picker">
                                    <span class="input-group-text colorpicker-input-addon"
                                        [style.background]="componentcolor" [(colorPicker)]="componentcolor"
                                        [cpPosition]="'bottom'"><i></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mt-4">
                                <h5 class="font-size-14">Monolith Demo</h5>
                                <div class="color_picker">
                                    <span class="input-group-text colorpicker-input-addon"
                                        id="colorpicker-showpaletteonly" [style.background]="monolith"
                                        [(colorPicker)]="monolith" [cpPosition]="'bottom'"
                                        [cpPresetColors]="['#fff','#000','#2889e9','#e920e9','#fff500','rgb(236,64,64)']"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="mt-4">
                                <h5 class="font-size-14">Nano Demo</h5>
                                <div class="color_picker">
                                    <span class="input-group-text colorpicker-input-addon"
                                        id="colorpicker-showpaletteonly" [style.background]="nano"
                                        [(colorPicker)]="nano" [cpPosition]="'bottom'" [cpDisableInput]="true"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Datepicker</h4>
                <p class="card-title-desc">flatpickr is a lightweight and powerful datetime picker.</p>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="mb-3">
                            <label class="form-label">Basic</label>

                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true"
                                [convertModelValue]="true">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">DateTime</label>
                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true"
                                [enableTime]="true" [convertModelValue]="true" [dateFormat]="'Y-m-d H:i'">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Human-friendly Dates</label>
                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true"
                                [convertModelValue]="true" altFormat="F j, Y" dateFormat="Y-m-d">
                        </div>

                        <div class="mb-3">
                            <label class="form-label">Selecting multiple dates</label>
                            <input class="form-control flatpickr-input" type="text" id="example-week-input" mwlFlatpickr
                                [altInput]="true" [convertModelValue]="true" mode="multiple">
                        </div>

                        <div>
                            <label class="form-label">Range</label>
                            <input class="form-control flatpickr-input" type="text" mwlFlatpickr [altInput]="true"
                                [convertModelValue]="true" mode="range">
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="mt-3 mt-lg-0">
                            <div class="mb-3">
                                <label class="form-label">Timepicker</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr [noCalendar]="true"
                                    [enableTime]="true" [dateFormat]="'H:i'">
                            </div>

                            <div>
                                <label class="form-label">Inline</label>
                                <input class="form-control flatpickr-input" type="text" mwlFlatpickr
                                    [(ngModel)]="inlineDatePicker" [altInput]="true" [convertModelValue]="true"
                                    [inline]="true">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->