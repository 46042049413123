import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'MENUITEMS.MENU.TEXT',
    isTitle: true,
  },
  {
    id: 2,
    label: 'MENUITEMS.DASHBOARDS.TEXT',
    icon: 'mdi mdi-home-outline',
    link: '/',
  },
  {
    id: 3,
    label: 'MENUITEMS.APPS.LIST.CUSTOMERS.TEXT',
    icon: 'mdi mdi-account-group-outline',
    subItems: [
      {
        id: 4,
        label: 'MENUITEMS.APPS.LIST.CUSTOMERS.LIST.REQUESTS',
        link: '/requests',
        parentId: 3,
      },
      {
        id: 5,
        label: 'MENUITEMS.APPS.LIST.CUSTOMERS.LIST.ACCEPTED',
        link: '/customers',
        parentId: 3,
      },
    ],
  },
  {
    id: 6,
    label: 'MENUITEMS.APPS.LIST.ASSETS.TEXT',
    icon: 'mdi mdi-city',
    subItems: [
      {
        id: 7,
        label: 'MENUITEMS.APPS.LIST.ASSETS.LIST.ONBOARDING',
        link: '/onboarding',
        parentId: 6,
      },
      {
        id: 8,
        label: 'MENUITEMS.APPS.LIST.ASSETS.LIST.ONBOARDEDASSETS',
        link: '/onboarded-assets',
        parentId: 6,
      },
      {
        id: 9,
        label: 'Portfolio onboarding',
        link: '/assets/portfolio-onboarding',
        parentId: 6,
      },
    ],
  },
  {
    id: 10,
    label: 'NDA requests',
    icon: 'mdi mdi-fountain-pen-tip',
    subItems: [
      {
        id: 11,
        label: 'MENUITEMS.APPS.LIST.ASSETS.LIST.NDAREQUESTS',
        link: '/nda/nda-requests',
        parentId: 10,
      },
      {
        id: 16,
        label: 'MENUITEMS.APPS.LIST.ASSETS.LIST.ADDCUSTOMERMANUALY',
        link: '/nda/allow-operation-manually/nda',
        parentId: 10,
      }
    ],
  },
  {
    id: 12,
    label: 'More info requests',
    icon: 'mdi mdi-folder-information-outline',
    subItems: [
      {
        id: 13,
        label: 'MENUITEMS.APPS.LIST.ASSETS.LIST.ASSETINFOREQUESTS',
        link: '/more-info-req/asset-information-requests',
        parentId: 12,
      },
      {
        id: 17,
        label: 'MENUITEMS.APPS.LIST.ASSETS.LIST.ADDCUSTOMERMANUALY',
        link: '/nda/allow-operation-manually/moreInfo',
        parentId: 12,
      }
    ],
  },
  {
    id: 14,
    label: 'MENUITEMS.APPS.LIST.TOOLTIPS.TEXT',
    icon: 'mdi mdi-information-outline',
    subItems: [
      {
        id: 15,
        label: 'MENUITEMS.APPS.LIST.TOOLTIPS.LIST.TOOLTIPSDESC',
        link: '/tooltips/tooltips-desc',
        parentId: 14,
      },
    ],
  }
];
