<div *ngIf="isInitialized">
  <app-pagetitle title=" {{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.TEXT' | translate }}" [breadcrumbItems]="breadCrumbItems" [displayBackButton]="true" backRout="/onboarded-assets"></app-pagetitle>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title"> {{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.TEXT' | translate }}</h4>
          <p class="card-title-desc">{{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.ASSETTOKENIZATIONMESSAGE' | translate }}</p>
        </div>
        <div class="card-body p-4">
          <form [formGroup]="tokenizationForm" *ngIf="tokenizationForm" (ngSubmit)="onSubmit()">
            <div class="row justify-content-center" style="margin-top: 45px">
              <div class="row">
                <div class="col-lg-6">
                  <div class="row justify-content-center">
                    <div class="col-lg-8">
                      <div class="text-center" style="margin-bottom: 10%;">
                        <h5>{{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.INFO' | translate }}</h5>
                      </div>
                      <div class="row" style="margin-top: 5%;">
                        <div class="col-lg-12">
                          <div class="mb-3">
                            <label for="name" class="form-label">{{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.NAME' | translate }}</label>
                            <input class="form-control" type="text" formControlName="name"
                              [ngClass]="{'is-invalid': formSubmit && formData.name.errors}">
                          </div>
                          <div class="mb-3">
                            <label for="symbol" class="form-label">{{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.SYMBOL' | translate }}</label>
                            <input class="form-control" type="text" formControlName="symbol"
                              [ngClass]="{'is-invalid': (formSubmit || checked) && formData.symbol.errors}"
                              (change)="checkToken()">
                            <div *ngIf="formData.symbol.errors" class="invalid-feedback">
                              <span *ngIf="formData.symbol.errors.incorrect">Symbol already exists.</span>
                            </div>
                          </div>
                          <div class="mb-3">
                            <label for="supply" class="form-label">{{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.INITIALSUPLY' | translate }}</label>
                            <div class="input-group">
                              <input class="form-control"  formControlName="initialSupply" mask="separator.2"
                                thousandSeparator="{{separator}}" [ngClass]="{'is-invalid': formSubmit && formData.initialSupply.errors}">
                            </div>
                          </div>
                          <div class="mb-3">
                            <label for="supply" class="form-label">{{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.OWNER' | translate }}</label>
                            <div class="input-group">
                              <input class="form-control" type="text" formControlName="owner"
                                [ngClass]="{'is-invalid': formSubmit && formData.owner.errors}">
                              <button type="button" (click)="copyFromClipboard()" class="btn btn-soft-light waves-effect waves-light"
                                title="paste"><i class="bx bx-clipboard"></i></button>
                              <div *ngIf="formData.owner.errors" class="invalid-feedback">
                                <span *ngIf="formData.owner.errors.pattern">Please provide a valid Ethereum Externally Owned Address. For more
                                  info visit <a href="https://info.etherscan.com/what-is-an-ethereum-address/" target="_blank">link</a></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row">
                    <form [formGroup]="assetForm">
                      <div class="col-lg-8">
                        <div class="text-center" style="margin-bottom: 10%;">
                          <h5>{{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.SMALLPREVIEW' | translate }}</h5>
                        </div>
                        <div class="row" style="margin-top: 5%;">
                          <div class="col-lg-6">
                            <div class="mb-3">
                              <label for="name" class="form-label">Id:</label>
                              <input class="form-control" type="text" formControlName="id" readonly>
                            </div>
                            <div class="mb-3">
                              <label for="phoneNumber" class="form-label">{{ 'ASSETSPAGE.LIST.DEBTSITUATION.LIST.GROSSVALUE' | translate }}</label>
                              <input class="form-control" mask="separator.2" thousandSeparator="{{separator}}" formControlName="grossValue" readonly>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="mb-3">
                              <label for="name" class="form-label">{{ 'ASSETSPAGE.LIST.PROPERTYTITLE' | translate }}:</label>
                              <input class="form-control" type="text" formControlName="title" readonly>
                            </div>
                            <div class="mb-3">
                              <label for="symbol" class="form-label">{{ 'ASSETSPAGE.LIST.ADDRESS.TEXT' | translate }}:</label>
                              <div class="input-group">
                                <input class="form-control" type="text" formControlName="address" readonly>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="mb-3">
                            <label for="e-mail" class="form-label">{{ 'ASSETSPAGE.LIST.ASSETDESC' | translate }}</label>
                            <p  [innerHTML]="assetForm?.value?.description ?? 'n/a'" style="overflow-y: auto; height:150px;"></p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" >
              <div class="col-lg-6">
                <div class="row justify-content-center">
                  <div class="col-lg-8">
                    <div class="row" style="margin-top: 5%;">
                      <div class="col-lg-6">
                        <div class="row justify-content-end">
                          <button type = "button" class="btn btn-soft-light waves-effect waves-ligh p-0 avatar-sm" (click)="openModal(gallery)" title="{{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.GALLERY' | translate }}">
                            <i class="mdi mdi-image-multiple-outline"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row justify-content-center">
                  <div class="col-lg-8">
                    <div class="row" style="margin-top: 5%;">
                      <div class="col-lg-6">
                        <div class="row justify-content-end">
                          <button class="btn btn-primary" type="submit" style="width: 150px !important; height: 50px !important">
                            {{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.START' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #gallery role="reject" let-modal>
  <div class="modal-header">
      <h5 class="modal-title mt-0">{{ 'ASSETSPAGE.LIST.ASSETTOKENIZATION.LIST.GALLERY' | translate }}</h5>
      <button type="button" class="btn-close" aria-hidden="true"
          (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ngx-gallery *ngIf="galleryImages" [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-light"
          (click)="modal.close('Close click')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.CLOSE' | translate }}</button>
  </div>
</ng-template>
