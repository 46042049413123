import { DecimalPipe } from '@angular/common';
import { Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { SortColumn, SortDirection } from 'src/app/pages/apps/onboarding/onboarding-wizard/social-economics/social-economics-table/social-economics-table.directive';
import { SocialEconomics } from '../models/social-economics.models';


interface SearchResult {
  tables: SocialEconomics[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

const compare = (v1: String | number, v2: String | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

/**
 * Sort the table data
 * @param OnboardedAssetsList field value
 * @param column Fetch the column
 * @param direction Sort direction Ascending or Descending
 */
function sort(
  tables: SocialEconomics[],
  column: SortColumn,
  direction: string
): SocialEconomics[] {
  if (direction === '' || column === '') {
    return tables;
  } else {
    return [...tables].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

/**
 * Table Data Match with Search input
 * @param  Table field value fetch
 * @param term Search the value
 */
function matches(
  table: SocialEconomics,
  term: string,
  pipe: PipeTransform
) {
  return (
    table.region.toLowerCase().includes(term) ||
    pipe.transform(table.centrality).includes(term) ||
    pipe.transform(table.commuters).includes(term) ||
    pipe.transform(table.expected_population_growth).includes(term) ||
    pipe.transform(table.number_of_inhabitants).includes(term) ||
    pipe.transform(table.purchasing_power).includes(term)
  );
}


@Injectable({
  providedIn: 'root'
})
export class SocialEconomicsService {
  // tslint:disable-next-line: variable-name
  private _loading$ = new BehaviorSubject<boolean>(true);
  // tslint:disable-next-line: variable-name
  private _search$ = new Subject<void>();
  // tslint:disable-next-line: variable-name
  private _tables$ = new BehaviorSubject<SocialEconomics[]>([]);
  // tslint:disable-next-line: variable-name
  private _total$ = new BehaviorSubject<number>(0);
  tableData!: SocialEconomics[];
  // tslint:disable-next-line: variable-name
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    startIndex: 0,
    endIndex: 9,
    totalRecords: 0,
  };

  constructor(private pipe: DecimalPipe) {
    this._search().subscribe((result) => {
      this._tables$.next(result.tables);
      this._total$.next(result.total);
    });
    this._search$.next();
  }

  /**
   * Returns the value
   */
  get tables$() {
    return this._tables$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  get startIndex() {
    return this._state.startIndex;
  }
  get endIndex() {
    return this._state.endIndex;
  }
  get totalRecords() {
    return this._state.totalRecords;
  }

  /**
   * set the value
   */
  // tslint:disable-next-line: adjacent-overload-signatures
  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
    this.setPaging();
  }
  // tslint:disable-next-line: adjacent-overload-signatures
  // tslint:disable-next-line: adjacent-overload-signatures
  set startIndex(startIndex: number) {
    this._set({ startIndex });
  }
  // tslint:disable-next-line: adjacent-overload-signatures
  set endIndex(endIndex: number) {
    this._set({ endIndex });
  }
  // tslint:disable-next-line: adjacent-overload-signatures
  set totalRecords(totalRecords: number) {
    this._set({ totalRecords });
  }
  // tslint:disable-next-line: adjacent-overload-signatures
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: SortColumn) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }
  set tables(tables: SocialEconomics[]) {
    this._tables$.next(tables);
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  /**
   * Search Method
   */
  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;

    // 1. sort
    let tables = sort(this._tables$.getValue(), sortColumn, sortDirection);

    // 2. filter
    tables = tables.filter((table) => matches(table, searchTerm, this.pipe));
    const total = tables.length;

    return of({ tables, total });
  }
  public setPaging() {
    this.tables = this.tableData;
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;
    let tables = sort(this._tables$.getValue(), sortColumn, sortDirection);

    this.totalRecords = this._tables$.value.length;
    this._state.startIndex = (this._state.page - 1) * this.pageSize + 1;
    this._state.endIndex =
      (this._state.page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    this._tables$.next(
      tables.slice(this._state.startIndex - 1, this._state.endIndex)
    );
    this._total$.next(this.totalRecords);
  }

  onPageChange(pageNum: number) {
    this.tables = this.tableData;
    const { sortColumn, sortDirection, pageSize, page, searchTerm } =
      this._state;
    this._state.startIndex = (pageNum - 1) * this.pageSize + 1;
    this._state.endIndex = (pageNum - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    let tables = sort(this._tables$.getValue(), sortColumn, sortDirection);
    this._tables$.next(
      tables.slice(this._state.startIndex - 1, this._state.endIndex)
    );
  }
}
