import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: 'apps',
    loadChildren: () => import('./apps/apps.module').then((m) => m.AppsModule),
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./extraspages/extraspages.module').then(
        (m) => m.ExtraspagesModule
      ),
  },
  {
    path: 'nda',
    loadChildren: () => import('./nda/nda.module').then((m) => m.NdaModule),
  },
  {
    path: 'tooltips',
    loadChildren: () => import('./tooltips/tooltip.module').then((m) => m.TooltipModule),
  },
  {
    path: 'more-info-req',
    loadChildren: () =>
      import('./more-information/more-information.module').then(
        (m) => m.MoreInformationModule
      ),
  },
  {
    path: 'assets',
    loadChildren: () =>
      import('./properties-assets/assets.module').then(
        (m) => m.AssetsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
