<form [formGroup]="breakdownForm" (ngSubmit)="passBack()">

    <div class="modal-header">
        <h5 class="modal-title mt-0">{{ 'ASSETSPAGE.LIST.BREAKDOWN.TEXT' | translate }}</h5>
        <button type="button" class="btn-close" aria-hidden="true"
            (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="row">
          <div class="col-md-6 columns_styling justify-content-center" formArrayName="area">
          <h6>{{ 'ASSETSPAGE.LIST.BREAKDOWN.LIST.AREA' | translate }}</h6>
            <div *ngFor = "let usageRow of areaForm.controls; let i = index" [formGroupName]="i">
                <div class="col-md-12" style="margin-bottom: 10px;">
                    <div class="mb-6 position-relative">
                        <label>{{usageRow.get('description')?.value}}</label>
                        <div class="input-group">
                            <input  class="form-control" id="validationTooltip_{{i}}" formControlName="value"
                            mask="separator.2" thousandSeparator="{{separator}}"
                            placeholder="{{usageRow.get('description')?.value}}" [ngClass]="{'is-invalid':formsubmit && checkValidity(usageRow.get('id')?.value)}"/>
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="validationTooltip_{{i}}_Prepend">m²</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-6 columns_styling justify-content-center" formArrayName="rent">
          <h6>{{ 'ASSETSPAGE.LIST.BREAKDOWN.LIST.RENT' | translate }}</h6>
          <div *ngFor = "let usageRowRent of rentForms.controls; let y = index" [formGroupName]="y">
              <div class="col-md-12" style="margin-bottom: 10px;">
                  <div class="mb-6 position-relative">
                      <label>{{usageRowRent.get('description')?.value}}</label>
                      <div class="input-group">
                          <input class="form-control" id="validationTooltip_{{y}}" formControlName="value"
                          mask="separator.2" thousandSeparator="{{separator}}"
                          placeholder="{{usageRowRent.get('description')?.value}}" [ngClass]="{'is-invalid':formsubmit && checkValidityRent(usageRowRent.get('id')?.value)}"/>
                          <div class="input-group-prepend">
                              <span class="input-group-text" id="validationTooltip_{{y}}_Prepend">€</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="activeModal.close('Close click')">{{ 'ASSETSPAGE.LIST.BREAKDOWN.LIST.CLOSE' | translate }}</button>
        <button type="submit" class="btn btn-primary">{{ 'ASSETSPAGE.LIST.BREAKDOWN.LIST.SAVE' | translate }}</button>
    </div>
</form>
