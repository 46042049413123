<!-- start page title -->
<app-pagetitle title="Dropdowns" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Single button dropdowns</h4>
                <p class="card-title-desc">Any single <code class="highlighter-rouge">.btn</code> can be turned into a
                    dropdown
                    toggle with some markup changes. Here’s how you can put them to work
                    with either <code class="highlighter-rouge">&lt;button&gt;</code>
                    elements:
                </p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="dropdown" ngbDropdown>
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                                data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                Dropdown button <i class="mdi mdi-chevron-down"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                                <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            </div>
                        </div>
                    </div><!-- end col -->

                    <div class="col-sm-6">
                        <div class="dropdown mt-4 mt-sm-0" ngbDropdown>
                            <a href="javascript:void(0);" class="btn btn-secondary dropdown-toggle"
                                data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                                Dropdown link <i class="mdi mdi-chevron-down"></i>
                            </a>

                            <div class="dropdown-menu" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                                <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            </div>
                        </div>
                    </div><!-- end col -->
                </div><!-- end row -->
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Variant</h4>
                <p class="card-title-desc">The best part is you can do this with any button variant, too:</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="d-flex gap-2 flex-wrap">
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false" ngbDropdownToggle>Primary <i
                                class="mdi mdi-chevron-down"></i></button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false" ngbDropdownToggle>Secondary <i
                                class="mdi mdi-chevron-down"></i></button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-success dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false" ngbDropdownToggle>Success <i
                                class="mdi mdi-chevron-down"></i></button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false" ngbDropdownToggle>Info <i class="mdi mdi-chevron-down"></i></button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-warning dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false" ngbDropdownToggle>Warning <i
                                class="mdi mdi-chevron-down"></i></button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-danger dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="false" ngbDropdownToggle>Danger <i class="mdi mdi-chevron-down"></i></button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div> <!-- end row -->

<div class="row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Dropdown Menu Item Color</h4>
                <p class="card-title-desc">Example of dropdown menu item color</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="row">
                    <div class="col-lg-3">

                        <div class="">
                            <h5 class="font-size-13 mb-3">Dropdown Menu Success link example</h5>
                            <div class="clearfix">
                                <div class="dropdown-menu d-inline-block position-relative dropdownmenu-success"
                                    style="z-index: 1;">
                                    <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                    <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                                    <a class="dropdown-item active" href="javascript:void(0);">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-9">
                        <div class="mt-lg-0 mt-3">
                            <h5 class="font-size-13 mb-0">Dropdown Menu link Color example</h5>
                            <div>
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="mt-3">
                                            <p class="font-size-13 mb-2">Dropdown menu Primary link</p>
                                            <div class="btn-group" ngbDropdown>
                                                <button type="button" class="btn btn-primary dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                    ngbDropdownToggle>Primary <i
                                                        class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu dropdownmenu-primary" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Another
                                                        action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Something else
                                                        here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" href="javascript:void(0);">Separated
                                                        link</a>
                                                </div>
                                            </div><!-- /btn-group -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="mt-3">
                                            <p class="font-size-13 mb-2">Dropdown menu Secondary link</p>
                                            <div class="btn-group" ngbDropdown>
                                                <button type="button" class="btn btn-secondary dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                    ngbDropdownToggle>Secondary <i
                                                        class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu dropdownmenu-secondary" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Another
                                                        action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Something else
                                                        here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" href="javascript:void(0);">Separated
                                                        link</a>
                                                </div>
                                            </div><!-- /btn-group -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="mt-3">
                                            <p class="font-size-13 mb-2">Dropdown menu Success link</p>
                                            <div class="btn-group" ngbDropdown>
                                                <button type="button" class="btn btn-success dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                    ngbDropdownToggle>Success <i
                                                        class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu dropdownmenu-success" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Another
                                                        action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Something else
                                                        here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" href="javascript:void(0);">Separated
                                                        link</a>
                                                </div>
                                            </div><!-- /btn-group -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="mt-3">
                                            <p class="font-size-13 mb-2">Dropdown menu Warning link</p>
                                            <div class="btn-group" ngbDropdown>
                                                <button type="button" class="btn btn-warning dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                    ngbDropdownToggle>Warning <i
                                                        class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu dropdownmenu-warning" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Another
                                                        action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Something else
                                                        here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" href="javascript:void(0);">Separated
                                                        link</a>
                                                </div>
                                            </div><!-- /btn-group -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="mt-3">
                                            <p class="font-size-13 mb-2">Dropdown menu Info link</p>
                                            <div class="btn-group" ngbDropdown>
                                                <button type="button" class="btn btn-info dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                    ngbDropdownToggle>Info <i class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu dropdownmenu-info" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Another
                                                        action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Something else
                                                        here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" href="javascript:void(0);">Separated
                                                        link</a>
                                                </div>
                                            </div><!-- /btn-group -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-6">
                                        <div class="mt-3">
                                            <p class="font-size-13 mb-2">Dropdown menu Danger link</p>
                                            <div class="btn-group" ngbDropdown>
                                                <button type="button" class="btn btn-danger dropdown-toggle"
                                                    data-bs-toggle="dropdown" aria-expanded="false"
                                                    ngbDropdownToggle>Danger <i
                                                        class="mdi mdi-chevron-down"></i></button>
                                                <div class="dropdown-menu dropdownmenu-danger" ngbDropdownMenu>
                                                    <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Another
                                                        action</a>
                                                    <a class="dropdown-item" href="javascript:void(0);">Something else
                                                        here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" href="javascript:void(0);">Separated
                                                        link</a>
                                                </div>
                                            </div><!-- /btn-group -->
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div> <!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Split Button Dropdowns</h4>
                <p class="card-title-desc">The best part is you can do this with any button variant, too:</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="d-flex gap-2 flex-wrap">
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-primary">Primary</button>
                        <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                            <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-secondary">Secondary</button>
                        <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                            <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-success">Success</button>
                        <button type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                            <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-info">Info</button>
                        <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                            <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-warning">Warning</button>
                        <button type="button" class="btn btn-warning dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                            <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-danger">Danger</button>
                        <button type="button" class="btn btn-danger dropdown-toggle dropdown-toggle-split"
                            data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                            <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div><!-- /btn-group -->
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Sizing</h4>
                <p class="card-title-desc">Button dropdowns work with buttons of
                    all sizes, including default and split dropdown buttons.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <!-- Large button groups (default and split) -->
                <div class="btn-group me-1 mt-2" ngbDropdown>
                    <button class="btn btn-primary btn-lg dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false" ngbDropdownToggle>
                        Large button <i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">Action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                    </div>
                </div>
                <div class="btn-group me-1 mt-2" ngbDropdown>
                    <button class="btn btn-secondary btn-lg" type="button">
                        Large button
                    </button>
                    <button type="button" class="btn btn-lg btn-secondary dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                        <i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">Action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                    </div>
                </div>
                <!-- Small button groups (default and split) -->
                <div class="btn-group me-1 mt-2" ngbDropdown>
                    <button class="btn btn-info btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false" ngbDropdownToggle>
                        Small button <i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">Action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                    </div>
                </div>
                <div class="btn-group me-1 mt-2" ngbDropdown>
                    <button class="btn btn-secondary btn-sm" type="button">
                        Small button
                    </button>
                    <button type="button" class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                        <i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">Action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                    </div>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Menu Content</h4>
                <p class="card-title-desc">Example of dropdown menu Headers, Text, Forms content</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="d-flex flex-wrap gap-2">
                    <!-- Header -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            Header <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <div class="dropdown-header noti-title">
                                <h5 class="font-size-13 text-muted text-truncate mn-0">Welcome Jessie!</h5>
                            </div>
                            <!-- item-->
                            <a class="dropdown-item" href="javascript:void(0);">Action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                            <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                        </div>
                    </div>

                    <!-- Text -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-success dropdown-toggle" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            Text <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-md p-3" ngbDropdownMenu>
                            <p>
                                Some example text that's free-flowing within the dropdown menu.
                            </p>
                            <p class="mb-0">
                                And this is more example text.
                            </p>
                        </div>
                    </div>

                    <!-- Forms -->
                    <div class="btn-group" ngbDropdown>
                        <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            Forms <i class="mdi mdi-chevron-down"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-md p-4" ngbDropdownMenu>
                            <form>
                                <div class="mb-2">
                                    <label class="form-label" for="exampleDropdownFormEmail">Email address</label>
                                    <input type="email" class="form-control" id="exampleDropdownFormEmail"
                                        placeholder="email@example.com">
                                </div>
                                <div class="mb-2">
                                    <label class="form-label" for="exampleDropdownFormPassword">Password</label>
                                    <input type="password" class="form-control" id="exampleDropdownFormPassword"
                                        placeholder="Password">
                                </div>
                                <div class="mb-2">
                                    <div class="form-check custom-checkbox">
                                        <input type="checkbox" class="form-check-input" id="rememberdropdownCheck">
                                        <label class="form-check-label" for="rememberdropdownCheck">Remember me</label>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary">Sign in</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Menu Alignment</h4>
                <p class="card-title-desc">Add <code class="highlighter-rouge">.dropdown-menu-end</code>
                    to a <code class="highlighter-rouge">.dropdown-menu</code> to right
                    align the dropdown menu.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="btn-group" ngbDropdown>
                    <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown"
                        aria-expanded="false" ngbDropdownToggle>
                        Menu is right-aligned <i class="mdi mdi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">Action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                    </div>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div> <!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Manual and custom triggers</h4>
                <p class="card-title-desc">You can easily control dropdowns programmatically using the exported dropdown instance.</p>
                    
            </div><!-- end card header -->

            <div class="card-body">
                <div class="d-inline-block" ngbDropdown #customDropdown="ngbDropdown">
                    <button class="btn btn-outline-primary me-2" id="dropdownManual" ngbDropdownAnchor (focus)="customDropdown.open()">Toggle dropdown</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownManual">
                      <button ngbDropdownItem>Action - 1</button>
                      <button ngbDropdownItem>Another Action</button>
                      <button ngbDropdownItem>Something else is here</button>
                    </div>
                  </div>
                  
                  <button class="btn btn-outline-success me-2" (click)="$event.stopPropagation(); customDropdown.open();">Open from outside</button>
                  <button class="btn btn-outline-danger" (click)="$event.stopPropagation(); customDropdown.close();">Close from outside</button>
            </div>
        </div>
    </div>
</div>