import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { LocationSocialEconomics } from 'src/app/core/models/assetsModels/location.models';
import { LocationSocialEconomicsPinService } from 'src/app/core/services/location-social-economics-pin.service';
import { ShowPinOnMapService } from 'src/app/core/services/showPinOnMap.service ';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, AfterViewInit {

  public isApiLoaded: boolean = true;

  @Input() readOnly: boolean = false;

  @ViewChild(GoogleMap, { static: false })
  public tectrexMap!: GoogleMap;
  @ViewChild('search')
  public searchElementRef!: ElementRef;

  @Output() mapValid = new EventEmitter<any>();

  latitude: number | undefined;
  longitude: number | undefined;


  public infoWindow!: google.maps.InfoWindow;
  public markers: google.maps.Marker[] = [];
  public markerClusterer: MarkerClusterer | undefined;

  @Output() public addSocialEconomics = new EventEmitter<EventClass>();
  @Output() public removeSocialEconomics = new EventEmitter<EventClass>();


  @ViewChild(GoogleMap, { static: false }) set map(tectrexMap: GoogleMap) {
    this.tectrexMap = tectrexMap;
    if (tectrexMap && !this.readOnly) {
      this.initDrawingManager(tectrexMap);
      this.initSearch(tectrexMap);
    }
  }

  zoom = 13;
  maxZoom = 18;
  minZoom = 3;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: 1.0 as google.maps.MapTypeControlStyle,
      mapTypeIds: ["roadmap", "terrain", "satellite"],
    },
    maxZoom: this.maxZoom,
    minZoom: this.minZoom,
  }

  drawingManager: any;

  listOfMarkers = [] as any;
  listOfPolygons = [] as any;

  constructor(private ngZone: NgZone, private showPinOnMapService: ShowPinOnMapService, private locationSocialEconomicsPinService: LocationSocialEconomicsPinService) { }

  ngOnInit(): void {
    this.locationSocialEconomicsPinService.locationPins = [];
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    });
    this.showPinOnMapService.showPin.subscribe(
      (result) => {
        this.findLocationByAddress(result);
      }
    );

    this.showPinOnMapService.removePin.subscribe(
      (result) => {
        let marker = this.markers[this.markers.indexOf(result)];
        if (marker != undefined) {
          marker.setMap(null);
          this.markers.splice(this.markers.indexOf(result), 1);
          this.removeMarker(marker.getPosition()!.toJSON());
          this.createMarkerClusterer();
          this.checkValidity();
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.isApiLoaded = true;
  }


  initDrawingManager(tectrexMap: GoogleMap) {
    const drawingOptions = {
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: true,
      drawingControlOptions: {
        position: 9.0 as google.maps.ControlPosition,
        drawingModes: [
          'polygon' as google.maps.drawing.OverlayType,
          'marker' as google.maps.drawing.OverlayType
        ],
      },
      polygonOptions: {
        strokeColor: '#33AEB1',
        clickable: true
      },
      markerOptions: {
        animation: google.maps.Animation.DROP,
        clickable: true,
        icon: 'assets/images/google/spotlight-poi2_hdpi.png'
      }
    };
    this.drawingManager = new google.maps.drawing.DrawingManager(drawingOptions);
    this.drawingManager.setMap(tectrexMap.googleMap);
    this.drawingManager.addListener('markercomplete', (marker: any) => {
      this.insertMarker(marker.position.toJSON());
      this.checkValidity();
      this.makeMouseClickEvent(marker);
      this.addMarkerToMarkerClusterer(marker);
    });
    this.tectrexMap.googleMap!.addListener('zoom_changed', () => {
      if (this.infoWindow != undefined) {
        this.infoWindow.close();
      }
      this.createMarkerClusterer();
    });
    this.drawingManager.addListener(
      'overlaycomplete',
      (event: any) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          this.updatePointList(event.overlay.getPath());
          this.checkValidity();
          this.makePolygonClickEvent(event.overlay);
        }
      }
    );

  }

  initSearch(tectrexMap: GoogleMap) {
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    // Align search box to center
    this.tectrexMap.controls[9.0 as google.maps.ControlPosition].push(
      this.searchElementRef.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {

        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        this.latitude = place.geometry.location?.lat();
        this.longitude = place.geometry.location?.lng();
        if (this.latitude != undefined && this.longitude != undefined) {
          this.center = { lat: this.latitude, lng: this.longitude };
          this.drowMarker(this.center);
          this.zoom = 18;
        }
      });
    });
  }

  public insertMarker(latlang: any) {
    this.listOfMarkers.push({
      lat: latlang.lat,
      lng: latlang.lng,
    });
    this.getReverseGeocodingData(latlang.lat, latlang.lng, true, false);
  }

  public drowMarker(latlang: any) {
    let marker = new google.maps.Marker({
      position: new google.maps.LatLng(latlang.lat, latlang.lng),
      map: this.tectrexMap.googleMap,
      icon: 'assets/images/google/spotlight-poi2_hdpi.png'
    });
    this.addMarkerToMarkerClusterer(marker);

    this.makeMouseClickEvent(marker);
    this.insertMarker({
      lat: latlang.lat,
      lng: latlang.lng,
    })
  }

  public drowPolygon(latlangs: any[]) {
    let paths = [];
    for (let latlang of latlangs) {
      if (latlang.lat == undefined || latlang.lng == undefined) {
        paths.push(new google.maps.LatLng(latlang[0], latlang[1]));
      } else {
        paths.push(new google.maps.LatLng(latlang.lat, latlang.lng));

      }
    }
    let polygon = new google.maps.Polygon({
      paths: paths,
      map: this.tectrexMap.googleMap,
      strokeColor: '#33AEB1'
    });
    if (!this.readOnly) {
      this.makePolygonClickEvent(polygon);
    }
    this.updatePointList(polygon.getPath());
  }

  public removeMarker(latlang: any) {
    for (let i = 0; i < this.listOfMarkers.length; i++) {
      let marker = this.listOfMarkers[i];
      if (marker.lat == latlang.lat && marker.lng == latlang.lng) {
        this.listOfMarkers.splice(i, 1);
        this.getReverseGeocodingData(latlang.lat, latlang.lng, false, true);
      }
    }
  }

  public flyTo(lat: number, lng: number) {
    this.center = {
      lat: lat,
      lng: lng,
    }
  }


  public updatePointList(path: any) {
    let polygon = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      polygon.push(
        path.getAt(i).toJSON()
      );
    }
    this.listOfPolygons.push(polygon);
  }

  public removePolygon(latlang: any) {
    for (const iterator of this.listOfPolygons) {
      if (this.arraysAreIdentical(iterator, latlang)) {
        this.listOfPolygons.splice(
          this.listOfPolygons.indexOf(iterator),
          1
        );
      }
    }
  }

  private arraysAreIdentical(arr1: [], arr2: []) {
    if (arr1.length !== arr2.length) return false;
    for (var i = 0, len = arr1.length; i < len; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }
    return true;
  }

  validateMap(value: any) {
    this.mapValid.emit(value);
  }

  private checkValidity() {
    if (this.listOfMarkers.length !== 0 || this.listOfPolygons.length !== 0) {
      this.validateMap(true);
    } else {
      this.validateMap(false);
    }
  }


  private makeMouseClickEvent(marker: any) {
    if (!this.readOnly) {
      marker.addListener('click', () => {
        this.markers.splice(this.markers.indexOf(marker), 1);
        marker.setMap(null);
        this.removeMarker(marker.position.toJSON());
        this.checkValidity();
      });
    }


    marker.addListener("mouseover", () => {
      this.choseInfoWindowToOpen(marker);
      if (this.infoWindow.getContent() != "" && this.infoWindow.getContent() != undefined) {
        this.infoWindow.open({
          anchor: marker,
          map: this.tectrexMap.googleMap!,
          shouldFocus: true
        })
      }
      
    });
    marker.addListener("mouseout", () => {
      this.infoWindow.close();
    });
  }

  private makePolygonClickEvent(polygon: any) {
    polygon.addListener('click', () => {
      polygon.setMap(null);
      let langlang = [];
      let path = polygon.getPath();
      const len = path.getLength();
      for (let i = 0; i < len; i++) {
        langlang.push(
          path.getAt(i).toJSON()
        );
      }
      this.removePolygon(langlang);
      this.checkValidity();
    });
  }

  private addMarkerToMarkerClusterer(marker: google.maps.Marker) {
    this.markers.push(marker);
    this.createMarkerClusterer();
  }

  private createMarkerClusterer() {
    if (this.markerClusterer != undefined) {
      this.markerClusterer.clearMarkers();
    }
    this.markerClusterer = new MarkerClusterer(this.tectrexMap.googleMap!, this.markers, {
      zoomOnClick: false,
      maxZoom: 17,
      imagePath:
        "assets/images/google/m"
    });
    let self = this;
    this.markerClusterer.addListener('mouseover', function (cluster: any) {
      self.infoWindow = new google.maps.InfoWindow()
      self.infoWindow.setContent(self.chooseClusterInfoWindowToOpen(cluster.markers_[0]));
      self.infoWindow.setPosition(cluster.getCenter());
      if (self.infoWindow.getContent() != "" && self.infoWindow.getContent() != undefined) {
        self.infoWindow.open(self.tectrexMap.googleMap!);
      }
    });
    this.markerClusterer.addListener('mouseout', function (cluster: any) {
      self.infoWindow.close();
    });
  }


  public createMapPinText(locationSocialEconomics: LocationSocialEconomics) {
    let string = "";
    string = "<h5 class=\"text-center\" style=\"color: #38aeb0\">Socioeconomics</h5>"
    if (locationSocialEconomics.region != undefined) {
      string += "<div class=\"row\">"
      string += "<div class=\"col\"><label style=\"margin-right: 5px\"><strong>Region:</strong></label>" + locationSocialEconomics.region;
      string += "</div></div>"
    }
    if (locationSocialEconomics.numberOfInhabitants != undefined) {
      string += "<div class=\"row\">"
      string += "<div class=\"col\"><label style=\"margin-right: 5px\"><strong>Number of Inhabitants:</strong></label>" + locationSocialEconomics.numberOfInhabitants;
      string += "</div></div>"
    }
    if (locationSocialEconomics.expectedPopulationGrowth != undefined) {
      string += "<div class=\"row\">"
      string += "<div class=\"col\"><label style=\"margin-right: 5px\"><strong>Expected population growth:</strong></label>" + locationSocialEconomics.expectedPopulationGrowth;
      string += "</div></div>"
    }
    if (locationSocialEconomics.netNumberOfCommuters != undefined) {
      string += "<div class=\"row\">"
      string += "<div class=\"col\"><label style=\"margin-right: 5px\"><strong>Net number of commuters:</strong></label>" + locationSocialEconomics.netNumberOfCommuters;
      string += "</div></div>"
    }
    if (locationSocialEconomics.retailCentrality != undefined) {
      string += "<div class=\"row\">"
      string += "<div class=\"col\"><label style=\"margin-right: 5px\"><strong>Retail centrality (Ø = 100):</strong></label>" + locationSocialEconomics.retailCentrality;
      string += "</div></div>"
    }
    if (locationSocialEconomics.retailPurchasingPower != undefined) {
      string += "<div class=\"row\">"
      string += "<div class=\"col\"><label style=\"margin-right: 5px\"><strong>Retail purchasing power (Ø = 100):</strong></label>" + locationSocialEconomics.retailPurchasingPower;
      string += "</div></div>"
    }
    return string;
  }

  private getReverseGeocodingData(lat: number, lng: number, addOne: boolean, removeOne: boolean) {
    var latlng = new google.maps.LatLng(lat, lng);
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let city = "";
        let municipalitie = "";
        let state = "";
        for (let i = 0; i < results!.length; i++) {
          if (results![i].types[0] == "administrative_area_level_1") {
            state = results![i].formatted_address;
          }
          if (results![i].types[0] == "administrative_area_level_3") {
            municipalitie = results![i].formatted_address;
          }
          if (results![i].types[0] == "locality") {
            city = results![i].formatted_address;
          }
        }
        if (addOne) {
          this.addNewSocialEconomicsPin(latlng, state, 4);
          this.addNewSocialEconomicsPin(latlng, municipalitie, 2);
          this.addNewSocialEconomicsPin(latlng, city, 1)
        }
        if (removeOne) {
          this.removeSocialEconomicsPin(state, 4);
          this.removeSocialEconomicsPin(municipalitie, 2);
          this.removeSocialEconomicsPin(city, 1)
        }
      }
    });
  }

  private findLocationByAddress(event: EventClass) {
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: event.region }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
      } else {
        this.tectrexMap.googleMap!.fitBounds(results![0].geometry.viewport);
      }
    });
  }


  private addNewSocialEconomicsPin(latLng: google.maps.LatLng, region_name: string, level: number) {
    if (region_name == undefined || region_name == "") {
      return;
    }
    let marker = this.markers.filter((marker) => marker.getPosition()?.lat() == latLng.lat() && marker.getPosition()?.lng() == latLng.lng())[0]
    let array = this.locationSocialEconomicsPinService.locationPins.filter((existing) => existing.level == level);
    let exist = array.filter((existing) => existing.locationSocialEconomics?.region == region_name).length > 0;
    if (exist) {
      array.filter((existing) => existing.locationSocialEconomics?.region == region_name).forEach((existing) => {
        existing.count!++;
        existing.markers!.push(marker);
      });
    } else {
      let new_pin = new LocationPin();
      new_pin.markers?.push(marker);
      new_pin.count = 1;
      new_pin.level = level;
      new_pin.locationSocialEconomics = {
        region: region_name
      }
      new_pin.infoWindow = new google.maps.InfoWindow({
        content: this.createMapPinText(new_pin.locationSocialEconomics!)
      });
      this.locationSocialEconomicsPinService.addLocation(new_pin);
      let eventClass = new EventClass();
      eventClass.region = region_name;
      eventClass.level = level;
      this.addSocialEconomics.emit(eventClass);
    }
  }

  private removeSocialEconomicsPin(region_name: string, level: number) {
    if (region_name == undefined || region_name == "") {
      return;
    }
    let array = this.locationSocialEconomicsPinService.locationPins.filter((existing) => existing.level == level);
    let exist = array.filter((existing) => existing.locationSocialEconomics?.region == region_name).length > 0;
    if (exist) {
      array.filter((existing) => existing.locationSocialEconomics?.region == region_name).forEach((existing) => {
        existing.count!--;
      });
      array.forEach(
        (existing) => {
          if (existing.count == 0) {
            let eventClass = new EventClass();
            eventClass.region = region_name;
            eventClass.level = level;
            this.removeSocialEconomics.emit(eventClass);
          }
        }
      );
      array = array.filter((existing) => existing.count != 0);
    }
  }

  private choseInfoWindowToOpen(marker: google.maps.Marker) {
    this.infoWindow = new google.maps.InfoWindow();
    let zoom = this.tectrexMap.googleMap?.getZoom()!;
    let level = 4;
    if (zoom > 15) {
      level = 1;
    } else if (zoom <= 15 && zoom > 10) {
      level = 2;
    } else if ( zoom > 7) {
      level = 4;
    }
    this.locationSocialEconomicsPinService.locationPins.forEach(
      (location) => {
        location.markers?.forEach(
          (locationMarker) => {
            if (locationMarker == marker && location.level == level) {
              this.infoWindow.setContent(this.createMapPinText(location.locationSocialEconomics!));
            }
          }
        )

      }
    )
  }
  private chooseClusterInfoWindowToOpen(marker: google.maps.Marker) {
    let zoom = this.tectrexMap.googleMap?.getZoom()!;
    let level = 3;
    if (zoom > 15) {
      level = 1;
    } else if (zoom <= 15 && zoom > 10) {
      level = 2;
    } else if (zoom > 7) {
      level = 4;
    }
    let text = ""
    this.locationSocialEconomicsPinService.locationPins.forEach(
      (location) => {
        location.markers?.forEach(
          (locationMarker) => {
            if (locationMarker == marker && location.level == level) {
              text = this.createMapPinText(location.locationSocialEconomics!);
            }
          }
        )

      }
    );
    return text;
  }


  deleteAllPins() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete all!',
    }).then((result) => {
      if (result.value) {
        for (let marker of this.markers) {
          this.removeMarker(marker.getPosition()?.toJSON());
          marker.setMap(null);
        }
        this.markers = [];
        this.listOfMarkers = [];
      }
    });
    
  }

}

export class LocationPin {
  public locationSocialEconomics?: LocationSocialEconomics;
  public count?: number;
  public level?: number;
  public markers?: google.maps.Marker[] = [];
  public infoWindow!: google.maps.InfoWindow;
}

export class EventClass {
  public region?: string;
  public level?: number;
}



