<div class="row  p-2" style="margin-top: 20px;">
    <div class="row p-2">
        <label for="basicpill-phoneno-input1" class="form-label">{{ 'ASSETSPAGE.LIST.AVAILABLEHIGHLIGHTS' | translate}}:</label>
        <div cdkDropList #hihglighList="cdkDropList" [cdkDropListData]="highlights" cdkDropListOrientation="horizontal"
            [cdkDropListConnectedTo]="[selectedHihglighList]" class="highlight-block"
            (cdkDropListDropped)="onDrop($event)">
            <div class="highlight-box" *ngFor="let h of highlights; let i = index" cdkDrag>
                <div class="row">
                    <div class="col-sm-8">
                        <img src="../../../../../assets/images/highlights/{{h.pictureName}}">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-2">
        <label for="basicpill-phoneno-input1" class="form-label">{{ 'ASSETSPAGE.LIST.SELECTEDHIGHLIGHTS' | translate}}:</label>
        <div cdkDropList #selectedHihglighList="cdkDropList" [cdkDropListData]="checkedHighlights" cdkDropListOrientation="horizontal"
            [cdkDropListConnectedTo]="[hihglighList]" class="highlight-block" (cdkDropListDropped)="onDrop($event)">
            <div class="highlight-box" *ngFor="let h of checkedHighlights; let i = index" cdkDrag [ngClass]="{'highlight-box-invalid':checkValidity(h.id)}">
                <div class="row " >
                    <div class="col">
                        <img  src="../../../../../assets/images/highlights/{{h.pictureName}}">
                        <button (click)="openModal(h)" type="button" class="btn btn-soft-light btn-sm waves-effect waves-light highlight-button"><i class="bx bx-highlight font-size-16 align-middle"></i></button>
                    </div>

                </div>
                <div class="row p-2">
                    <div style="
                                    text-overflow: ellipsis;
                                    max-width: 150px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-align: center;
                                  " title="{{h.highlightDescription}}">
                        {{h.highlightDescription}}
                    </div>
                </div>
            </div>

        </div>
    </div>
    
</div>