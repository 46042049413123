<!-- start page title -->
<app-pagetitle title="Tabs & Accordions" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Default Tabs</h4>
                <p class="card-title-desc">Example of defalut tabs.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <!-- Nav tabs -->
                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                            <span class="d-none d-sm-block">Home</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                            <span class="d-none d-sm-block">Profile</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                            <span class="d-none d-sm-block">Messages</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                            <span class="d-none d-sm-block">Settings</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="text-muted"></div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Justify Tabs</h4>
                <p class="card-title-desc"> Use <code>.nav-pills</code> class for pills and <code>.nav-justified </code>
                    class for justify tab. </p>
            </div><!-- end card header -->

            <div class="card-body">
                <!-- Nav tabs -->
                <ul ngbNav #justifiednav="ngbNav" [activeId]="1" class="nav-pills nav-justified">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                            <span class="d-none d-sm-block">Home</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                            <span class="d-none d-sm-block">Profile</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                            <span class="d-none d-sm-block">Messages</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                            <span class="d-none d-sm-block">Settings</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="justifiednav" class="text-muted"></div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Vertical Nav Tabs</h4>
                <p class="card-title-desc">Example of Vertical nav tabs.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="row">
                    <div class="col-md-3">
                        <ul ngbNav #verticalNav="ngbNav" [activeId]="1" class="nav-pills flex-column">
                            <li [ngbNavItem]="1">
                                <a ngbNavLink class="mb-2">
                                    <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                    <span class="d-none d-sm-block">Home</span>
                                </a>
                                <ng-template ngbNavContent>
                                    <p>
                                        Raw denim you probably haven't heard of them jean shorts Austin.
                                        Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache
                                        cliche tempor, williamsburg carles vegan helvetica. Reprehenderit
                                        butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi,
                                        qui irure terry richardson ex squid. Aliquip placeat salvia cillum
                                        iphone. Seitan aliquip quis cardigan.
                                    </p>
                                    <p>Reprehenderit butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh
                                        mi,
                                        qui irure terry richardson ex squid.</p>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                                <a ngbNavLink class="mb-2">
                                    <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                                    <span class="d-none d-sm-block">Profile</span>
                                </a>
                                <ng-template ngbNavContent>
                                    <p>
                                        Food truck fixie locavore, accusamus mcsweeney's marfa nulla
                                        single-origin coffee squid. Exercitation +1 labore velit, blog
                                        sartorial PBR leggings next level wes anderson artisan four loko
                                        farm-to-table craft beer twee. Qui photo booth letterpress,
                                        commodo enim craft beer mlkshk.
                                    </p>
                                    <p class="mb-0"> Qui photo booth letterpress, commodo enim craft beer mlkshk aliquip
                                        jean shorts
                                        ullamco ad vinyl cillum PBR. Homo nostrud organic, assumenda labore aesthetic
                                        magna 8-bit</p>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="3">
                                <a ngbNavLink class="mb-2">
                                    <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                                    <span class="d-none d-sm-block">Messages</span>
                                </a>
                                <ng-template ngbNavContent>
                                    <p>
                                        Etsy mixtape wayfarers, ethical wes anderson tofu before they
                                        sold out mcsweeney's organic lomo retro fanny pack lo-fi
                                        farm-to-table readymade. Messenger bag gentrify pitchfork
                                        tattooed craft beer, iphone skateboard locavore carles etsy
                                        salvia banksy hoodie helvetica. DIY synth PBR banksy irony.
                                        Leggings gentrify squid 8-bit cred.
                                    </p>
                                    <p class="mb-0">DIY synth PBR banksy irony.
                                        Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                                        mi whatever gluten-free.</p>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="4">
                                <a ngbNavLink class="mb-2">
                                    <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                                    <span class="d-none d-sm-block">Settings</span>
                                </a>
                                <ng-template ngbNavContent>
                                    <p>
                                        Trust fund seitan letterpress, keytar raw denim keffiyeh etsy
                                        art party before they sold out master cleanse gluten-free squid
                                        scenester freegan cosby sweater. Fanny pack portland seitan DIY,
                                        art party locavore wolf cliche high life echo park Austin. Cred
                                        vinyl keffiyeh DIY salvia PBR, banh mi before they sold out
                                        farm-to-table.
                                    </p>
                                    <p class="mb-0">Fanny pack portland seitan DIY,
                                        art party locavore wolf cliche high life echo park Austin. Cred
                                        vinyl keffiyeh DIY salvia PBR, banh mi before they sold out
                                        farm-to-table.
                                    </p>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-9">
                        <div [ngbNavOutlet]="verticalNav" class="text-muted"></div>
                    </div>
                </div><!-- end row -->
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Custom Tabs</h4>
                <p class="card-title-desc">Example of custom tabs.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <!-- Nav tabs -->
                <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom nav-justified">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                            <span class="d-none d-sm-block">Home</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                            <span class="d-none d-sm-block">Profile</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                            <span class="d-none d-sm-block">Messages</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                        <a ngbNavLink>
                            <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                            <span class="d-none d-sm-block">Settings</span>
                        </a>
                        <ng-template ngbNavContent>
                            <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="customNav" class="text-muted"></div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Card Header Tabs</h4>
                <div class="flex-shrink-0">
                    <ul ngbNav #cardHeader="ngbNav" [activeId]="1"
                        class="nav justify-content-end nav-tabs-custom rounded card-header-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                <span class="d-none d-sm-block">Home</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                                <span class="d-none d-sm-block">Profile</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                                <span class="d-none d-sm-block">Messages</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                            </ng-template>
                        </li>
                    </ul>
                </div>
            </div><!-- end card header -->
            <div class="card-body">
                <div [ngbNavOutlet]="cardHeader" class="text-muted"></div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Card Header Pills</h4>
                <div class="flex-shrink-0">
                    <ul ngbNav #cardHeaderPills="ngbNav" [activeId]="1"
                        class="nav justify-content-end nav-pills card-header-pills">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                <span class="d-none d-sm-block">Home</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                                <span class="d-none d-sm-block">Profile</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="TabContent1"></ng-template>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                                <span class="d-none d-sm-block">Messages</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                            </ng-template>
                        </li>
                    </ul>
                </div>
            </div><!-- end card header -->

            <div class="card-body">
                <div [ngbNavOutlet]="cardHeaderPills" class="text-muted"></div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Card Header Form Select</h4>
                <div class="flex-shrink-0">
                    <select class="form-select form-select-sm mb-0 my-n1">
                        <option value="MAY" selected="">May</option>
                        <option value="AP">April</option>
                        <option value="MA">March</option>
                        <option value="FE">February</option>
                        <option value="JA">January</option>
                        <option value="DE">December</option>
                    </select>
                </div>
            </div><!-- end card header -->

            <div class="card-body text-muted">
                <p class="mb-0">
                    Etsy mixtape wayfarers, ethical wes anderson tofu before they
                    sold out mcsweeney's organic lomo retro fanny pack lo-fi
                    farm-to-table readymade. Messenger bag gentrify pitchfork
                    tattooed craft beer, iphone skateboard locavore carles etsy
                    salvia banksy hoodie helvetica. DIY synth PBR banksy irony.
                    Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                    mi whatever gluten-free carles.
                </p>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Card Header Button</h4>
                <div class="flex-shrink-0">
                    <div class="d-flex flex-wrap gap-2 mb-0 my-n1">
                        <div class="btn-group" ngbDropdown>
                            <button type="button" class="btn btn-primary btn-sm dropdown-toggle"
                                data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>Dropdown <i
                                    class="mdi mdi-chevron-down"></i></button>
                            <div class="dropdown-menu" ngbDropdownMenu>
                                <a class="dropdown-item" href="javascript:void(0);">Action</a>
                                <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                                <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="javascript:void(0);">Separated link</a>
                            </div>
                        </div><!-- /btn-group -->
                        <button type="button" class="btn btn-soft-secondary btn-sm">
                            Button
                        </button>
                    </div>
                </div>
            </div><!-- end card header -->

            <div class="card-body text-muted">
                <p class="mb-0">
                    Raw denim you probably haven't heard of them jean shorts Austin.
                    Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache
                    cliche tempor, williamsburg carles vegan helvetica. Reprehenderit
                    butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi,
                    qui irure terry richardson ex squid. Aliquip placeat salvia cillum
                    iphone. Seitan aliquip quis cardigan american apparel, butcher
                    voluptate nisi qui.
                </p>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<!-- Collapse -->
<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Collapse Example</h4>
                <p class="card-title-desc">
                    Collapse is set using <code>ngbCollapse</code> selector, here is an example.
                </p>
            </div><!-- end card header -->

            <div class="card-body">
                <p>
                    <button type="button" class="btn btn-primary" (click)="collapse.toggle()"
                        [attr.aria-expanded]="!Collapsed" aria-controls="collapseExample">
                        Link with href
                    </button>
                    <button type="button" class="btn btn-primary ms-2" (click)="collapse.toggle()"
                        [attr.aria-expanded]="!Collapsed" aria-controls="collapseExample">
                        Button with data-target
                    </button>
                </p>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="Collapsed">
                    <div class="card">
                        <div class="card-body text-muted">
                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                            squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente
                            ea proident.Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                            richardson ad squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred
                            nesciunt sapiente ea proident.Anim pariatur cliche reprehenderit, enim eiusmod high life
                            accusamus terry richardson ad squid.
                        </div>
                    </div>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Multiple Targets Collapse</h4>
                <p class="card-title-desc">
                    Example of multiple target collapse.
                </p>
            </div><!-- end card header -->

            <div class="card-body">
                <p>
                    <button type="button" class="btn btn-primary" (click)="firstcollapse.toggle()"
                        [attr.aria-expanded]="!firstCollapse" aria-controls="collapseExample1">
                        Toggle first element
                    </button>
                    <button type="button" class="btn btn-primary ms-2" (click)="secondcollapse.toggle()"
                        [attr.aria-expanded]="!secondCollapse" aria-controls="collapseExample1">
                        Toggle second element
                    </button>
                    <button type="button" class="btn btn-primary ms-2" (click)="bothcollapse.toggle()"
                        [attr.aria-expanded]="!secondCollapse" aria-controls="collapseExample1">
                        Toggle both elements
                    </button>
                </p>

                <div class="row" #bothcollapse="ngbCollapse" [(ngbCollapse)]="bothColleaps">
                    <div class="col">
                        <div class="collapse multi-collapse show" #firstcollapse="ngbCollapse"
                            [(ngbCollapse)]="firstCollapse">
                            <div class="card border shadow-none card-body text-muted mb-0">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                                sapiente ea proident.
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="collapse multi-collapse show" #secondcollapse="ngbCollapse"
                            [(ngbCollapse)]="secondCollapse">
                            <div class="card border shadow-none card-body text-muted mb-0">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt
                                sapiente ea proident.
                            </div>
                        </div>
                    </div>
                </div>

            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-6 custom_accordion">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Accordion Example</h4>
                <p class="card-title-desc">Click the accordions below to expand/collapse the accordion content.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <ngb-accordion #acc="ngbAccordion" activeIds="static-1" [closeOthers]="true">
                    <ngb-panel id="static-1">
                        <ng-template ngbPanelTitle class="text-black">
                            Accordion Item #1
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="text-muted">
                                <strong class="text-dark">This is the first item's accordion body.</strong> Anim
                                pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck
                                quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it
                                squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                                craft beer labore wes anderson cred nesciunt sapiente ea proident.
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-2">
                        <ng-template ngbPanelTitle>
                            Accordion Item #2
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="text-muted">
                                <strong class="text-dark">This is the second item's accordion body.</strong> Anim
                                pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck
                                quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it
                                squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                                craft beer labore wes anderson cred nesciunt sapiente ea proident.
                            </div>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="static-3">
                        <ng-template ngbPanelTitle>
                            Accordion Item #3
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="text-muted">
                                <strong class="text-dark">This is the third item's accordion body.</strong> Anim
                                pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad
                                squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck
                                quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it
                                squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                                craft beer labore wes anderson cred nesciunt sapiente ea proident.
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion><!-- end accordion -->
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!--  end row -->

<ng-template #TabContent>
    <p class="p-3 mb-0">
        Raw denim you probably haven't heard of them jean shorts Austin.
        Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache
        cliche tempor, williamsburg carles vegan helvetica. Reprehenderit
        butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi,
        qui irure terry richardson ex squid. Aliquip placeat salvia cillum
        iphone. Seitan aliquip quis cardigan american apparel, butcher
        voluptate nisi qui.
    </p>
</ng-template>

<!-- Tab Content -->
<ng-template #TabContent1>
    <p class="p-3 mb-0">
        Food truck fixie locavore, accusamus mcsweeney's marfa nulla
        single-origin coffee squid. Exercitation +1 labore velit, blog
        sartorial PBR leggings next level wes anderson artisan four loko
        farm-to-table craft beer twee. Qui photo booth letterpress,
        commodo enim craft beer mlkshk aliquip jean shorts ullamco ad
        vinyl cillum PBR. Homo nostrud organic, assumenda labore
        aesthetic magna delectus.
    </p>
</ng-template>