import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { SocialEconomics } from 'src/app/core/models/social-economics.models';
import { LocationSocialEconomicsPinService } from 'src/app/core/services/location-social-economics-pin.service';
import { ShowPinOnMapService } from 'src/app/core/services/showPinOnMap.service ';
import { SocialEconomicsService } from 'src/app/core/services/social-economics.service';
import Swal from 'sweetalert2';
import { SocialEconomicsTableDirective, SortEvent } from './social-economics-table.directive';

@Component({
  selector: 'app-social-economics-table',
  templateUrl: './social-economics-table.component.html',
  styleUrls: ['./social-economics-table.component.scss']
})
export class SocialEconomicsTableComponent implements OnInit {

  @ViewChildren(SocialEconomicsTableDirective)
  headers!: QueryList<SocialEconomicsTableDirective>;

  hideme: boolean[] = [];
  tables$: Observable<SocialEconomics[]>;
  total$: Observable<number>;

  @Input() socialEconomicsArray!: FormArray;

  public socialEconomics!: FormGroup;
  public separator: string = ',';

  constructor(public socialEconomicsService: SocialEconomicsService, private locationSocialEconomicsPinService: LocationSocialEconomicsPinService,
    private showPinOnMapService: ShowPinOnMapService, public modalService: NgbModal, private fb: FormBuilder) {
    this.tables$ = socialEconomicsService.tables$;
    this.total$ = socialEconomicsService.total$;

    this.socialEconomics = this.fb.group({
      region: ['', Validators.required],
      number_of_inhabitants: [0, Validators.required],
      expected_population_growth: [0, Validators.required],
      commuters: [0, Validators.required],
      centrality: [0, Validators.required],
      purchasing_power: [0, Validators.required],
      administrative_level: [0, Validators.required]
    })
  }

  ngOnInit(): void {
    this.refreshTable();
    this.showPinOnMapService.refreshPins.subscribe(
      (res) => {
        this.refreshTable();
      }
    )
  }

  get socialEconomicsControls() {
    return this.socialEconomicsArray.controls;
  }


  onSort({ column, direction }: any) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.socialeconomicsSortable !== column) {
        header.direction = '';
      }
    });
    this.socialEconomicsService.sortColumn = column;
    this.socialEconomicsService.sortDirection = direction;
  }


  enterInfoIfValid(control: any) {
    if (control.valid) {
      let locationPin = this.locationSocialEconomicsPinService.getLocationByRegionAndLevel(control.get('region')?.value, control.get('administrative_level')?.value);
      locationPin.locationSocialEconomics!.expectedPopulationGrowth = +control.get('expected_population_growth')?.value;
      locationPin.locationSocialEconomics!.netNumberOfCommuters = +control.get('commuters')?.value;
      locationPin.locationSocialEconomics!.numberOfInhabitants = +control.get('number_of_inhabitants')?.value;
      locationPin.locationSocialEconomics!.retailCentrality = +control.get('centrality')?.value;
      locationPin.locationSocialEconomics!.retailPurchasingPower = +control.get('purchasing_power')?.value;
    }
  }


  deletePins(region: string, level: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert changes!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      let locationPin = this.locationSocialEconomicsPinService.getLocationByRegionAndLevel(region, level);
      locationPin.markers?.forEach(
        (marker) => {
          this.showPinOnMapService.removePinFromMap(marker);
        });
      this.refreshTable();
    });
  }

  public showPin(region: string, level: number) {
    this.showPinOnMapService.showPinOnMap(region, level);
  }

  openModal(content: any, region: string, level: number) {
    let control: AbstractControl | undefined = undefined;
    for (let i = 0; i < this.socialEconomicsArray.controls.length; i++) {
      if (this.socialEconomicsArray.controls[i].get('region')?.value === region && this.socialEconomicsArray.controls[i].get('administrative_level')?.value === level) {
        control = this.socialEconomicsArray.controls[i];
      }
    }
    if (control != undefined) {
      this.socialEconomics.get('region')?.setValue(control.get('region')?.value);
      this.socialEconomics.get('number_of_inhabitants')?.setValue(control.get('number_of_inhabitants')?.value);
      this.socialEconomics.get('expected_population_growth')?.setValue(control.get('expected_population_growth')?.value);
      this.socialEconomics.get('commuters')?.setValue(control.get('commuters')?.value);
      this.socialEconomics.get('centrality')?.setValue(control.get('centrality')?.value);
      this.socialEconomics.get('purchasing_power')?.setValue(control.get('purchasing_power')?.value);
      this.socialEconomics.get('administrative_level')?.setValue(control.get('administrative_level')?.value);
      let ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        size: 'lg',
        centered: true
      };
      this.modalService.open(content, ngbModalOptions)
        .result.then(
          (res: string) => {
            if (res === 'Save click') {
              this.tables$.subscribe(
                (res: SocialEconomics[]) => {
                  for (let se of res) {
                    if (se.region === this.socialEconomics.get('region')?.value && se.administrative_level === this.socialEconomics.get('administrative_level')?.value) {
                      se.region = this.socialEconomics.get('region')?.value;
                      se.number_of_inhabitants = this.socialEconomics.get('number_of_inhabitants')?.value;
                      se.expected_population_growth = this.socialEconomics.get('expected_population_growth')?.value;
                      se.commuters = this.socialEconomics.get('commuters')?.value;
                      se.centrality = this.socialEconomics.get('centrality')?.value;
                      se.purchasing_power = this.socialEconomics.get('purchasing_power')?.value;
                    }
                  }

                  for (let i = 0; i < this.socialEconomicsArray.controls.length; i++) {
                    if (this.socialEconomicsArray.controls[i].get('region')?.value === region && this.socialEconomicsArray.controls[i].get('administrative_level')?.value === level) {
                      this.socialEconomicsArray.controls[i].get('region')?.setValue(this.socialEconomics.get('region')?.value);
                      this.socialEconomicsArray.controls[i].get('number_of_inhabitants')?.setValue(this.socialEconomics.get('number_of_inhabitants')?.value);
                      this.socialEconomicsArray.controls[i].get('expected_population_growth')?.setValue(this.socialEconomics.get('expected_population_growth')?.value);
                      this.socialEconomicsArray.controls[i].get('commuters')?.setValue(this.socialEconomics.get('commuters')?.value);
                      this.socialEconomicsArray.controls[i].get('centrality')?.setValue(this.socialEconomics.get('centrality')?.value);
                      this.socialEconomicsArray.controls[i].get('purchasing_power')?.setValue(this.socialEconomics.get('purchasing_power')?.value);
                    }
                  }
                }
              );
            }
          }
        );
    }

  }

  refreshTable() {
    this.socialEconomicsService.tableData = this.socialEconomicsArray.controls.map(control => ({
      region: control.get('region')?.value,
      number_of_inhabitants: control.get('number_of_inhabitants')?.value || 0,
      expected_population_growth: control.get('expected_population_growth')?.value || 0,
      commuters: control.get('commuters')?.value || 0,
      centrality: control.get('centrality')?.value || 0,
      purchasing_power: control.get('purchasing_power')?.value || 0,
      administrative_level: control.get('administrative_level')?.value || 0
    }))
    
    this.socialEconomicsService.setPaging();
  }
}
