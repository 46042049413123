<div class="modal-header">
    <h5 class="modal-title mt-0">{{ 'ASSETSPAGE.LIST.HIGHLIGHTSDESC' | translate }}</h5>
    <button type="button" class="btn-close" aria-hidden="true"
        (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <textarea class="form-control"
    maxlength="30"
    [(ngModel)]="description" [ngClass]="{'is-invalid': formsubmit && (description=='' || description == undefined)}"></textarea>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="passBack()">{{ 'ASSETSPAGE.LIST.SAVEHIGHLIGHTS' | translate }}</button>
</div>
