import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetModule } from './widget/widget.module';
import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { RouterModule } from '@angular/router';
import { HereMapComponent } from './here-map/here-map.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    HereMapComponent,
    PagetitleComponent,
    GoogleMapComponent
  ],
  imports: [
    CommonModule,
    WidgetModule,
    RouterModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
    TranslateModule,
    NgbPopoverModule
  ],
  exports: [PagetitleComponent, HereMapComponent,GoogleMapComponent]
})
export class SharedModule { }
