import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/classes/user';
import { Status } from 'src/app/core/enums/status_enum';
import { Address } from 'src/app/core/models/assetsModels/addres.models';
import { DocumentsService } from 'src/app/core/services/documets.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-requesting-user',
  templateUrl: './requesting-user.component.html',
  styleUrls: ['./requesting-user.component.scss'],
})
export class RequestingUserComponent implements OnInit {
  user!: User;
  userForm!: FormGroup;
  emailForm!: FormGroup;
  showTextArea: boolean = false;
  Status: typeof Status = Status;
  dialog: any;
  breadCrumbItems!: Array<{}>;
  status!: string;
  fileName = '';
  src = '';
  docExcetnsions:Array<string> = ["doc","dot", "docx", "dotx", "docm", "dotm"];
  xslExcetnsions:Array<string> = ["xls","xlt", "xla", "xlsx", "xltx", "xlsm", "xltm", "xlam", "xlsb"];
  pttExcetnsions:Array<string> = ["ppt","pot", "pps", "ppa", "pptx", "potx", "ppsx", "ppam", "pptm", "potm", "ppsm"];
  imgExcetnsions:Array<string> = ["png","jpg", "jpeg"];
  pdfExcetnsions:Array<string> = ["pdf"];
  fileType!:string;

  constructor(
    private route: ActivatedRoute,
    private userService: UserProfileService,
    private modalService: NgbModal,
    private documentsService: DocumentsService,
    private sanitizer: DomSanitizer,
    public translate: TranslateService,
    public fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    let link = '';
    let label = '';
    this.route.url.subscribe((url) => {
      link = url[0].path;
    });
    if (link == 'requests') {
      label = 'Requesting customer';
    } else {
      label = 'Accepted customer';
    }
    this.breadCrumbItems = [
      { label: 'Apps' },
      { label: 'Customers' },
      { label: label, active: true },
    ];
    this.user = new User();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.user.id = +params.get('id')!;
    });
    this.userService.getUserById(this.user.id).subscribe((userFromApi) => {
      this.user = userFromApi;
      this.status = this.ajustStatus(this.user.registrationStatus.status);

      this.initForm();
    });
  }

  updateUserStatus(status: string) {
    this.status = this.ajustStatus(status);
    let oldStatus = this.user.registrationStatus.status
    this.user.registrationStatus.status = status;
    this.userService
      .updateUserStatus(this.user, this.emailForm.value['emailBody']).subscribe(
        data => { },
        error => {
          this.status = this.ajustStatus(oldStatus);
          this.user.registrationStatus.status = oldStatus;
          Swal.fire({
            title: 'Incorrect...',
            text: `${error} Please contact your onboarding manager.`,
            icon: 'error',
            confirmButtonColor: '#c33434',
            cancelButtonColor: '#f46a6a',
            confirmButtonText: 'OK',
          });
        }
      );
    this.modalService.dismissAll();
  }

  openModal(content: any) {
    this.modalService.open(content);
  }

  sendMailForAditionalDocs() {
    this.status = this.ajustStatus('STATUS_WAITING_FOR_ADDITIONAL_DOCUMENTS');
    this.user.registrationStatus.status =
      'STATUS_WAITING_FOR_ADDITIONAL_DOCUMENTS';
    let oldStatus = this.user.registrationStatus.status;
    Swal.fire({
      title: 'Sending mail for additional documents...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      text: '',
      didOpen: () => {
        Swal.showLoading();
      },
    });
    this.userService
      .updateUserStatus(this.user, this.emailForm.value['emailBody']).subscribe(
        data => {
          Swal.fire({
            title: 'Mail sent successfully',
            html: `Customer will recive the email for providing <b>additional documents</b>.`,
            icon: 'success',
            confirmButtonColor: '#34c38f',
            cancelButtonColor: '#f46a6a',
            confirmButtonText: 'OK',
          });
          this.showTextArea = false;
          this.emailForm.get('emailBody')?.setValue('');
        },
        error => {
          Swal.fire({
            title: 'Incorrect...',
            text: `${error}`,
            icon: 'error',
            confirmButtonColor: '#c33434',
            cancelButtonColor: '#f46a6a',
            confirmButtonText: 'OK',
          });
        }
      );
  }

  trimPath(path: string): string {
    var splitted = path.split(environment.pathDelimiter);
    return splitted[splitted.length - 1];
  }

  openPdf(id: number, path: string, content: any) {
    let name = this.trimPath(path);
    this.fileType = name.split(".")[name.split(".").length - 1];
    this.documentsService.getDocument(id).subscribe((file) => {
      let fileData = new File([file.body], name, { type: file.body.type });
      let fileURL = URL.createObjectURL(fileData);
      this.src = fileURL;
      this.fileName = fileData.name;

      this.modalService
        .open(content, {
          size: 'xl',
          ariaLabelledBy: 'modal-basic-title',
          centered: true,
        })
        .result.then(
          (result) => {
            // this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    });
  }

  downloadPdf(id: number, name: string) {
    this.documentsService.getDocument(id).subscribe((file) => {
      let fileData = new File([file.body], name, { type: file.body.type });
      let fileURL = URL.createObjectURL(fileData);
      let fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.download = name;
      fileLink.click();
    });
  }

  previewMail(content:any) {
    this.modalService.open(content);
  }

  ajustStatus(status: string): string {
    switch (status) {
      case 'STATUS_ACCEPTED':
        return 'Accepted';
      case 'STATUS_REGISTRATION_PENDING':
        return 'Pending';
      case 'STATUS_REOPENED':
        return 'Reopened';
      case 'STATUS_WAITING_FOR_DOCUMENTS':
        return 'Waiting for documents';
      case 'STATUS_WAITING_FOR_ADDITIONAL_DATA':
        return 'Waiting for additional data';
      case 'STATUS_WAITING_FOR_ADDITIONAL_DOCUMENTS':
        return 'Waiting for additional documents';
      case 'STATUS_REJECTED':
        return 'Rejected';
      default:
        return 'Unknown';
    }
  }

  public loadImageSource(path:string){
    var splitted = path.split(".");
    let extension = splitted[splitted.length - 1];
    if (this.docExcetnsions.includes(extension)){
      return "../../../../assets/images/doc.svg";
    } else if (this.xslExcetnsions.includes(extension)){
      return "../../../../assets/images/excell.svg";
    } else {
      return "../../../../assets/images/pdf.png";
    }
  }

  public loadIconClass(path:string){
    var splitted = path.split(".");
    let extension = splitted[splitted.length - 1];
    if (this.docExcetnsions.includes(extension)){
      return "far fa-file-word";
    } else if (this.xslExcetnsions.includes(extension)){
      return "far fa-file-excel";
    } else if (this.pttExcetnsions.includes(extension)){
      return "far fa-file-powerpoint";
    } else if (this.imgExcetnsions.includes(extension)){
      return "far fa-file-image";
    } else {
      return "far fa-file-pdf";
    }
  }

  private initForm() {
    
    this.userForm = this.fb.group({
      firstName: [{ value: this.user.name, disabled: true }, [Validators.required]],
      lastName: [{ value: this.user.lastName, disabled: true }, [Validators.required]],
      username: [{ value: this.user.username, disabled: true }],
      email: [{ value: this.user.email, disabled: true }, [Validators.required]],
      phoneNumber: [{
        value: this.user.phoneNumber,
        disabled: true,
      }],
      lei: [{ value: this.user.lei, disabled: true }, [Validators.required]],
      companyName:[{ value: this.user.companyName, disabled: true }, [Validators.required]],
      companyAddress:[{ value: this.prepareAddress(this.user.companyAddress), disabled: true }, [Validators.required]],
      keycloakId: [{ value: this.user.keycloakId, disabled: true }]
    });
    this.emailForm = new FormGroup({
      emailBody: new FormControl('', Validators.required),
    });
  }

  prepareAddress(companyAddress: Address) {
    if (companyAddress != undefined) {
      return `${companyAddress.street} ${companyAddress.streetNumber}, ${companyAddress.postalCode} ${companyAddress.city}, ${companyAddress.country}`;
    } else {
      return ""
    }
  }

  public validForm(): boolean{
    let valid = true;
    valid = (this.userForm.controls['firstName'].value != null && this.userForm.controls['firstName'].value != "")
      && (this.userForm.controls['lastName'].value != null && this.userForm.controls['lastName'].value != "")
      && (this.userForm.controls['email'].value != null && this.userForm.controls['email'].value != "")
      && (this.userForm.controls['lei'].value != null && this.userForm.controls['lei'].value != "")
      && (this.userForm.controls['companyName'].value != null && this.userForm.controls['companyName'].value != "")
      && (this.userForm.controls['companyAddress'].value != null && this.userForm.controls['companyAddress'].value != "")
    
    return valid;
  }


  _handleError(error: any) {
    // tslint:disable-next-line: prefer-const
    var errorMessage = error.message;
    return errorMessage;
  }
  
  transform(url:any):SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  
  translateStatus(status: string){
    switch (status) {
      case 'Accepted':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.ACCEPTED';
      case 'Pending':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.PENDING';
      case 'Reopened':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.REOPENED';
      case 'Waiting for documents':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.WAITINGFORDOCUMENTS';
      case 'Waiting for additional data':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.REQUESTADDITIONALDATA';
      case 'Waiting for additional documents':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.WAITINGFORADDITIONALDOCUMENTS';
      case 'Rejected':
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.REJECTED';
      default:
        return 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.LIST.UNKNOWN';
    }
  }
}
