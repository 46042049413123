<!-- start page title -->
<app-pagetitle title="Chartjs" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
  <div class="col-xl-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">Line Chart</h4>
      </div>
      <div class="card-body">
        <canvas baseChart height="300" [datasets]="lineAreaChart.datasets" [labels]="lineAreaChart.labels"
          [options]="lineAreaChart.options" chartType="line">
        </canvas>
      </div>
    </div>
  </div> <!-- end col -->

  <div class="col-xl-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">Bar Chart</h4>
      </div>
      <div class="card-body">
        <canvas baseChart [datasets]="lineBarChart.datasets" chartType="bar" [options]="lineBarChart.options"
          height="300" [labels]="lineBarChart.labels">
        </canvas>
      </div>
    </div>
  </div> <!-- end col -->
</div> <!-- end row -->

<div class="row">
  <div class="col-xl-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">Pie Chart</h4>
      </div>
      <div class="card-body">
        <canvas baseChart [datasets]="pieChart.datasets" chartType="pie" [options]="pieChart.options" height="260">
        </canvas>
      </div>
    </div>
  </div> <!-- end col -->

  <div class="col-xl-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">Donut Chart</h4>
      </div>
      <div class="card-body">
        <canvas baseChart [datasets]="donutChart.datasets" chartType="doughnut" [options]="donutChart.options"
          height="260"></canvas>
      </div>
    </div>
  </div> <!-- end col -->
</div> <!-- end row -->

<div class="row">
  <div class="col-xl-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">Polar Chart</h4>
      </div>
      <div class="card-body">
        <canvas baseChart [datasets]="polarChart.datasets" [labels]="polarChart.labels" [options]="polarChart.options"
          chartType="polarArea" height="130"></canvas>
      </div>
    </div>
  </div> <!-- end col -->

  <div class="col-xl-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title mb-0">Radar Chart</h4>
      </div>
      <div class="card-body">
        <canvas baseChart [datasets]="radarChart.datasets" chartType="radar" [options]="radarChart.options" height="300"
          [labels]="radarChart.labels">
        </canvas>
      </div>
    </div>
  </div> <!-- end col -->
</div> <!-- end row -->