<!-- start page title -->
<app-pagetitle title="Chat" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="d-lg-flex">
    <div class="chat-leftsidebar card">
        <div class="p-3 px-4 border-bottom">
            <div class="d-flex align-items-start ">
                <div class="flex-shrink-0 me-3 align-self-center">
                    <img src="assets/images/users/avatar-1.jpg" class="avatar-sm rounded-circle" alt="">
                </div>

                <div class="flex-grow-1">
                    <h5 class="font-size-16 mb-1"><a href="javascript:void(0)" class="text-dark">Shawn <i
                                class="mdi mdi-circle text-success align-middle font-size-10 ms-1"></i></a></h5>
                    <p class="text-muted mb-0">Available</p>
                </div>

                <div class="flex-shrink-0">
                    <div class="dropdown chat-noti-dropdown" ngbDropdown>
                        <button class="btn dropdown-toggle p-0" type="button" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                            <i class="bx bx-dots-horizontal-rounded"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Profile</a>
                            <a class="dropdown-item" href="javascript:void(0);">Edit</a>
                            <a class="dropdown-item" href="javascript:void(0);">Add Contact</a>
                            <a class="dropdown-item" href="javascript:void(0);">Setting</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-3">
            <div class="search-box position-relative">
                <input type="text" class="form-control rounded border" placeholder="Search...">
                <i class="bx bx-search search-icon"></i>
            </div>
        </div>

        <div class="chat-leftsidebar-nav">
            <ul ngbNav #transactions="ngbNav" [activeId]="1" class="nav nav-pills nav-justified bg-soft-light p-1">
                <li [ngbNavItem]="1" class="nav-item">
                    <a ngbNavLink href="javascript:void(0)" data-bs-toggle="tab" aria-expanded="true" class="nav-link">
                        <i class="bx bx-chat font-size-20 d-sm-none"></i>
                        <span class="d-none d-sm-block">Chat</span>
                    </a>
                    <ng-template ngbNavContent>
                        <ngx-simplebar class="chat-message-list">
                            <div class="px-3">
                                <h5 class="font-size-14 mb-3">Recent</h5>
                            </div>
                            <ul class="list-unstyled chat-list">
                                <li class="" *ngFor="let data of chatData" [ngClass]="{'active': data.name == username}">
                                    <a href="javascript: void(0);" (click)="chatUsername(data.name, data.image)">
                                        <div class="d-flex align-items-start">

                                            <div class="flex-shrink-0 user-img online align-self-center me-3">
                                                <div class="user-img online align-self-center" *ngIf="data.image">
                                                    <img src="{{ data.image }}" class="rounded-circle avatar-sm" alt="">
                                                    <span class="user-status"></span>
                                                </div>
                                                <div class="avatar-sm align-self-center" *ngIf="!data.image">
                                                    <span
                                                        class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                        {{ data.name.charAt(0) }}
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="flex-grow-1 overflow-hidden">
                                                <h5 class="text-truncate font-size-14 mb-1">{{data.name}}</h5>
                                                <p class="text-truncate mb-0">{{data.message}}</p>
                                            </div>
                                            <div class="flex-shrink-0">
                                                <div class="font-size-11">{{ data.time }}</div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </ngx-simplebar>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2" class="nav-item">
                    <a ngbNavLink>Groups</a>
                    <ng-template ngbNavContent>
                        <ngx-simplebar class="chat-message-list">
                        
                                <div class="px-3">
                                    <h5 class="font-size-14 mb-3">Groups</h5>
                                </div>
                                <ul class="list-unstyled chat-list">
                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 avatar-sm me-3">
                                                    <span
                                                        class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                        G
                                                    </span>
                                                </div>

                                                <div class="flex-grow-1">
                                                    <h5 class="font-size-14 mb-0">General</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 avatar-sm me-3">
                                                    <span
                                                        class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                        R
                                                    </span>
                                                </div>

                                                <div class="flex-grow-1">
                                                    <h5 class="font-size-14 mb-0">Reporting</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 avatar-sm me-3">
                                                    <span
                                                        class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                        M
                                                    </span>
                                                </div>

                                                <div class="flex-grow-1">
                                                    <h5 class="font-size-14 mb-0">Meeting</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 avatar-sm me-3">
                                                    <span
                                                        class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                        A
                                                    </span>
                                                </div>

                                                <div class="flex-grow-1">
                                                    <h5 class="font-size-14 mb-0">Project A</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </li>

                                    <li>
                                        <a href="javascript:void(0)">
                                            <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 avatar-sm me-3">
                                                    <span
                                                        class="avatar-title rounded-circle bg-soft-primary text-primary">
                                                        B
                                                    </span>
                                                </div>

                                                <div class="flex-grow-1">
                                                    <h5 class="font-size-14 mb-0">Project B</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                         
                        </ngx-simplebar>
                    </ng-template>
                </li>
                <li [ngbNavItem]="3" class="nav-item">
                    <a ngbNavLink>Contacts</a>
                    <ng-template ngbNavContent>
                        <ngx-simplebar class="chat-message-list">
                            <div class="px-3">
                                <h5 class="font-size-14 mb-3">Contacts</h5>
                            </div>
                            <div>
                                <div>
                                    <div class="px-3 contact-list">A</div>

                                    <ul class="list-unstyled chat-list">
                                        <li>
                                            <a href="javascript:void(0)">
                                                <h5 class="font-size-14 mb-0">Adam Miller</h5>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)">
                                                <h5 class="font-size-14 mb-0">Alfonso Fisher</h5>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="mt-4">
                                    <div class="px-3 contact-list">B</div>

                                    <ul class="list-unstyled chat-list">
                                        <li>
                                            <a href="javascript:void(0)">
                                                <h5 class="font-size-14 mb-0">Bonnie Harney</h5>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="mt-4">
                                    <div class="px-3 contact-list">C</div>

                                    <ul class="list-unstyled chat-list">
                                        <li>
                                            <a href="javascript:void(0)">
                                                <h5 class="font-size-14 mb-0">Charles Brown</h5>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <h5 class="font-size-14 mb-0">Carmella Jones</h5>
                                            </a>
                                            <a href="javascript:void(0)">
                                                <h5 class="font-size-14 mb-0">Carrie Williams</h5>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="mt-4">
                                    <div class="px-3 contact-list">D</div>

                                    <ul class="list-unstyled chat-list">
                                        <li>
                                            <a href="javascript:void(0)">
                                                <h5 class="font-size-14 mb-0">Dolores Minter</h5>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ngx-simplebar>
                    </ng-template>
                </li>
            </ul>
            <div [ngbNavOutlet]="transactions" class="mt-2"></div>
        </div>

    </div>
    <!-- end chat-leftsidebar -->

    <div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
        <div class="card">
            <div class="p-3 px-lg-4 border-bottom">
                <div class="row">
                    <div class="col-xl-4 col-7">
                        <div class="d-flex align-items-center">
                            <div *ngIf="profile" class="flex-shrink-0 avatar-sm me-3 d-sm-block d-none">
                                <img :src="{{profile}}" alt="" class="img-fluid d-block rounded-circle">
                            </div>
                            <div *ngIf="!profile" class="avatar-sm align-self-center me-3"><span
                                    class="avatar-title rounded-circle bg-soft-primary text-primary">
                                    {{username.charAt(0)}} </span></div>
                            <div class="flex-grow-1">
                                <h5 class="font-size-14 mb-1 text-truncate"><a href="javascript:void(0)"
                                        class="text-dark">{{username}}</a></h5>
                                <p class="text-muted text-truncate mb-0">Online</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-5">
                        <ul class="list-inline user-chat-nav text-end mb-0">
                            <li class="list-inline-item">
                                <div class="dropdown" ngbDropdown>
                                    <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                        <i class="bx bx-search"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-end dropdown-menu-md p-2" ngbDropdownMenu>
                                        <form class="px-2">
                                            <div>
                                                <input type="text" class="form-control border bg-soft-light"
                                                    placeholder="Search...">
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </li>

                            <li class="list-inline-item">
                                <div class="dropdown" ngbDropdown>
                                    <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                        <i class="bx bx-dots-horizontal-rounded"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                        <a class="dropdown-item" href="javascript:void(0)">Profile</a>
                                        <a class="dropdown-item" href="javascript:void(0)">Archive</a>
                                        <a class="dropdown-item" href="javascript:void(0)">Muted</a>
                                        <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>           
            <ngx-simplebar class="chat-conversation p-3 px-2">
                <ul class="list-unstyled mb-0">
                    <li class="chat-day-title">
                        <span class="title">Today</span>
                    </li>
                    <li *ngFor="let data of chatMessagesData" [ngClass]="{'right': data.align === 'right' }">
                        <div class="conversation-list" [ngClass]="{'order-3': data.align === 'right' }">
                            <div class="ctext-wrap">
                                <div class="ctext-wrap-content">
                                    <h5 class="conversation-name"><a href="javascript:void(0)"
                                            class="user-name">{{data.name}}</a>
                                        <span class="time">{{data.time}}</span>
                                    </h5>
                                    <p class="mb-0">{{ data.message }}</p>
                                </div>
                                <div class="dropdown align-self-start" ngbDropdown>
                                    <a class="dropdown-toggle" href="javascript:void(0);" role="button"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        ngbDropdownToggle>
                                        <i class="mdi mdi-dots-vertical"></i>
                                    </a>
                                    <div class="dropdown-menu" ngbDropdownMenu>
                                        <a class="dropdown-item" href="javascript:void(0);">Copy</a>
                                        <a class="dropdown-item" href="javascript:void(0);">Save</a>
                                        <a class="dropdown-item" href="javascript:void(0);">Forward</a>
                                        <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </ngx-simplebar>
            <div class="p-3 border-top">
                <div class="row">
                    <form (ngSubmit)="messageSave()" [formGroup]="formData" class="row">
                        <div class="p-3 chat-input-section">
                            <div class="row">
                                <div class="col">
                                    <div class="position-relative">
                                        <input formControlName="message"
                                            [ngClass]="{'is-invalid': chatSubmit && form.message.errors}" type="text"
                                            class="form-control chat-input rounded" placeholder="Enter Message..." />
                                        <div *ngIf="chatSubmit && form.message.errors" class="invalid-feedback">
                                            <span *ngIf="form.message.errors.required">This value is required.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <button type="submit"
                                        class="btn btn-primary chat-send w-md waves-effect waves-light">
                                        <span class="d-none d-sm-inline-block me-2">Send</span>
                                        <i class="mdi mdi-send float-end"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- end user chat -->
</div>
<!-- End d-lg-flex  -->