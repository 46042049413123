<app-pagetitle title="{{ 'MENUITEMS.APPS.LIST.ASSETS.LIST.ONBOARDING' | translate }}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<div style="
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px dashed #e9e9ef;
    padding: 50px 0px 25px;
    margin: 0 35px;
  ">
    <h2>{{ 'ASSETSPAGE.LIST.WIZARD.LIST.MESSAGE' | translate }}</h2>
    <a routerLink="./onboardingwizard">{{ 'ASSETSPAGE.LIST.WIZARD.TEXT' | translate }}</a>
</div>
<!-- <div style="margin: 0 35px; padding: 25px 0px 25px">
    <h2>Open onbording processes</h2>
    <p style="margin-top: 35px;">No open onbording processes</p>
</div> -->
