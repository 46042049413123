import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeocoderService {


  private geocoder: google.maps.Geocoder = new google.maps.Geocoder();

  constructor() { }

  public async getPlaceIdByLatLng(lat: number, lng: number) {
    var latlng = new google.maps.LatLng(lat, lng);
    return this.geocoder.geocode({ location: latlng });
  }

  public async getPlaceIdByBound(path: {lat:number, lng:number}[]){
    var center  = this.calculateCenter(path);
    return this.geocoder.geocode({ location: center });
  }

  calculateCenter(path: {lat:number, lng:number}[]): google.maps.LatLng {
    var longitudes = path.map((i) => i.lng);
    var latitudes = path.map((i) => i.lat);

    latitudes.sort();
    longitudes.sort();

    var lowX = latitudes[0];
    var highX = latitudes[latitudes.length -1];
    var lowy = longitudes[0];
    var highy = longitudes[latitudes.length -1];

    var centerX = lowX + ((highX - lowX) / 2);
    var centerY = lowy + ((highy - lowy) / 2);

    return new google.maps.LatLng(centerX, centerY);
  }
}
