
export class MyUploadAdapter {
    public loader: any;
    public reader!: any;
  
    constructor(loader: any) {
        this.loader = loader;
    }
  
    upload() {
        return new Promise( ( resolve, reject ) => {
			const reader = this.reader = new window.FileReader();

			reader.addEventListener( 'load', () => {
				resolve( { default: reader.result } );
			} );

			reader.addEventListener( 'error', err => {
				reject( err );
			} );

			reader.addEventListener( 'abort', () => {
				reject();
			} );

			this.loader.file.then( (file: Blob) => {
				reader.readAsDataURL( file );
			} );
		} );
    }
  
    abort() {
		this.reader.abort();
	}
  
  }
  