<div *ngIf="isApiLoaded" id="map">
    <div class="display-flex" *ngIf="!readOnly">
        <div class="col-2">
            <input type="text" class="form-control" style="width: 260px;" (keydown.enter)="$event.preventDefault()" placeholder="Search Location"
            type="text" #search />
        </div>
       
    </div>
    <google-map #tectrexGoogleMap height="450px" width="100%" [center]="center" [zoom]="zoom" [options] ="options">
    </google-map>
    <div class="legend_wrapper">
        <div class="delete_apll">
            <button *ngIf="!readOnly" type="button" class="btn btn-light p-0 avatar-sm rounded-circle" title="Delete all pins" (click)="deleteAllPins()">
                <span class="avatar-title bg-transparent text-reset">
                    <i class="bx bx-trash"></i>
                </span>
            </button>
        </div>
        <div class="legend">
            <button type="button" class="btn btn-light p-0 avatar-sm rounded-circle" placement="left"
                popoverTitle="{{ 'GOOGLEMAPS.LIST.LEGEND' | translate }}"
                [ngbPopover]="popContent">
                <span class="avatar-title bg-transparent text-reset">
                    <i class="bx bx-info-circle"></i>
                </span>
                <ng-template #popContent>
                    <img src="../../../assets/images/google/pin.png"/>{{ 'GOOGLEMAPS.LIST.ADDPIN' | translate }}<br>
                    <img src="../../../assets/images/google/polygon.png"/>{{ 'GOOGLEMAPS.LIST.ADDPOLYGON' | translate }}<br>
                    <img src="../../../assets/images/google/hand.png"/>{{ 'GOOGLEMAPS.LIST.REMOVEPIN' | translate }}
                </ng-template>
            </button>
        </div>
    </div>
    
</div>