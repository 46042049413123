const FaqsData = [
    {
        no: `01.`,
        title: `What is Lorem Ipsum?`,
        content: `New common language will be more simple and regular than the existing European languages. It will be as simple as occidental.`,
        icon: `bx-help-circle`
    },
    {
        no: `02.`,
        title: `Where does it come from?`,
        content: `Everyone realizes why a new common language would be desirable one could refuse to pay expensive translators.`,
        icon: `bx-help-circle`
    },
    {
        no: `03.`,
        title: `Where can I get some?`,
        content: `If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual languages.`,
        icon: `bx-help-circle`
    },
    {
        no: `04.`,
        title: `Why do we use it?`,
        content: `Their separate existence is a myth. For science, music, sport, etc, Europe uses the same vocabulary.`,
        icon: `bx-help-circle`
    },
    {
        no: `05.`,
        title: `Where can I get some?`,
        content: `The point of using Lorem Ipsum is that it has a more-or-less normal they distribution of letters opposed to using content here.`,
        icon: `bx-help-circle`
    },
    {
        no: `06.`,
        title: `What is Lorem Ipsum?`,
        content: `To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental`,
        icon: `bx-help-circle`
    }
];

export { FaqsData };