import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Asset } from 'src/app/core/models/assetsModels/asset.models';
import { AssetService } from 'src/app/core/services/asset.service';
import Swal from 'sweetalert2';
import { DocumentsService } from 'src/app/core/services/documets.service';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-asset-tokenization',
  templateUrl: './asset-tokenization.component.html',
  styleUrls: ['./asset-tokenization.component.scss'],
})
export class AssetTokenizationComponent implements OnInit {
  breadCrumbItems!: Array<{}>;

  tokenizationForm!: FormGroup;
  assetForm!: FormGroup;
  formSubmit: boolean = false;
  isInitialized: boolean = false;
  asset!: Asset;
  imagePath?: string;
  checked: boolean = false;
  public separator: string = ',';
  images: File[] = [];
  constructor(private route: ActivatedRoute, private fb: FormBuilder, private assetService: AssetService, private router: Router, private documentService: DocumentsService, private modalService: NgbModal) { }

  galleryOptions!: NgxGalleryOptions[];
  galleryImages!: NgxGalleryImage[];

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Onboarded assets' },
      { label: 'Asset tokenization', active: true }
    ];
    
    this.galleryOptions = [
      {
        width: '100%',
        height: '800px',
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      },
    ];
    let id = 0;
    this.route.paramMap.subscribe((params: ParamMap) => {
      id = +params.get('id')!;
    });
    this.galleryImages = [];
    this.assetService.getAssetById(id).subscribe(
      (res) => {
        this.asset = res;
        this.tokenizationForm = this.fb.group({
          name: [this.asset?.title, Validators.required],
          symbol: ['', Validators.required],
          initialSupply: ['', Validators.required],
          owner: ['', [Validators.required, Validators.pattern('^(0x){1}[0-9a-fA-F]{40}$') ]]
        });
        this.assetForm = this.fb.group({
          id: this.asset?.id,
          title: this.asset?.title,
          address: this.asset?.address?.streetNumber + ' ' + this.asset?.address?.street + ', ' + this.asset?.address?.city + ' ' + this.asset?.address?.postalCode,
          description: this.asset?.description,
          grossValue: this.asset?.grossValue,
          lettableArea: this.asset?.lettableArea
        });
      }
    );
    this.documentService.getAssetImages(id).subscribe((response) => {
      for (var assetImage of response.body) {
        let img = assetImage as any;
        let fileData = this.readFileData(img);
        this.images.push(fileData);
        let imgObject = {
          small: URL.createObjectURL(fileData),
          medium: URL.createObjectURL(fileData),
          big: URL.createObjectURL(fileData),
        };
        this.galleryImages.push(imgObject);
      }
    });
    this.isInitialized = true;
  }


  onSubmit() {
    this.formSubmit = true;
    if (this.tokenizationForm.valid) {
      Swal.fire({
        title: 'Tokenization in progress',
        imageUrl: 'assets/images/loader.gif',
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.assetService.createToken(this.tokenizationForm.value.name, this.tokenizationForm.value.symbol, this.tokenizationForm.value.initialSupply, this.tokenizationForm.value.owner)
        .subscribe(
          (res) => {
            if (res.errors != undefined) {
              Swal.fire({
                title: 'Incorrect...',
                text: res.errors[0].message,
                icon: 'error',
                confirmButtonColor: '#c33434',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'OK',
              });
            } else {
              this.assetService.saveToken(+this.asset.id, this.tokenizationForm.value.symbol, res.data.mintERC20.transactionHash).subscribe(
                (res) => {
                  if (res.errors != undefined) {
                    Swal.fire({
                      title: 'Incorrect...',
                      text: res.errors[0].message,
                      icon: 'error',
                      confirmButtonColor: '#c33434',
                      cancelButtonColor: '#f46a6a',
                      confirmButtonText: 'OK',
                    });
                  } else {
                    Swal.fire({
                      title: 'Success',
                      text: 'The asset was successfully tokenized!',
                      icon: 'success',
                      confirmButtonColor: '#34c38f',
                      cancelButtonColor: '#f46a6a',
                      confirmButtonText: 'OK',
                    }).then((result) => {
                      this.router.navigate(['/apps/onboarded-assets']);
                    });
                  }
              });
            }
          }
        );
    }
  }

  checkToken(){
    this.checked = true;
    this.assetService.checkToken(this.tokenizationForm.value.symbol).subscribe(
      res=>{
        let resAddress = res as any;
        if (resAddress.data?.tokenToAddress != undefined && resAddress.data?.tokenToAddress !== "0x0000000000000000000000000000000000000000"){
          this.tokenizationForm.controls['symbol']?.setErrors({'incorrect': true});
        } else {
          this.tokenizationForm.controls['symbol']?.setErrors(null);
        }
      }
    );
  }

  get formData() {
    return this.tokenizationForm.controls;
  }

  public copyFromClipboard(){
    navigator.clipboard.readText().then(s => {
      this.tokenizationForm.controls['owner'].setValue(s);
    });
  }

  private readFileData(media: any): File {
    var sliceSize = 1024;
    var byteCharacters = atob(media.encodedBytes);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);
    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new File(byteArrays, media.fileName, { type: media.contentType });
  }

  openModal(content: any) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      size: 'lg'
    };
    const modalRef = this.modalService.open(
      content,
      ngbModalOptions
    );
  }
}
