<app-pagetitle title="{{ 'ASSETSPAGE.LIST.UPDATEASSET' | translate }}" [breadcrumbItems]="breadCrumbItems">
</app-pagetitle>
<div *ngIf="isInitialized">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header d-flex flex-row align-items-center">
                    <h4 class="card-title mb-0">{{ 'ASSETSPAGE.LIST.UPDATEASSET' | translate }}</h4>
                </div>
                <div class="card-body">
                    <!-- Nav tabs -->
                    <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                                <span class="d-none d-sm-block">Real estate snapshot</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="RealEstateSnapshot"></ng-template>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                                <span class="d-none d-sm-block">Token snapshot</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="TokenSnapshot"></ng-template>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3" >
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                                <span class="d-none d-sm-block">Token position</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ng-template [ngTemplateOutlet]="TokenPositions"></ng-template>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav" class="text-muted"></div>
                    <ng-template #RealEstateSnapshot>
                        <div class="row" style="margin-top: 35px">
                            <form [formGroup]="propertyDetailsForm" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-check form-switch form-switch-lg mb-3" dir="ltr">
                                            <input type="checkbox" class="form-check-input" id="hiddenAsset"
                                                formControlName="enabled">
                                            <label class="form-check-label" for="hiddenAsset">{{
                                                'PORTFOLIOPAGE.ENABLEDISABLEPROJECT' | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="mb-3">
                                            <label for="basicpill-firstname-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.PROPERTYTITLE' | translate }}:</label>
                                            <input type="text" class="form-control" id="basicpill-firstname-input1"
                                                formControlName="title"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.title.errors}">
                                            <div *ngIf="formsubmit && propertyDetailsFormData.title.errors"
                                                class="invalid-feedback">
                                                Property title is required
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-xl-9">
                                                <label for="basicpill-firstname-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.ADDRESS.LIST.STREET' | translate }}:</label>
                                                <input type="text" class="form-control" id="basicpill-firstname-input1"
                                                    formControlName="street"
                                                    [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.street.errors}">
                                                <div *ngIf="formsubmit && propertyDetailsFormData.street.errors"
                                                    class="invalid-feedback">
                                                    Street is required
                                                </div>
                                            </div>
                                            <div class="col-xl-3">
                                                <label for="basicpill-firstname-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.ADDRESS.LIST.NUMBER' | translate }}:</label>
                                                <input type="text" class="form-control" id="basicpill-firstname-input1"
                                                    formControlName="streetNumber"
                                                    [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.streetNumber.errors}">
                                                <div *ngIf="formsubmit && propertyDetailsFormData.streetNumber.errors"
                                                    class="invalid-feedback">
                                                    St. number is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-xl-9">
                                                <label for="basicpill-firstname-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.ADDRESS.LIST.CITY' | translate }}:</label>
                                                <ng-select [items]="searchCitiesHttp$ | async" #filterCity
                                                    [searchable]="true" typeToSearchText="Type to search for cities"
                                                    [multiple]="false" bindLabel="value" formControlName="city"
                                                    [loading]="loadingCities" [typeahead]="citiesInput$"
                                                    (change)="selectedCity()"
                                                    [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.city.errors}">
                                                    <ng-template ng-optgroup-tmp let-item="usage">
                                                    </ng-template>
                                                </ng-select>
                                                <div *ngIf="formsubmit && propertyDetailsFormData.city.errors"
                                                    class="invalid-feedback">
                                                    City is required
                                                </div>
                                            </div>
                                            <div class="col-xl-3">
                                                <label for="basicpill-firstname-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.ADDRESS.LIST.ZIP' | translate }}:</label>
                                                <ng-select [items]="postalCodesHttp$ | async" [searchable]="true"
                                                    typeToSearchText="Type to search for postal codes" [multiple]="false"
                                                    bindLabel="value" formControlName="postalCode"
                                                    [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.postalCode.errors}">
                                                    <ng-template ng-optgroup-tmp let-item="postalCode">
                                                    </ng-template>
                                                </ng-select>
                                                <div *ngIf="formsubmit && propertyDetailsFormData.postalCode.errors"
                                                    class="invalid-feedback" style="display: block;">
                                                    Postal code is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="basicpill-firstname-input1" class="form-label">{{
                                                'PORTFOLIOPAGE.COUNTRY' | translate }}:</label>
                                            <input type="text" class="form-control" id="basicpill-firstname-input1"
                                                formControlName="country"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.country.errors}">
                                            <div *ngIf="formsubmit && propertyDetailsFormData.country.errors"
                                                class="invalid-feedback">
                                                Federal state is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 offset-xl-1">
                                        <div class="mb-3">
                                            <label for="basicpill-lastname-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.ASSETDESC' | translate }}:</label>
                                            <ckeditor #myEditor [editor]="Editor"
                                                [config]="{ toolbar: [ 'bold', 'italic' , '|', 'bulletedList', 'numberedList']}"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.description.errors}"
                                                formControlName="description">
                                            </ckeditor>
                                            <div *ngIf="formsubmit && propertyDetailsFormData.description.errors"
                                                class="invalid-feedback">
                                                Property description is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="custom-dropzone" ngx-dropzone (change)="onSelect($event)"
                                    [accept]="acceptedType()"
                                    [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.images.errors}">
                                    <ngx-dropzone-label>
                                        <div>
                                            <p>{{ 'ASSETSPAGE.LIST.ASSETIMAGES' | translate }}</p>
                                        </div>
                                    </ngx-dropzone-label>
                                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of images"
                                        [file]="f" [removable]="true" (removed)="onRemove(f)">
                                        <ngx-dropzone-label>{{ trimName(f.name) }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-image-preview>
                                    <ngx-dropzone-video-preview ngProjectAs="ngx-dropzone-preview" *ngIf="video != null"
                                        [file]="video" [removable]="true" (removed)="onRemove(video)">
                                        <ngx-dropzone-label>{{ trimName( video.name) }} ({{ video.type }})
                                        </ngx-dropzone-label>
                                    </ngx-dropzone-video-preview>
                                </div>
                                <div *ngIf="propertyDetailsFormData.images.errors" class="invalid-feedback mb-3">
                                    Images are required
                                </div>
                                <div class="row" style="margin-top: 40px">
                                    <div class="col-md-2 columns_styling">
                                        <div class="row mb-3 g-0">
                                            <div class="col-lg-6">
                                                <label for="basicpill-phoneno-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.MAINIMAGE' | translate }}:</label>
                                                <ng-select #ngSelectComponent [items]="listOfImagesName" [multiple]="false"
                                                    bindLabel="value" formControlName="listOfImagesName"
                                                    [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.listOfImagesName.errors}">
                                                    <ng-template ng-optgroup-tmp let-item="listOfImagesName">
                                                    </ng-template>
                                                </ng-select>
                                                <div *ngIf="propertyDetailsFormData.listOfImagesName.errors"
                                                    class="invalid-feedback">
                                                    Main image is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 columns_styling">
                                        <div class="row mb-3 g-0">
                                            <div class="col-lg-6">
                                                <label for="basicpill-phoneno-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.CITYIMAGE' | translate }}</label>
                                                <input type="file" (change)="cityFileChange($event)"
                                                    onclick="this.value=null;" class="inputfile" id="cityImage"
                                                    accept=".png, .jpg, .jpeg" />
                                                <label for="cityImage">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' |
                                                    translate }}</label>
                                                <div *ngIf="propertyDetailsFormData.cityImage.errors"
                                                    class="invalid-feedback" style="display: block;">
                                                    City image is required
                                                </div>
                                            </div>
                                            <div class="col-lg-6" *ngIf="cityImageUrl!= '' && cityImageUrl != undefined">
                                                <div class="image-area">
                                                    <img [src]="cityImageUrl" id="cityImage">
                                                    <a class="remove-image" (click)="removeCityImg()">&#215;</a>
                                                    <div class="cityImageText">
                                                        {{ trimName( propertyDetailsFormData.cityImage.value.name ) }} ({{
                                                        propertyDetailsFormData.cityImage.value.type }})
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 40px">
                                    <div class="col-md-2 columns_styling">
                                        <div class="row mb-3 g-0">
                                            <label for="basicpill-phoneno-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.CONSTRACTIONYEAR' | translate }}:</label>
                                            <input type="text" class="form-control" id="construction_year"
                                                formControlName="construction_year"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.construction_year.errors}">
                                            <div *ngIf="formsubmit && propertyDetailsFormData.construction_year.errors"
                                                class="invalid-feedback">
                                                Construction year is required
                                            </div>
                                        </div>
                                        <div class="row mb-3 g-0">
                                            <label for="basicpill-phoneno-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.LASTREFURB' | translate }}:</label>
                                            <input type="text" class="form-control" id="refurbishment_year"
                                                formControlName="refurbishment_year"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.refurbishment_year.errors}">
                                        </div>
                                        <div class="row mb-3 gx-3">
                                            <div class="col-lg-6">
                                                <label for="basicpill-phoneno-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.USAGE.TEXT' | translate }}:</label>
                                                <ng-select [items]="usages" [multiple]="true" bindLabel="description"
                                                    formControlName="multiUsageOption" (change)="openModalVar = false;"
                                                    [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.multiUsageOption.errors}">
                                                    <ng-template ng-optgroup-tmp let-item="usage">
                                                    </ng-template>
                                                </ng-select>
                                                <div *ngIf="propertyDetailsFormData.multiUsageOption.errors"
                                                    class="invalid-feedback">
                                                    Usages are required
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <label for="basicpill-phoneno-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.USAGE.LIST.TEXT' | translate
                                                    }}:</label>
                                                <input type="text" class="form-control" id="multiUsageOptionText"
                                                    formControlName="multiUsageOptionText"
                                                    [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.multiUsageOptionText.errors}">
                                                <div *ngIf="propertyDetailsFormData.multiUsageOptionText.errors"
                                                    class="invalid-feedback">
                                                    Usages text is required
                                                </div>
                                            </div>
                                        </div>
    
                                        <div class="row mb-3 g-0">
                                            <label for="basicpill-phoneno-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.OWNERSHIP' | translate }}:</label>
                                            <ng-select [items]="ownerships" [multiple]="false" bindLabel="description"
                                                formControlName="ownershipOption"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.ownershipOption.errors}">
                                                <ng-template ng-optgroup-tmp let-item="ownership">
                                                </ng-template>
                                            </ng-select>
                                            <div *ngIf="propertyDetailsFormData.ownershipOption.errors"
                                                class="invalid-feedback">
                                                Ownership is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 columns_styling">
                                        <div class="row mb-3 g-0">
                                            <label for="basicpill-phoneno-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.PLOT' | translate }}:</label>
                                            <input class="form-control" id="basicpill-phoneno-input1"
                                                formControlName="plot_size" mask="separator.2"
                                                thousandSeparator="{{separator}}"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.plot_size.errors}">
                                            <div *ngIf="formsubmit && propertyDetailsFormData.plot_size.errors"
                                                class="invalid-feedback">
                                                Plot size is required
                                            </div>
                                        </div>
                                        <div class="row mb-3 g-0">
                                            <label for="basicpill-phoneno-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.PARKINGLOTS' | translate }}:</label>
                                            <input class="form-control" id="basicpill-phoneno-input1"
                                                formControlName="parking_lots" mask="separator.2"
                                                thousandSeparator="{{separator}}"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.parking_lots.errors}">
                                            <div *ngIf="formsubmit && propertyDetailsFormData.parking_lots.errors"
                                                class="invalid-feedback">
                                                Number of parking lots is required
                                            </div>
                                        </div>
                                        <div class="row mb-3 g-0">
                                            <label for="basicpill-phoneno-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.OCCUPANCYRATE' | translate }}:</label>
                                            <div class="input-group">
                                                <input type="number" min="1" max="100" class="form-control"
                                                    id="basicpill-phoneno-input1" formControlName="occupancy_rate"
                                                    [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.occupancy_rate.errors}">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"
                                                        id="validationTooltipOtherPrepend">%</span>
                                                </div>
                                                <div *ngIf="formsubmit && propertyDetailsFormData.occupancy_rate.errors?.required"
                                                    class="invalid-feedback">
                                                    Occupancy rate is required
                                                </div>
                                                <div *ngIf="formsubmit && propertyDetailsFormData.occupancy_rate.errors?.max"
                                                    class="invalid-feedback">
                                                    Occupancy rate must be less then 100%
                                                </div>
                                                <div *ngIf="formsubmit && propertyDetailsFormData.occupancy_rate.errors?.min"
                                                    class="invalid-feedback">
                                                    Occupancy rate must be more then 0%
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-3 g-0">
                                            <label for="basicpill-phoneno-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.WALT' | translate }}:</label>
                                            <input type="text" class="form-control" id="basicpill-phoneno-input1"
                                                formControlName="walt"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.walt.errors}">
                                            <span class="text-muted">{{ 'ASSETSPAGE.LIST.WALTSHORTEXT' | translate }}</span>
                                            <div *ngIf="formsubmit && propertyDetailsFormData.walt.errors"
                                                class="invalid-feedback">
                                                WALT is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="row" style="margin-top: 40px">
                                    <div class="col-sm-6">
                                        <button type="button" class="btn btn-primary w-sm waves-effect waves-light"
                                            [disabled]="propertyDetailsFormData.multiUsageOption.value.length == 0"
                                            (click)="openModal()">{{ 'ASSETSPAGE.LIST.BREAKDOWN.TEXT' | translate
                                            }}</button>
                                        <span
                                            *ngIf="(formsubmit || openModalVar) && (propertyDetailsForm.get('areaBreakdown')?.status==='INVALID' || propertyDetailsForm.get('rentBreakdown')?.status==='INVALID')"
                                            style="margin-left: 25px;" class="btn btn-danger"><i
                                                class="bx bx-block font-size-16 align-middle"></i></span>
                                        <span
                                            *ngIf="(formsubmit || openModalVar) && propertyDetailsForm.get('areaBreakdown')?.status==='VALID' && propertyDetailsForm.get('rentBreakdown')?.status==='VALID'"
                                            style="margin-left: 25px;" class="btn btn-success"><i
                                                class="bx bx-check-double font-size-16 align-middle"></i></span>
                                    </div>
                                    <div *ngIf="formsubmit && (propertyDetailsFormData.areaBreakdown.errors ||propertyDetailsFormData.rentBreakdown.errors)"
                                        class="invalid-feedback" style="display: block;">
                                        Breakdown values are required
                                    </div>
                                </div>
                                <div class="row  p-2" style="margin-top: 40px;">
                                    <div class="row mb-3 g-0">
                                        <h6>{{ 'ASSETSPAGE.LIST.HIGHLIGHTS' | translate }}:</h6>
                                        <app-highlights [form]="propertyDetailsForm" [update]="true"></app-highlights>
                                        <div *ngIf="formsubmit && propertyDetailsFormData.highlightOption.errors"
                                            class="invalid-feedback" style="display: block;">
                                            Asset highlights are required
                                        </div>
                                    </div>
                                    <div class="row p-2">
                                        <label for="basicpill-lastname-input1" class="form-label">{{
                                            'ASSETSPAGE.LIST.HIGHLIGHTS' | translate
                                            }}:</label>
                                        <ckeditor #highlightSection [editor]="Editor" [config]="editorConfig"
                                            formControlName="highlightSection"
                                            [ngClass]="{'is-invalid': formsubmit &&  propertyDetailsFormData.highlightSection.errors}">
                                        </ckeditor>
                                        <div *ngIf="formsubmit &&  propertyDetailsFormData.highlightSection.errors"
                                            class="invalid-feedback">
                                            Highlight section is required
                                        </div>
                                    </div>
                                </div>
                                <!--<app-here-map #map style="width: 100%;" [showControls]="true" *ngIf="asset"
                                    (mapValid)="validateMap($event)"></app-here-map>-->
                                <app-google-map #googleMap style="width: 100%;" (mapValid)="validateMap($event)" (addSocialEconomics)="addNewSocialEconomics($event)" (removeSocialEconomics)="removeDistrictSocialEconomics($event)"
                                    [readOnly]="false"></app-google-map>
    
                                <div *ngIf="formsubmit && propertyDetailsFormData.map.errors" class="invalid-feedback"
                                    style="margin-top: 15px; display: block;">
                                    {{ 'PORTFOLIOPAGE.MAPLOCATIONISREQ' | translate }}
                                </div>
                                <div class="row" style="margin-top: 30px; margin-bottom: 50px">
                                    <social-economics [form]="socialEconomics" [formsubmit]="formsubmit"></social-economics>
                                </div>
                                <div class="row" style="margin-top: 30px; margin-bottom: 50px">
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="basicpill-address-input" class="form-label">{{
                                                'ASSETSPAGE.LIST.LOCATIONDESC' | translate }}:</label>
                                            <ckeditor #myEditor [editor]="Editor"
                                                [config]="{ toolbar: [ 'bold', 'italic' , '|', 'bulletedList', 'numberedList']}"
                                                [ngClass]="{'is-invalid': formsubmit && propertyDetailsFormData.location_description.errors}"
                                                formControlName="location_description">
                                            </ckeditor>
                                            <div *ngIf="formsubmit && propertyDetailsFormData.location_description.errors"
                                                class="invalid-feedback">
                                                Location description is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                            </form>
                        </div>
                        <hr style="margin: 35px 0" />
                        <div class="row" style="margin-top: 35px">
                            <form [formGroup]="secondStepForm">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <h2>{{ 'ASSETSPAGE.LIST.RENT.TEXT' | translate }}</h2>
                                        <div formGroupName="rentData">
                                            <div class="mb-3 col-md-6">
                                                <label for="basicpill-servicetax-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.RENT.LIST.TOP5' | translate }}:</label>
                                                <input class="form-control" formControlName="topFive" mask="separator.2"
                                                    thousandSeparator="{{separator}}" [ngClass]="{
                                                     'is-invalid': topFive?.invalid && formsubmit}" />
                                                <div *ngIf="topFive?.invalid && formsubmit" class="invalid-feedback">
                                                    <div *ngIf="topFive?.errors">
                                                        Top five tenants data is required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3 col-md-6">
                                                <label for="basicpill-servicetax-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.RENT.LIST.OTHERTENANTS' | translate }}:</label>
                                                <input class="form-control" readonly formControlName="others"
                                                    mask="separator.2" [allowNegativeNumbers]="true"
                                                    [dropSpecialCharacters]="false" thousandSeparator="{{separator}}"
                                                    [ngClass]="{
                                          'is-invalid': others?.invalid}" />
                                                <div *ngIf="others?.invalid" class="invalid-feedback">
                                                    <div *ngIf="others?.errors?.required">
                                                        Number of other tenants data is required
                                                    </div>
                                                    <div *ngIf="others?.errors?.min">
                                                        Number of other tenats value added up with top five value must equal
                                                        rent total
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3 col-md-6">
                                                <label for="basicpill-servicetax-input1" class="form-label">{{
                                                    'ASSETSPAGE.LIST.RENT.LIST.TOTAL' | translate }}:</label>
                                                <input class="form-control" readonly formControlName="rentTotal"
                                                    mask="separator.2" thousandSeparator="{{separator}}" [ngClass]="{
                                          'is-invalid': rentTotal?.invalid && formsubmit }" />
                                            </div>
                                        </div>
                                        <hr style="margin: 35px 0" />
                                        <h2>
                                            {{ 'ASSETSPAGE.LIST.TOP5TENANTS.LIST.TOP5TENANTS' | translate }}&nbsp;&nbsp;<i
                                                class="mdi mdi-information"
                                                ngbTooltip="{{ 'ASSETSPAGE.LIST.TOP5TENANTS.LIST.EXPLENATION' | translate }}"
                                                placement="auto"></i>
                                        </h2>
                                        <div class="d-flex flex-row align-items-center">
                                            <div style="margin-right: 25px">1.</div>
                                            <div class="mb-3 col-md-6">
                                                <input type="text" class="form-control" formControlName="firstTenant"
                                                    [ngClass]="{
                                          'is-invalid':
                                            firstTenant?.invalid && formsubmit
                                        }" />
                                                <div *ngIf="firstTenant?.invalid && formsubmit" class="invalid-feedback">
                                                    <div *ngIf="firstTenant?.errors">
                                                        First tenant is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row align-items-center">
                                            <div style="margin-right: 25px">2.</div>
                                            <div class="mb-3 col-md-6">
                                                <input type="text" class="form-control" formControlName="secondTenant"
                                                    [ngClass]="{
                                          'is-invalid':
                                            secondTenant?.invalid && formsubmit
                                        }" />
                                                <div *ngIf="secondTenant?.invalid && formsubmit" class="invalid-feedback">
                                                    <div *ngIf="secondTenant?.errors">
                                                        Second tenant is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row align-items-center">
                                            <div style="margin-right: 25px">3.</div>
                                            <div class="mb-3 col-md-6">
                                                <input type="text" class="form-control" formControlName="thirdTenant"
                                                    [ngClass]="{
                                          'is-invalid':
                                            thirdTenant?.invalid && formsubmit
                                        }" />
                                                <div *ngIf="thirdTenant?.invalid && formsubmit" class="invalid-feedback">
                                                    <div *ngIf="thirdTenant?.errors">
                                                        Third tenant is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row align-items-center">
                                            <div style="margin-right: 25px">4.</div>
                                            <div class="mb-3 col-md-6">
                                                <input type="text" class="form-control" formControlName="fourthTenant"
                                                    [ngClass]="{
                                          'is-invalid':
                                            fourthTenant?.invalid && formsubmit
                                        }" />
                                                <div *ngIf="fourthTenant?.invalid && formsubmit" class="invalid-feedback">
                                                    <div *ngIf="fourthTenant?.errors">
                                                        Fourth tenant is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-row align-items-center">
                                            <div style="margin-right: 25px">5.</div>
                                            <div class="mb-3 col-md-6">
                                                <input type="text" class="form-control" formControlName="fifthTenant"
                                                    [ngClass]="{
                                          'is-invalid':
                                            fifthTenant?.invalid && formsubmit
                                        }" />
                                                <div *ngIf="fifthTenant?.invalid && formsubmit" class="invalid-feedback">
                                                    <div *ngIf="fifthTenant?.errors">
                                                        Fifth tenant is required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-6 align-items-center">
                                        <div class="row" style="width: 100%; height: 500px; margin-top: 25px">
                                            <div echarts [options]="donughnutChart" [merge]="dynamicData"
                                                class="demo-chart"></div>
                                        </div>
                                    </div>
                                </div>
    
                                <hr style="margin: 40px 0" />
                                <div class="row" style="margin-bottom: 40px">
                                    <div formArrayName="leasData" class="row">
                                        <div *ngFor="
                                            let leasDataRow of leasDataForm.controls;
                                            let i = index
                                            " [formGroupName]="i">
                                            <div class="d-flex align-items-center justify-content-around mb-3" *ngIf="i==0">
                                                <div style="width: 5%"><b>{{ 'ASSETSPAGE.LIST.LEASE.LIST.YEAR' | translate
                                                        }}</b></div>
                                                <div style="width: 20%">
                                                    <b>{{ 'ASSETSPAGE.LIST.LEASE.LIST.BREAKOPTION' | translate }}</b>
                                                </div>
                                                <div style="width: 20%"><b>{{ 'ASSETSPAGE.LIST.LEASE.LIST.LEASEEND' |
                                                        translate }}</b></div>
                                                <div style="width: 20%">
                                                    <b>{{ 'ASSETSPAGE.LIST.LEASE.LIST.EXTENSIONOPTION' | translate }}</b>
                                                </div>
                                            </div>
                                            <div class="d-flex align-items-center justify-content-around mb-3" *ngIf="i!=0">
                                                <div style="width: 5%">{{ yearsArr[i] }}</div>
                                                <input formControlName="breakOptionsControl" class="form-control"
                                                    style="width: 20%" mask="separator.2" thousandSeparator="{{separator}}"
                                                    [ngClass]="{
                                                    'is-invalid':  leasDataRow.get('breakOptionsControl')?.invalid
                                                    }" />
                                                <input formControlName="leasEndControl" class="form-control"
                                                    style="width: 20%" mask="separator.2" thousandSeparator="{{separator}}"
                                                    [ngClass]="{
                                                    'is-invalid':  leasDataRow.get('leasEndControl')?.invalid
                                                    }" />
                                                <input formControlName="extensionOptionsControl" class="form-control"
                                                    style="width: 20%" mask="separator.2" thousandSeparator="{{separator}}"
                                                    [ngClass]="{
                                                    'is-invalid':  leasDataRow.get('extensionOptionsControl')?.invalid
                                                    }" />
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-around mb-3">
                                            <div style="width: 5%"><b>{{ 'ASSETSPAGE.LIST.RENT.LIST.TOTAL' | translate
                                                    }}:</b></div>
                                            <div style="width: 20%">
                                                <b>{{ breakOptionsSum | number: "1.2-2" }}</b>
                                                <div *ngIf="breakOptionsSum!==rentSum " class="invalid-feedback"
                                                    style="display: block;">
                                                    <div *ngIf="breakOptionsSum!==rentSum">
                                                        Lease end incl. Break option must be equal to total rent sum
                                                        ({{rentSum | number: "1.2-2"}})
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width: 20%">
                                                <b>{{ leasEndSum | number: "1.2-2" }}</b>
                                                <div *ngIf="leasEndSum!==rentSum" class="invalid-feedback"
                                                    style="display: block;">
                                                    <div *ngIf="leasEndSum!==rentSum">
                                                        Lease End sum must be equal to total rent sum ({{rentSum | number:
                                                        "1.2-2"}})
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width: 20%">
                                                <b>{{ extensionOptionsSum | number: "1.2-2"}}</b>
                                                <div *ngIf="extensionOptionsSum!==rentSum" class="invalid-feedback"
                                                    style="display: block;">
                                                    <div *ngIf="extensionOptionsSum!==rentSum">
                                                        Lease end incl. extension option sum must be equal to total rent sum
                                                        ({{rentSum | number: "1.2-2"}})
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <hr style="margin: 35px 0" />
                        <div class="row justify-content-center" style="margin: 35px 0">
                            <form [formGroup]="thirdStepForm">
                                <div class="row">
                                    <div class="col-lg-6" style="
                                    border-right: 1px solid #ced4da;
                                    padding-right: 100px;
                                  " formGroupName="debtSituation">
                                        <h2 style="margin-bottom: 35px">{{ 'ASSETSPAGE.LIST.DEBTSITUATION.TEXT' | translate
                                            }}</h2>
                                        <div class="mb-3">
                                            <label for="basicpill-namecard-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.DEBTSITUATION.LIST.GROSSVALUE' | translate }}:</label>
                                            <input class="form-control" formControlName="grossAssetValue" mask="separator.2"
                                                thousandSeparator="{{separator}}" [ngClass]="{
                                        'is-invalid':
                                          grossAssetValue?.invalid && grossAssetValue?.touched
                                      }" />
                                            <div *ngIf="
                                        grossAssetValue?.invalid && grossAssetValue?.touched
                                      " class="invalid-feedback">
                                                <div *ngIf="grossAssetValue?.errors?.required">
                                                    Gross Asset Value is required
                                                </div>
                                                <div *ngIf="grossAssetValue?.errors?.min">
                                                    Gross Asset Value must be a positive number
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="basicpill-namecard-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.DEBTSITUATION.LIST.TOTALDEBT' | translate }}:</label>
                                            <input class="form-control" formControlName="totalDebt" mask="separator.2"
                                                thousandSeparator="{{separator}}" [ngClass]="{
                                        'is-invalid': totalDebt?.invalid && totalDebt?.touched
                                      }" />
                                            <div *ngIf="totalDebt?.invalid && totalDebt?.touched" class="invalid-feedback">
                                                <div *ngIf="totalDebt?.errors?.required">
                                                    Total Debt is required
                                                </div>
                                                <div *ngIf="totalDebt?.errors?.min">
                                                    Total Debt must be a positive number
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="basicpill-namecard-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.DEBTSITUATION.LIST.DEBTOUTSTANDING' | translate }}:</label>
                                            <input class="form-control" formControlName="debtOutstanding" mask="separator.2"
                                                thousandSeparator="{{separator}}" [ngClass]="{
                                        'is-invalid':
                                          debtOutstanding?.invalid && debtOutstanding?.touched
                                      }" />
                                            <div *ngIf="
                                        debtOutstanding?.invalid && debtOutstanding?.touched
                                      " class="invalid-feedback">
                                                <div *ngIf="debtOutstanding?.errors?.required">
                                                    Debt Outstanding is required
                                                </div>
                                                <div *ngIf="debtOutstanding?.errors?.min">
                                                    Debt Outstanding must be a positive number
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">{{ 'ASSETSPAGE.LIST.DEBTSITUATION.LIST.LTV' |
                                                translate }}:</label>
                                            <div>
                                                <b>{{ ltv | number: "1.2-2" }} %</b>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="basicpill-namecard-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.DEBTSITUATION.LIST.DEBTSERVICE' | translate }}:</label>
                                            <input type="number" class="form-control" formControlName="debtService"
                                                [ngClass]="{
                                        'is-invalid':
                                          debtService?.invalid && debtService?.touched
                                      }" />
                                            <div *ngIf="debtService?.invalid && debtService?.touched"
                                                class="invalid-feedback">
                                                <div *ngIf="debtService?.errors?.required">
                                                    Debt service is required
                                                </div>
                                                <div *ngIf="debtService?.errors?.min">
                                                    Debt service must be a positive number
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div class="col-lg-6" style="padding-left: 100px" formGroupName="debtBreakDown">
                                        <h2 style="margin-bottom: 35px">{{ 'ASSETSPAGE.LIST.DEBTBREAKDOWN.TEXT' | translate
                                            }}</h2>
                                        <div class="mb-3">
                                            <label for="basicpill-namecard-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.DEBTBREAKDOWN.LIST.FLOATING' | translate }}:</label>
                                            <input class="form-control" formControlName="floating" mask="separator.2"
                                                thousandSeparator="{{separator}}" [ngClass]="{
                                        'is-invalid': floating?.invalid && floating?.touched
                                      }" />
                                            <div *ngIf="floating?.invalid && floating?.touched" class="invalid-feedback">
                                                <div *ngIf="floating?.errors?.required">
                                                    Floating is required
                                                </div>
                                                <div *ngIf="floating?.errors?.min">
                                                    Floating must be a positive number
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="basicpill-namecard-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.DEBTBREAKDOWN.LIST.LESSTHAN1YEAR' | translate }}</label>
                                            <input class="form-control" formControlName="ltOneYear" mask="separator.2"
                                                thousandSeparator="{{separator}}" [ngClass]="{
                                        'is-invalid': ltOneYear?.invalid && ltOneYear?.touched
                                      }" />
                                            <div *ngIf="ltOneYear?.invalid && ltOneYear?.touched" class="invalid-feedback">
                                                <div *ngIf="ltOneYear?.errors?.required">
                                                    Less than 1 year value is required
                                                </div>
                                                <div *ngIf="ltOneYear?.errors?.min">
                                                    Less than 1 year value must be a positive number
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label for="basicpill-namecard-input1" class="form-label">{{
                                                'ASSETSPAGE.LIST.DEBTBREAKDOWN.LIST.MORETHAN1YEAR' | translate }}:</label>
                                            <input class="form-control" formControlName="mtOneYear" mask="separator.2"
                                                thousandSeparator="{{separator}}" [ngClass]="{
                                        'is-invalid': mtOneYear?.invalid && mtOneYear?.touched
                                      }" />
                                            <div *ngIf="mtOneYear?.invalid && mtOneYear?.touched" class="invalid-feedback">
                                                <div *ngIf="mtOneYear?.errors?.required">
                                                    More than 1 year value is required
                                                </div>
                                                <div *ngIf="mtOneYear?.errors?.min">
                                                    More than 1 year value must be a positive number
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <hr style="margin: 35px 0" />
                        <div class="row justify-content-center" style="margin-top: 35px">
                            <form [formGroup]="fourthStepForm">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.NDA' | translate }}:
                                            </h4>
                                        </div>
                                        <div class="card-body d-flex flex-row align-items-center">
                                            <input type="file" (change)="
                                                  onFileSelected($event, 'ndaFiles')
                                                " class="inputfile" id="ndaFiles" onclick="this.value=null;"
                                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                            <label for="ndaFiles">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' |
                                                translate }}</label>
                                            <app-document [files]="filesTypesSubtypesList.ndaFiles" [isNda]=true>
                                            </app-document>
                                            <div *ngIf="formsubmit && fourthStepFormData.nda.errors"
                                                class="invalid-feedback" style="display: block;">
                                                {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.NDAREQ' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.TEXT' |
                                                translate }}:</h4>
                                        </div>
    
                                        <div class="card-body row">
                                            <div class="col-lg-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.LIST.CADASTRAL' |
                                                            translate }}:</h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple (change)="
                                                onFileSelected($event, 'cadastralMaps')
                                              " class="inputfile" id="cadastralMaps" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="cadastralMaps">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document [files]="filesTypesSubtypesList.cadastralMapsFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">
                                                            {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.LIST.LANDREGISTRATION'
                                                            | translate }}:
                                                        </h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple (change)="
                                                onFileSelected(
                                                  $event,
                                                  'landRegistrationExcerpt'
                                                )
                                              " class="inputfile" id="landRegistrationExcerpt" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="landRegistrationExcerpt">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document
                                                            [files]="filesTypesSubtypesList.landRegistrationExcerptFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">
                                                            {{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.LIST.CONTAMINATIONREGISTER'
                                                            | translate }}:
                                                        </h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple (change)="
                                                onFileSelected(
                                                  $event,
                                                  'contaminationRegisterEx'
                                                )
                                              " class="inputfile" id="contaminationRegisterEx" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="contaminationRegisterEx">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document
                                                            [files]="filesTypesSubtypesList.contaminationRegisterExFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.LIST.BUILDINGPERMIT' |
                                                            translate }}:</h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple (change)="
                                                onFileSelected($event, 'buildingPermit')
                                              " class="inputfile" id="buildingPermit" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="buildingPermit">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document [files]="filesTypesSubtypesList.buildingPermitFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.TEXT' |
                                                translate }}:</h4>
                                        </div>
    
                                        <div class="card-body row">
                                            <div class="col-lg-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.LIST.FLOORSPACETABEL'
                                                            | translate }}:</h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple (change)="
                                                onFileSelected($event, 'floorSpaceTable')
                                              " class="inputfile" id="floorSpaceTable" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="floorSpaceTable">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document [files]="filesTypesSubtypesList.floorSpaceTableFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.LIST.CURVATUREANALYSIS'
                                                            | translate }}:</h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple (change)="
                                                onFileSelected($event, 'curvatureAnalysis')
                                              " class="inputfile" id="curvatureAnalysis" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="curvatureAnalysis">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document
                                                            [files]="filesTypesSubtypesList.curvatureAnalysisFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.LIST.FLOORPLAN' |
                                                            translate }}:</h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple
                                                            (change)="onFileSelected($event, 'floorPlan')" class="inputfile"
                                                            id="floorPlan" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="floorPlan">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document [files]="filesTypesSubtypesList.floorPlanFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-6">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.LIST.OBJECTDESCRIPTION'
                                                            | translate }}:</h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple (change)="
                                                onFileSelected($event, 'objectDescription')
                                              " class="inputfile" id="objectDescription" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="objectDescription">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document
                                                            [files]="filesTypesSubtypesList.objectDescriptionFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TENANCY.TEXT' |
                                                translate }}:</h4>
                                        </div>
    
                                        <div class="card-body row">
                                            <div class="col-lg-4">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.TENANCY.LIST.RENTROLL' |
                                                            translate }}:</h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple
                                                            (change)="onFileSelected($event, 'rentRoll')" class="inputfile"
                                                            id="rentRoll" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="rentRoll">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document [files]="filesTypesSubtypesList.rentRollFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-4">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">
                                                            {{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.TENANCY.LIST.SAMPLERENTALAGREEMENT'
                                                            | translate }}:
                                                        </h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" (change)="
                                                onFileSelected(
                                                  $event,
                                                  'sampleRentalAgreement'
                                                )
                                              " multiple class="inputfile" id="sampleRentalAgreement"
                                                            onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="sampleRentalAgreement">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document
                                                            [files]="filesTypesSubtypesList.sampleRentalAgreementFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-4">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">
                                                            {{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.TENANCY.LIST.ANALYSISOFANCILLARYCOTS'
                                                            | translate }}:
                                                        </h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple (change)="
                                                onFileSelected(
                                                  $event,
                                                  'analysisOfAncillaryCosts'
                                                )
                                              " class="inputfile" id="analysisOfAncillaryCosts" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="analysisOfAncillaryCosts">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document
                                                            [files]="filesTypesSubtypesList.analysisOfAncillaryCostsFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.REPORT.TEXT' |
                                                translate }}:</h4>
                                        </div>
    
                                        <div class="card-body row">
                                            <div class="col-lg-4">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.REPORT.LIST.VALUATIONREPORTS' |
                                                            translate }}:</h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" (change)="
                                                onFileSelected($event, 'valuationReport')
                                              " multiple class="inputfile" id="valuationReport" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="valuationReport">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document [files]="filesTypesSubtypesList.valuationReportFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-4">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.REPORT.LIST.ANNUALACCOUNTS' |
                                                            translate }}:</h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" (change)="
                                                onFileSelected($event, 'annualAccounts')
                                              " multiple class="inputfile" id="annualAccounts" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="annualAccounts">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document [files]="filesTypesSubtypesList.annualAccountsFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div class="col-lg-4">
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h5 class="card-title">
                                                            {{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.REPORT.LIST.TECHNICALDUEDILIGENCEREPORT'
                                                            | translate }}:
                                                        </h5>
                                                    </div>
                                                    <div class="card-body d-flex flex-row">
                                                        <input type="file" multiple (change)="
                                                onFileSelected($event, 'techDueDiligence')
                                              " class="inputfile" id="techDueDiligence" onclick="this.value=null;"
                                                            accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                        <label for="techDueDiligence">{{
                                                            'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                            }}</label>
                                                        <app-document
                                                            [files]="filesTypesSubtypesList.techDueDiligenceFiles">
                                                        </app-document>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.INSURANCE.TEXT' |
                                                    translate }}:</h4>
                                            </div>
    
                                            <div class="card-body row">
                                                <div class="col-lg-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h5 class="card-title">{{
                                                                'ASSETSPAGE.LIST.DOCUMENTS.LIST.INSURANCE.LIST.AGREEMENT' |
                                                                translate }}:</h5>
                                                        </div>
                                                        <div class="card-body d-flex flex-row">
                                                            <input type="file" (change)="
                                                    onFileSelected($event, 'insuranceAgreement')
                                                " multiple class="inputfile" id="insuranceAgreement"
                                                                onclick="this.value=null;"
                                                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                            <label for="insuranceAgreement">{{
                                                                'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                                }}</label>
                                                            <app-document
                                                                [files]="filesTypesSubtypesList.insuranceAgreementFiles">
                                                            </app-document>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">{{
                                                    'ASSETSPAGE.LIST.DOCUMENTS.LIST.STRUCTURECHARTDOCUMENTS.TEXT' |
                                                    translate }}:</h4>
                                            </div>
    
                                            <div class="card-body row">
                                                <div class="col-lg-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h5 class="card-title">
                                                                {{
                                                                'ASSETSPAGE.LIST.DOCUMENTS.LIST.STRUCTURECHARTDOCUMENTS.LIST.ORGANIZATIONALSTRUCTURE'
                                                                | translate }}:
                                                            </h5>
                                                        </div>
                                                        <div class="card-body d-flex flex-row">
                                                            <input type="file" multiple (change)="
                                                    onFileSelected(
                                                    $event,
                                                    'organizationStructure'
                                                    )
                                                " class="inputfile" id="organizationStructure" onclick="this.value=null;"
                                                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                            <label for="organizationStructure">{{
                                                                'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                                }}</label>
                                                            <app-document
                                                                [files]="filesTypesSubtypesList.organizationStructureFiles">
                                                            </app-document>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">
                                                    {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.LEGALREGULATORY' | translate }}:
                                                </h4>
                                            </div>
    
                                            <div class="card-body row">
                                                <div class="col-lg-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h5 class="card-title">
                                                                {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.LEGALREGULATORY' |
                                                                translate }}:
                                                            </h5>
                                                        </div>
                                                        <div class="card-body d-flex flex-row">
                                                            <input type="file" (change)="
                                                    onFileSelected($event, 'legalAndRegulatory')
                                                " multiple class="inputfile" id="legalAndRegulatory"
                                                                onclick="this.value=null;"
                                                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                            <label for="legalAndRegulatory">{{
                                                                'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                                }}</label>
                                                            <app-document
                                                                [files]="filesTypesSubtypesList.legalAndRegulatoryFiles">
                                                            </app-document>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.OTHER' | translate
                                                    }}:</h4>
                                            </div>
    
                                            <div class="card-body row">
                                                <div class="col-lg-12">
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.OTHER'
                                                                | translate }}:</h5>
                                                        </div>
                                                        <div class="card-body d-flex flex-row">
                                                            <input type="file" (change)="onFileSelected($event, 'other')"
                                                                multiple class="inputfile" id="other"
                                                                onclick="this.value=null;"
                                                                accept=".doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx,.png,.jpg,.jpeg" />
                                                            <label for="other">{{
                                                                'ASSETSPAGE.LIST.DOCUMENTS.LIST.CHOOSEFILES' | translate
                                                                }}</label>
                                                            <app-document [files]="filesTypesSubtypesList.otherFiles">
                                                            </app-document>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ng-template>
                    <ng-template #TokenSnapshot>
                    </ng-template>
                    <ng-template #TokenPositions>
                    </ng-template>
                </div>
            </div>
        </div>
        <ul class="list-inline wizard mb-0">
            <li class="next list-inline-item float-end">
                <button type="button" class="btn btn-primary" (click)="onSubmit()">{{ 'ASSETSPAGE.LIST.UPDATE' |
                    translate }}</button>
            </li>
        </ul>
    </div>
</div>