<!-- start page title -->
<app-pagetitle title="Pricing" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Start your creative project right plans</h4>
                <div class="flex-shrink-0 align-self-end">
                    <ul ngbNav #nav="ngbNav" [activeId]="1"
                        class="nav justify-content-end nav-tabs-custom rounded card-header-tabs">
                        <li [ngbNavItem]="1" class="nav-item">
                            <a ngbNavLink class="nav-link px-3">
                                Monthly
                            </a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane fade show" id="month" role="tabpanel" aria-labelledby="monthly">
                                    <div class="row">
                                        <div class="col-xl-3 col-sm-6" *ngFor="let data of monthlyData">
                                            <div class="card mb-xl-0"
                                                [ngClass]=" { 'bg-primary': data.badge === 'Featured' }">
                                                <div class="card-body">
                                                    <div class="p-2">
                                                        <div class="pricing-badge" *ngIf="data.badge">
                                                            <span class="badge">Featured</span>
                                                        </div>
                                                        <h5 class="font-size-16"
                                                            [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                            {{data.title}}</h5>
                                                        <h1 class="mt-3"
                                                            [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                            ${{data.price}} <span
                                                                class="text-muted font-size-16 fw-medium"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">/
                                                                Month</span></h1>
                                                        <p class="text-muted mt-3 font-size-15"
                                                            [ngClass]=" { 'text-white-50': data.badge === 'Featured' }">
                                                            For small teams trying out Minia for an unlimited
                                                            period of time</p>
                                                        <div class="mt-4 pt-2 text-muted">
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Verifide
                                                                work and
                                                                reviews
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Dedicated
                                                                Ac managers
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Unlimited
                                                                proposals
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Project
                                                                tracking
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Dedicated
                                                                Ac managers
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Unlimited
                                                                proposals
                                                            </p>
                                                        </div>

                                                        <div class="mt-4 pt-2">
                                                            <a href="javascript:void(0);"
                                                                class="btn btn-outline-primary w-100"
                                                                [ngClass]=" { 'btn-light': data.badge === 'Featured' }">Choose
                                                                Plan</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- end card body -->
                                            </div>
                                            <!-- end card -->
                                        </div>
                                        <!-- end col -->
                                    </div>
                                    <!-- end row -->
                                </div>
                            </ng-template>
                        </li>

                        <li [ngbNavItem]="2" class="nav-item">
                            <a ngbNavLink class="nav-link px-3">
                                Yearly
                            </a>
                            <ng-template ngbNavContent>
                                <div class="tab-pane fade show" id="month" role="tabpanel" aria-labelledby="monthly">
                                    <div class="row">
                                        <div class="col-xl-3 col-sm-6" *ngFor="let data of yearlyData">
                                            <div class="card mb-xl-0"
                                                [ngClass]=" { 'bg-primary': data.badge === 'Featured' }">
                                                <div class="card-body">
                                                    <div class="p-2">
                                                        <div class="pricing-badge" *ngIf="data.badge">
                                                            <span class="badge">Featured</span>
                                                        </div>
                                                        <h5 class="font-size-16"
                                                            [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                            {{data.title}}</h5>
                                                        <h1 class="mt-3"
                                                            [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                            ${{data.price}} <span
                                                                class="text-muted font-size-16 fw-medium"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">/
                                                                Month</span></h1>
                                                        <p class="text-muted mt-3 font-size-15"
                                                            [ngClass]=" { 'text-white-50': data.badge === 'Featured' }">
                                                            For small teams trying out Minia for an unlimited
                                                            period of time</p>
                                                        <div class="mt-4 pt-2 text-muted">
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Verifide
                                                                work and
                                                                reviews
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Dedicated
                                                                Ac managers
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Unlimited
                                                                proposals
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Project
                                                                tracking
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Dedicated
                                                                Ac managers
                                                            </p>
                                                            <p class="mb-3 font-size-15"
                                                                [ngClass]=" { 'text-white': data.badge === 'Featured' }">
                                                                <i class="mdi mdi-check-circle text-secondary font-size-18 me-2"
                                                                    [ngClass]=" { 'text-light': data.badge === 'Featured' }"></i>Unlimited
                                                                proposals
                                                            </p>
                                                        </div>

                                                        <div class="mt-4 pt-2">
                                                            <a href="javascript:void(0);"
                                                                class="btn btn-outline-primary w-100"
                                                                [ngClass]=" { 'btn-light': data.badge === 'Featured' }">Choose
                                                                Plan</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- end card body -->
                                            </div>
                                            <!-- end card -->
                                        </div>
                                        <!-- end col -->
                                    </div>
                                    <!-- end row -->
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content" id="pills-tabContent">
                    <div [ngbNavOutlet]="nav" class=""></div>
                </div>
                <!-- end tab content -->
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->

<div class="row">
    <div class="col-xl-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Project Plans</h4>
                <p class="card-title-desc">Call to action pricing table is really crucial to your for your business
                    website.
                    Make your bids stand-out with amazing options.
                </p>
            </div>
            <!-- end card header -->

            <div class="card-body">
                <div class="row">
                    <div class="col-xl-3">
                        <ul ngbNav #nav1="ngbNav" [activeId]="1" class="flex-column nav-pills">
                            <li [ngbNavItem]="1" class="nav flex-column nav-pills pricing-tab-box">
                                <a ngbNavLink class="nav-link mb-3">
                                    <div class="d-flex align-items-center">
                                        <i class="bx bx-check-circle h3 mb-0 me-4"></i>
                                        <div class="flex-1">
                                            <h2 class="fw-medium">$29 <span class="text-muted font-size-15">/ Month
                                                    Plans</span></h2>
                                            <p class="fw-normal mb-0 text-muted">For small teams trying out Minia for an
                                                unlimited period of time</p>
                                        </div>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="tab-pane fade show active" id="v-price-one" role="tabpanel"
                                                aria-labelledby="v-pills-tab-one">
                                                <div class="table-responsive text-center">
                                                    <table class="table table-bordered mb-0 table-centered">
                                                        <tbody>
                                                            <tr>
                                                                <td></td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">1 Month</h5>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">3 Month</h5>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">6 Month</h5>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">1 Year</h5>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Users</th>
                                                                <td>1</td>
                                                                <td>3</td>
                                                                <td>5</td>
                                                                <td>7</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Storage</th>
                                                                <td>1 GB</td>
                                                                <td>10 GB</td>
                                                                <td>20 GB</td>
                                                                <td>40 GB</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Domain</th>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-close-circle text-danger font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>1</td>
                                                                <td>2</td>
                                                                <td>4</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Hidden Fees</th>
                                                                <td>Yes</td>
                                                                <td>Yes</td>
                                                                <td>No</td>
                                                                <td>No</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Support</th>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-close-circle text-danger font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Update</th>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-close-circle text-danger font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-close-circle text-danger font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="2" class="nav flex-column nav-pills pricing-tab-box">
                                <a ngbNavLink class="nav-link mb-3">
                                    <div class="d-flex align-items-center">
                                        <i class="bx bx-check-circle h3 mb-0 me-4"></i>
                                        <div class="flex-1">
                                            <h2 class="fw-medium">$79 <span class="text-muted font-size-15">/ Month
                                                    Plans</span></h2>
                                            <p class="fw-normal mb-0 text-muted">For larger businesses or those with
                                                onal administration needs</p>
                                        </div>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="tab-pane fade show active" id="v-price-one" role="tabpanel"
                                                aria-labelledby="v-pills-tab-one">
                                                <div class="table-responsive text-center">
                                                    <table class="table table-bordered mb-0 table-centered">
                                                        <tbody>
                                                            <tr>
                                                                <td></td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">1 Month</h5>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">3 Month</h5>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">6 Month</h5>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">1 Year</h5>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Users</th>
                                                                <td>1</td>
                                                                <td>3</td>
                                                                <td>5</td>
                                                                <td>7</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Storage</th>
                                                                <td>5 GB</td>
                                                                <td>15 GB</td>
                                                                <td>25 GB</td>
                                                                <td>50 GB</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Domain</th>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-close-circle text-danger font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>3</td>
                                                                <td>4</td>
                                                                <td>8</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Hidden Fees</th>
                                                                <td>Yes</td>
                                                                <td>No</td>
                                                                <td>No</td>
                                                                <td>No</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Support</th>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Update</th>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-close-circle text-danger font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="3" class="nav flex-column nav-pills pricing-tab-box">
                                <a ngbNavLink>
                                    <div class="d-flex align-items-center">
                                        <i class="bx bx-check-circle h3 mb-0 me-4"></i>
                                        <div class="flex-1">
                                            <h2 class="fw-medium">$99 <span class="text-muted font-size-15">/ Month
                                                    Plans</span></h2>
                                            <p class="fw-normal mb-0 text-muted">For extra large businesses or those in
                                                regulated industries</p>
                                        </div>
                                    </div>
                                </a>
                                <ng-template ngbNavContent>
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="tab-pane fade show active" id="v-price-one" role="tabpanel"
                                                aria-labelledby="v-pills-tab-one">
                                                <div class="table-responsive text-center">
                                                    <table
                                                        class="table table-bordered mb-0 table-centered align-middle">
                                                        <tbody>
                                                            <tr>
                                                                <td></td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">1 Month</h5>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">3 Month</h5>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">6 Month</h5>
                                                                    </div>
                                                                </td>
                                                                <td style="width: 20%;">
                                                                    <div class="py-3">
                                                                        <h5 class="font-size-16 mb-0">1 Year</h5>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Users</th>
                                                                <td>1</td>
                                                                <td>3</td>
                                                                <td>5</td>
                                                                <td>7</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Storage</th>
                                                                <td>5 GB</td>
                                                                <td>30 GB</td>
                                                                <td>50 GB</td>
                                                                <td>100 GB</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Domain</th>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>3</td>
                                                                <td>5</td>
                                                                <td>10</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Hidden Fees</th>
                                                                <td>No</td>
                                                                <td>No</td>
                                                                <td>No</td>
                                                                <td>No</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Support</th>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Update</th>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <i
                                                                            class="mdi mdi-check-circle text-success font-size-20"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xl-9">
                        <div [ngbNavOutlet]="nav1"></div>
                    </div>
                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->