import { Injectable, Output, EventEmitter } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class RemoveNdaService {


    @Output() removeNdaEvent = new EventEmitter<string>();

    removeNda() {
        this.removeNdaEvent.emit();
    }
}
