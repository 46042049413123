import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-break-down-dialog',
  templateUrl: './break-down-dialog.component.html',
  styleUrls: ['./break-down-dialog.component.scss']
})
export class BreakDownDialogComponent implements OnInit {

  @Input() type: string | undefined;
  @Input() sign: string | undefined;
  @Input() areaValues!: []
  @Input() rentValues!: []

  breakdownForm!: FormGroup;
  formsubmit!: boolean;
  public separator:string = ','

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {

  }
  ngOnInit(): void {
    this.breakdownForm = this.fb.group({
      area: this.fb.array([]),
      rent: this.fb.array([])
    });
    for (let areaValue of this.areaValues) {
      let value = areaValue as any;
      const usageRow = this.fb.group({
        description: [value.description, Validators.required],
        id: [value.id, Validators.required],
        value: [value.value, [Validators.required, Validators.min(0)]],
      });
      this.areaForm.push(usageRow);
    }
    for (let rentValue of this.rentValues) {
      let value = rentValue as any;
      const usageRow = this.fb.group({
        description: [value.description, Validators.required],
        id: [value.id, Validators.required],
        value: [value.value, [Validators.required, Validators.min(0)]],
      });
      this.rentForms.push(usageRow);
    }
  }

  passBack() {
    this.formsubmit = true;
    if (this.breakdownForm.valid) {
      this.activeModal.close(this.breakdownForm.controls);
    }
  }

  get formData() {
    return this.breakdownForm.controls;
  }

  get areaForm() {
    return this.breakdownForm.get('area') as FormArray;
  }
  get rentForms(){
    return this.breakdownForm.get('rent') as FormArray;
  }

  checkValidity(id: number){
    for (let area of this.areaForm.controls) {
      if (area.get('id')?.value == id){
        return area.invalid;
      }
    }
    return false;
  }
  checkValidityRent(id: number){
    for (let rent of this.rentForms.controls) {
      if (rent.get('id')?.value == id){
        return rent.invalid;
      }
    }
    return false;
  }

}
