import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/classes/ErrorResponse';
import { Highlight } from 'src/app/classes/Highlight';
import { Ownership } from 'src/app/classes/Ownership';
import { Usage } from 'src/app/classes/Usage';

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WizarService {
  constructor(private http: HttpClient) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  httpOptionsMultipart = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    }),
  };

  public getAllUsages(): Observable<Array<Usage>> {
    return this.http
      .get<Array<Usage>>(environment.apiBase + '/assets/usages')
      .pipe(catchError(this.handleError));
  }

  public getAllOwnerships(): Observable<Array<Ownership>> {
    return this.http
      .get<Array<Ownership>>(environment.apiBase + '/assets/ownerships')
      .pipe(catchError(this.handleError));
  }

  public getAllHighlights(): Observable<Array<Highlight>> {
    return this.http
      .get<Array<Highlight>>(environment.apiBase + '/assets/highlights')
      .pipe(catchError(this.handleError));
  }

  public postAsset(body: string) {
    return this.http
      .post(environment.apiBase + '/assets', body, this.httpOptions)
      .pipe(catchError(this.handleError));
  }
  public postPortfolio(body: string) {
    return this.http
      .post(environment.apiBase + '/portfolio', body, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  public postImages(
    assetId: number,
    files: any,
    main: any,
    cityPhotoFile: File
  ) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('main', main.value);
    formData.append('files', cityPhotoFile);
    formData.append('city', cityPhotoFile.name);
    return this.http
      .post(environment.apiBase + '/assets/' + assetId + '/images', formData)
      .pipe(catchError(this.handleError));
  }

  public postImagesPortfolio(
    assetId: number,
    files: any,
    main: any,
    cityPhotoFile: File
  ) {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    formData.append('main', main.value);
    formData.append('files', cityPhotoFile);
    formData.append('city', cityPhotoFile.name);
    return this.http
      .post(environment.apiBase + '/portfolio/' + assetId + '/images', formData)
      .pipe(catchError(this.handleError));
  }

  public postDocuments(assetId: number, files: any) {
    const formData: FormData = new FormData();
    let listofFiles: any = [];
    for (const list of Object.entries(files)) {
      if (list[1]) {
        for (const iterator of list[1] as []) {
          listofFiles.push(iterator['file']);
          formData.append('files', iterator['file']);
          formData.append('params', JSON.stringify({"name": iterator['name'],
          "subTypeId": iterator['subtype']
          }));
        }
      }
    }
    if (!formData.has('params')) {
      formData.append('params', '');
    }
    return this.http
      .post(environment.apiBase + '/assets/' + assetId + '/documents', formData)
      .pipe(catchError(this.handleError));
  }

  public postDocumentsPortfolio(assetId: number, files: any) {
    const formData: FormData = new FormData();
    let listofFiles: any = [];
    for (const list of Object.entries(files)) {
      if (list[1]) {
        for (const iterator of list[1] as []) {
          listofFiles.push(iterator['file']);
          formData.append('files', iterator['file']);
          formData.append('params', JSON.stringify({"name": iterator['name'],
          "subTypeId": iterator['subtype']
          }));
        }
      }
    }
    if (!formData.has('params')) {
      formData.append('params', '');
    }
    return this.http
      .post(environment.apiBase + '/portfolio/' + assetId + '/documents', formData)
      .pipe(catchError(this.handleError));
  }

  public postVideo(assetId: number, file: any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post(environment.apiBase + '/assets/' + assetId + '/video', formData)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: ErrorResponse) {
    let errorMessage = '';
    if (error.errorMessage != null) {
      errorMessage = `Error: ${error.errorMessage}`;
    } else  if (error.httpStatus != null) {
      errorMessage = `Error Code: ${error.httpStatus}`;
    } else {
      errorMessage = `Error Code: ${error}`;
    }
    return throwError(errorMessage);
  }
  public searchCity(query: any): Observable<string[]> {
    return this.http.get<string[]>(
      environment.apiBase + '/cities/search-city/' + query,
      this.httpOptions
    );
  }
  public searchPostalCodeByCityName(searchCityName: any): Observable<any[]> {
    return this.http.get<string[]>(
      environment.apiBase + '/zip-codes/cities/' + searchCityName,
      this.httpOptions
    );
  }

  public deleteVideo(assetId: number) {
    return this.http
      .delete(environment.apiBase + '/assets/' + assetId + '/video')
      .pipe(catchError(this.handleError));
  }
}
