import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { CustomerTable } from './customers.datatable.model';

export type CustomerSortColumn = keyof CustomerTable | '';
export type CustomerSortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: CustomerSortDirection } = { 'asc': 'desc', 'desc': '', '': 'asc' };

export interface CustomerSortEvent {
  column: CustomerSortColumn;
  direction: CustomerSortDirection;
}

@Directive({
  selector: 'th[CostSorTable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})

export class costuemerDataTableSortableDirective {

  constructor() { }

  @Input() costSorTable: CustomerSortColumn = '';
  @Input() direction: CustomerSortDirection = '';
  @Output() sort = new EventEmitter<CustomerSortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.costSorTable, direction: this.direction });
  }
}
