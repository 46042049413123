import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { User } from 'src/app/classes/user';
import { ErrorResponse } from 'src/app/classes/ErrorResponse';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

    private authPath:string;

    constructor(
        private httpClient:HttpClient
    ) {
        this.authPath = environment.apiBase + '/authenticate';
    }

    authenticate(username: string, password: any) {
        return this.httpClient.post<any>(this.authPath, {username,password}).pipe(
        map(
            userData => {
            sessionStorage.setItem('username',username);
            sessionStorage.setItem('token', userData.token);
            return userData;
            }
        )

        ).pipe(catchError(this.handleError));
    }

    private handleError(error: ErrorResponse) {
        let errorMessage = '';
        if (error.errorMessage != null) {
          errorMessage = `Error: ${error.errorMessage}`;
        } else {
          errorMessage = `Error Code: ${error.httpStatus}`;
        }
        return throwError(errorMessage);
    }
    
    isUserLoggedIn() {
        let user = sessionStorage.getItem('username');
        let token = sessionStorage.getItem('token')
        return !(user === null) && !(token === null);
    }

    logOut() {
        sessionStorage.removeItem('username')
        sessionStorage.removeItem('token');
    }
}
