<div class="bg-soft-light min-vh-100 py-5">
    <div class="py-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <div class="mb-5">
                            <a routerLink="/">
                                <img src="assets/images/logo-sm.svg" alt="" height="30" class="me-1"><span
                                    class="logo-txt text-dark font-size-22">Minia</span>
                            </a>
                        </div>

                        <div class="maintenance-cog-icon text-primary pt-4">
                            <i class="mdi mdi-cog spin-right display-3"></i>
                            <i class="mdi mdi-cog spin-left display-4 cog-icon"></i>
                        </div>
                        <h3 class="mt-4">Site is Under Maintenance</h3>
                        <p>Please check back in sometime.</p>

                        <div class="mt-4">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="mt-4 maintenance-box">
                                        <div class="p-4">
                                            <div class="avatar-md mx-auto">
                                                <span class="avatar-title bg-soft-primary rounded-circle">
                                                    <i
                                                        class="mdi mdi-access-point-network font-size-24 text-primary"></i>
                                                </span>
                                            </div>

                                            <h5 class="font-size-15 text-uppercase mt-4">Why is the Site Down?</h5>
                                            <p class="text-muted mb-0">There are many variations of passages of
                                                Lorem Ipsum available, but the majority have suffered alteration.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mt-4 maintenance-box">
                                        <div class="p-4">
                                            <div class="avatar-md mx-auto">
                                                <span class="avatar-title bg-soft-primary rounded-circle">
                                                    <i class="mdi mdi-clock-outline font-size-24 text-primary"></i>
                                                </span>
                                            </div>
                                            <h5 class="font-size-15 text-uppercase mt-4">
                                                What is the Downtime?</h5>
                                            <p class="text-muted mb-0">Contrary to popular belief, Lorem Ipsum is not
                                                simply random text. It has roots in a piece of classical.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="mt-4 maintenance-box">
                                        <div class="p-4">
                                            <div class="avatar-md mx-auto">
                                                <span class="avatar-title bg-soft-primary rounded-circle">
                                                    <i class="mdi mdi-email-outline font-size-24 text-primary"></i>
                                                </span>
                                            </div>
                                            <h5 class="font-size-15 text-uppercase mt-4">
                                                Do you need Support?</h5>
                                            <p class="text-muted mb-0">If you are going to use a passage of Lorem
                                                Ipsum, you need to be sure there isn't anything embar.. <a
                                                    href="mailto:no-reply@domain.com"
                                                    class="text-decoration-underline">no-reply@domain.com</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end row -->
                        </div>
                    </div>
                </div>
                <!-- end col -->
            </div>
            <!-- end row -->
        </div>
        <!-- end container -->
    </div>
</div>