<!-- start page title -->
<app-pagetitle title="{{ 'MENUITEMS.APPS.LIST.CUSTOMERS.TEXT' | translate }}" [breadcrumbItems]="breadCrumbItems" [displayBackButton]="true" backRout="../"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ 'REQUESTINGUSER.TEXT' | translate }}</h4>
                <p class="card-title-desc" innerHTML="{{ 'REQUESTINGUSER.LIST.INTRO' | translate }}"></p>
            </div>
            <div class="card-body p-4">
                <form [formGroup]="userForm" *ngIf="userForm">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class = "row">
                                <div class="col d-flex align-items-star flex-column">
                                    <div class="mb-3">
                                        <label for="registrationStatus" class="form-label">{{ 'REQUESTINGUSER.LIST.REGISTRATIONSTATUS.TEXT' | translate }}</label><br>
                                        <button class= 'btn w-sm waves-effect btn-label waves-light' [ngClass]="{
                                            'btn-warning': status === 'Pending',
                                            'btn-success': status === 'Accepted',
                                            'btn-danger': status === 'Rejected',
                                            'btn-primary': status === 'Waiting for documents' || 'Waiting for additional data',
                                            'btn-cyan': status === 'Waiting for additional documents',
                                            'btn-light': status === 'Reopened'}">
                                            <span [ngSwitch]="status">
                                                <i *ngSwitchCase="'Accepted'" class="bx bx-check-double label-icon"></i>
                                                <i *ngSwitchCase="'Rejected'" class="bx bx-block label-icon"></i>
                                                <i *ngSwitchCase="'Pending' " class="bx bx-hourglass label-icon"></i>
                                                <i *ngSwitchCase="'Waiting for documents'" class="bx bx-hourglass label-icon"></i>
                                                <i *ngSwitchCase="'Waiting for additional data'" class="bx bx-hourglass label-icon"></i>
                                                <i *ngSwitchCase="'Waiting for additional documents'" class="bx bx-hourglass label-icon"></i>
                                                <i *ngSwitchCase="'Reopened'" class="bx bx-smile label-icon"></i>
        
                                            </span>
                                            {{ translateStatus(status) | translate }}
                                        </button>
                                    </div>
                                </div>
                                <div class="col d-flex align-items-end flex-column">
                                    <div class="mb-3">
                                        <label>Professional investor</label><br>
                                        <button class="btn w-sm waves-effect btn-label waves-light btn-success" *ngIf="user.professionalInvestor == true">
                                            <span><i class="bx bx-check-double label-icon"></i></span>
                                            {{user.professionalInvestor}}
                                        </button>
                                        <button class="btn w-sm waves-effect btn-label waves-light btn-danger" *ngIf="user.professionalInvestor == false">
                                            <span><i class="bx bx-block label-icon"></i></span>
                                            {{user.professionalInvestor}}
                                        </button>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        <div class="col-lg-6" *ngIf="user.keycloakId != undefined">
                            <label for="firstName" class="form-label">Keycloak id</label>
                            <input class="form-control" type="text" formControlName="keycloakId" readonly>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div>
                                <div class="mb-3">
                                    <label for="firstName" class="form-label">{{ 'REQUESTINGUSER.LIST.NAME' | translate }}</label>
                                    <input class="form-control" type="text" formControlName="firstName" readonly>
                                </div>
                                <div class="mb-3">
                                    <label for="lastName" class="form-label">{{ 'REQUESTINGUSER.LIST.LASTNAME' | translate }}</label>
                                    <input class="form-control" type="text" formControlName="lastName" readonly>
                                </div>
                                <div class="mb-3">
                                    <label for="username" class="form-label">{{ 'REQUESTINGUSER.LIST.USERNAME' | translate }}</label>
                                    <div class="input-group">
                                        <div class="input-group-text">@</div>
                                        <input class="form-control" type="text" formControlName="username" readonly>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="companyName" class="form-label">{{ 'REQUESTINGUSER.LIST.COMPANYNAME' | translate }}</label>
                                    <input class="form-control" type="text" formControlName="companyName" readonly>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div>
                                <div class="mb-3">
                                    <label for="e-mail" class="form-label">{{ 'REQUESTINGUSER.LIST.EMAIL' | translate }}</label>
                                    <input class="form-control" type="text" formControlName="email" readonly>
                                </div>
                                <div class="mb-3">
                                    <label for="phoneNumber" class="form-label">{{ 'REQUESTINGUSER.LIST.PHONENUMBER' | translate }}</label>
                                    <input class="form-control" type="text" formControlName="phoneNumber" readonly>
                                </div>
                                <div class="mb-3">
                                    <label for="lei" class="form-label">LEI / CRN</label>
                                    <input class="form-control" type="text" formControlName="lei" readonly>
                                </div>
                                <div class="mb-3">
                                    <label for="companyName" class="form-label">{{ 'REQUESTINGUSER.LIST.COMPANYADDRESS' | translate }}</label>
                                    <input class="form-control" type="text" formControlName="companyAddress" readonly>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label for="documents" class="form-label" style="margin-right: 5px;">{{ 'REQUESTINGUSER.LIST.DOCUMENTS' | translate }}</label>
                                <span class="btn btn-light position-relative p-0 avatar-sm rounded-circle">
                                    <span class="avatar-title bg-transparent text-reset">
                                        <i *ngIf="user.documents.length == 0" class="bx bx-block font-size-16 align-middle"></i>
                                        <i *ngIf="user.documents.length > 0" class="bx bx-check-double font-size-16 align-middle"></i>
                                    </span>
                                </span>

                                <div style="margin-top: 20px;">
                                    <div *ngFor="let item of user.documents" class="mb-1">
                                        <button class="btn btn-soft-light waves-effect waves-ligh p-0 avatar-sm" (click) = "downloadPdf(item.id, trimPath(item.path))" style="margin-right: 10px;" title="Download">
                                            <span class="avatar-title bg-transparent text-reset">
                                                <i class="fas fa-download"></i>
                                            </span>
                                        </button>
                                        <button class="btn btn-soft-light waves-effect waves-ligh p-0 avatar-sm" (click) = "openPdf(item.id, trimPath(item.path), content)" style="margin-right: 10px;" title="Preview">
                                            <span class="avatar-title bg-transparent text-reset">
                                                <i [ngClass] = loadIconClass(item.path)></i>
                                            </span>
                                        </button>
                                        <!--<button class="btn btn-soft-light waves-effect waves-ligh p-0 avatar-sm" (click) = "downloadPdf(item.id, trimPath(item.path))" style="margin-right: 10px;" title="Download"><img src="../../../../assets/images/download.png" style="width: 20px; height: 20px;"></button>
                                        <button class="btn btn-soft-light waves-effect waves-light p-0 avatar-sm" (click) = "openPdf(item.id, trimPath(item.path), content)" style="margin-right: 10px;" title="Preview"><img [src]="loadImageSource(item.path)" style="width: 20px; height: 20px;"></button>-->
                                        <label>{{trimPath(item.path)}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template #content let-modal>
                        <div class="modal-header">
                            <h4 class="modal-title" id="modal-basic-title">{{fileName}}</h4>
                            <div type="btn" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
                                style="cursor: pointer;">
                                <span aria-hidden="true" style="font-size: 2.5em;">&times;</span>
                            </div>
                        </div>
                        <div class="modal-body">
                            <pdf-viewer *ngIf="pdfExcetnsions.includes(fileType)" [src]="src" [rotation]="0" [fit-to-page]="true"
                                        [original-size]="true" [show-all]="true" style="display: block; height: 70vh;"></pdf-viewer>
                            <div class="d-flex justify-content-center mb-3">
                                <div class="p-2">
                                    <img *ngIf="imgExcetnsions.includes(fileType)" [src]="transform(src)"
                                        style="display: inline; height: 70vh; justify-content: center;" />
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Ok</button>
                        </div>
                    </ng-template>
                    <div class="btn_wrapper">

                        <button
                              class="btn btn-danger waves-effect waves-light"
                              [ngClass]="
                              user?.registrationStatus?.status === 'STATUS_REJECTED' ||
                              user?.registrationStatus?.status === 'STATUS_ACCEPTED'
                                ? 'displayNone'
                                : 'displayBlock'
                            "
                            (click)="openModal(reject)">{{ 'REQUESTINGUSER.LIST.STATUSBUTTONS.LIST.REJECT' | translate }}</button>
                        <button
                              class="btn btn-secondary waves-effect waves-light"
                              [ngClass]="
                              user?.registrationStatus?.status === 'STATUS_REJECTED'
                                ? 'displayBlock'
                                : 'displayNone'
                            "
                              (click)="openModal(reopen)">{{ 'REQUESTINGUSER.LIST.STATUSBUTTONS.LIST.REOPEN' | translate }}</button>
                        <button
                              class="btn btn-primary waves-effect waves-light"
                              [ngClass]="
                              !user?.external && (
                              user?.registrationStatus?.status === 'STATUS_REGISTRATION_PENDING' ||
                              user?.registrationStatus?.status === 'STATUS_REOPENED')
                                ? 'displayBlock'
                                : 'displayNone'
                            "
                              (click)="openModal(documents)">{{ 'REQUESTINGUSER.LIST.STATUSBUTTONS.LIST.REQUESTDOCUMENTS' | translate }}</button>
                        <button
                              class="btn btn-primary waves-effect waves-light"
                              [ngClass]="
                              user?.external && (
                              user?.registrationStatus?.status === 'STATUS_REGISTRATION_PENDING' ||
                              user?.registrationStatus?.status === 'STATUS_REOPENED')
                                ? 'displayBlock'
                                : 'displayNone'
                            "
                              (click)="openModal(data)">{{ 'REQUESTINGUSER.LIST.STATUSBUTTONS.LIST.REQUESTADDITIONALDATA' | translate }}</button>
                        <button
                              class="btn btn-warning waves-effect waves-light"
                              [ngClass]="
                              user?.registrationStatus?.status === 'STATUS_WAITING_FOR_DOCUMENTS' ||
                              user?.registrationStatus?.status === 'STATUS_WAITING_FOR_ADDITIONAL_DATA' ||
                              user?.registrationStatus?.status === 'STATUS_WAITING_FOR_ADDITIONAL_DOCUMENTS'
                                ? 'displayBlock'
                                : 'displayNone'
                            "
                              (click)="showTextArea = true">{{ 'REQUESTINGUSER.LIST.STATUSBUTTONS.LIST.REQUESTADDITIONALDOCUMENTS' | translate }}</button>
                        <button [disabled]="!validForm()"
                              class="btn btn-success waves-effect waves-light"
                              [ngClass]="
                              user?.registrationStatus?.status === 'STATUS_WAITING_FOR_DOCUMENTS' ||
                              user?.registrationStatus?.status === 'STATUS_WAITING_FOR_ADDITIONAL_DATA' ||
                              user?.registrationStatus?.status ===
                                'STATUS_WAITING_FOR_ADDITIONAL_DOCUMENTS'
                                ? 'displayBlock'
                                : 'displayNone'
                            "
                              (click)="openModal(accept)">{{ 'REQUESTINGUSER.LIST.STATUSBUTTONS.LIST.ACCEPT' | translate }}</button>
                    </div>
                    <!-- sample modal content -->
                    <ng-template #reject role="reject" let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.REJECT' | translate }}</h5>
                            <button type="button" class="btn-close" aria-hidden="true"
                                (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body">
                            <h5>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.REJECTQUESTOION' | translate }}</h5>
                            <p>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.REJECTEXPLANATION' | translate }}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                                (click)="modal.close('Close click')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.CLOSE' | translate }}</button>
                            <button type="button" class="btn btn-primary" (click)="updateUserStatus('STATUS_REJECTED')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.SAVE' | translate }}</button>
                        </div>
                    </ng-template>
                    <!-- sample modal content -->
                    <ng-template #reopen role="reopen" let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.REOPEN' | translate }}</h5>
                            <button type="button" class="btn-close" aria-hidden="true"
                                (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body">
                            <h5>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.REOPENQUESTOION' | translate }}</h5>
                            <p>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.REOPENEXPLANATION' | translate }}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                                (click)="modal.close('Close click')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.CLOSE' | translate }}</button>
                            <button type="button" class="btn btn-primary" (click)="updateUserStatus('STATUS_REOPENED')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.SAVE' | translate }}</button>
                        </div>
                    </ng-template>
                     <!-- sample modal content -->
                     <ng-template #data role="data" let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.DATA' | translate }}</h5>
                            <button type="button" class="btn-close" aria-hidden="true"
                                (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body">
                            <h5>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.DATAQUESTOION' | translate }}</h5>
                            <p>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.DATAEXPLANATION' | translate }}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                                (click)="modal.close('Close click')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.CLOSE' | translate }}</button>
                            <button type="button" class="btn btn-primary" (click)="updateUserStatus('STATUS_WAITING_FOR_ADDITIONAL_DATA')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.SAVE' | translate }}</button>
                        </div>
                    </ng-template>
                    <!-- sample modal content -->
                    <ng-template #documents role="documents" let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.DOCUMENTS' | translate }}</h5>
                            <button type="button" class="btn-close" aria-hidden="true"
                                (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body">
                            <h5>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.DOCUMENTSQUESTOION' | translate }}</h5>
                            <p>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.DOCUMENTSEXPLANATION' | translate }}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                                (click)="modal.close('Close click')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.CLOSE' | translate }}</button>
                            <button type="button" class="btn btn-primary" (click)="updateUserStatus('STATUS_WAITING_FOR_DOCUMENTS')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.SAVE' | translate }}</button>
                        </div>
                    </ng-template>
                    <!-- sample modal content -->
                    <ng-template #accept role="accept" let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.ACCEPT' | translate }}</h5>
                            <button type="button" class="btn-close" aria-hidden="true"
                                (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body">
                            <h5>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.ACCEPTQUESTOION' | translate }}</h5>
                            <p>{{ 'REQUESTINGUSER.LIST.MODAL.LIST.ACCEPTEXPLANATION' | translate }}</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                                (click)="modal.close('Close click')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.CLOSE' | translate }}</button>
                            <button type="button" class="btn btn-primary" (click)="updateUserStatus('STATUS_ACCEPTED')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.SAVE' | translate }}</button>
                        </div>
                    </ng-template>
                    <div class="input_wrapper" style="margin-top: 25px" *ngIf="showTextArea">
                        <form [formGroup]="emailForm" *ngIf="emailForm" (ngSubmit)="sendMailForAditionalDocs()">
                            <label>{{ 'REQUESTINGUSER.LIST.USERMESSAGE' | translate }}</label><br>
                            <strong>Dear Mr. {{user?.lastName}} / Dear Ms. {{user?.lastName}} / Dear Dr. {{user?.lastName}}</strong>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" formControlName="emailBody"></textarea>
                            You can do it on the link below.<br>
                            in order to successfully complete the onboarding process, please upload the following documents <a
                                href="javascript:void(0);"><u>here</u></a><br>
                            <br>
                            -ID<br>
                            -Proof of ownership and/or legal representation documents for the company<br>
                            -Document confirming LEI<br>
                            -Copy of commercial register extract<br>
                            <br>
                            <strong>Kind regards</strong><br>
                            Your tectrex customer support team<br>
                            <button class="btn btn-light position-relative p-0 avatar-sm rounded-circle" type="button" (click)="previewMail(mail)">
                                <i class="mdi mdi-email-outline"></i>
                            </button>
                            <div style="display: flex; justify-content: flex-end; margin-top: 25px">
                                <button class="btn btn-danger waves-effect waves-light" type="button" (click)="showTextArea = false">{{
                                    'REQUESTINGUSER.LIST.CANCEL' | translate }}</button>
                                <div style="width: 30px"></div>
                                <button class="btn btn-success waves-effect waves-light" type="submit" [disabled]="!emailForm.valid">{{
                                    'REQUESTINGUSER.LIST.SEND' | translate }}</button>
                            </div>
                        </form>
                    </div>
                    <ng-template #mail role="mail" let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">E-mail</h5>
                            <button type="button" class="btn-close" aria-hidden="true"
                                (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body" style="font-family: 'San Francisco Pro'; font-size: 12px;">
                            <div class="row justify-content-center">
                                <img src="../../../../assets/images/logo-sm.svg" style="height: 40px; padding: 0 1em;">
                            </div>
                            <div class="row mt-5">
                                <strong>Dear Mr. {{user?.lastName}} / Dear Ms. {{user?.lastName}} / Dear Dr. {{user?.lastName}}</strong>
                            </div>
                            <p>
                                {{this.emailForm.value['emailBody']}}<br>
                                in order to successfully complete the onboarding process, please upload the following documents  <a href="javascript:void(0);"><u>here</u></a><br>
                                <br>
                                -ID<br>
                                -Proof of ownership and/or legal representation documents for the company<br>
                                -Document confirming LEI<br>
                                -Copy of commercial register extract<br>
                                <br>
                                <strong>Kind regards</strong><br>
                                the tectrex team<br>
                            </p>
                            <hr>
                            <p style="font-size: 9px;">
                                <strong>360X RE AG (tectrex) </strong> <br>
                                Gervinusstrase 17 <br>
                                60322 Frankfurt am Main <br>
                                <br>
                                E-mail: contact@tectrex.com <br>
                                Phone: +49 69 5050 27426 <br>
                                <br>
                                Executive Board: Kay Hofmann | Supervisory Board: David Hable (Chairman), David Ziper, Thomas Spaun<br>
                                Commercial register: Amtsgericht Frankfurt am Main | Commercial register number: HRB 123356 | Tax-ID:
                                DE344390597
                                <br>
                                <br>
                                Please do not reply to this email. In case you need any assistance please contact us <a
                                    href="mailto:contact@tectrex.com"><u>here</u></a>
                            </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                                (click)="modal.close('Close click')">{{ 'REQUESTINGUSER.LIST.MODAL.LIST.CLOSE' | translate }}</button>
                        </div>
                    </ng-template>
                </form>
            </div>
        </div>
    </div>
</div>
