<!-- start page title -->
<app-pagetitle title="General" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Badges</h4>
                <p class="card-title-desc">Add any of the below mentioned modifier classes to change the appearance of a
                    badge.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div>
                    <h5 class="font-size-14">Default</h5>
                    <div class="mt-1">
                        <span class="badge bg-primary">Primary</span>
                        <span class="badge bg-success ms-1">Success</span>
                        <span class="badge bg-info ms-1">Info</span>
                        <span class="badge bg-warning ms-1">Warning</span>
                        <span class="badge bg-danger ms-1">Danger</span>
                        <span class="badge bg-dark ms-1">Dark</span>
                    </div>
                </div>

                <div class="mt-4">
                    <h5 class="font-size-14">Soft Badge</h5>
                    <div class="mt-1">
                        <span class="badge badge-soft-primary">Primary</span>
                        <span class="badge badge-soft-success ms-1">Success</span>
                        <span class="badge badge-soft-info ms-1">Info</span>
                        <span class="badge badge-soft-warning ms-1">Warning</span>
                        <span class="badge badge-soft-danger ms-1">Danger</span>
                        <span class="badge badge-soft-dark ms-1">Dark</span>
                    </div>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Pill Badges</h4>
                <p class="card-title-desc">Use the <code>.rounded-pill</code> modifier class to make
                    badges more rounded.
                </p>
            </div><!-- end card header -->

            <div class="card-body">
                <div>
                    <h5 class="font-size-14">Default</h5>
                    <div class="mt-1">
                        <span class="badge rounded-pill bg-primary">Primary</span>
                        <span class="badge rounded-pill bg-success ms-1">Success</span>
                        <span class="badge rounded-pill bg-info ms-1">Info</span>
                        <span class="badge rounded-pill bg-warning ms-1">Warning</span>
                        <span class="badge rounded-pill bg-danger ms-1">Danger</span>
                        <span class="badge rounded-pill bg-dark ms-1">Dark</span>
                    </div>
                </div>

                <div class="mt-4">
                    <h5 class="font-size-14">Soft Badge</h5>
                    <div class="mt-1">
                        <span class="badge rounded-pill badge-soft-primary">Primary</span>
                        <span class="badge rounded-pill badge-soft-success ms-1">Success</span>
                        <span class="badge rounded-pill badge-soft-info ms-1">Info</span>
                        <span class="badge rounded-pill badge-soft-warning ms-1">Warning</span>
                        <span class="badge rounded-pill badge-soft-danger">Danger</span>
                        <span class="badge rounded-pill badge-soft-dark ms-1">Dark</span>
                    </div>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Badges in Buttons</h4>
                <p class="card-title-desc">Badges can be used as part of links or buttons to provide a counter.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="d-flex flex-wrap gap-2">
                    <button type="button" class="btn btn-primary">
                        Notifications <span class="badge bg-success ms-1">4</span>
                    </button>
                    <button type="button" class="btn btn-success">
                        Messages <span class="badge bg-danger ms-1">2</span>
                    </button>
                    <button type="button" class="btn btn-outline-secondary">
                        Draft <span class="badge bg-success ms-1">2</span>
                    </button>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Badges Position Examples</h4>
                <p class="card-title-desc">Example of Badges Position</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="d-flex flex-wrap gap-3">
                    <button type="button" class="btn btn-primary position-relative">
                        Mails <span
                            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">+99
                            <span class="visually-hidden">unread messages</span></span>
                    </button>


                    <button type="button" class="btn btn-light position-relative">
                        Alerts <span
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1"><span
                                class="visually-hidden">unread messages</span></span>
                    </button>

                    <button type="button" class="btn btn-primary position-relative p-0 avatar-sm rounded">
                        <span class="avatar-title bg-transparent">
                            <i class="bx bxs-envelope"></i>
                        </span>
                        <span
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1"><span
                                class="visually-hidden">unread messages</span></span>
                    </button>

                    <button type="button" class="btn btn-light position-relative p-0 avatar-sm rounded-circle">
                        <span class="avatar-title bg-transparent text-reset">
                            <i class="bx bxs-bell"></i>
                        </span>
                    </button>

                    <button type="button" class="btn btn-light position-relative p-0 avatar-sm rounded-circle">
                        <span class="avatar-title bg-transparent text-reset">
                            <i class="bx bx-menu"></i>
                        </span>
                        <span
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-1"><span
                                class="visually-hidden">unread messages</span></span>
                    </button>
                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Popovers</h4>
                <p class="card-title-desc">Four options are available: top, right, bottom, and left aligned. Directions
                    are mirrored when using Bootstrap in RTL.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="d-flex flex-wrap gap-2">

                    <button type="button" class="btn btn-secondary" placement="top"
                        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                        Popover on top
                    </button>

                    <button type="button" class="btn btn-secondary" placement="right"
                        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                        Popover on right
                    </button>

                    <button type="button" class="btn btn-secondary" placement="bottom"
                        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                        Popover on bottom
                    </button>

                    <button type="button" class="btn btn-secondary" placement="left" popoverTitle="Popover Title"
                        ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                        Popover on left
                    </button>

                    <button type="button" class="btn btn-success waves-effect waves-light" data-bs-toggle="popover"
                        data-bs-trigger="focus" title="Dismissible popover"
                        data-bs-content="And here's some amazing content. It's very engaging. Right?">Dismissible
                        popover</button>
                </div>

            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Tooltips</h4>
                <p class="card-title-desc">Hover over the links below to see tooltips:</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="d-flex flex-wrap gap-2">
                    <button type="button" class="btn btn-primary" ngbTooltip="Tooltip on top" placement="top">
                        Tooltip on top
                    </button>

                    <button type="button" class="btn btn-primary" ngbTooltip="Tooltip on right" placement="right">
                        Tooltip on right
                    </button>

                    <button type="button" class="btn btn-primary" ngbTooltip="Tooltip on bottom" placement="bottom">
                        Tooltip on bottom
                    </button>

                    <button type="button" class="btn btn-primary" ngbTooltip="Tooltip on left" placement="left">
                        Tooltip on left
                    </button>

                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Pagination Default Example</h4>
                <p class="card-title-desc">Pagination links indicate a series of related content exists across multiple
                    pages.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <ngb-pagination [collectionSize]="30" [(page)]="default" aria-label="Custom pagination">
                    <ng-template ngbPaginationPrevious>Previous</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>

                <ngb-pagination [collectionSize]="50" [(page)]="defaultwithicon" [maxSize]="5" [rotate]="true" [ellipses]="false"
                    [boundaryLinks]="true"></ngb-pagination>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h5 class="font-size-14">Pagination Disabled and Active</h5>
                <p class="card-title-desc">Pagination links are customizable for
                    different circumstances. Use <code>.disabled</code> for links that appear
                    un-clickable and <code>.active</code> to
                    indicate the current page.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <ngb-pagination [collectionSize]="30" [(page)]="pages" aria-label="Custom pagination">
                    <ng-template ngbPaginationPrevious>Previous</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>

                <ngb-pagination [collectionSize]="50" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                    [boundaryLinks]="true"></ngb-pagination>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h5 class="font-size-14">Pagination Sizing</h5>
                <p class="card-title-desc">Fancy larger or smaller pagination? Add <code>size="lg"</code> or
                    <code>size="sm"</code> on <code>ngb-pagination</code> for additional
                    sizes.
                </p>
            </div><!-- end card header -->

            <div class="card-body">
                <ngb-pagination [collectionSize]="30" [(page)]="largepage" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" size="lg" aria-label="Custom pagination">
                    <ng-template ngbPaginationPrevious>Previous</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>

                <ngb-pagination [collectionSize]="30" [(page)]="smallpage" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" size="sm" aria-label="Custom pagination">
                    <ng-template ngbPaginationPrevious>Previous</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->


    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h5 class="font-size-14">Pagination Alignment</h5>
                <p class="card-title-desc">Change the alignment of pagination
                    components with flexbox utilities.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <ngb-pagination [collectionSize]="30" [(page)]="alignpage1" aria-label="Custom pagination"
                    class="pagination justify-content-center">
                    <ng-template ngbPaginationPrevious>Previous</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>

                <ngb-pagination [collectionSize]="30" [(page)]="alignpage2" aria-label="Custom pagination"
                    class="pagination justify-content-end mb-0">
                    <ng-template ngbPaginationPrevious>Previous</ng-template>
                    <ng-template ngbPaginationNext>Next</ng-template>
                </ngb-pagination>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Border Spinner</h4>
                <p class="card-title-desc">Use the border spinners for a lightweight loading indicator.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div>
                    <div class="spinner-border text-primary m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-border text-secondary m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-border text-success m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-border text-info m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-border text-warning m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-border text-danger m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-border text-dark m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-xl-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Growing Spinner</h4>
                <p class="card-title-desc">If you don’t fancy a border spinner, switch to the grow spinner. While it
                    doesn’t technically spin, it does repeatedly grow!</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div>
                    <div class="spinner-grow text-primary m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-secondary m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-success m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-info m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-danger m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-dark m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->