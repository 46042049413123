import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {


  breadCrumbItems!: Array<{}>;

  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Apps' },
      { label: 'Customers' },
      { label: 'Accepted customers', active: true }];
  }
}
