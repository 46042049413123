import { formatNumber } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { EChartsOption } from 'echarts/types/dist/echarts';
import { Observable } from 'rxjs/internal/Observable';
import { Highlight } from 'src/app/classes/Highlight';
import { Ownership } from 'src/app/classes/Ownership';
import { Usage } from 'src/app/classes/Usage';
import { WizarService } from 'src/app/core/services/wizard.service';
import Swal from 'sweetalert2';
import { BreakDownDialogComponent } from './break-down-dialog/break-down-dialog.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
  concat,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
  tap,
} from 'rxjs/operators';
import { RemoveNdaService } from 'src/app/core/services/removeNda.service';
import { AcceptedFileTypeService } from 'src/app/core/services/acceptedFileType.service';
import { MyUploadAdapter } from 'src/app/classes/myUploadAdapter';
import { GoogleMapComponent } from 'src/app/shared/google-map/google-map.component';
import { ShowPinOnMapService } from 'src/app/core/services/showPinOnMap.service ';
import { GeocoderService } from 'src/app/core/services/geocoder.service';

@Component({
  selector: 'app-onboarding-wizard',
  templateUrl: './onboarding-wizard.component.html',
  styleUrls: ['./onboarding-wizard.component.scss'],
})
export class OnboardingWizardComponent implements OnInit {
  /*@ViewChild('map')
  public mapElement!: HereMapComponent;

  @ViewChild('mapOverview')
  public overviewMap!: HereMapComponent;*/
  
  @ViewChild('googleMap')
  public mapElement!: GoogleMapComponent;

  @ViewChild('googleMapOverview')
  public overviewMap!: GoogleMapComponent;

  @ViewChild('ngSelectComponent') ngSelectComponent!: NgSelectComponent;

  @ViewChild('myEditor') myEditor: any;

  @ViewChild('filterCity') filterCity!: ElementRef;

  breadCrumbItems!: Array<{}>;
  editorConfig  = {
    placeholder: 'Type the content here!',
    
  };
  public Editor = ClassicEditor;
  public separator: string = ',';

  galleryOptions!: NgxGalleryOptions[];
  galleryImages!: NgxGalleryImage[];
  curretnYear!: number;
  yearsArr: string[] = [];
  breakOptionsSum: number = 0;
  leasEndSum: number = 0;
  tenantTotalSum: number = 0;
  extensionOptionsSum: number = 0;
  secondStepForm!: FormGroup;
  thirdStepForm!: FormGroup;
  rentForm!: FormGroup;
  topFiveValue: number = 0;
  othersValue: number = 0;
  dynamicData: any = {};
  dynamicLeasDataChar: any = {};
  debtDynamic: any = {};
  areaBreakdownDonughnutChartDynamic: any = {};
  rentBreakDownDonughnutChartDynamic: any = {};
  propertyDetailsForm!: FormGroup;
  usages!: Array<Usage>;
  ownerships!: Array<Ownership>;
  highlights!: Array<Highlight>;
  listOfImagesName: Array<any> = [];
  debtSituationForm!: FormGroup;
  debtBreakDownForm!: FormGroup;
  ltv: number = 0;
  rentSum!: number;
  lettableAreaSum!: number;
  grossInitialYield!: number;
  formsubmit: boolean = false;
  secondStepSubmit: boolean = false;
  mapCoordinates: any = {};
  highlightsForPreview: any = [];
  leasDataForHttp: any = {};
  dataUploaded: boolean = false;
  openModalVar: boolean = false;
  keyUpSubscription!: Subscription;
  searchCitiesHttp$!: Observable<any>;
  postalCodesHttp$!: Observable<any>;
  citiesInput$ = new Subject<string>();
  loadingCities: boolean = false;

  cityImageUrl!: string;
  listOfImages: any = [];
  filesTypesSubtypesList: any = {
    ndaFiles: [],
    cadastralMapsFiles: [],
    landRegistrationExcerptFiles: [],
    contaminationRegisterExFiles: [],
    buildingPermitFiles: [],
    floorSpaceTableFiles: [],
    curvatureAnalysisFiles: [],
    floorPlanFiles: [],
    objectDescriptionFiles: [],
    rentRollFiles: [],
    sampleRentalAgreementFiles: [],
    analysisOfAncillaryCostsFiles: [],
    valuationReportFiles: [],
    annualAccountsFiles: [],
    techDueDiligenceFiles: [],
    insuranceAgreementFiles: [],
    organizationStructureFiles: [],
    legalAndRegulatoryFiles: [],
    otherFiles: [],
  };

  fourthStepForm!: FormGroup;
  fourthStepSubmit!: Boolean;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private wizardService: WizarService,
    private modalService: NgbModal,
    private removeNdaService: RemoveNdaService,
    private acceptedFileTypeService: AcceptedFileTypeService, 
    private showPinOnMapService: ShowPinOnMapService,
    private geocoderService: GeocoderService
  ) {
    this.curretnYear = new Date().getFullYear();
    for (let index = 0; index < 11; index++) {
      let temp = this.curretnYear + index;
      this.yearsArr.push(temp.toString());
    }

    // this.yearsArr.unshift('unlimited');
    this.yearsArr[this.yearsArr.length - 1] =
      '>' + this.yearsArr[this.yearsArr.length - 1];
    this.yearsArr.push('unlimited');
  }

  searchCityFromDB() {
    this.searchCitiesHttp$ = concat(
      of([]),
      this.citiesInput$.pipe(
        filter((res) => {
          return res !== null && res !== '';
        }),
        debounceTime(1000),
        distinctUntilChanged(),
        tap(() => (this.loadingCities = true)),
        switchMap((term) => {
          return this.wizardService.searchCity(term).pipe(
            catchError(() => of([])),
            tap(() => (this.loadingCities = false))
          );
        })
      )
    );
  }

  selectedCity() {
    if (this.propertyDetailsForm.get('city')?.value) {
      this.postalCodesHttp$ = this.wizardService.searchPostalCodeByCityName(
        this.propertyDetailsForm.get('city')?.value
      );
      this.propertyDetailsForm.get('postalCode')?.enable();
    } else {
      this.propertyDetailsForm.get('postalCode')?.disable();
    }
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.dataUploaded) {
      return true;
    } else {
      return false;
    }
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Asset onbording' },
      { label: 'Asset onbording wizard', active: true },
    ];

    const rentFormVar = (this.rentForm = this.fb.group({
      topFive: [0, [Validators.required]],
      others: [0, [Validators.required, Validators.min(0)]],
      rentTotal: [],
    }));
    this.secondStepForm = this.fb.group({
      rentData: rentFormVar,
      firstTenant: ['', [Validators.required]],
      secondTenant: ['', [Validators.required]],
      thirdTenant: ['', [Validators.required]],
      fourthTenant: ['', [Validators.required]],
      fifthTenant: ['', [Validators.required]],
      leasData: this.fb.array([]),
    });

    for (let index = 0; index < this.yearsArr.length; index++) {
      this.addRow();
    }

    this.topFive?.valueChanges.subscribe((value) => {
      if (value) {
        this.topFiveValue = value;
      } else {
        this.topFiveValue = 0;
      }
      this.othersValue = this.rentSum - this.topFiveValue;
      this.others?.setValue(this.othersValue);
      this.updateChart();
    });

    this.leasDataForm.valueChanges.subscribe((value) => {
      let sumBreakOptions = 0;
      let sumLeasEnd = 0;
      let sumExtensionOptions = 0;
      for (var key of value) {
        if (key.breakOptionsControl && key.breakOptionsControl != 0) {
          sumBreakOptions += key.breakOptionsControl;
        }
        if (key.leasEndControl && key.leasEndControl != 0) {
          sumLeasEnd += key.leasEndControl;
        }
        if (key.extensionOptionsControl && key.extensionOptionsControl != 0) {
          sumExtensionOptions += key.extensionOptionsControl;
        }
      }

      this.breakOptionsSum = sumBreakOptions * 1;
      this.leasEndSum = sumLeasEnd * 1;
      this.extensionOptionsSum = sumExtensionOptions * 1;
    });

    //third step forms
    const debtSituationFormVar = (this.debtSituationForm = this.fb.group({
      grossAssetValue: [, [Validators.required, Validators.min(0)]],
      totalDebt: [, [Validators.required, Validators.min(0)]],
      debtOutstanding: [, [Validators.required, Validators.min(0)]],
      debtService: [, [Validators.required, Validators.min(0)]],
    }));

    const debtBreakDownFormVar = (this.debtBreakDownForm = this.fb.group({
      floating: [, [Validators.required, Validators.min(0)]],
      ltOneYear: [, [Validators.required, Validators.min(0)]],
      mtOneYear: [, [Validators.required, Validators.min(0)]],
    }));

    this.thirdStepForm = this.fb.group({
      debtSituation: debtSituationFormVar,
      debtBreakDown: debtBreakDownFormVar,
    });

    this.debtSituationForm.valueChanges.subscribe((value) => {
      let totalGrossAssetValue = 0;
      let totalDebtOutStanding = 0;

      for (var key in value) {
        if (value.hasOwnProperty(key)) {
          if (key === 'grossAssetValue') {
            totalGrossAssetValue = value[key];
          } else if (key === 'debtOutstanding') {
            totalDebtOutStanding = value[key];
          }
        }
      }
      if (totalGrossAssetValue !== 0) {
        this.ltv = (totalDebtOutStanding / totalGrossAssetValue) * 100;
      }
    });

    this.galleryOptions = [
      {
        width: '100%',
        height: '800px',
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      },
    ];
    this.galleryImages = [];
    this.propertyDetailsForm = this.fb.group({
      title: ['', [Validators.required]],
      street: ['', [Validators.required]],
      streetNumber: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postalCode: [{ value: '', disabled: true }, [Validators.required]],
      country: ['', [Validators.required]],
      description: ['', [Validators.required]],
      construction_year: ['', [Validators.required]],
      refurbishment_year: [''],
      multiUsageOption: ['', [Validators.required]],
      multiUsageOptionText: ['', [Validators.required]],
      ownershipOption: ['', Validators.required],
      highlightOption: this.fb.array([], Validators.required),
      plot_size: ['', Validators.required],
      parking_lots: ['', Validators.required],
      occupancy_rate: [
        '',
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      walt: ['', Validators.required],
      images: [null, Validators.required],
      video: [null],
      cityImage: [null, Validators.required],
      areaBreakdown: this.fb.array([], Validators.required),
      rentBreakdown: this.fb.array([], Validators.required),
      location_description: ['', Validators.required],
      socialEconomics: this.fb.group({
        socialEconomicsCities: this.fb.array([]),
        socialEconomicsMunicipalities: this.fb.array([]),
        socialEconomicsStates: this.fb.array([]),
      }),
      map: ['', [Validators.required]],
      listOfImagesName: [{ value: '', disabled: true }, [Validators.required]],
      highlightSection: ['',],
      enabled: [true, Validators.required]
    });

    this.getAllUsages();
    this.getAllOwnerships();
    this.getAllHighlights();
    this.searchCityFromDB();

    this.propertyDetailsForm.get('multiUsageOption')?.valueChanges.subscribe((values) => {
      let oldArea = this.propertyDetailsForm.get(
        'areaBreakdown'
      ) as FormArray;
      let oldRent = this.propertyDetailsForm.get(
        'rentBreakdown'
      ) as FormArray;
      let newArea = this.fb.array([]);
      let newRent = this.fb.array([]);
      for (const value of values) {
        let numAreaValue = undefined;
        for (let odlAreaValue of oldArea.value) {
          if (odlAreaValue.description == value.description) {
            numAreaValue = odlAreaValue.value;
            break;
          }
        }
        let numRentValue = undefined;
        for (let odlRentValue of oldRent.value) {
          if (odlRentValue.description == value.description) {
            numRentValue = odlRentValue.value;
            break;
          }
        }
        const areaBreakDownRow = this.fb.group({
          description: [
            value.description,
            Validators.required,
          ],
          id: [value.id, Validators.required],
          value: [
            numAreaValue,
            [Validators.required, Validators.min(0)],
          ],
        });
        const rentBreakDownRow = this.fb.group({
          description: [
            value.description,
            Validators.required,
          ],
          id: [value.id, Validators.required],
          value: [
            numRentValue,
            [Validators.required, Validators.min(0)],
          ],
        });
        newArea.push(areaBreakDownRow);
        newRent.push(rentBreakDownRow);
      }
      let formArray = this.propertyDetailsForm.get(
        'areaBreakdown'
      ) as FormArray;
      this.clearFormArray(formArray);
      for (let area of newArea.controls) {
        formArray.push(area);
      }
      //for rent
      let formArrayRent = this.propertyDetailsForm.get(
        'rentBreakdown'
      ) as FormArray;
      this.clearFormArray(formArrayRent);
      for (let rent of newRent.controls) {
        formArrayRent.push(rent);
      }
    });
    this.fourthStepForm = this.fb.group({
      nda: [false, Validators.requiredTrue]
    })
    this.fourthStepSubmit = false;
    this.removeNdaService.removeNdaEvent
    .subscribe(() => {
      this.fourthStepForm.get('nda')?.setValue(false);
    });
  }

  get topFive() {
    return this.rentForm.get('topFive');
  }
  get others() {
    return this.rentForm.get('others');
  }
  get rentTotal() {
    return this.rentForm.get('rentTotal');
  }
  get firstTenant() {
    return this.secondStepForm.get('firstTenant');
  }
  get secondTenant() {
    return this.secondStepForm.get('secondTenant');
  }
  get thirdTenant() {
    return this.secondStepForm.get('thirdTenant');
  }
  get fourthTenant() {
    return this.secondStepForm.get('fourthTenant');
  }
  get fifthTenant() {
    return this.secondStepForm.get('fifthTenant');
  }
  get leasDataForm() {
    return this.secondStepForm.get('leasData') as FormArray;
  }
  //---------------------third step form---------------------
  get grossAssetValue() {
    return this.debtSituationForm.get('grossAssetValue');
  }
  get totalDebt() {
    return this.debtSituationForm.get('totalDebt');
  }
  get debtOutstanding() {
    return this.debtSituationForm.get('debtOutstanding');
  }
  get debtService() {
    return this.debtSituationForm.get('debtService');
  }
  get floating() {
    return this.debtBreakDownForm.get('floating');
  }
  get ltOneYear() {
    return this.debtBreakDownForm.get('ltOneYear');
  }
  get mtOneYear() {
    return this.debtBreakDownForm.get('mtOneYear');
  }
  get fourthStepFormData() {
    return this.fourthStepForm.controls;
  }
  get socialEconomics() {
    return this.propertyDetailsForm.get('socialEconomics') as FormGroup;
  }
  get socialEconomicsCities(){
    return this.socialEconomics.get('socialEconomicsCities') as FormArray
  }
  get socialEconomicsMunicipalities(){
    return this.socialEconomics.get('socialEconomicsMunicipalities') as FormArray
  }
  get socialEconomicsStates(){
    return this.socialEconomics.get('socialEconomicsStates') as FormArray
  }

  displayAllForms() {
    this.rentSum = 0;
    this.lettableAreaSum = 0;

    this.setAreaBreakDownChartPreview();

    this.setRentBreakDownChartPreview();
    //leas data chart
    this.setLeasData();

    this.setDebtDataPreview();

    this.setMapInformationOverview();

    this.setImagesForPreview();

    this.setHighLightsForPreview();
  }
  setAreaBreakDownChartPreview() {
    let areaBreakDownArray: any[] = [];
    let fromAreaBreakDownArray = this.propertyDetailsForm.get(
      'areaBreakdown'
    ) as FormArray;
    for (let i = 0; i < fromAreaBreakDownArray.controls.length; i++) {
      let breakdownDesc =
        fromAreaBreakDownArray.controls[i].get('description')?.value;
      let breakdownValue =
        fromAreaBreakDownArray.controls[i].get('value')?.value;
      this.lettableAreaSum += breakdownValue * 1;
      areaBreakDownArray.push([breakdownDesc, breakdownValue]);
    }
    areaBreakDownArray.sort((a, b) => {
      return b[1] - a[1];
    });

    this.areaBreakdownDonughnutChartDynamic = this.areaBreakdownDonughnutChart;

    this.areaBreakdownDonughnutChartDynamic.series.data = [];
    for (const iterator of areaBreakDownArray) {
      this.areaBreakdownDonughnutChartDynamic?.series?.data.push({
        value: parseFloat(iterator[1]),
        name: iterator[0],
      });
    }
    this.areaBreakdownDonughnutChartDynamic.title = {
      text: formatNumber(this.lettableAreaSum, 'en-US', '1.0-0') + ' m²',
      left: 'center',
      top: 'center',
    };
    this.areaBreakdownDonughnutChart = {
      ...this.areaBreakdownDonughnutChart,
      ...this.areaBreakdownDonughnutChartDynamic,
    };
  }
  setRentBreakDownChartPreview() {
    let rentBreakDownArray: any[] = [];
    let fromRentBreakdownArray = this.propertyDetailsForm.get(
      'rentBreakdown'
    ) as FormArray;
    for (let i = 0; i < fromRentBreakdownArray.controls.length; i++) {
      let breakdownDesc =
        fromRentBreakdownArray.controls[i].get('description')?.value;
      let breakdownValue =
        fromRentBreakdownArray.controls[i].get('value')?.value;
      this.rentSum += breakdownValue * 1;
      rentBreakDownArray.push([breakdownDesc, breakdownValue]);
    }
    rentBreakDownArray.sort((a, b) => {
      return b[1] - a[1];
    });
    this.rentBreakDownDonughnutChartDynamic = this.rentBrakDownDonughnutChart;

    this.rentBreakDownDonughnutChartDynamic.series.data = [];
    for (const iterator of rentBreakDownArray) {
      this.rentBreakDownDonughnutChartDynamic?.series?.data.push({
        value: parseFloat(iterator[1]),
        name: iterator[0],
      });
    }
    this.rentBreakDownDonughnutChartDynamic.title = {
      text: '€' + formatNumber(this.rentSum, 'en-US', '1.0-0') + ' p.a.',
      left: 'center',
      top: 'center',
    };
    this.rentBrakDownDonughnutChart = {
      ...this.rentBrakDownDonughnutChart,
      ...this.rentBreakDownDonughnutChartDynamic,
    };

    this.grossInitialYield =
      (this.rentSum /
        parseFloat(this.thirdStepForm?.value?.debtSituation?.grossAssetValue)) *
      100;
  }
  setDebtDataPreview() {
    this.debtDynamic = this.debtBreakdownBarChart;
    this.debtDynamic.series = {
      type: 'bar',
      data: Object.values(this.thirdStepForm.value.debtBreakDown),
    };

    this.debtBreakdownBarChart = {
      ...this.debtBreakdownBarChart,
      ...this.debtDynamic,
    };
  }
  async setMapInformationOverview() {
    this.mapCoordinates = {
      mapInfo: [],
    };
    if (this.mapElement.listOfMarkers) {
      for (const iterator of this.mapElement.listOfMarkers) {
        this.overviewMap.drowMarker({
          lat:iterator.lat,
          lng: iterator.lng
        });
        this.overviewMap.flyTo(iterator.lat, iterator.lng);
        let place_id = (await this.geocoderService.getPlaceIdByLatLng(iterator.lat, iterator.lng)).results[0].place_id;
        let markerTemp = {
          type: google.maps.drawing.OverlayType.MARKER,
          coordinates: [
            {
              lat: iterator.lat,
              lng: iterator.lng
            }
          ],
          placeId: place_id
        }
        this.mapCoordinates.mapInfo.push(markerTemp);
      }
    }
    if (this.mapElement.listOfPolygons) {
      for (const polygon of this.mapElement.listOfPolygons) {
        let i= 0;
        this.overviewMap.drowPolygon(polygon);
        let coordinatesPlaceholder: any = [];
        for (const iterator of polygon) {
          coordinatesPlaceholder.push({
            lat: iterator.lat,
            lng: iterator.lng,
            orderNumber: i++
          });
        }
        let place_id = (await this.geocoderService.getPlaceIdByBound(polygon)).results[0].place_id;
        let tempPolygon = {
          type: google.maps.drawing.OverlayType.POLYGON,
          coordinates: coordinatesPlaceholder,
          placeId: place_id
        };
        this.mapCoordinates.mapInfo.push(tempPolygon);
      }
    }
  }
  setLeasData() {
    let localArray = [];
    localArray.push([
      'Year',
      'Lease end incl. Break option',
      'Lease End',
      'Lease end incl. extension option',
    ]);
    for (const value of this.secondStepForm.value.leasData) {
      let array = Object.values(value);
      let index = this.secondStepForm.value.leasData.indexOf(value);
      array.unshift(this.yearsArr[index * 1]);
      localArray.push(array);
    }
    this.leasDataForHttp = {
      source: [],
    };
    this.leasDataForHttp.source = localArray;
    this.dynamicLeasDataChar = this.barChartLeas;
    this.dynamicLeasDataChar.dataset = {
      source: localArray,
    };
    this.barChartLeas = { ...this.barChartLeas, ...this.dynamicLeasDataChar };
  }
  setImagesForPreview() {
    for (const iterator of this.propertyDetailsForm.value.images) {
      let imgObject = {
        small: URL.createObjectURL(iterator),
        medium: URL.createObjectURL(iterator),
        big: URL.createObjectURL(iterator),
      };
      this.galleryImages.push(imgObject);
    }
  }
  setHighLightsForPreview() {
    this.highlightsForPreview = [];
    let formHighlightsArray = this.propertyDetailsForm.get(
      'highlightOption'
    ) as FormArray;
    for (let highlight of formHighlightsArray.controls) {
      this.highlightsForPreview.push({
        pictureName: highlight.get('pictureName')?.value,
        highlightDescription: highlight.get('highlightDescription')?.value,
        orderNumber: highlight.get('orderNumber')?.value
      });
    }

    this.highlightsForPreview.sort(function (first: any, second: any) {
      let a:any = first;
      let b:any = second;
      return a.orderNumber - b.orderNumber;
    });
  }

  onFileSelected(event: any, subtype: String) {
    this.clearOldList(subtype);
    let validation = this.acceptedFileTypeService.validateFileTypes(event.target.files);
    if (validation.valid == false) {
      let message = 'Unsuported file(s): ' + validation.files.toString();
      Swal.fire({
        title: 'Incorrect...',
        text: message,
        icon: 'error',
        confirmButtonColor: '#c33434',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'OK',
      }).then((result) => {
        
      });
      return;
    }
    for (let file of event.target.files) {
      switch (subtype) {
        case 'ndaFiles':
          this.filesTypesSubtypesList.ndaFiles.push({
            file: file,
            name: file.name,
            subtype: 19,
          });
          this.fourthStepForm.get('nda')?.setValue(true);
          break;
        case 'cadastralMaps':
          this.filesTypesSubtypesList.cadastralMapsFiles.push({
            file: file,
            name: file.name,
            subtype: 1,
          });
          break;
        case 'landRegistrationExcerpt':
          this.filesTypesSubtypesList.landRegistrationExcerptFiles.push({
            file: file,
            name: file.name,
            subtype: 2,
          });
          break;
        case 'contaminationRegisterEx':
          this.filesTypesSubtypesList.contaminationRegisterExFiles.push({
            file: file,
            name: file.name,
            subtype: 3,
          });
          break;
        case 'buildingPermit':
          this.filesTypesSubtypesList.buildingPermitFiles.push({
            file: file,
            name: file.name,
            subtype: 4,
          });
          break;
        case 'floorSpaceTable':
          this.filesTypesSubtypesList.floorSpaceTableFiles.push({
            file: file,
            name: file.name,
            subtype: 5,
          });
          break;
        case 'curvatureAnalysis':
          this.filesTypesSubtypesList.curvatureAnalysisFiles.push({
            file: file,
            name: file.name,
            subtype: 6,
          });
          break;
        case 'floorPlan':
          this.filesTypesSubtypesList.floorPlanFiles.push({
            file: file,
            name: file.name,
            subtype: 7,
          });
          break;
        case 'objectDescription':
          this.filesTypesSubtypesList.objectDescriptionFiles.push({
            file: file,
            name: file.name,
            subtype: 8,
          });
          break;
        case 'rentRoll':
          this.filesTypesSubtypesList.rentRollFiles.push({
            file: file,
            name: file.name,
            subtype: 9,
          });
          break;
        case 'sampleRentalAgreement':
          this.filesTypesSubtypesList.sampleRentalAgreementFiles.push({
            file: file,
            name: file.name,
            subtype: 10,
          });
          break;
        case 'analysisOfAncillaryCosts':
          this.filesTypesSubtypesList.analysisOfAncillaryCostsFiles.push({
            file: file,
            name: file.name,
            subtype: 11,
          });
          break;
        case 'valuationReport':
          this.filesTypesSubtypesList.valuationReportFiles.push({
            file: file,
            name: file.name,
            subtype: 12,
          });
          break;
        case 'annualAccounts':
          this.filesTypesSubtypesList.annualAccountsFiles.push({
            file: file,
            name: file.name,
            subtype: 13,
          });
          break;
        case 'techDueDiligence':
          this.filesTypesSubtypesList.techDueDiligenceFiles.push({
            file: file,
            name: file.name,
            subtype: 14,
          });
          break;
        case 'insuranceAgreement':
          this.filesTypesSubtypesList.insuranceAgreementFiles.push({
            file: file,
            name: file.name,
            subtype: 15,
          });
          break;
        case 'organizationStructure':
          this.filesTypesSubtypesList.organizationStructureFiles.push({
            file: file,
            name: file.name,
            subtype: 16,
          });
          break;
        case 'legalAndRegulatory':
          this.filesTypesSubtypesList.legalAndRegulatoryFiles.push({
            file: file,
            name: file.name,
            subtype: 18,
          });
          break;
        case 'other':
          this.filesTypesSubtypesList.otherFiles.push({
            file: file,
            name: file.name,
            subtype: 17,
          });
          break;
      }
    }
  }

  validateMap(value: any) {
    if (value) {
      this.propertyDetailsFormData.map.setValue('ok');
    } else {
      this.propertyDetailsFormData.map.setValue(null);
    }
  }

  clearOldList(subtype: String) {
    switch (subtype) {
      case 'ndaFiles':
        this.filesTypesSubtypesList.ndaFiles = [];
        this.fourthStepForm.get('nda')?.setValue(false);
        break;
      case 'cadastralMaps':
        this.filesTypesSubtypesList.cadastralMapsFiles = [];
        break;
      case 'landRegistrationExcerpt':
        this.filesTypesSubtypesList.landRegistrationExcerptFiles = [];
        break;
      case 'contaminationRegisterEx':
        this.filesTypesSubtypesList.contaminationRegisterExFiles = [];
        break;
      case 'buildingPermit':
        this.filesTypesSubtypesList.buildingPermitFiles = [];
        break;
      case 'floorSpaceTable':
        this.filesTypesSubtypesList.floorSpaceTableFiles = [];
        break;
      case 'curvatureAnalysis':
        this.filesTypesSubtypesList.curvatureAnalysisFiles = [];
        break;
      case 'floorPlan':
        this.filesTypesSubtypesList.floorPlanFiles = [];
        break;
      case 'objectDescription':
        this.filesTypesSubtypesList.objectDescriptionFiles = [];
        break;
      case 'rentRoll':
        this.filesTypesSubtypesList.rentRollFiles = [];
        break;
      case 'sampleRentalAgreement':
        this.filesTypesSubtypesList.sampleRentalAgreementFiles = [];
        break;
      case 'analysisOfAncillaryCosts':
        this.filesTypesSubtypesList.analysisOfAncillaryCostsFiles = [];
        break;
      case 'valuationReport':
        this.filesTypesSubtypesList.valuationReportFiles = [];
        break;
      case 'annualAccounts':
        this.filesTypesSubtypesList.annualAccountsFiles = [];
        break;
      case 'techDueDiligence':
        this.filesTypesSubtypesList.techDueDiligenceFiles = [];
        break;
      case 'insuranceAgreement':
        this.filesTypesSubtypesList.insuranceAgreementFiles = [];
        break;
      case 'organizationStructure':
        this.filesTypesSubtypesList.organizationStructureFiles = [];
        break;
      case 'legalAndRegulatory':
        this.filesTypesSubtypesList.legalAndRegulatoryFiles = [];
        break;
      case 'other':
        this.filesTypesSubtypesList.otherFiles = [];
        break;
    }
  }

  addRow() {
    const leasDataRow = this.fb.group({
      breakOptionsControl: [0, Validators.required],
      leasEndControl: [0, Validators.required],
      extensionOptionsControl: [0, Validators.required],
    });
    this.leasDataForm.push(leasDataRow);
  }

  donughnutChart: EChartsOption = {
    tooltip: { trigger: 'item', formatter: '{a} <br/>{b}: {c} ({d}%)' },
    legend: {
      orient: 'vertical',
      bottom: '0%',
      left: 'right',
    },
    color: ['#33aeb1', '#2a8d8f'],
    series: {
      name: 'Rent percentage',
      type: 'pie',
      radius: ['40%', '80%'],
    },
  };
  barChartLeas: EChartsOption = {
    legend: {
      bottom: '0%',
    },
    tooltip: {},
    dataset: {
      source: [],
    },
    color: ['#7ed4d6','#33aeb1', '#2a8d8f'],
    xAxis: { type: 'category' },
    yAxis: {
      type: 'value',
      position: 'left',
      axisLabel: {
        formatter: function (value: any) {
          return '€' + value / 1000 + 'k';
        },
      },
    },
    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
  };
  debtBreakdownBarChart: EChartsOption = {
    xAxis: {
      type: 'category',
      data: ['floating', '<1 year', '>1 year'],
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: function (value: any) {
          return '€' + value;
        },
      },
    },
    series: [
      {
        data: [],
        type: 'bar',
      },
    ],
  };
  areaBreakdownDonughnutChart: EChartsOption = {
    tooltip: { trigger: 'item', formatter: '{a} <br/>{b}: {c} ({d}%)' },
    legend: {
      orient: 'vertical',
      bottom: '0%',
      left: 'right',
    },
    color: ['#1181A4', '#064659'],
    series: {
      name: 'Area Breakdown',
      type: 'pie',
      radius: ['50%', '90%'],
      avoidLabelOverlap: false,
      label: {
        show: true,
        formatter: '{d}%',
        position: 'inside',
        color: 'white',
      },
      labelLine: {
        show: false,
      },
      data: [],
      color: [
        '#71BCD3',
        '#677FCA',
        '#9E76CA',
        '#C276CC',
        '#13549D',
        '#FBB162',
        '#2FE1B9',
      ],
    },
  };
  rentBrakDownDonughnutChart: EChartsOption = {
    tooltip: { trigger: 'item', formatter: '{a} <br/>{b}: {c} ({d}%)' },
    title: {
      text: '€702,847 p.a.',
      left: 'center',
      top: 'center',
    },

    legend: {
      orient: 'vertical',
      bottom: '0%',
      left: 'right',
    },
    color: ['#1181A4', '#064659'],
    series: {
      name: 'Rent Breakdown',
      type: 'pie',
      radius: ['50%', '90%'],
      avoidLabelOverlap: false,
      label: {
        show: true,
        formatter: '{d}%',
        position: 'inside',
        color: 'white',
      },
      labelLine: {
        show: false,
      },
      data: [],
      color: [
        '#71BCD3',
        '#677FCA',
        '#9E76CA',
        '#C276CC',
        '#13549D',
        '#FBB162',
        '#2FE1B9',
      ],
    },
  };

  images: File[] = [];
  video!: File | null;

  onSelect(event: any) {
    for (const addedMedia of event.addedFiles) {
      let containsFile = false;
      for (const iterator of this.images) {
        if (iterator.name === addedMedia.name) {
          containsFile = true;
          break;
        }
      }
      if (this.video != null && this.video.name === addedMedia.name) {
        containsFile = true;
        break;
      }
      if (!containsFile) {
        if (addedMedia.type === "video/mp4") {
          this.video = addedMedia;
          this.propertyDetailsForm.get('video')?.setValue(this.video);
        } else {
          this.images.push(addedMedia);
          this.propertyDetailsForm.get('listOfImagesName')?.enable();
          this.listOfImagesName = [
            ...this.listOfImagesName,
            { id: addedMedia.lastModified, value: addedMedia.name },
          ];
          this.propertyDetailsForm.get('images')?.setValue(this.images);
        }
      }
    }
  }

  onRemove(event: any) {
    if (event.type === "video/mp4") {
      this.video = null;
      this.propertyDetailsForm.get('video')?.setValue(this.video);
    } else {
      this.images.splice(this.images.indexOf(event), 1);
      for (const iterator of this.listOfImagesName) {
        if (iterator.value === event.name) {
          let selected = this.ngSelectComponent.selectedValues[0] as any;
          if (selected.value == iterator.value) {
            this.ngSelectComponent.clearModel();
          }
          this.listOfImagesName.splice(this.listOfImagesName.indexOf(iterator), 1);
        }
      }
      this.listOfImagesName = [...this.listOfImagesName];
      if (this.listOfImagesName.length === 0) {
        this.propertyDetailsForm.get('listOfImagesName')?.disable();
      }
      this.propertyDetailsForm.get('images')?.setValue(this.images);
    }
  }

  submitAsset() {
    let asset: any = {};

    asset.enabled = this.propertyDetailsForm.value.enabled ?? false;
    asset.title = this.propertyDetailsForm.value.title ?? '';
    asset.constructionYear =
      this.propertyDetailsForm?.value?.construction_year ?? 0;
    asset.lastRefurbish =
      this.propertyDetailsForm?.value?.refurbishment_year ?? 0;
    asset.grossValue =
      this.thirdStepForm?.value?.debtSituation?.grossAssetValue ?? 0;
    asset.description = this.propertyDetailsForm?.value?.description ?? '';
    asset.plotSize = this.propertyDetailsForm?.value?.plot_size ?? 0;
    asset.assetUsageText = this.propertyDetailsForm?.value?.multiUsageOptionText ?? '';
    asset.debt = this.thirdStepForm?.value?.debtSituation?.totalDebt ?? 0;
    asset.debtBreakdown = {
      floating: this.thirdStepForm?.value?.debtBreakDown?.floating ?? 0,
      grYear: this.thirdStepForm?.value?.debtBreakDown?.mtOneYear ?? 0,
      ltYear: this.thirdStepForm?.value?.debtBreakDown?.ltOneYear ?? 0,
    };
    asset.debtService =
      this.thirdStepForm?.value?.debtSituation?.debtService ?? 0;
    asset.debtOutstanding =
      this.thirdStepForm?.value?.debtSituation?.debtOutstanding ?? 0;
    asset.netRentalIncome = this.rentSum ?? 0;
    asset.occupancyRate = this.propertyDetailsForm?.value?.occupancy_rate ?? 0;
    asset.numberOfParkingLots =
      this.propertyDetailsForm?.value?.parking_lots ?? 0;
    asset.walt = this.propertyDetailsForm?.value?.walt ?? 0;
    asset.lettableArea = this.lettableAreaSum ?? 0;
    asset.tenantValue = {
      othersValue: this.secondStepForm?.value?.rentData?.others ?? 0,
      top5Value: this.secondStepForm?.value?.rentData?.topFive ?? 0,
    };
    asset.ownership = {
      description:
        this.propertyDetailsForm?.value?.ownershipOption?.description ?? '',
      id: this.propertyDetailsForm?.value?.ownershipOption?.id ?? 0,
    };

    asset.address = {
      city: this.propertyDetailsForm?.value?.city ?? '',
      postalCode: this.propertyDetailsForm?.value?.postalCode ?? '',
      street: this.propertyDetailsForm?.value?.street ?? '',
      streetNumber: this.propertyDetailsForm?.value?.streetNumber ?? '', 
      country: this.propertyDetailsForm?.value?.country ?? ''     
    };
    asset.assetTenants = [
      {
        orderValue: 1,
        tenantName: this.secondStepForm?.value?.firstTenant ?? '',
      },
      {
        orderValue: 2,
        tenantName: this.secondStepForm?.value?.secondTenant ?? '',
      },
      {
        orderValue: 3,
        tenantName: this.secondStepForm?.value?.thirdTenant ?? '',
      },
      {
        orderValue: 4,
        tenantName: this.secondStepForm?.value?.fourthTenant ?? '',
      },
      {
        orderValue: 5,
        tenantName: this.secondStepForm?.value?.fifthTenant ?? '',
      },
    ];
    let socialEconomicsForHttp: any[] = [];

    this.addSocialEconomicsForHttp(this.socialEconomicsCities,socialEconomicsForHttp);
    this.addSocialEconomicsForHttp(this.socialEconomicsMunicipalities,socialEconomicsForHttp);
    this.addSocialEconomicsForHttp(this.socialEconomicsStates,socialEconomicsForHttp);
    asset.location = {
      locationSocialEconomics: socialEconomicsForHttp,
    };
    asset.leaseExpiryProfile = {
      leaseData: this.leasDataForHttp,
    };
    asset.locationMapDetails = this.mapCoordinates;
    asset.locationDescription = this.propertyDetailsForm?.value?.location_description ?? '';
    let highLightsForHttp = [];
    for (const highPrev of this.highlightsForPreview) {
      for (const iterator of this.highlights) {
        if (highPrev.pictureName === iterator.pictureName) {
          highLightsForHttp.push({
            description: highPrev.highlightDescription,
            highlight: {
              id: iterator.id,
              pictureName: iterator.pictureName,
            },
            orderNumber: highPrev.orderNumber
          });
          break;
        }
      }
    }
    asset.assetHighlights = highLightsForHttp;
    let assetUsagesForHttp: any = [];
    let areaBreakDown = this.propertyDetailsForm.get(
      'areaBreakdown'
    ) as FormArray;
    let rentBreakDown = this.propertyDetailsForm.get(
      'rentBreakdown'
    ) as FormArray;

    for (const iterator of areaBreakDown.value) {
      let assetUsageObject = {};
      for (const rentIterator of rentBreakDown.value) {
        if (iterator.id === rentIterator.id) {
          assetUsageObject = {
            areaBreakdownValue: iterator.value,
            rentBreakdownValue: rentIterator.value,
            usage: {
              description: iterator.description,
              id: iterator.id,
            },
          };
          assetUsagesForHttp.push(assetUsageObject);
        }
      }
    }
    asset.assetUsages = assetUsagesForHttp;
    asset.highlightSection = this.propertyDetailsForm.value.highlightSection ?? '';
    Swal.fire({
      title: 'Uploading asset data...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      text: '',
      didOpen: () => {
        Swal.showLoading();
      },
    });
    this.dataUploaded = true;
    this.wizardService
      .postAsset(JSON.stringify(asset))
      .subscribe((res: any) => {
        Swal.getTitle()!.textContent = 'Uploading asset images...';
        let assetId = res.id;
        this.wizardService
          .postImages(
            assetId,
            this.propertyDetailsForm.get('images')?.value,
            this.propertyDetailsForm.get('listOfImagesName')?.value,
            this.propertyDetailsForm.get('cityImage')?.value
          ).pipe(catchError(err => of({ message: err, error: true })))
          .subscribe((res: any) => {
            if (res.error != undefined && res.error == true) {
              Swal.fire({
                title: 'Incorrect...',
                text: res.message,
                icon: 'error',
                confirmButtonColor: '#c33434',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'OK',
              }).then((result) => {
                this.router.navigate(['/apps/onboarded-assets']);
              });
            }
          });
        if (this.propertyDetailsForm.get('video')?.value != null) {
          Swal.getTitle()!.textContent = 'Updating asset video...';
          this.wizardService
            .postVideo(
              assetId,
              this.propertyDetailsForm.get('video')?.value
            )
            .pipe(catchError(err => of({ message: err, error: true })))
            .subscribe((res: any) => {
              if (res.error != undefined && res.error == true) {
                Swal.fire({
                  title: 'Incorrect...',
                  text: res.message,
                  icon: 'error',
                  confirmButtonColor: '#c33434',
                  cancelButtonColor: '#f46a6a',
                  confirmButtonText: 'OK',
                }).then((result) => {
                  this.router.navigate(['/apps/onboarded-assets']);
                });
              }
            });
        }
        Swal.getTitle()!.textContent = 'Uploading asset documents...';
        this.wizardService
          .postDocuments(assetId, this.filesTypesSubtypesList)
          .pipe(catchError(err => of({ message: err, error: true })))
          .subscribe((res: any) => {
            if (res.error != undefined && res.error == true) {
              Swal.fire({
                title: 'Incorrect...',
                text: res.errors[0].message,
                icon: 'error',
                confirmButtonColor: '#c33434',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'OK',
              });
            } else {
              Swal.fire({
                title: 'Success',
                text: 'Asset was successfully onboarded!',
                icon: 'success',
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'OK',
              }).then((result) => {
                this.router.navigate(['/apps/onboarding']);
              });
            }
          });
      });
  }

  private addSocialEconomicsForHttp(arr: any, socialEconomicsForHttp: any) {
    for (const iterator of arr.controls) {
      let portfolioSocEconomicsObject =  {
        administrativeLevel: {id: iterator.get('administrative_level')?.value},
        numberOfInhabitants: iterator.get('number_of_inhabitants')?.value,
        expectedPopulationGrowth: iterator.get('expected_population_growth')?.value,
        netNumberOfCommuters: iterator.get('commuters')?.value,
        retailCentrality: iterator.get('centrality')?.value,
        retailPurchasingPower:  iterator.get('purchasing_power')?.value,
        region: iterator.get('region')?.value,
        id: iterator.get('id')?.value
      };
      socialEconomicsForHttp.push(portfolioSocEconomicsObject);
    }
  }

  updateChart() {
    this.dynamicData = this.donughnutChart;
    this.dynamicData.series = [];
    this.dynamicData.title = {
      text: '€' + formatNumber(this.rentSum, 'en-US', '1.0-0') + ' p.a.',
      left: 'center',
      top: 'center',
    };
    this.dynamicData.series = {
      name: 'Rent percentage',
      type: 'pie',
      radius: ['40%', '80%'],
      avoidLabelOverlap: false,
      label: {
        show: true,
        formatter: '{d}%',
        position: 'inside',
        color: 'white',
      },
      data: [
        { value: this.topFiveValue, name: 'Top 5 tenants' },
        { value: this.othersValue, name: 'Others' },
      ],
    };
    this.donughnutChart = { ...this.donughnutChart, ...this.dynamicData };
  }

  public getAllUsages() {
    this.wizardService.getAllUsages().subscribe((response) => {
      this.usages = response;
    });
  }

  public getAllOwnerships() {
    this.wizardService.getAllOwnerships().subscribe((response) => {
      this.ownerships = response;
    });
  }
  getAllHighlights() {
    this.wizardService.getAllHighlights().subscribe((response) => {
      this.highlights = response;
    });
  }
  cityFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.cityImageUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
    this.propertyDetailsForm.get('cityImage')?.setValue(event.target.files[0]);
  }
  public onSubmit() {
    this.formsubmit = true;
    this.rentSum = 0;
    let fromRentBreakdownArray = this.propertyDetailsForm.get(
      'rentBreakdown'
    ) as FormArray;
    for (let i = 0; i < fromRentBreakdownArray.controls.length; i++) {
      let breakdownValue =
        fromRentBreakdownArray.controls[i].get('value')?.value;
      this.rentSum += breakdownValue * 1;
    }
    this.others?.setValue(this.rentSum);
    this.rentTotal?.setValue(this.rentSum);
    this.topFiveValue = 0;
    this.topFive?.setValue(null);
    this.updateChart();
  }

  openModal() {
    this.openModalVar = true;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(
      BreakDownDialogComponent,
      ngbModalOptions
    );
    modalRef.componentInstance.areaValues =
      this.propertyDetailsForm.get('areaBreakdown')?.value;
    modalRef.componentInstance.rentValues =
      this.propertyDetailsForm.get('rentBreakdown')?.value;
    modalRef.result
      .then((result) => {
        if (!result.area && !result.rent) {
        } else {
          //for area
          let formArray = this.propertyDetailsForm.get(
            'areaBreakdown'
          ) as FormArray;
          this.clearFormArray(formArray);
          for (let area of result.area.controls) {
            formArray.push(area);
          }
          //for rent
          let formArrayRent = this.propertyDetailsForm.get(
            'rentBreakdown'
          ) as FormArray;
          this.clearFormArray(formArrayRent);
          for (let rent of result.rent.controls) {
            formArrayRent.push(rent);
          }
        }
      })
      .catch((res) => {});
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  get propertyDetailsFormData() {
    return this.propertyDetailsForm.controls;
  }

  removeCityImg(){
    this.propertyDetailsForm.get('cityImage')?.setValue(null);
    this.cityImageUrl = '';
  }
  acceptedType(){
    if (this.video != null) {
      return 'image/jpg,image/jpeg,image/png'
    } else {
      return 'image/jpg,image/jpeg,image/png, video/mp4';
    }
  }

  trimName(name:string) {
    if (name.length > 25) {
      return name.substring(0,25) + "...";
    }
    return name;
  }

  public onReady(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
      return new MyUploadAdapter(loader);
    };
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
  }

  public addNewSocialEconomics(event: any) {
    let socialEconomicsForm: any;
    socialEconomicsForm = this.fb.group({
      region: [{ value: event.region, disabled: true }, Validators.required],
      number_of_inhabitants: ['', Validators.required],
      expected_population_growth: ['', Validators.required],
      commuters: ['', Validators.required],
      centrality: ['', Validators.required],
      purchasing_power: ['', Validators.required],
      administrative_level: [event.level, Validators.required],
      include: [true,  Validators.required]
    });
    switch (event.level) {
      case 1:
        this.socialEconomicsCities.push(socialEconomicsForm);
        break;
      case 2:
        this.socialEconomicsMunicipalities.push(socialEconomicsForm);
        break;
      case 4:
        this.socialEconomicsStates.push(socialEconomicsForm);
        break;
    }
    this.showPinOnMapService.refreshPinsOnMap();
  }

  public removeDistrictSocialEconomics(event: any) {
    let arr: any;
    switch (event.level) {
      case 1:
        arr = this.socialEconomicsCities;
        break;
      case 2:
        arr =  this.socialEconomicsMunicipalities;
        break;
      case 4:
        arr =  this.socialEconomicsStates;
        break;
    }
    if (arr != undefined) {
      for (let i = 0; i < arr.length; i++) {
        let socialEconomics = arr.controls[i];
        if (socialEconomics.get('region')?.value == event.region) {
          arr.removeAt(i);
        }
      }
    }
    this.showPinOnMapService.refreshPinsOnMap();
  }
}
