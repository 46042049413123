import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { CommonModule, DecimalPipe } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppsRoutingModule } from './apps-routing.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbTypeaheadModule,
  NgbDatepickerModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { TranslateModule } from '@ngx-translate/core';


import { CalendarComponent } from './calendar/calendar.component';
import { ChatComponent } from './chat/chat.component';
import { InboxComponent } from './inbox/inbox.component';
import { ReademailComponent } from './reademail/reademail.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceListSortableDirective } from './invoice-list/Invoice-sortable.directive';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { UserGridComponent } from './user-grid/user-grid.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserSortableDirective } from './user-list/user-list-sortable.directive';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomersTableComponent } from './customers-table/customers-table.component';
import { RegistrationRequestsComponent } from './registration-requests/registration-requests.component';
import { RequestingUserComponent } from './requesting-user/requesting-user.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingWizardComponent } from './onboarding/onboarding-wizard/onboarding-wizard.component';
import { ArchwizardModule } from 'angular-archwizard';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgSelectModule } from '@ng-select/ng-select';
import { BreakDownDialogComponent } from './onboarding/onboarding-wizard/break-down-dialog/break-down-dialog.component';

import { HighlightsComponent } from './onboarding/onboarding-wizard/highlights/highlights.component';
import { DocumentsSectionComponent } from './onboarding/onboarding-wizard/documents-section/documents-section.component';
import { HighlightDescriptionComponent } from './onboarding/onboarding-wizard/highlights/highlight-description/highlight-description.component';
import { OnboardedAssetsComponent } from './onboarding/onboarded-assets/onboarded-assets.component';
import { OnboardedAssetsSortableDirective } from './onboarding/onboarded-assets/onboarded-assets-sortable.directive';
import { AssetTokenizationComponent } from './onboarding/asset-tokenization/asset-tokenization.component';
import { CanExitWizardGuard } from 'src/app/core/guards/can-exit-wizard.guard';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { UpdateAssetComponent } from './onboarding/update-asset/update-asset.component';
import { DocumentComponent } from './onboarding/update-asset/document/document.component';
import {  UpdatePortfolioComponent } from './onboarding/update-portfolio/update-portfolio.component';
import { PortfolioTokenizationComponent } from './onboarding/portfolio-tokenization/portfolio-tokenization.component';
import { SocialEconomicsComponent } from './onboarding/onboarding-wizard/social-economics/social-economics.component';
import { SocialEconomicsTableComponent } from './onboarding/onboarding-wizard/social-economics/social-economics-table/social-economics-table.component';
import { SocialEconomicsTableDirective } from './onboarding/onboarding-wizard/social-economics/social-economics-table/social-economics-table.directive';

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
]);
export const options: Partial<IConfig> = {
  thousandSeparator: ","
};
@NgModule({
  declarations: [
    CalendarComponent,
    ChatComponent,
    InboxComponent,
    ReademailComponent,
    InvoiceListComponent,
    InvoiceListSortableDirective,
    InvoiceDetailComponent,
    UserGridComponent,
    UserListComponent,
    UserSortableDirective,
    UserProfileComponent,
    CustomersComponent,
    CustomersTableComponent,
    RegistrationRequestsComponent,
    RequestingUserComponent,
    OnboardingComponent,
    OnboardingWizardComponent,
    BreakDownDialogComponent,
    HighlightsComponent,
    DocumentsSectionComponent,
    HighlightDescriptionComponent,
    OnboardedAssetsComponent,
    OnboardedAssetsSortableDirective,
    AssetTokenizationComponent,
    UpdateAssetComponent,
    DocumentComponent,
    UpdatePortfolioComponent,
    PortfolioTokenizationComponent,
    SocialEconomicsComponent,
    SocialEconomicsTableComponent,
    SocialEconomicsTableDirective,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppsRoutingModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbPaginationModule,
    SimplebarAngularModule,
    CKEditorModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    ArchwizardModule,
    LeafletModule,
    NgxDropzoneModule,
    NgbTooltipModule,
    NgxMaskModule.forRoot(options),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NgxGalleryModule,
    PdfViewerModule,
    NgSelectModule,
    TranslateModule,
    DragDropModule
  ],
  exports:[BreakDownDialogComponent, DocumentsSectionComponent, HighlightsComponent, HighlightDescriptionComponent, DocumentComponent, SocialEconomicsComponent, OnboardedAssetsSortableDirective],
  providers: [DecimalPipe, CanExitWizardGuard],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppsModule {}
