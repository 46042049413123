<h6>{{ 'ASSETSPAGE.LIST.SOCIOECONOMICS.TEXT' | translate }}</h6>
<div class="card-body">
    <!-- Nav tabs -->
    <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
        <li [ngbNavItem]="1" *ngIf="socialEconomicsCities.controls.length > 0">
            <a ngbNavLink>
                <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                <div *ngIf="socialEconomicsCities.status === 'INVALID'" class="invalid-message">
                    <span  class="d-none d-sm-block">{{
                    'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.CITY' |
                    translate }}</span></div>
                <div *ngIf="socialEconomicsCities.status === 'VALID'">
                    <span  class="d-none d-sm-block">{{
                    'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.CITY' |
                    translate }}</span></div>
                
            </a>
            <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="Cities"></ng-template>
            </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngIf="socialEconomicsMunicipalities.controls.length > 0">
            <a ngbNavLink>
                <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                <div *ngIf="socialEconomicsMunicipalities.status === 'INVALID'" class="invalid-message">
                <span class="d-none d-sm-block">{{
                    'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.MUNICIPALITY' |
                    translate }}</span></div>
                <div *ngIf="socialEconomicsMunicipalities.status === 'VALID'">
                    <span class="d-none d-sm-block">{{
                    'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.MUNICIPALITY' |
                    translate }}</span></div>
            </a>
            <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="Municipalities"></ng-template>
            </ng-template>
        </li>
        <li [ngbNavItem]="3" *ngIf="socialEconomicsStates.controls.length > 0">
            <a ngbNavLink>
                <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                <div *ngIf="socialEconomicsStates.status === 'INVALID'" class="invalid-message">
                <span class="d-none d-sm-block">{{
                    'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.STATE' |
                    translate }}</span></div>
                    <div *ngIf="socialEconomicsStates.status === 'VALID'">
                    <span class="d-none d-sm-block">{{
                    'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.STATE' |
                    translate }}</span></div>
            </a>
            <ng-template ngbNavContent>
                <ng-template [ngTemplateOutlet]="States"></ng-template>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="text-muted"></div>
</div><!-- end card-body -->

<ng-template #Cities>
    <div class="row  p-2" [formGroup]="socialEconomicsForm">
        <div class="row mb-3 g-0">
            <div class="row-fluid" formArrayName="socialEconomicsCities">
                <app-social-economics-table [socialEconomicsArray] = "socialEconomicsCities"></app-social-economics-table>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #Municipalities>
    <div class="row  p-2" [formGroup]="socialEconomicsForm">
        <div class="row mb-3 g-0">
            <div class="row-fluid" formArrayName="socialEconomicsMunicipalities">
                <app-social-economics-table [socialEconomicsArray] = "socialEconomicsMunicipalities"></app-social-economics-table>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #States>
    <div class="row  p-2" [formGroup]="socialEconomicsForm">
        <div class="row mb-3 g-0">
            <div class="row-fluid" formArrayName="socialEconomicsStates">
                <app-social-economics-table [socialEconomicsArray] = "socialEconomicsStates"></app-social-economics-table>
            </div>
        </div>
    </div>
</ng-template>