import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { RemoveNdaService } from 'src/app/core/services/removeNda.service';
import { DocumentsService } from 'src/app/core/services/documets.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

  @Input() files!: File[];
  @Input() isNda!: Boolean;
  fileType!:string;
  src = '';
  fileName = '';
  pdfExcetnsions:Array<string> = ["pdf"];
  imgExcetnsions:Array<string> = ["png","jpg", "jpeg"];


  constructor(
    private removeNdaService: RemoveNdaService,
    private modalService: NgbModal,
    private documentsService: DocumentsService,
    private  sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {    
  }

  removeFile(i: number) {
    this.files.splice(i,1);
    if (this.isNda != undefined && this.isNda == true){
      this.removeNdaService.removeNda();
    }
  }
    public previewFile(content: any,file:any){
    let fileURL = URL.createObjectURL(file.file);
    this.src = fileURL;
    this.fileName = file.name;
    this.fileType = file.name.split(".")[file.name.split(".").length - 1];

    this.modalService
      .open(content, {
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      })
      .result.then(
        (result) => {},
        (reason) => {}
      );
  }
  transform(url:any):SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

