import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { UserProfileComponent } from './user-profile/user-profile.component';
import { CustomersComponent } from './customers/customers.component';
import { RegistrationRequestsComponent } from './registration-requests/registration-requests.component';
import { RequestingUserComponent } from './requesting-user/requesting-user.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingWizardComponent } from './onboarding/onboarding-wizard/onboarding-wizard.component';
import { OnboardedAssetsComponent } from './onboarding/onboarded-assets/onboarded-assets.component';
import { AssetTokenizationComponent } from './onboarding/asset-tokenization/asset-tokenization.component';
import { CanExitWizardGuard } from 'src/app/core/guards/can-exit-wizard.guard';
import { UpdateAssetComponent } from './onboarding/update-asset/update-asset.component';
import { UpdatePortfolioComponent } from './onboarding/update-portfolio/update-portfolio.component';
import { PortfolioTokenizationComponent } from './onboarding/portfolio-tokenization/portfolio-tokenization.component';

const routes: Routes = [
  {
    path: 'profile',
    component: UserProfileComponent,
  },
  {
    path: 'customers',
    component: CustomersComponent,
  },
  {
    path: 'requests',
    component: RegistrationRequestsComponent,
  },
  {
    path: 'customers/:id',
    component: RequestingUserComponent,
  },
  {
    path: 'requests/:id',
    component: RequestingUserComponent,
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
  },
  {
    path: 'onboarding/onboardingwizard',
    component: OnboardingWizardComponent,
    canDeactivate: [CanExitWizardGuard],
  },
  {
    path: 'onboarded-assets',
    component: OnboardedAssetsComponent,
  },
  {
    path: 'tokenize-asset/:id',
    component: AssetTokenizationComponent,
  },
  {
    path: 'tokenize-portfolio/:id',
    component: PortfolioTokenizationComponent,
  },
  {
    path: 'update-assets/:id',
    component: UpdateAssetComponent,
  },
  {
    path: 'update-portfolio/:id',
    component: UpdatePortfolioComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppsRoutingModule {}
