import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Highlight } from 'src/app/classes/Highlight';
import { WizarService } from 'src/app/core/services/wizard.service';
import { HighlightDescriptionComponent } from './highlight-description/highlight-description.component';


@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss']
})
export class HighlightsComponent implements OnInit {

  @Input("form")
  propertyDetailsForm!: FormGroup;
  @Input("update")
  update!: boolean;


  highlightsForm!: FormGroup;

  highlights!: Array<Highlight>;
  checkedHighlights: Array<Highlight> = [];

  constructor(private wizardService: WizarService, private modalService: NgbModal, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.highlightsForm = this.fb.group({
      highlights: this.fb.array([]),
    });
    this.wizardService.getAllHighlights().subscribe((response) => {
      this.highlights = response;
      if (this.update) {
        let formHighlightsArray = this.propertyDetailsForm.get(
          'highlightOption'
        ) as FormArray;
        for (let i = 0; i < formHighlightsArray.controls.length; i++){
          const assetHighlight = formHighlightsArray.controls[i];
          for (let j = 0; j < this.highlights.length; j++) {
            const iterator = this.highlights[j];
            if (assetHighlight.get('pictureName')?.value === iterator.pictureName) {
              iterator.highlightDescription = assetHighlight.get('highlightDescription')?.value;
              this.checkedHighlights.push(iterator);
              this.highlights.splice(j,1);
              iterator.orderNumber = i +1;
            }
          }
        }
      }
    });
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  get highlightArrayForm() {
    return this.highlightsForm.get('highlights') as FormArray;
  }

  get propertyDetailsFormData() {
    return this.propertyDetailsForm.controls
  }

  checkValidity(id: number){
    var formArray = this.propertyDetailsForm.get('highlightOption') as FormArray;
    for (let i=0; i< this.highlightArrayForm.controls.length; i++) {
      let control = this.highlightArrayForm.controls[i];
      if (control.get('id')?.value == id){
        return control.invalid;
      }
    }
    return false;
  }

  openModal(h: Highlight) {
    const modalRef = this.modalService.open(HighlightDescriptionComponent, { centered: true, windowClass: 'modal-holder' });
    modalRef.componentInstance.description = h.highlightDescription;
    modalRef.result
      .then((result) => {
        if (result != 'Cross click') {
          h.highlightDescription = result;
          for (let i=0; i< this.highlightArrayForm.controls.length; i++) {
            let control = this.highlightArrayForm.controls[i];
            if (control.get('id')?.value == h.id){
              control.get('highlightDescription')?.setValue(result);
            }
          }
        }
      })
      .catch((res) => {});
  }

  onDrop(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.clearFormArray(this.highlightArrayForm);
    for (let i=0; i< this.checkedHighlights.length; i++) {
      const highlight = this.fb.group({
        id: [this.checkedHighlights[i].id, Validators.required],
        pictureName: [this.checkedHighlights[i].pictureName, Validators.required],
        highlightDescription: [this.checkedHighlights[i].highlightDescription || '', [Validators.required, Validators.maxLength(30)]],
        orderNumber: [ i +1 , Validators.required]
      });
      this.highlightArrayForm.push(highlight);
    }
    var formArray = this.propertyDetailsForm.get('highlightOption') as FormArray;
    this.clearFormArray(formArray);
    for (let i=0; i< this.highlightArrayForm.controls.length; i++) {
      formArray.push(this.highlightArrayForm.controls[i]);
    }
  }  
}
