<app-pagetitle title="{{ 'MENUITEMS.APPS.LIST.ASSETS.LIST.ONBOARDEDASSETS' | translate }}"
  [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.ASSETSTABLE' | translate}}</h4>
        <p class="card-title-desc">{{ 'ASSETSPAGE.LIST.ASSETSTABLEMESSAGE' | translate}}
        </p>
      </div>
      <div class="card-body">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_length" id="tickets-table_length"><label
                class="d-inline-flex align-items-center">Show
                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                  [(ngModel)]="onboardedAssetsService.pageSize" class="form-control form-control-sm mx-2">
                  <option [ngValue]="10">10</option>
                  <option [ngValue]="25">25</option>
                  <option [ngValue]="50">50</option>
                  <option [ngValue]="100">100</option>
                </select> entries</label></div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_filter" class="dataTables_filter text-md-end"><label
                    class="d-inline-flex align-items-center">Search:
                    <input type="text" name="searchTerm" class="form-control form-control-sm ms-2"
                        aria-controls="tickets-table" [(ngModel)]="onboardedAssetsService.searchTerm" (change)="onSearch()"></label></div>
          </div>
        </div>

        <table id="datatable" class="table align-middle datatable dt-responsive table-check nowrap datatables"
          style="border-collapse: collapse; border-spacing: 0 8px; width: 100%;">
          <thead>
            <tr>
              <th class="noSort">{{ 'ASSETSPAGE.LIST.TYPE' | translate }}</th>
              <th assetsSortable="assetName" (sort)="onSort($event)">{{ 'ASSETSPAGE.LIST.PROPERTYTITLE' | translate }}
              </th>
              <th assetsSortable="grossValue" (sort)="onSort($event)">{{ 'ASSETSPAGE.LIST.DEBTSITUATION.LIST.GROSSVALUE'
                | translate }}</th>
              <th assetsSortable="constructionYear" (sort)="onSort($event)">{{ 'ASSETSPAGE.LIST.CONSTRACTIONYEAR' |
                translate }}</th>
              <th class="noSort">{{ 'ASSETSPAGE.LIST.TOKENIZED' | translate }}</th>
              <th class="noSort">{{ 'PORTFOLIOPAGE.ENABLEFORINVESTORS' | translate }}</th>
              <th class="noSort">{{ 'ASSETSPAGE.LIST.ACTIONS' | translate }}</th>
            </tr>
          </thead>
          <tbody *ngFor="let table of tables$ | async;let i=index">
            <tr>
              <td>
                <span *ngIf="table.isPortfolio != true" title="asset"> <i
                    class="mdi mdi-office-building-outline"></i></span>
                <span *ngIf="table.isPortfolio == true" title="portfolio"><i
                    class="mdi mdi-home-city-outline"></i></span>
              </td>
              <td>
                <ngb-highlight [result]="table.assetName.toString()" [term]="onboardedAssetsService.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="table.grossValue.toString() | number: '.0'"
                  [term]="onboardedAssetsService.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <ngb-highlight [result]="table.constructionYear.toString()" [term]="onboardedAssetsService.searchTerm">
                </ngb-highlight>
              </td>
              <td>
                <i *ngIf="table.symbol != ''" class="bx bx-check-double font-size-16 align-middle"
                  style="color: rgb(43, 161, 43);"></i>
                <i *ngIf="table.symbol == ''" class="bx bx-block font-size-16 align-middle"
                  style="color: rgb(161, 43, 43);"></i>
              </td>
              <td>
                <i *ngIf="table.enabled" class="bx bx-check-double font-size-16 align-middle"
                  style="color: rgb(43, 161, 43);"></i>
                <i *ngIf="!table.enabled" class="bx bx-block font-size-16 align-middle"
                  style="color: rgb(161, 43, 43);"></i>
              </td>
              <td>
                <div class="btn-group" role="group" ngbDropdown>
                  <button id="btnGroupVerticalDrop1" type="button"
                    class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle"
                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    <i class="bx bx-dots-horizontal-rounded"></i>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1" ngbDropdownMenu>
                    <button *ngIf="table.isPortfolio == true" [routerLink]="['../update-portfolio', table.id]"
                      class="dropdown-item"><i class="mdi mdi-update"></i>&nbsp;&nbsp;{{ 'ASSETSPAGE.LIST.UPDATEASSET' |
                      translate }} ...</button>
                    <button *ngIf="table.isPortfolio != true" [routerLink]="['../update-assets', table.id]"
                      class="dropdown-item"><i class="mdi mdi-update"></i>&nbsp;&nbsp;{{ 'ASSETSPAGE.LIST.UPDATEASSET' |
                      translate }} ...</button>
                    <button *ngIf="table.symbol != ''"
                      (click)="openEtherscan(table.symbol.toString())" class="dropdown-item"> <i
                        class="mdi mdi-ethereum"></i>&nbsp;&nbsp;Etherscan </button>
                    <button *ngIf="table.symbol != '' && table.isPortfolio != true" class="dropdown-item"
                      (click)="removeToken(table)"><i class="bx bx-trash-alt"></i>&nbsp;&nbsp;{{
                      'ASSETSPAGE.LIST.REMOVETOKEN' |
                      translate }}</button>
                    <button *ngIf="table.symbol != '' && table.isPortfolio == true" class="dropdown-item"
                      (click)="removePortfolioToken(table)"><i class="bx bx-trash-alt"></i>&nbsp;&nbsp;{{
                      'ASSETSPAGE.LIST.REMOVETOKEN' |
                      translate }}</button>
                    <button *ngIf="table.symbol == '' && table.isPortfolio != true"
                      [routerLink]="['../tokenize-asset', table.id]" class="dropdown-item"><i
                        class="mdi mdi-webpack"></i>&nbsp;&nbsp;{{ 'ASSETSPAGE.LIST.TOKENIZE' | translate }}
                      ...</button>
                    <button *ngIf="table.symbol == '' && table.isPortfolio == true"
                      [routerLink]="['../tokenize-portfolio', table.id]" class="dropdown-item"><i
                        class="mdi mdi-webpack"></i>&nbsp;&nbsp;{{ 'ASSETSPAGE.LIST.TOKENIZE' | translate }}
                      ...</button>
                    <button class="dropdown-item" (click)="removeAsset(table.id, table.isPortfolio)"><i
                        class="bx bx-trash-alt"></i>&nbsp;&nbsp;{{ 'ASSETSPAGE.LIST.REMOVE' | translate }}</button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row justify-content-md-between align-items-md-center mt-2">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
              Showing
              {{onboardedAssetsService.startIndex}} to
              {{onboardedAssetsService.endIndex}} of {{onboardedAssetsService.totalRecords}}
              entries
            </div>
          </div>
          <!-- Pagination -->
          <div class="col-sm-12 col-md-5">
            <div class="text-md-right float-md-end pagination-rounded">
              <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="onboardedAssetsService.page"
                [pageSize]="onboardedAssetsService.pageSize"
                (pageChange)="onboardedAssetsService.onPageChange(onboardedAssetsService.page)"
                [maxSize]="3"
                [rotate]="true">
              </ngb-pagination>
            </div>
          </div>
          <!-- End Pagination -->
        </div>
      </div>

    </div>
  </div> <!-- end col -->
</div> <!-- end row -->
