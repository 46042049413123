import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Asset } from '../models/assetsModels/asset.models';
import { ListOfOpenNDAsAndMoreInformationAssets } from '../models/assetsModels/listOfOpenNDAsAndMoreInformationAssets.model';
import { SpecificAssetNDA } from '../models/assetsModels/specificAssetNDA.models';
import { UserNDARequestAndMoreInformation } from '../models/UserNDARequest.models';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  asssetUrl!: string;
  projectsUrl!: string;
  asssetNDAList!: string;
  asssetInfoReq!: string;

  constructor(private http: HttpClient) {
    this.asssetUrl = environment.apiBase + '/assets/';
    this.projectsUrl = environment.apiBase + '/projects/';
    this.asssetNDAList = environment.apiBase + '/projects/all-nda-requests';
    this.asssetInfoReq = environment.apiBase + '/projects/all-requests-to-view';

  }

  getAssetById(id: number): Observable<Asset> {
    return this.http.get<Asset>(this.asssetUrl + id);
  }

  getAllAssets(): Observable<Array<Asset>> {
    return this.http.get<Array<Asset>>(this.asssetUrl);
  }

  
  getAllProjects(): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.projectsUrl);
  }

  getAssetsNDAs(): Observable<Array<ListOfOpenNDAsAndMoreInformationAssets>> {
    return this.http.get<Array<ListOfOpenNDAsAndMoreInformationAssets>>(
      this.asssetNDAList
    );
  }

  getClosedSpecificAssetAcceptedNdas(
    assetId: string
  ): Observable<SpecificAssetNDA> {
    return this.http.get<SpecificAssetNDA>(
      environment.apiBase + `/assets/${assetId}/accepted-nda-requests/users`
    );
  }
  getClosedSpecificAssetRejectedNdas(
    assetId: string
  ): Observable<SpecificAssetNDA> {
    return this.http.get<SpecificAssetNDA>(
      environment.apiBase + `/assets/${assetId}/rejected-nda-requests/users`
    );
  }

  getAssetInfoReq(): Observable<
    Array<ListOfOpenNDAsAndMoreInformationAssets>
  > {
    return this.http.get<Array<ListOfOpenNDAsAndMoreInformationAssets>>(
      this.asssetInfoReq
    );
  }

  getClosedAssetInfoReq(): Observable<
    Array<ListOfOpenNDAsAndMoreInformationAssets>
  > {
    return this.http.get<Array<ListOfOpenNDAsAndMoreInformationAssets>>(
      environment.apiBase + `/assets/closed-requests-to-view`
    );
  }

  getSpecificAssetWithAllNDAs(
    assetId: string
  ): Observable<Array<UserNDARequestAndMoreInformation>> {
    return this.http
      .get<Array<UserNDARequestAndMoreInformation>>(
        environment.apiBase +
          '/assets/' +
          assetId +
          '/all-nda-requests/users'
      )
      .pipe(map((value: any) => value.usersData));
  }

  getSpecificPortfolioWithAllNDAs(
    portfolioId: string
  ): Observable<Array<UserNDARequestAndMoreInformation>> {
    return this.http
      .get<Array<UserNDARequestAndMoreInformation>>(
        environment.apiBase +
          '/portfolio/' +
          portfolioId +
          '/all-nda-requests/users'
      )
      .pipe(map((value: any) => value.usersData));
  }

  getSpecificAssetMoreInfoReq(
    assetId: string
  ): Observable<Array<UserNDARequestAndMoreInformation>> {
    return this.http
      .get<Array<UserNDARequestAndMoreInformation>>(
        environment.apiBase + `/assets/${assetId}/all-requests-to-view/users`
      )
      .pipe(map((value: any) => value.usersData));
  }
  getSpecificPortfolioMoreInfoReq(
    assetId: string
  ): Observable<Array<UserNDARequestAndMoreInformation>> {
    return this.http
      .get<Array<UserNDARequestAndMoreInformation>>(
        environment.apiBase + `/portfolio/${assetId}/all-requests-to-view/users`
      )
      .pipe(map((value: any) => value.usersData));
  }


  getAcceptedSpecificAssetMoreInfoReq(
    assetId: string
  ): Observable<Array<UserNDARequestAndMoreInformation>> {
    return this.http
      .get<Array<UserNDARequestAndMoreInformation>>(
        environment.apiBase +
          `/assets/${assetId}/accepted-requests-to-view/users`
      )
      .pipe(map((value: any) => value.usersData));
  }
  getRejectedSpecificAssetMoreInfoReq(
    assetId: string
  ): Observable<Array<UserNDARequestAndMoreInformation>>{
    return this.http
      .get<Array<UserNDARequestAndMoreInformation>>(
        environment.apiBase +
          `/assets/${assetId}/rejected-requests-to-view/users`
      )
      .pipe(map((value: any) => value.usersData));
  }
  createToken(
    name: string,
    symbol: string,
    initialSupply: number,
    owner: string
  ): Observable<any> {
    const formData: FormData = new FormData();
    var operations = {
      query:
        `
                mutation {
                    mintERC20(input: {name: "` +
        name +
        `", symbol:"` +
        symbol +
        `", initialSupply:` +
        initialSupply +
        `, owner:"` +
        owner +
        `"}) {
                        transactionHash
                        blockNumber
                        effectiveGasPrice
                        contractAddress
                    }
                }
            `,
      variables: null,
    };
    formData.append('operations', JSON.stringify(operations));
    return this.http.post(environment.blockchainBase, formData);
  }

  checkToken(symbol: string) {
    const formData: FormData = new FormData();
    var operations = {
      query: `query { tokenToAddress(param0:"` + symbol + `")}`,
      variables: null,
    };
    formData.append('operations', JSON.stringify(operations));
    return this.http.post(environment.blockchainBase, formData);
  }

  saveToken(
    id: number,
    symbol: string,
    transactionHash: string
  ): Observable<any> {
    return this.http.post(this.asssetUrl + 'saveToken/' + id, {
      symbol,
      transactionHash,
    });
  }

  removeToken(symbol: string): Observable<any> {
    const formData: FormData = new FormData();
    var operations = {
      query:
        `
                mutation{
                    removeToken(symbol:"` +
        symbol +
        `") {
                        transactionHash
                        blockNumber
                        effectiveGasPrice
                        contractAddress
                    }
                }
            `,
      variables: null,
    };
    formData.append('operations', JSON.stringify(operations));
    return this.http.post(environment.blockchainBase, formData);
  }

  removeAsset(id: number): Observable<any> {
    return this.http.delete(this.asssetUrl + id);
  }



  updateAsset(id: number, asset: Asset): Observable<any> {
    return this.http.put(this.asssetUrl + id, asset);
  }
}
