import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class DocumentsService {

  downloadDocumentsUrl!: string;
  downloadAssetDocumentsUrl!: string;
  assetUrl!: string;

  constructor(private http: HttpClient) {
    this.downloadDocumentsUrl = environment.apiBase + '/documents?id=';
    this.downloadAssetDocumentsUrl = environment.apiBase + '/asset-documents?id=';
    this.assetUrl = environment.apiBase + '/assets/';
  }

  downloadDocument(id: number) {
    return this.http.get(this.downloadDocumentsUrl + id);
  }

  getAssetDocument(id: number): Observable<any> {
    return this.http.get(this.downloadAssetDocumentsUrl + id, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  getDocument(
    id: number
  ): Observable<any> {
    return this.http.get(
      this.downloadDocumentsUrl + id,
      {
        observe: 'response',
        responseType: 'blob'
      }
    );

  }

  getAssetImages(id: number): Observable<any> {
    return this.http.get(
      this.assetUrl + id + '/images',
      {
        observe: 'response'
      }
    );
  }

  getAssetImagesWithUrl(path: string): Observable<any> {
    return this.http.get(window.location.origin + "/" + path,{
      responseType:"blob"
    });
  }

  getAssetImageByPath(path: string): Observable<any> {
    return this.http.get(environment.apiBase + "/" + path, {
      observe: 'response',
    });
  }

  getAssetVideo(id: number): Observable<any> {
    return this.http.get(
      this.assetUrl + id + '/video',
      {
        observe: 'response'
      }
    );
  }
}