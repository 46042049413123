<!-- start page title -->
<app-pagetitle title="Timeline" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Bootstrap Timeline</h4>
            </div><!-- end card header -->

            <div class="card-body">

                <div class="row justify-content-center">
                    <div class="col-xl-10">
                        <div class="timeline">
                            <div class="timeline-container">
                                <div class="timeline-end">
                                    <p>Start</p>
                                </div>
                                <div class="timeline-continue">
                                    <div *ngFor="let data of timelineData">
                                        <div *ngIf="data.align === 'right'">
                                            <div class="row timeline-right">
                                                <div class="col-md-6">
                                                    <div class="timeline-icon">
                                                        <i class="bx {{data.icon}} text-primary h2 mb-0"></i>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="timeline-box">
                                                        <div class="timeline-date bg-primary text-center rounded">
                                                            <h3 class="text-white mb-0">{{data.date}}</h3>
                                                            <p class="mb-0 text-white-50">{{data.month}}</p>
                                                        </div>
                                                        <div class="event-content">
                                                            <div class="timeline-text">
                                                                <h3 class="font-size-18">{{data.title}}</h3>
                                                                <p class="mb-0 mt-2 pt-1 text-muted">{{data.content}}
                                                                    <a href="javascript:void(0);">{{data.link}}</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="data.align === 'left'">
                                            <div class="row timeline-left">
                                                <div class="col-md-6 d-md-none d-block">
                                                    <div class="timeline-icon">
                                                        <i class="bx {{data.icon}} text-primary h2 mb-0"></i>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="timeline-box">
                                                        <div class="timeline-date bg-primary text-center rounded">
                                                            <h3 class="text-white mb-0">{{data.date}}</h3>
                                                            <p class="mb-0 text-white-50">{{data.month}}</p>
                                                        </div>
                                                        <div class="event-content">
                                                            <div class="timeline-text">
                                                                <h3 class="font-size-18">{{data.title}}</h3>
                                                                <p class="mb-0 mt-2 pt-1 text-muted">{{data.content}}
                                                                </p>
                                                                <div *ngIf="data.link">
                                                                    <button type="button"
                                                                        class="btn btn-primary btn-rounded waves-effect waves-light mt-4">{{data.link}}</button>
                                                                </div>
                                                                <div
                                                                    class="d-flex flex-wrap align-items-start event-img mt-3 gap-2">
                                                                    <img src="{{images}}" alt=""
                                                                        class="img-fluid rounded" width="60"
                                                                        *ngFor="let images of data.image">
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-6 d-md-block d-none">
                                                    <div class="timeline-icon">
                                                        <i class="bx bx-user-pin text-primary h2 mb-0"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="timeline-start">
                                    <p>End</p>
                                </div>
                                <div class="timeline-launch">
                                    <div class="timeline-box">
                                        <div class="timeline-text">
                                            <h3 class="font-size-18">Launched our company on 21 June 2021</h3>
                                            <p class="text-muted mb-0">Pellentesque sapien ut est.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->