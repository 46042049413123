<!-- start page title -->
<app-pagetitle title="{{ 'HOME.TEXT' | translate }}" [displayBackButton]="false"></app-pagetitle>
<!-- end page title -->
<div class="container margin-botom">
    <a routerLink="/" class="logo logo-dark">
        <span class="logo-sm">
            <img src="assets/images/favicon.svg" alt="">
        </span>
        <span class="logo-lg">
            <img src="assets/images/logo-sm.svg" alt="">
        </span>
    </a>
    <a routerLink="/" class="logo logo-light">
        <span class="logo-sm">
            <img src="assets/images/whitefavicon.png" alt="">
        </span>
        <span class="logo-lg">
            <img src="assets/images/whitelogo.png" alt="">
        </span>
    </a>
</div>
<div class="container">
    <h1>{{ 'HOME.LIST.WELCOME' | translate }}</h1>
</div>
<div class="row ">
    <!-- end col -->
    <div class="container">
        <label>{{ 'HOME.LIST.WELCOMETEXT' | translate }}</label>
    </div>
</div><!-- end row -->
