import { Injectable, ErrorHandler } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { User } from 'src/app/classes/user';
import { ErrorResponse } from 'src/app/classes/ErrorResponse';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
    registrationUrl: string;
    confirmationUrl: string;
    updateUserUrl: string;
    reqDocsUrl: string;
    reqAdditionalDocsUrl: string;
    acceptedUsersUrl: string;
    registrationRequestsUrl: string;
  
    httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  
    constructor(private http: HttpClient) {
      this.registrationUrl = environment.apiBase + '/users';
      this.acceptedUsersUrl = environment.apiBase + '/users/accepted';
      this.confirmationUrl = environment.apiBase + '/users/regitrationConfirm/';
      this.updateUserUrl = environment.apiBase + '/users/registrationStatus';
      this.reqDocsUrl = environment.apiBase + '/documents/';
      this.reqAdditionalDocsUrl = environment.apiBase + '/users/additionalDocuments';
      this.registrationRequestsUrl = environment.apiBase + '/users/disabled';
    }
  
    registerUser(user: User): Observable<User> {
      return this.http
        .post<User>(this.registrationUrl, user, this.httpOptions)
        .pipe(catchError(this.handleError));
    }
  
    confirmRegistration(token: string): Observable<User> {
      return this.http.get<User>(this.confirmationUrl + token);
    }
  
    updateUserStatus(user: User, message: string) {
      return this.http.put(this.updateUserUrl, {"userId":user.id, "status": user.registrationStatus.status, "message": message}, this.httpOptions);
    }
    requestDocuments(userId:number){
      return this.http.post(this.reqDocsUrl + userId, this.httpOptions);
    }
    sendMailForAdditionalDocs(body: string, email: string){
        return this.http.post(this.reqAdditionalDocsUrl,{"body": body, "email": email}, this.httpOptions);
    }
  
    private handleError(error: ErrorResponse) {
      let errorMessage = '';
      if (error.errorMessage != null) {
        errorMessage = `Error: ${error.errorMessage}`;
      } else {
        errorMessage = `Error Code: ${error.httpStatus}`;
      }
      return throwError(errorMessage);
    }
  
    getUserById(id: number) {
      return this.http.get<User>(
        this.registrationUrl + '/' + id,
        this.httpOptions
      );
    }
  
    getAllAcceptedUsers() {
      return this.http.get<User[]>(
        this.acceptedUsersUrl,
        this.httpOptions
      );
    }
    
    getAllRegistrationRequests() {
      return this.http.get<User[]>(
        this.registrationRequestsUrl,
        this.httpOptions
      );
    }

    public searchUser(query: any): Observable<string[]> {
      return this.http.get<string[]>(
        this.registrationUrl + '/search-user/' + query,
        this.httpOptions
      );
    }

}
