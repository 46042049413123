import {
  AfterViewInit,
  Component,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { OnboardedAssetsList } from 'src/app/core/models/onboarded-assets-list.model';
import { AssetService } from 'src/app/core/services/asset.service';
import { PortfolioService } from 'src/app/core/services/portfolio.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { OnboardedAssetsService } from '../../../../core/services/onboarded-assets.service';
import {
  OnboardedAssetsSortableDirective,
  SortEvent,
} from './onboarded-assets-sortable.directive';

@Component({
  selector: 'app-onboarded-assets',
  templateUrl: './onboarded-assets.component.html',
  styleUrls: ['./onboarded-assets.component.scss'],
})
export class OnboardedAssetsComponent implements AfterViewInit {
  breadCrumbItems!: Array<{}>;
  @ViewChildren(OnboardedAssetsSortableDirective)
  headers!: QueryList<OnboardedAssetsSortableDirective>;
  total$: Observable<number>;
  tables$!: Observable<OnboardedAssetsList[]>;

  constructor(
    public onboardedAssetsService: OnboardedAssetsService,
    private assetServie: AssetService,
    private portfolioService: PortfolioService
  ) {
    this.tables$ = onboardedAssetsService.tables$;
    this.total$ = onboardedAssetsService.total$;
    this.breadCrumbItems = [{ label: 'Onboarded assets', active: true }];
  }
  ngAfterViewInit(): void {
    this._fetchData();
  }

  _fetchData() {
    this.assetServie.getAllProjects().subscribe((res) => {
      let arr = [];

      for (let i = 0; i < res.length; i++) {
        let constrtion = [];
        if ( res[i].constructionYear != undefined) {
          constrtion.push(res[i].constructionYear);
        } else {
          for (let j=0; j < res[i].constructionYears.length; j++){
            constrtion.push(res[i].constructionYears[j].year);
          }
        }

        if (res[i].portfolio){
          arr[i] = {
            id: res[i].id + '',
            assetName: res[i].title || '',
            grossValue: res[i].grossValue || 0,
            constructionYear: constrtion,
            enabled: res[i]?.enabled || false,
            symbol: res[i].portfolioToken?.symbol || '',
            transactionHash: res[i].portfolioToken?.transactionHash || '',
            isPortfolio: res[i].portfolio,
            isSelected: false
          };
        } else {
          arr[i] = {
            id: res[i].id + '',
            assetName: res[i].title || '',
            grossValue: res[i].grossValue || 0,
            constructionYear: constrtion,
            enabled: res[i]?.enabled || false,
            symbol: res[i].assetToken?.symbol || '',
            transactionHash: res[i].assetToken?.transactionHash || '',
            isPortfolio: res[i].portfolio,
            isSelected: false
          };
        }

        
      }
      this.onboardedAssetsService.tableData = arr;
      this.onboardedAssetsService.setPaging();
    });
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.assetsSortable !== column) {
        header.direction = '';
      }
    });
    this.onboardedAssetsService.sortColumn = column;
    this.onboardedAssetsService.sortDirection = direction;
  }

  public openEtherscan(symbol: string) {
    this.assetServie.checkToken(symbol).subscribe((res) => {
      let resAddress = res as any;
      if (
        resAddress.data?.tokenToAddress != undefined &&
        resAddress.data?.tokenToAddress !==
          '0x0000000000000000000000000000000000000000'
      ) {
        let url =
          environment.etherscanBase +
          'token/' +
          resAddress.data?.tokenToAddress;
        window.open(url, '_blank');
      }
    });
  }

  public removeToken(table: OnboardedAssetsList) {
    Swal.fire({
      title: 'Removing token',
      text:
        'You are about to remove the ' +
        table.symbol.toString() +
        ' token from the blockchain. Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#33aeb1',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#fd625e',
      cancelButtonText: 'No, cancel!',
    }).then((res) => {
      if (res.isConfirmed) {
        Swal.fire({
          title: 'Removing token...',
          didOpen: () => {
            Swal.showLoading();
          },
        });
        this.assetServie
          .removeToken(table.symbol.toString())
          .subscribe((res) => {
            if (res.errors != undefined) {
              Swal.fire({
                title: 'Incorrect...',
                text: res.errors[0].message,
                icon: 'error',
                confirmButtonColor: '#c33434',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'OK',
              });
            } else {
              Swal.fire({
                title: 'Success',
                text: 'The token was successfully removed!',
                icon: 'success',
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'OK',
              }).then((res) => {
                table.symbol = '';
              });
            }
          });
      }
    });
  }

  public removePortfolioToken(table: OnboardedAssetsList) {
    Swal.fire({
      title: 'Removing token',
      text:
        'You are about to remove the ' +
        table.symbol.toString() +
        ' token from the blockchain. Are you sure?',
      showCancelButton: true,
      confirmButtonColor: '#33aeb1',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#fd625e',
      cancelButtonText: 'No, cancel!',
    }).then((res) => {
      if (res.isConfirmed) {
        Swal.fire({
          title: 'Removing token...',
          didOpen: () => {
            Swal.showLoading();
          },
        });
        this.portfolioService
          .removeToken(table.symbol.toString())
          .subscribe((res) => {
            if (res.errors != undefined) {
              Swal.fire({
                title: 'Incorrect...',
                text: res.errors[0].message,
                icon: 'error',
                confirmButtonColor: '#c33434',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'OK',
              });
            } else {
              Swal.fire({
                title: 'Success',
                text: 'The token was successfully removed!',
                icon: 'success',
                confirmButtonColor: '#34c38f',
                cancelButtonColor: '#f46a6a',
                confirmButtonText: 'OK',
              }).then((res) => {
                table.symbol = '';
              });
            }
          });
      }
    });
  }

  public removeAsset(id: String, isPortfolio: boolean) {
    if (!isPortfolio) {
      Swal.fire({
        title: 'Removing asset',
        text: 'You are about to remove the asset. Are you sure?',
        showCancelButton: true,
        confirmButtonColor: '#33aeb1',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonColor: '#fd625e',
        cancelButtonText: 'No, cancel!',
      }).then((res) => {
        if (res.isConfirmed) {
          Swal.fire({
            title: 'Removing asset...',
            didOpen: () => {
              Swal.showLoading();
            },
          });
          this.assetServie.removeAsset(+id).subscribe((res) => {
            Swal.fire({
              title: 'Success',
              text: 'The asset was successfully removed!',
              icon: 'success',
              confirmButtonColor: '#34c38f',
              cancelButtonColor: '#f46a6a',
              confirmButtonText: 'OK',
            }).then((res) => {
              this._fetchData();
            });
          });
        }
      });
    } else {
      Swal.fire({
        title: 'Removing portfolio',
        text: 'You are about to remove the portfolio. Are you sure?',
        showCancelButton: true,
        confirmButtonColor: '#33aeb1',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonColor: '#fd625e',
        cancelButtonText: 'No, cancel!',
      }).then((res) => {
        if (res.isConfirmed) {
          Swal.fire({
            title: 'Removing portfolio...',
            didOpen: () => {
              Swal.showLoading();
            },
          });
          this.portfolioService.removePortfolio(+id).subscribe((res) => {
            Swal.fire({
              title: 'Success',
              text: 'The portfolio was successfully removed!',
              icon: 'success',
              confirmButtonColor: '#34c38f',
              cancelButtonColor: '#f46a6a',
              confirmButtonText: 'OK',
            }).then((res) => {
              this._fetchData();
            });
          });
        }
      });
    }
  }

  onSearch(){
    this.onboardedAssetsService.onSearch();
  }
}
