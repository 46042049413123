import { Address } from "../core/models/assetsModels/addres.models";
import { RegistrationStatus } from "./registrationStatus";

export class User {
  public id!: number;
    public name!:string;
    public lastName!:string;
    public username!:string;
    public email!:string;
    public phoneNumber!:string;
    public lei!:string;
    public password!:string;
    public creationDate!:string;
    public modifiedDate!:string;
    public enabled!:boolean;
    public verified!:boolean;
    public tokenExpired!:boolean;
    public registrationStatus!: RegistrationStatus;
    public documents! :any[];
    public keycloakId!:string;
    public companyName!: string;
    public companyAddress!:Address;
    public professionalInvestor!: boolean;
    public external!: boolean;
    constructor(){

    }
}
