import { Injectable } from '@angular/core';
import { LocationPin } from 'src/app/shared/google-map/google-map.component';

@Injectable({
  providedIn: 'root'
})
export class LocationSocialEconomicsPinService {
  private _list: LocationPin[];

  constructor() { 
    this._list = [];
  }

  get locationPins(){
    return this._list;
  }

  set locationPins(_list: LocationPin[]){
    this._list = _list;
  }

  public addLocation(location: LocationPin){
    this._list.push(location);
  }

  public getLocationByRegionAndLevel(region:string, level: number) {
    return this._list.filter((location) => {
      return location.level == level && location.locationSocialEconomics?.region == region
    }) [0]
  }
}
