<div class="row" style="margin: 50px 0">

  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.NDA' | translate }}:</h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.NDA' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of ndaFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.TEXT' | translate }}:</h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.LIST.CADASTRAL' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of cadastralMapsFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.LIST.LANDREGISTRATION' | translate }}:
              </h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of landRegistrationExcerptFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.LIST.CONTAMINATIONREGISTER' | translate }}:
              </h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of contaminationRegisterExFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.PUBLIC.LIST.BUILDINGPERMIT' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of buildingPermitFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.TEXT' | translate }}:</h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.LIST.FLOORSPACETABEL' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of floorSpaceTableFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.LIST.CURVATUREANALYSIS' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of curvatureAnalysisFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.LIST.FLOORPLAN' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of floorPlanFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TECHNICAL.LIST.OBJECTDESCRIPTION' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of objectDescriptionFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TENANCY.TEXT' | translate }}:</h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TENANCY.LIST.RENTROLL' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of rentRollFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TENANCY.LIST.SAMPLERENTALAGREEMENT' | translate }}:
              </h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of sampleRentalAgreementFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.TENANCY.LIST.ANALYSISOFANCILLARYCOTS' | translate }}::
              </h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of analysisOfAncillaryCostsFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.REPORT.TEXT' | translate }}:</h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.REPORT.LIST.VALUATIONREPORTS' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of valuationReportFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.REPORT.LIST.ANNUALACCOUNTS' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of annualAccountsFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.REPORT.LIST.TECHNICALDUEDILIGENCEREPORT' | translate }}:
              </h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of techDueDiligenceFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.INSURANCE.TEXT' | translate }}:</h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.INSURANCE.LIST.AGREEMENT' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of insuranceAgreementFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.STRUCTURECHARTDOCUMENTS.TEXT' | translate }}:</h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.STRUCTURECHARTDOCUMENTS.LIST.ORGANIZATIONALSTRUCTURE' | translate }}:
              </h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of organizationStructureFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.LEGALREGULATORY' | translate }}:
        </h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">
                {{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.LEGALREGULATORY' | translate }}:
              </h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="
                    let file of legalAndRegulatoryFiles;
                    let i = index
                  ">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.OTHER' | translate }}:</h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.OTHER' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="let file of otherFiles; let i = index">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6" *ngIf="moreInfo != undefined">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.MOREINFO.TEXT' | translate }}:</h4>
      </div>

      <div class="card-body row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title">{{ 'ASSETSPAGE.LIST.DOCUMENTS.LIST.MOREINFO.LIST.ASSETSMOREINFO' | translate }}:</h5>
            </div>
            <div class="card-body d-flex flex-row">
              <div>
                <div class="file-list" *ngFor="let file of moreInfo; let i = index">
                  <b  [title] = "file.name" class="might-overflow">• {{ file.name }}</b>&nbsp;<button type="button" class="btn btn-soft-light btn-sm waves-effect waves-light" (click)="previewFile(content,file)" title="Preview"> <i [ngClass] = loadIconClass(file.name)></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{fileName}}</h4>
        <div type="btn" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
            style="cursor: pointer;">
            <span aria-hidden="true" style="font-size: 2.5em;">&times;</span>
        </div>
    </div>
    <div class="modal-body">
        <pdf-viewer *ngIf="pdfExcetnsions.includes(fileType)" [src]="src" [rotation]="0" [fit-to-page]="true"
                    [original-size]="true" [show-all]="true" style="display: block; height: 70vh;"></pdf-viewer>
        <div class="d-flex justify-content-center mb-3">
            <div class="p-2">
                <img *ngIf="imgExcetnsions.includes(fileType)" [src]="transform(src)"
                    style="display: inline; height: 70vh; justify-content: center;" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Ok</button>
    </div>
</ng-template>
</div>
