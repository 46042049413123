<!-- start page title -->
<app-pagetitle title="Form wizard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title mb-0">Icon Wizard</h4>
            </div>
            <div class="card-body">
                <aw-wizard [navBarLayout]="'large-empty-symbols'">
                    <aw-wizard-step>
                        <ng-template awWizardStepSymbol>
                            <i class="bx bx-list-ul"></i>
                        </ng-template>
                        <div class="row">
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-firstname-input" class="form-label">First name</label>
                                            <input type="text" class="form-control" id="basicpill-firstname-input">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-lastname-input" class="form-label">Last name</label>
                                            <input type="text" class="form-control" id="basicpill-lastname-input">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-phoneno-input" class="form-label">Phone</label>
                                            <input type="text" class="form-control" id="basicpill-phoneno-input">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-email-input" class="form-label">Email</label>
                                            <input type="email" class="form-control" id="basicpill-email-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="basicpill-address-input1" class="form-label">Address</label>
                                            <textarea id="basicpill-address-input1" class="form-control"
                                                rows="2"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <ul class="list-inline wizard mb-0">
                            <li class="next list-inline-item float-end"><button class="btn btn-primary"
                                    awNextStep>Next</button>
                            </li>
                        </ul>
                    </aw-wizard-step>

                    <aw-wizard-step>
                        <ng-template awWizardStepSymbol>
                            <i class="bx bx-book-bookmark"></i>
                        </ng-template>
                        <div class="row">
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-pancard-input" class="form-label">PAN Card</label>
                                            <input type="text" class="form-control" id="basicpill-pancard-input">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-vatno-input" class="form-label">VAT/TIN No.</label>
                                            <input type="text" class="form-control" id="basicpill-vatno-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-cstno-input" class="form-label">CST No.</label>
                                            <input type="text" class="form-control" id="basicpill-cstno-input">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-servicetax-input" class="form-label">Service Tax
                                                No.</label>
                                            <input type="text" class="form-control" id="basicpill-servicetax-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-companyuin-input" class="form-label">Company
                                                UIN</label>
                                            <input type="text" class="form-control" id="basicpill-companyuin-input">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-declaration-input"
                                                class="form-label">Declaration</label>
                                            <input type="text" class="form-control" id="basicpill-declaration-input">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <ul class="list-inline wizard mb-0">
                            <li class="previous list-inline-item"><button class="btn btn-primary"
                                    awPreviousStep>Previous</button>
                            </li>
                            <li class="next list-inline-item float-end"><button class="btn btn-primary"
                                    awNextStep>Next</button>
                            </li>
                        </ul>
                    </aw-wizard-step>

                    <aw-wizard-step>
                        <ng-template awWizardStepSymbol>
                            <i class="bx bxs-bank"></i>
                        </ng-template>
                        <div class="row justify-content-center">
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-namecard-input" class="form-label">Name on
                                                Card</label>
                                            <input type="text" class="form-control" id="basicpill-namecard-input">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label">Credit Card Type</label>
                                            <select class="form-select">
                                                <option selected>Select Card Type</option>
                                                <option value="AE">American Express</option>
                                                <option value="VI">Visa</option>
                                                <option value="MC">MasterCard</option>
                                                <option value="DI">Discover</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-cardno-input" class="form-label">Credit Card
                                                Number</label>
                                            <input type="text" class="form-control" id="basicpill-cardno-input">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-card-verification-input" class="form-label">Card
                                                Verification Number</label>
                                            <input type="text" class="form-control"
                                                id="basicpill-card-verification-input">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-expiration-input" class="form-label">Expiration
                                                Date</label>
                                            <input type="text" class="form-control" id="basicpill-expiration-input">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <ul class="list-inline wizard mb-0">
                            <li class="previous list-inline-item"><button class="btn btn-primary"
                                    awPreviousStep>Previous</button>
                            </li>
                        </ul>
                    </aw-wizard-step>
                </aw-wizard>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title mb-0">Title Wizard</h4>
            </div>
            <div class="card-body">
                <aw-wizard [navBarLayout]="'large-empty-symbols'">
                    <aw-wizard-step stepTitle="Seller Details">
                        <ng-template awWizardStepSymbol>
                            <i class="bx bx-list-ul"></i>
                        </ng-template>
                        <div class="row">
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-firstname-input1" class="form-label">First name</label>
                                            <input type="text" class="form-control" id="basicpill-firstname-input1">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-lastname-input1" class="form-label">Last name</label>
                                            <input type="text" class="form-control" id="basicpill-lastname-input1">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-phoneno-input1" class="form-label">Phone</label>
                                            <input type="text" class="form-control" id="basicpill-phoneno-input1">
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-email-input1" class="form-label">Email</label>
                                            <input type="email" class="form-control" id="basicpill-email-input1">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="mb-3">
                                            <label for="basicpill-address-input" class="form-label">Address</label>
                                            <textarea id="basicpill-address-input" class="form-control"
                                                rows="2"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <ul class="list-inline wizard mb-0">
                            <li class="next list-inline-item float-end"><button class="btn btn-primary"
                                    awNextStep>Next</button>
                            </li>
                        </ul>
                    </aw-wizard-step>

                    <aw-wizard-step stepTitle="Bank Details">
                        <ng-template awWizardStepSymbol>
                            <i class="bx bx-book-bookmark"></i>
                        </ng-template>
                        <div class="row">
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-pancard-input1" class="form-label">PAN Card</label>
                                            <input type="text" class="form-control" id="basicpill-pancard-input1">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-vatno-input1" class="form-label">VAT/TIN No.</label>
                                            <input type="text" class="form-control" id="basicpill-vatno-input1">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-cstno-input1" class="form-label">CST No.</label>
                                            <input type="text" class="form-control" id="basicpill-cstno-input1">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-servicetax-input1" class="form-label">Service Tax
                                                No.</label>
                                            <input type="text" class="form-control" id="basicpill-servicetax-input1">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-companyuin-input1" class="form-label">Company
                                                UIN</label>
                                            <input type="text" class="form-control" id="basicpill-companyuin-input1">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-declaration-input1"
                                                class="form-label">Declaration</label>
                                            <input type="text" class="form-control" id="basicpill-declaration-input1">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <ul class="list-inline wizard mb-0">
                            <li class="previous list-inline-item"><button class="btn btn-primary"
                                    awPreviousStep>Previous</button>
                            </li>
                            <li class="next list-inline-item float-end"><button class="btn btn-primary"
                                    awNextStep>Next</button>
                            </li>
                        </ul>
                    </aw-wizard-step>

                    <aw-wizard-step stepTitle="Confirm Detail">
                        <ng-template awWizardStepSymbol>
                            <i class="bx bxs-bank"></i>
                        </ng-template>
                        <div class="row justify-content-center">
                            <form>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-namecard-input1" class="form-label">Name on
                                                Card</label>
                                            <input type="text" class="form-control" id="basicpill-namecard-input1">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label class="form-label">Credit Card Type</label>
                                            <select class="form-select">
                                                <option selected>Select Card Type</option>
                                                <option value="AE">American Express</option>
                                                <option value="VI">Visa</option>
                                                <option value="MC">MasterCard</option>
                                                <option value="DI">Discover</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-cardno-input1" class="form-label">Credit Card
                                                Number</label>
                                            <input type="text" class="form-control" id="basicpill-cardno-input1">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-card-verification-input1" class="form-label">Card
                                                Verification Number</label>
                                            <input type="text" class="form-control"
                                                id="basicpill-card-verification-input1">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="mb-3">
                                            <label for="basicpill-expiration-input1" class="form-label">Expiration
                                                Date</label>
                                            <input type="text" class="form-control" id="basicpill-expiration-input1">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <ul class="list-inline wizard mb-0">
                            <li class="previous list-inline-item"><button class="btn btn-primary"
                                    awPreviousStep>Previous</button>
                            </li>
                        </ul>
                    </aw-wizard-step>
                </aw-wizard>
            </div>
            <!-- end card body -->
        </div>
        <!-- end card -->
    </div>
    <!-- end col -->
</div>
<!-- end row -->