<!-- start page title -->
<app-pagetitle title="File Upload" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Dropzone</h4>
                <p class="card-title-desc">Dropzone is an open source library
                    that provides drag’n’drop file uploads with image previews.
                </p>
            </div>
            <div class="card-body">

                <div>
                    <form>
                        <dropzone class="dropzone"></dropzone>
                    </form>
                </div>

                <div class="text-center mt-4">
                    <button type="button" class="btn btn-primary waves-effect waves-light">Send
                        Files</button>
                </div>
            </div>
        </div>
    </div> <!-- end col -->
</div> <!-- end row -->