<!-- start page title -->
<app-pagetitle title="User Grid" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row align-items-center">
    <div class="col-md-6">
        <div class="mb-3">
            <h5 class="card-title">Contact List <span class="text-muted fw-normal ms-2">({{totalRecords}})</span></h5>
        </div>
    </div>

    <div class="col-md-6">
        <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
            <div>
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/apps/user-list" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="List"><i class="bx bx-list-ul"></i></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active" routerLink="/apps/user-grid" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Grid"><i class="bx bx-grid-alt"></i></a>
                    </li>
                </ul>
            </div>
            <div>
                <a href="javascript:void(0);" class="btn btn-light" (click)="openModal(content)"><i
                        class="bx bx-plus me-1"></i> Add New</a>
            </div>
            <ng-template #content role="document" let-modal>
                <div class="modal-header">
                    <h5 class="modal-title mt-0">Add Contact</h5>
                    <button type="button" class="btn-close" aria-hidden="true"
                        (click)="modal.dismiss('Cross click')"></button>
                </div>
                <div class="modal-body">
                    <form (ngSubmit)="saveUser()" [formGroup]="userForm">
                        <div class="row">
                            <div class="col-12">
                                <div class="mb-3">
                                    <label class="control-label">Username</label>
                                    <input class="form-control" placeholder="Enter Username" type="text" name="name"
                                        formControlName="name"
                                        [ngClass]="{'is-invalid': submitted && form.name.errors}" />
                                    <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
                                        <span *ngIf="form.name.errors.required">Username is required.</span>
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <label class="control-label">Designation</label>
                                    <input class="form-control" placeholder="Enter designation" type="text"
                                        name="designation" formControlName="designation"
                                        [ngClass]="{'is-invalid': submitted && form.designation.errors}" />
                                    <div *ngIf="submitted && form.designation.errors" class="invalid-feedback">
                                        <span *ngIf="form.designation.errors.required">Designation is required.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-end">
                            <button type="submit" class="btn btn-success" id="btn-save-event">
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </ng-template>

            <div class="dropdown" ngbDropdown>
                <a class="btn btn-link text-muted py-1 font-size-16 shadow-none dropdown-toggle"
                    href="javascript:void(0);" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                    ngbDropdownToggle>
                    <i class="bx bx-dots-horizontal-rounded"></i>
                </a>

                <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <li><a class="dropdown-item" href="javascript:void(0);">Action</a></li>
                    <li><a class="dropdown-item" href="javascript:void(0);">Another action</a></li>
                    <li><a class="dropdown-item" href="javascript:void(0);">Something else here</a></li>
                </ul>
            </div>
        </div>

    </div>
</div>
<!-- end row -->

<div class="row">
    <div class="col-xl-3 col-sm-6" *ngFor="let data of userGridData">
        <div class="card text-center">
            <div class="card-body">
                <div class="dropdown text-end" ngbDropdown>
                    <a class="text-muted dropdown-toggle font-size-16" href="javascript:void(0);" role="button"
                        data-bs-toggle="dropdown" aria-haspopup="true" ngbDropdownToggle>
                        <i class="bx bx-dots-horizontal-rounded"></i>
                    </a>

                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">Edit</a>
                        <a class="dropdown-item" href="javascript:void(0);">Action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Remove</a>
                    </div>
                </div>

                <div class="avatar-xl mx-auto mb-4" *ngIf="!data.image">
                    <div class="avatar-title bg-soft-light text-light display-4 m-0 rounded-circle">
                        <i class="bx bxs-user-circle"></i>
                    </div>
                </div>
                <div class="mx-auto mb-4" *ngIf="data.image">
                    <img src="{{data.image}}" alt="" class="avatar-xl rounded-circle img-thumbnail">
                </div>
                <h5 class="font-size-16 mb-1"><a href="javascript:void(0);" class="text-dark">{{data.name}}</a></h5>
                <p class="text-muted mb-2">{{data.designation}}</p>

            </div>

            <div class="btn-group" role="group">
                <button type="button" class="btn btn-outline-light text-truncate"><i class="uil uil-user me-1"></i>
                    Profile</button>
                <button type="button" class="btn btn-outline-light text-truncate"><i
                        class="uil uil-envelope-alt me-1"></i> Message</button>

            </div>
        </div>
        <!-- end card -->
    </div>
</div>
<!-- end row -->

<div class="row justify-content-md-between align-items-md-center mt-2">
    <!-- <div class="col-sm-12 col-md-5">
        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
            Showing {{startIndex}} - {{endIndex}} of {{totalRecords}}
        </div>
    </div> -->
    <!-- Pagination -->
    <!-- <div class="col-5">
        <div class="btn-group float-end">
            <ngb-pagination class="pagination-rounded" [collectionSize]="totalRecords"
                (pageChange)="onPageChange($event)" [(page)]="page" [pageSize]="pageSize">
            </ngb-pagination>
        </div>
    </div> -->
    <!-- End Pagination -->
</div>