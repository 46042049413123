<div class="row mb-mt-5">
    <div class="col-sm-12 col-md-6">
        <div class="dataTables_length" id="tickets-table_length"><label class="d-inline-flex align-items-center">Show
                <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="socialEconomicsService.pageSize" class="form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                </select> entries</label></div>
    </div>

</div>
<table class="table align-middle datatable dt-responsive table-check nowrap datatables">
    <thead>
        <tr>
            <th class="noSort">
                Pin No.
            </th>
            <th socialeconomicsSortable="region" (sort)="onSort($event)">
                {{'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.REGION' | translate }}
            </th>
            <th socialeconomicsSortable="number_of_inhabitants" (sort)="onSort($event)">
                {{'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.INHABITANTS' | translate }}
            </th>
            <th socialeconomicsSortable="expected_population_growth" (sort)="onSort($event)">
                {{'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.POPULATIONGROWTH' | translate }}
            </th>
            <th socialeconomicsSortable="commuters" (sort)="onSort($event)">
                {{'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.COMMUTERS' | translate }}
            </th>
            <th socialeconomicsSortable="centrality" (sort)="onSort($event)">
                {{'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.RETAILCENTRALITY' | translate }}
            </th>
            <th socialeconomicsSortable="purchasing_power" (sort)="onSort($event)">
                {{'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.PURCHASINGPOWER' | translate }}
            </th>
            <th class="noSort">
                {{'ASSETSPAGE.LIST.ACTIONS' | translate }}
            </th>
        </tr>
    </thead>
    <tbody *ngFor="let table of tables$ | async;let i=index">
        <tr (mouseenter)="showPin(table.region.toString(),table.administrative_level)" [ngClass]="{'error': socialEconomicsControls[i] && socialEconomicsControls[i].status == 'INVALID'}">
            <td>
                {{i + 1}}
            </td>
            <td>
                <ngb-highlight [result]="table.region.toString()" [term]="socialEconomicsService.searchTerm">
                </ngb-highlight>
            </td>
            <td>
                <ngb-highlight [result]="table.number_of_inhabitants.toString() | number: '.0'"
                    [term]="socialEconomicsService.searchTerm">
                </ngb-highlight>
            </td>
            <td>
                <ngb-highlight [result]="table.expected_population_growth.toString() | number: '.0'" [term]="socialEconomicsService.searchTerm">
                </ngb-highlight>
            </td>
            <td>
                <ngb-highlight [result]="table.commuters.toString() | number: '.0'" [term]="socialEconomicsService.searchTerm">
                </ngb-highlight>
            </td>
            <td>
                <ngb-highlight [result]="table.centrality.toString() | number: '.0'" [term]="socialEconomicsService.searchTerm">
                </ngb-highlight>
            </td>
            <td>
                <ngb-highlight [result]="table.purchasing_power.toString() | number: '.0'" [term]="socialEconomicsService.searchTerm">
                </ngb-highlight>    
            </td>
            <td>
                <div class="btn-group" role="group" ngbDropdown >
                    <button id="btnGroupVerticalDrop1" type="button"
                      class="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle"
                      data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                      <i class="bx bx-dots-horizontal-rounded"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupVerticalDrop1" ngbDropdownMenu>
                        <button class="dropdown-item" (click)="openModal(content, table.region.toString(), table.administrative_level)" type="button"><i class="bx bx-edit-alt font-size-20 align-middle"></i>&nbsp;&nbsp;{{'ASSETSPAGE.LIST.UPDATE' | translate }}</button>
                        <button class="dropdown-item" type="button" (click)="deletePins(table.region.toString(), table.administrative_level)"><i class="bx bx-x-circle font-size-20 align-middle"></i>&nbsp;&nbsp;{{ 'ASSETSPAGE.LIST.REMOVE' | translate }}</button>
                    </div>
                </div>
            </td>
        </tr>
   
    </tbody>
</table>
<div *ngIf="socialEconomicsArray.status === 'INVALID' " class="invalid-feedback d-block">Fields are required</div>
<div class="row justify-content-md-between align-items-md-center mt-2">
    <div class="col-sm-12 col-md-5">
        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
            Showing
            {{socialEconomicsService.startIndex}} to
            {{socialEconomicsService.endIndex}} of {{socialEconomicsService.totalRecords}}
            entries
        </div>
    </div>
    <!-- Pagination -->
    <div class="col-sm-12 col-md-5">
        <div class="text-md-right float-md-end pagination-rounded">
            <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="socialEconomicsService.page"
                [pageSize]="socialEconomicsService.pageSize"
                (pageChange)="socialEconomicsService.onPageChange(socialEconomicsService.page)"
                [maxSize]="3"
                [rotate]="true">
            </ngb-pagination>
        </div>
    </div>
    <!-- End Pagination -->
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ 'ASSETSPAGE.LIST.SOCIOECONOMICS.TEXT' | translate }}</h4>
        <div type="btn" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"
            style="cursor: pointer;">
            <span aria-hidden="true" style="font-size: 2.5em;">&times;</span>
        </div>
    </div>
    <div class="modal-body">
        <div class="row" style="margin-top: 40px;" [formGroup] = "socialEconomics">
            <div class="row">
                <div class="col-md-6">
                    <div class="row mb-3 g-0">
                        <label for="basicpill-phoneno-input1" class="form-label">{{
                            'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.REGION' |
                            translate }}:</label>
                        <input type="text" class="form-control" id="construction_year" formControlName="region" (change)="enterInfoIfValid(socialEconomics)"
                            [ngClass]="{'is-invalid': socialEconomics.get('region')?.errors}">
                        <div *ngIf="socialEconomics.get('region')?.errors"
                            class="invalid-feedback">
                            Region is required
                        </div>
                    </div>
                    <div class="row mb-3 g-0">
                        <label for="basicpill-phoneno-input1" class="form-label">{{
                            'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.INHABITANTS'
                            | translate }}:</label>
                        <input class="form-control" id="refurbishment_year"
                            formControlName="number_of_inhabitants" mask="separator.2"
                            thousandSeparator="{{separator}}" (change)="enterInfoIfValid(socialEconomics)"
                            [ngClass]="{'is-invalid': socialEconomics.get('number_of_inhabitants')?.errors}">
                        <div *ngIf="socialEconomics.get('number_of_inhabitants')?.errors"
                            class="invalid-feedback">
                            Number of Inhabitants is required
                        </div>
                    </div>
                    <div class="row mb-3 g-0">
                        <label for="basicpill-phoneno-input1" class="form-label">{{
                            'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.POPULATIONGROWTH' | translate }}:</label>
                        <div class="input-group">
                            <input class="form-control" id="refurbishment_year"
                                formControlName="expected_population_growth" mask="separator.2"
                                thousandSeparator="{{separator}}" (change)="enterInfoIfValid(socialEconomics)"
                                [ngClass]="{'is-invalid': socialEconomics.get('expected_population_growth')?.errors}">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="validationTooltipOtherPrepend">%</span>
                            </div>
                            <div *ngIf="socialEconomics.get('expected_population_growth')?.errors"
                                class="invalid-feedback">
                                Expected population growth is required
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col-md-6 columns_styling">
                    <div class="row mb-3 g-0">
                        <label for="basicpill-phoneno-input1" class="form-label">{{
                            'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.COMMUTERS'
                            |
                            translate }}:</label>
                        <input class="form-control" id="construction_year"
                            formControlName="commuters" mask="separator.2"
                            thousandSeparator="{{separator}}" (change)="enterInfoIfValid(socialEconomics)"
                            [ngClass]="{'is-invalid': socialEconomics.get('commuters')?.errors}">
                        <div *ngIf="socialEconomics.get('commuters')?.errors"
                            class="invalid-feedback">
                            Net number of commuters is required
                        </div>
                    </div>
                    <div class="row mb-3 g-0">
                        <label for="basicpill-phoneno-input1" class="form-label">{{
                            'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.RETAILCENTRALITY' | translate }}:</label>
                        <input class="form-control" id="refurbishment_year" formControlName="centrality"
                            mask="separator.2" thousandSeparator="{{separator}}" (change)="enterInfoIfValid(socialEconomics)"
                            [ngClass]="{'is-invalid': socialEconomics.get('centrality')?.errors}">
                        <div *ngIf="socialEconomics.get('centrality')?.errors"
                            class="invalid-feedback">
                            Retail centrality is required
                        </div>
                    </div>
                    <div class="row mb-3 g-0">
                        <label for="basicpill-phoneno-input1" class="form-label">{{
                            'ASSETSPAGE.LIST.SOCIOECONOMICS.LIST.PURCHASINGPOWER' | translate }}:</label>
                        <input class="form-control" id="refurbishment_year"
                            formControlName="purchasing_power" mask="separator.2"
                            thousandSeparator="{{separator}}" (change)="enterInfoIfValid(socialEconomics)"
                            [ngClass]="{'is-invalid': socialEconomics.get('purchasing_power')?.errors}">
                        <div *ngIf="socialEconomics.get('purchasing_power')?.errors"
                            class="invalid-feedback">
                            Retail purchasing power is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Ok</button>
    </div>
</ng-template>