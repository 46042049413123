<!-- start page title -->
<app-pagetitle title="Modals" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Modals Examples</h4>
                <p class="card-title-desc">Modals are streamlined, but flexible
                    dialog prompts powered by Bootstrap NgbModal. They support a number of use cases
                    from user notification to completely custom content and feature a
                    handful of helpful subcomponents, sizes, and more.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="modal bs-example-modal" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Modal title</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>One fine body&hellip;</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary">Save changes</button>
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div><!-- /.modal -->
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Default Modal</h4>
                <p class="card-title-desc">Toggle a working modal demo by clicking the button below. It will slide down
                    and fade in from the top of the page.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div>
                    <button type="button" class="btn btn-primary waves-effect waves-light" data-bs-toggle="modal"
                        data-bs-target="#myModal" (click)="openModal(content)">Standard modal</button>

                    <!-- sample modal content -->
                    <ng-template #content role="document" let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Default Modal Heading</h5>
                            <button type="button" class="btn-close" aria-hidden="true"
                                (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body">
                            <h5>Overflowing text to show scroll behavior</h5>
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                            <p>Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Vivamus sagittis lacus vel
                                augue laoreet rutrum faucibus dolor auctor.</p>
                            <p>Aenean lacinia bibendum nulla sed consectetur.
                                Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Donec sed odio dui. Donec
                                ullamcorper nulla non metus auctor
                                fringilla.</p>
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                            <p>Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Vivamus sagittis lacus vel
                                augue laoreet rutrum faucibus dolor auctor.</p>
                            <p>Aenean lacinia bibendum nulla sed consectetur.
                                Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Donec sed odio dui. Donec
                                ullamcorper nulla non metus auctor
                                fringilla.</p>
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                                (click)="modal.close('Close click')">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                    </ng-template>
                </div> <!-- end preview-->

            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Fullscreen Modal</h4>
                <p class="card-title-desc">Another override is the option to pop up a modal that covers the user
                    viewport, available via modifier classes that are placed on a <code>.modal-dialog</code>.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div>
                    <button type="button" class="btn btn-primary waves-effect waves-light" data-bs-toggle="modal"
                        data-bs-target="#exampleModalFullscreen" (click)="fullModal(fullDataModal)">Fullscreen Modal
                        Heading</button>

                    <!--  Full Screen  modal example -->
                    <ng-template #fullDataModal let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Fullscreen Modal</h5>
                            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"
                                aria-hidden="true"></button>
                        </div>
                        <div class="modal-body">
                            <h5>Overflowing text to show scroll behavior</h5>
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                            <p>Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Vivamus sagittis lacus vel
                                augue laoreet rutrum faucibus dolor auctor.</p>
                            <p>Aenean lacinia bibendum nulla sed consectetur.
                                Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Donec sed odio dui. Donec
                                ullamcorper nulla non metus auctor
                                fringilla.</p>
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                            <p>Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Vivamus sagittis lacus vel
                                augue laoreet rutrum faucibus dolor auctor.</p>
                            <p>Aenean lacinia bibendum nulla sed consectetur.
                                Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Donec sed odio dui. Donec
                                ullamcorper nulla non metus auctor
                                fringilla.</p>
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                                (click)="modal.close('Close click')">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div>
                    </ng-template>
                </div> <!-- end preview-->
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Optional Sizes</h4>
                <p class="card-title-desc">Modals have three optional sizes, available via <code>size</code>. (e.g. size="lg")</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div class="d-flex flex-wrap gap-2">
                    <!-- Extra Large modal button -->
                    <button type="button" class="btn btn-primary waves-effect waves-light" data-bs-toggle="modal"
                        data-bs-target=".bs-example-modal-xl" (click)="extraLarge(exlargeModal)">Extra large
                        modal</button>

                    <!-- Large modal button -->
                    <button type="button" class="btn btn-light waves-effect" data-bs-toggle="modal"
                        data-bs-target=".bs-example-modal-lg" (click)="largeModal(largeDataModal)">Large modal</button>

                    <!-- Small modal button -->
                    <button type="button" class="btn btn-success waves-effect waves-light" data-bs-toggle="modal"
                        data-bs-target=".bs-example-modal-sm" (click)="smallModal(smallDataModal)">Small modal</button>
                </div>

                <div>
                    <!--  Extra Large modal example -->
                    <ng-template #exlargeModal let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Extra large modal</h5>
                            <button type="button" class="btn-close" aria-label="Close"
                                (click)="modal.dismiss('Close click')">
                            </button>
                        </div>
                        <div class="modal-body">
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                            <p>Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Vivamus sagittis lacus vel
                                augue laoreet rutrum faucibus dolor auctor.</p>
                            <p class="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Donec sed odio dui. Donec
                                ullamcorper nulla non metus auctor
                                fringilla.</p>
                        </div>
                    </ng-template>

                    <!--  Large modal example -->
                    <ng-template #largeDataModal let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Large modal</h5>
                            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"
                                aria-hidden="true"></button>
                        </div>
                        <div class="modal-body">
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                            <p>Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Vivamus sagittis lacus vel
                                augue laoreet rutrum faucibus dolor auctor.</p>
                            <p class="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Donec sed odio dui. Donec
                                ullamcorper nulla non metus auctor
                                fringilla.</p>
                        </div>
                    </ng-template><!-- /.modal -->

                    <!--  Small modal example -->
                    <ng-template #smallDataModal let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Small modal</h5>
                            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"
                                aria-hidden="true"></button>
                        </div>
                        <div class="modal-body">
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                            <p>Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Vivamus sagittis lacus vel
                                augue laoreet rutrum faucibus dolor auctor.</p>
                            <p class="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Donec sed odio dui. Donec
                                ullamcorper nulla non metus auctor
                                fringilla.</p>
                        </div>
                    </ng-template><!-- /.modal -->

                </div>
            </div><!-- end card-body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title">Vertically Centered</h5>
                <p class="card-title-desc">Add <code>centered</code> option to
                    vertically center the modal.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div>
                    <!-- center modal -->
                    <button type="button" class="btn btn-primary waves-effect waves-light" data-bs-toggle="modal"
                        data-bs-target=".bs-example-modal-center" (click)="centerModal(centerDataModal)">Center
                        modal</button>

                    <!--  Center modal example -->
                    <ng-template #centerDataModal let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Center modal</h5>
                            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"
                                aria-hidden="true"></button>
                        </div>
                        <div class="modal-body">
                            <p>Cras mattis consectetur purus sit amet fermentum.
                                Cras justo odio, dapibus ac facilisis in,
                                egestas eget quam. Morbi leo risus, porta ac
                                consectetur ac, vestibulum at eros.</p>
                            <p>Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Vivamus sagittis lacus vel
                                augue laoreet rutrum faucibus dolor auctor.</p>
                            <p class="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                Praesent commodo cursus magna, vel scelerisque
                                nisl consectetur et. Donec sed odio dui. Donec
                                ullamcorper nulla non metus auctor
                                fringilla.</p>
                        </div>
                    </ng-template><!-- /.modal -->
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->

<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title">Scrollable Modal</h5>
                <p class="card-title-desc">You can also create a scrollable modal that allows scroll the modal body by
                    adding <code>scrollable</code> option.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <!-- Scrollable modal button -->
                <button type="button" class="btn btn-primary waves-effect waves-light" data-bs-toggle="modal"
                    data-bs-target="#exampleModalScrollable" (click)="scrollModal(scrollDataModal)">Scrollable
                    modal</button>

                <!-- Scrollable modal -->
                <ng-template #scrollDataModal let-modal>
                    <div class="modal-header">
                        <h5 class="modal-title mt-0">Scrollable Modal</h5>
                        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"
                            aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                            odio, dapibus ac facilisis in, egestas
                            eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                            at eros.</p>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                            et. Vivamus sagittis lacus vel augue
                            laoreet rutrum faucibus dolor auctor.</p>
                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                            cursus magna, vel scelerisque nisl
                            consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                            metus auctor fringilla.</p>
                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                            odio, dapibus ac facilisis in, egestas
                            eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                            at eros.</p>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                            et. Vivamus sagittis lacus vel augue
                            laoreet rutrum faucibus dolor auctor.</p>
                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                            cursus magna, vel scelerisque nisl
                            consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                            metus auctor fringilla.</p>
                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                            odio, dapibus ac facilisis in, egestas
                            eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                            at eros.</p>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                            et. Vivamus sagittis lacus vel augue
                            laoreet rutrum faucibus dolor auctor.</p>
                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                            cursus magna, vel scelerisque nisl
                            consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                            metus auctor fringilla.</p>
                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                            odio, dapibus ac facilisis in, egestas
                            eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                            at eros.</p>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                            et. Vivamus sagittis lacus vel augue
                            laoreet rutrum faucibus dolor auctor.</p>
                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                            cursus magna, vel scelerisque nisl
                            consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                            metus auctor fringilla.</p>
                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                            odio, dapibus ac facilisis in, egestas
                            eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                            at eros.</p>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                            et. Vivamus sagittis lacus vel augue
                            laoreet rutrum faucibus dolor auctor.</p>
                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                            cursus magna, vel scelerisque nisl
                            consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                            metus auctor fringilla.</p>
                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                            odio, dapibus ac facilisis in, egestas
                            eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                            at eros.</p>
                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                            et. Vivamus sagittis lacus vel augue
                            laoreet rutrum faucibus dolor auctor.</p>
                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                            cursus magna, vel scelerisque nisl
                            consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                            metus auctor fringilla.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"
                            (click)="modal.close('Close click')">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                    </div>
                </ng-template><!-- /.modal -->
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->

    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title">Static Backdrop</h5>
                <p class="card-title-desc">When backdrop is set to static, the modal will not close when clicking
                    outside it. Click the button below to try it.</p>
            </div><!-- end card header -->

            <div class="card-body">
                <div>
                    <!-- Static Backdrop modal Button -->
                    <button type="button" class="btn btn-primary waves-effect waves-light" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop" (click)="staticModal(staticDataModal)">
                        Static backdrop modal
                    </button>

                    <!-- Static Backdrop Modal -->
                    <ng-template #staticDataModal let-modal>
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Center modal</h5>
                            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')"
                                aria-hidden="true"></button>
                        </div>
                        <div class="modal-body">
                            <p>I will not close if you click outside me. Don't even try to
                                press escape key.</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light"
                                (click)="modal.close('Close click')">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                        </div>
                    </ng-template>
                </div>
            </div><!-- end card body -->
        </div><!-- end card -->
    </div><!-- end col -->
</div><!-- end row -->