import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-documents-section',
  templateUrl: './documents-section.component.html',
  styleUrls: ['./documents-section.component.scss'],
})
export class DocumentsSectionComponent implements OnInit {
  @Input() ndaFiles: any[] = [];
  //public documents
  @Input() cadastralMapsFiles: any[] = [];
  @Input() landRegistrationExcerptFiles: any[] = [];
  @Input() contaminationRegisterExFiles: any[] = [];
  @Input() buildingPermitFiles: any[] = [];
  //tecnical documents
  @Input() floorSpaceTableFiles: any[] = [];
  @Input() curvatureAnalysisFiles: any[] = [];
  @Input() floorPlanFiles: any[] = [];
  @Input() objectDescriptionFiles: any[] = [];
  //tenancy documents
  @Input() rentRollFiles: any[] = [];
  @Input() sampleRentalAgreementFiles: any[] = [];
  @Input() analysisOfAncillaryCostsFiles: any[] = [];
  //Report documents
  @Input() valuationReportFiles: any[] = [];
  @Input() annualAccountsFiles: any[] = [];
  @Input() techDueDiligenceFiles: any[] = [];
  //Insurance documents
  @Input() insuranceAgreementFiles: any[] = [];
  //Structure chart documents
  @Input() organizationStructureFiles: any[] = [];
  //Legal and regulatroy documents
  @Input() legalAndRegulatoryFiles: any[] = [];
  //Other documents
  @Input() otherFiles: any[] = [];
  @Input() moreInfo!: any[];

  src!:string;
  fileName!:string;
  fileType!:string;

  docExcetnsions:Array<string> = ["doc","dot", "docx", "dotx", "docm", "dotm"];
  xslExcetnsions:Array<string> = ["xls","xlt", "xla", "xlsx", "xltx", "xlsm", "xltm", "xlam", "xlsb"];
  pttExcetnsions:Array<string> = ["ppt","pot", "pps", "ppa", "pptx", "potx", "ppsx", "ppam", "pptm", "potm", "ppsm"];
  imgExcetnsions:Array<string> = ["png","jpg", "jpeg"];
  pdfExcetnsions:Array<string> = ["pdf"];

  constructor(private modalService:NgbModal,private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}


  public previewFile(content: any,file:any){
    let fileURL = URL.createObjectURL(file.file);
    this.src = fileURL;
    this.fileName = file.name;
    this.fileType = file.name.split(".")[file.name.split(".").length - 1];

    this.modalService
      .open(content, {
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      })
      .result.then(
        (result) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  transform(url:any):SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public loadIconClass(path:string){
    var splitted = path.split(".");
    let extension = splitted[splitted.length - 1];
    if (this.docExcetnsions.includes(extension)){
      return "far fa-file-word";
    } else if (this.xslExcetnsions.includes(extension)){
      return "far fa-file-excel";
    } else if (this.pttExcetnsions.includes(extension)){
      return "far fa-file-powerpoint";
    } else if (this.imgExcetnsions.includes(extension)){
      return "far fa-file-image";
    } else {
      return "far fa-file-pdf";
    }
  }
}
